const defaultState = {
	// general state
	busy: false,
	serverError: false,
	pendingChanges: false,

	// current session info
	info: null,

	// did we (try to) create a session?
	created: false,

	// login
	loginBusy: false,

	// kiosk
	switchKioskUserBusy: false,

	// profile
	profile: null,

	// reset password
	resetPasswordUpdateSuccess: false,
	forgotPasswordRequestSuccess: false,

	// api access
	apiAccess: null,
};

const session = (state = defaultState, action) => {
	switch (action.type) {
		case 'SESSION_ERROR':
			return {
				...state,
				busy: false,
				loginBusy: false,
				serverError: action.serverError
			}

		case 'SESSION_CREATE_GUEST':
		case 'SESSION_REFRESH_INFO':
		case 'SESSION_ACCEPT_TERMS_AND_CONDITIONS':
		case 'SESSION_SWITCH_COMPANY':
		case 'SESSION_UPDATE_VISUAL_SIGNATURE':
		case 'SESSION_UPDATE_VISUAL_PARAPH':
		case 'SESSION_FETCH_USER_PROFILE':
		case 'SESSION_REGISTER_GUEST':
		case 'SESSION_FORGET_ITSME_IDENTITY':
		case 'SESSION_FETCH_API_ACCESS':
		case 'SESSION_UPDATE_API_ACCESS':
			return {
				...state,
				busy: true,
				serverError: null,
				forgotPasswordRequestSuccess: false,
			}

		case 'SESSION_CHECK':
			return {
				...state,
				busy: true,
				serverError: null,
				checked: false,
				created: false,
			}

		case 'SESSION_CHECK_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data,
				guestPath: action.guestPath,
				checked: true,
				created: action.created,
			}

		case 'SESSION_SWITCH_COMPANY_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data,
				created: true,
			}

		case 'SESSION_SWITCH_KIOSK_USER':
			return {
				...state,
				busy: true,
				switchKioskUserBusy: true
			}

		case 'SESSION_REFRESH_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				info: {
					...state.info,
					...action.data
				}
			}

		case 'SESSION_SWITCH_KIOSK_USER_SUCCESS':
			return {
				...state,
				busy: false,
				switchKioskUserBusy: false,
				serverError: null,
				info: {
					...state.info,
					kioskUserId: action.data.kioskUserId,
					kioskUserFullName: action.data.kioskUserFullName,
					kioskUserVisualSignature: action.data.kioskUserVisualSignature,
					kioskUserVisualParaph: action.data.kioskUserVisualParaph,
				}
			}

		case 'SESSION_UPDATE_VISUAL_SIGNATURE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				info: {
					...state.info,
					visualSignature: action.visualSignature
				}
			}

		case 'SESSION_UPDATE_VISUAL_PARAPH_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				info: {
					...state.info,
					visualParaph: action.visualParaph
				}
			}

		case 'SESSION_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				info: {
					...state.info,
					termsAndConditionsAccepted: true
				}
			}

		case 'SESSION_UPDATE_COMPANY_STYLE':
			return {
				...state,
				info: {
					...state.info,
					companyStyle: action.companyStyle
				}
			}

		case 'SESSION_UPDATE_COMPANY_SUPPORT':
			return {
				...state,
				info: {
					...state.info,
					companySupportEmailAddress: action.companySupport.emailAddress
				}
			}

		case 'SESSION_UPDATE_USER_PROFILE_DATA': {
			const profile = {
				...state.profile
			};
			profile[action.key] = action.value;
			return {
				...state,
				profile,
				pendingChanges: true,
			}
		}

		case 'SESSION_UPDATE_USER_PROFILE':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'SESSION_FETCH_USER_PROFILE_SUCCESS':
		case 'SESSION_UPDATE_USER_PROFILE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				pendingChanges: false,
				profile: action.profile,
				info: {
					...state.info,
					userFullName: (!!action.profile.firstName && !!action.profile.lastName) ? action.profile.firstName + ' ' + action.profile.lastName : state.info.userFullName,
					language: action.profile.language || state.info.language,
					capacities: action.profile.capacities || state.info.capacities,
					defaultRemark: action.profile.defaultRemarkVisualSignature || state.info.defaultRemark,
					defaultRowCount: action.profile.defaultRowsPerTablePage || state.info.defaultRowCount
				}
			}

		case 'SESSION_FORGET_ITSME_IDENTITY_SUCCES':
			return {
				...state,
				busy: false,
				serverError: null,
				...(state.profile && {
					...state.profile,
					itsmeUserCode: null
				})
			}

		case 'SESSION_RESET_PASSWORD_UPDATE':
			return {
				...state,
				busy: true,
				serverError: null,
				resetPasswordRequestSuccess: false
			}

		case 'SESSION_RESET_PASSWORD_UPDATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				resetPasswordRequestSuccess: true
			}

		case 'SESSION_FORGOT_PASSWORD':
			return {
				...state,
				busy: true,
				serverError: null,
				forgotPasswordRequestSuccess: false
			}

		case 'SESSION_FORGOT_PASSWORD_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				forgotPasswordRequestSuccess: true
			}

		case 'SESSION_DESTROY':
			return {
				...state,
				info: null,
				created: false,
			}

		case 'SESSION_FETCH_COMPANY_INFO':
			return {
				...state,
				busy: true,
				serverError: null,
				loginInfo: null,
				forgotPasswordRequestSuccess: false,
			}

		case 'SESSION_FETCH_COMPANY_INFO_SUCCESS':
			return {
				...state,
				loginInfo: action.data,
				serverError: null,
				busy: false
			}

		case 'SESSION_UPDATE_USER_PASSWORD':
			return {
				...state,
				serverError: null,
				busy: false
			}

		case 'SESSION_LOGIN_PASSWORD':
		case 'SESSION_LOGIN_EID':
			return {
				...state,
				loginBusy: true,
				serverError: null
			}

		case 'SESSION_LOGIN_SUCCESS':
			return {
				...state,
				loginBusy: false,
				serverError: null
			}

		case 'SESSION_FETCH_API_ACCESS_SUCCESS':
		case 'SESSION_UPDATE_API_ACCESS_SUCCESS':
			return {
				...state,
				serverError: null,
				busy: false,
				apiAccess: action.data,
				pendingChanges: false,
			}

		case 'EID_WRONG_PIN':
		case 'EID_PIN_TIMEOUT_OR_CANCEL':
			return {
				...state,
				busy: false,
				loginBusy: false
			}

		default:
			return state;
	}
}

export default session;
