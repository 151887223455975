import React, {Component, Fragment, useCallback} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {
	Alert,
	Avatar,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ViewIcon from "@mui/icons-material/Visibility";
import PendingIcon from "@mui/icons-material/MoreHoriz";
import ValidIcon from "@mui/icons-material/Done";
import InvalidIcon from "@mui/icons-material/Warning";
import VerifyIcon from "@mui/icons-material/DomainVerification";
import EditIcon from "@mui/icons-material/Edit";
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import EmailGenericFieldsDialog from "../mail/EmailGenericFieldsDialog";
import EmailTemplatesFieldsDialog from "../mail/EmailTemplatesFieldsDialog";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import PendingChangesDialog from "../common/PendingChangesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyEmailServerSettingsDialog from "./CompanyEmailServerSettingsDialog";
import {v4 as uuidv4} from "uuid";
import AddIcon from "@mui/icons-material/Add";


const EmailCustomSettingsColorComponent = ({busy, title, color, defaultColor, onChange}) => {

	const {t} = useTranslation();
	const custom = !!color && color.startsWith('#');
	const onLocalChange = useCallback((e) => {
		onChange(e.target.value === 'CUSTOM' ? defaultColor : e.target.value);
	}, [onChange, defaultColor]);

	return <Box>
		<Typography sx={{fontWeight: 700, mt: 2}}>{title}</Typography>
		<RadioGroup
			defaultValue="DEFAULT"
			onChange={onLocalChange}
			sx={{display: 'inline-flex'}}
		>
			<FormControlLabel value="DEFAULT"
							  checked={!color || 'DEFAULT' === color}
							  disabled={busy}
							  control={<Radio/>}
							  label={t('company.emailSettingsColorDefault')}/>
			<FormControlLabel value="COMPANY_MAIN"
							  checked={'COMPANY_MAIN' === color}
							  control={<Radio/>}
							  disabled={busy}
							  label={t('company.emailSettingsColorCompanyMain')}/>
			<FormControlLabel value="COMPANY_SECONDARY"
							  checked={'COMPANY_SECONDARY' === color}
							  control={<Radio/>}
							  disabled={busy}
							  label={t('company.emailSettingsColorCompanySecondary')}/>
			<Box>
				<FormControlLabel value="CUSTOM"
								  checked={custom}
								  control={<Radio/>}
								  disabled={busy}
								  label={t('company.emailSettingsColorCustom')}/>
				<TextField
					variant="outlined"
					size="small"
					type="color"
					value={custom ? color : defaultColor}
					onChange={onLocalChange}
					sx={{ml: 1, width: 50, ...(!custom && {opacity: 0.2})}}
					disabled={busy || !custom}
					autoComplete="off"
				/>
			</Box>
		</RadioGroup>
	</Box>

};

const AuthenticatedDomainsDnsRecordsDialog = ({emailAuthenticatedDomains, open, onClose}) => {

	const {t} = useTranslation();

	const onLocalClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			onClose(false);
		}
	}

	const dataGridColumns = [
		{
			field: 'type',
			headerName: t('company.emailSettingsAuthenticatedDomainRecordType'),
			editable: false,
		},
		{
			field: 'name',
			headerName: t('company.emailSettingsAuthenticatedDomainRecordName'),
			editable: false,
			flex: 1
		},
		{
			field: 'aliasFor',
			headerName: t('company.emailSettingsAuthenticatedDomainRecordAliasFor'),
			editable: false,
			flex: 1
		},
	];

	return <Dialog open={open} onClose={onLocalClose} maxWidth="md" fullWidth>
		<DialogTitle>{t('company.emailSettingsAuthenticatedDomainDialog')}</DialogTitle>
		<DialogContent>
			{!emailAuthenticatedDomains && <LoadingComponent/>}
			{!!emailAuthenticatedDomains && emailAuthenticatedDomains.map(authenticatedDomain => {
				return <Box key={authenticatedDomain.domain} sx={{mb: 2}}>
					<Typography sx={{mb: 1}}>{t('company.emailSettingsAuthenticatedDomainDomain') + ': ' + authenticatedDomain.domain}</Typography>
					<DataGrid autoHeight
							  disableColumnSelector
							  hideFooter
							  columns={dataGridColumns}

							  loading={!authenticatedDomain.dnsRecords}

							  disableColumnFilter
							  disableRowSelectionOnClick

							  rows={authenticatedDomain.dnsRecords.map((record, index) => {
								  return {...record, id: index}
							  })}
							  rowCount={!!authenticatedDomain.dnsRecords ? authenticatedDomain.dnsRecords.length : 0}
							  density="compact"/>

					<Box sx={{mt: 2}}>
						<Typography>{t('company.emailSettingsAuthenticatedDomainDmarcRecord')}</Typography>
						<Alert severity="info"><Typography variant="caption">v=DMARC1; p=none;</Typography></Alert>
					</Box>
				</Box>
			})}

			<DialogActions>
				<Button onClick={onClose}
						id="btn-dialog-ok"
				>
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}

const AuthenticatedDomainsDnsValidationDialog = ({validationSummaryList, open, onClose}) => {

	const {t} = useTranslation();

	const onLocalClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			onClose(false);
		}
	}
	const dataGridColumns = [
		{
			field: 'recordName',
			headerName: t('company.emailSettingsAuthenticatedDomainDnsRecordName'),
			editable: false,
		},
		{
			field: 'valid',
			headerName: t('company.emailSettingsAuthenticatedDomainDnsRecordValid'),
			editable: false,
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.valid}
					disabled={true}
				/>
		},
		{
			field: 'reason',
			headerName: t('company.emailSettingsAuthenticatedDomainDnsRecordReason'),
			editable: false,
			flex: 1
		},
	];

	return <Dialog open={open} onClose={onLocalClose} maxWidth="lg" fullWidth>
		<DialogTitle>{t('company.emailSettingsAuthenticatedDomainValidationDialog')}</DialogTitle>
		<DialogContent>

			{!validationSummaryList && <LoadingComponent/>}
			{!!validationSummaryList && validationSummaryList.map(summary => {
				return <Box key={summary.domain}
							sx={{mb: 2}}>
					<Typography
						sx={{mb: 1}}>{t('company.emailSettingsAuthenticatedDomainConfiguration') + ' ' + summary.domain}: {t('company.emailSettingsAuthenticatedDomainValidationResult_' + summary.result)}</Typography>

					<DataGrid autoHeight
							  disableColumnSelector
							  hideFooter
							  columns={dataGridColumns}

							  loading={!summary}

							  disableColumnFilter
							  disableRowSelectionOnClick

							  rows={summary.details.map((record, index) => {
								  return {...record, id: index}
							  })}
							  rowCount={!!summary?.details ? summary.details.length : 0}
							  density="compact"/>
				</Box>
			})}

			<DialogActions>
				<Button onClick={onClose}
						id="btn-dialog-ok"
				>
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}



class CompanyEmailSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editHeaderDialogOpen: false,
			editFooterDialogOpen: false,
			editContentDialogOpen: false,
			authenticatedDomainDialogOpen: false,
			authenticatedDomainValidationDialogOpen: false,
			authenticatedDomainPendingChangesDialogOpen: false,
			authenticatedDomainPendingChangesSaved: false,
			selectedServerSettings: null,
			emailServerSettingsDialogOpen: false
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchEmailSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave &&
			this.props.shouldSave) {
			this.onSave();
		} else if (!!this.props.companyServerError &&
				   !prevProps.companyServerError &&
			       (this.state.authenticatedDomainDialogOpen || this.state.authenticatedDomainValidationDialogOpen)) {
			// if the domain (validation) dialog opening resulted in an error, close the popup
			this.setState({
				authenticatedDomainDialogOpen: false,
				authenticatedDomainValidationDialogOpen: false,
				authenticatedDomainPendingChangesDialogOpen: false,
				authenticatedDomainPendingChangesSaved: false
			});
		} else if (this.state.authenticatedDomainPendingChangesSaved && !prevState.authenticatedDomainPendingChangesSaved &&
				   !this.state.authenticatedDomainDialogOpen) {
			this.setState({authenticatedDomainDialogOpen: true});
		} else if (this.state.authenticatedDomainDialogOpen &&
				   !!this.props.companyEmailSettings.emailAuthenticatedDomainsValidationResult &&
				   !this.props.companyEmailAuthenticatedDomains &&
				   this.state.authenticatedDomainPendingChangesSaved) {
			this.setState({authenticatedDomainPendingChangesSaved: false}, this.props.onCompanyEmailAuthenticatedDomainsFetch);
		}
	}

	render() {
		if (!this.props.companyEmailSettings) {
			return <LoadingComponent/>;
		}

		const companyEmailSettings = this.props.companyEmailSettings;

		return <Box sx={{mt: 2}}>
			<Typography variant="h6">{this.props.t('company.emailSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError} />


			<Box sx={{mt: 2}}>
				<Box sx={{mt: 2}}>
					<Typography sx={{fontWeight: 700}}>{this.props.t('company.emailSettingsCommunicationType')}</Typography>
					<Box sx={{ml: 2, mt: 1}}>
						<RadioGroup
							color="primary"
							defaultValue={'DEFAULT_HEADERS'}
							onChange={this.onChangeEmailCommunicationType}
							sx={{mt: 1}}
						>
							<FormControlLabel value="DEFAULT_HEADERS"
											  checked={'DEFAULT_HEADERS' === companyEmailSettings.communicationType}
											  control={<Radio/>}
											  label={this.props.t('company.emailSettingsDescription_DEFAULT_HEADERS')}/>

							<FormControlLabel value="DEFAULT_SPF"
											  checked={'DEFAULT_SPF' === companyEmailSettings.communicationType}
											  control={<Radio/>}
											  label={this.props.t('company.emailSettingsDescription_DEFAULT_SPF')}/>

							<FormControlLabel value="SENDGRID_CUSTOM"
											  checked={'SENDGRID_CUSTOM' === companyEmailSettings.communicationType}
											  control={<Radio/>}
											  label={this.props.t('company.emailSettingsDescription_SENDGRID_CUSTOM')}/>
						</RadioGroup>
					</Box>


					<Box>
						<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('company.emailSettingsSenderSettings')}</Typography>
						<FormControlLabel
							control={
								<Checkbox checked={companyEmailSettings.personalizeEmailsCompany}
										  onChange={this.onChangePersonalizeCompanyEmails}
										  disabled={this.props.companyBusy || companyEmailSettings.communicationType === 'SENDGRID_CUSTOM'}
								/>
							}
							label={this.props.t('company.emailSettingsPersonalizeCompany') + ('DEFAULT_HEADERS' === companyEmailSettings.communicationType ? ' ' + this.props.t('company.emailSettingsPersonalizeCompanyHeaders') : '')}
							sx={{width: '100%', ml: 1}}
						/>

						{companyEmailSettings.personalizeEmailsCompany && <Box sx={{ml: 2, mt: 1}}>
							<Box sx={{display: 'flex'}}>
								<TextField
									variant="outlined"
									label={this.props.t('company.emailSettingsAddress')}
									disabled={this.props.companyBusy}
									required={true}
									value={companyEmailSettings.address || ''}
									onChange={this.onChangeAddress}
									autoComplete="off"
									sx={{width: 300}}
									id="input-company-email-address-text"
								/>
								<TextField
									variant="outlined"
									label={this.props.t('company.emailSettingsName')}
									disabled={this.props.companyBusy}
									required={true}
									value={companyEmailSettings.name || ''}
									onChange={this.onChangeName}
									autoComplete="off"
									sx={{ml: 2, width: 300}}
									id="input-company-email-name-text"
								/>
							</Box>
						</Box>}

						<FormControlLabel
							control={
								<Checkbox checked={companyEmailSettings.personalize}
										  onChange={this.onChangePersonalizeEmails}
										  disabled={this.props.companyBusy}
								/>
							}
							label={this.props.t('company.emailSettingsPersonalizeUser')  + ('DEFAULT_HEADERS' === companyEmailSettings.communicationType ? ' ' + this.props.t('company.emailSettingsPersonalizeUserHeaders') : '')}
							sx={{width: '100%', ml: 1}}
						/>
					</Box>

					{companyEmailSettings.communicationType === 'SENDGRID_CUSTOM' && this.renderSendGridCustomComponent(companyEmailSettings)}
				</Box>


				{companyEmailSettings.communicationType === 'DEFAULT_SPF' && <>
					<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('company.emailSettingsAuthenticatedDomainValidation')}</Typography>
					<Box sx={{mt: 1, ml: 2}}>
							<Box>
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											{companyEmailSettings.emailAuthenticatedDomainsValidationResult === 'PENDING' && <PendingIcon/>}
											{companyEmailSettings.emailAuthenticatedDomainsValidationResult === 'VALID' && <ValidIcon/>}
											{companyEmailSettings.emailAuthenticatedDomainsValidationResult === 'INVALID' && <InvalidIcon/>}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={this.props.t('company.emailSettingsAuthenticatedDomainValidationResultStatus')}
										secondary={this.props.t('company.emailSettingsAuthenticatedDomainValidationResult_' + (!!companyEmailSettings.emailAuthenticatedDomainsValidationResult ? companyEmailSettings.emailAuthenticatedDomainsValidationResult : 'IDLE'))}/>
								</ListItem>
							</Box>

						<Box sx={{display: 'flex', gap: 2, mt: 2, mb: 1}}>
							<Button
								variant="contained"
								onClick={this.onAuthenticatedDomainOpenDialog}
								startIcon={<ViewIcon/>}
								id="btn-company-email-authenticated-domain-view"
								disabled={this.props.companyBusy || !this.props.companyEmailSettings.hasWhitelistedEmailDomains}
							>
								{this.props.t('company.emailSettingsAuthenticatedDomainView')}
							</Button>

							<Button
								variant="contained"
								onClick={this.onAuthenticatedDomainValidationOpenDialog}
								startIcon={<VerifyIcon/>}
								id="btn-company-email-authenticated-domains-validate"
								disabled={this.props.companyBusy || !this.props.companyEmailSettings.hasWhitelistedEmailDomains || !this.props.companyEmailSettings.emailAuthenticatedDomainsValidationResult}
							>
								{this.props.t('company.emailSettingsAuthenticatedDomainValidate')}
							</Button>
						</Box>

						{!this.props.companyEmailSettings.hasWhitelistedEmailDomains && <Alert severity="warning">
							{this.props.t('company.emailSettingsAuthenticatedDomainNoWhitelistedDomains')}
						</Alert>}
					</Box>

					<AuthenticatedDomainsDnsRecordsDialog
						open={this.state.authenticatedDomainDialogOpen}
						onClose={this.onAuthenticatedDomainCloseDialog}
						emailAuthenticatedDomains={this.props.companyEmailAuthenticatedDomains}
					/>
					<AuthenticatedDomainsDnsValidationDialog
						open={this.state.authenticatedDomainValidationDialogOpen}
						onClose={this.onAuthenticatedDomainValidationCloseDialog}
						validationSummaryList={this.props.companyEmailAuthenticatedDomainsValidationSummaryList}
					/>
				</>}
			</Box>



			<Box sx={{ mt: 2 }}>
				<Typography sx={{ fontWeight: 700 }}>{this.props.t('company.emailSettingsDistributionList')}</Typography>
				<Box sx={{ ml: 2, mt: 1 }}>
					<Typography sx={{mb: 0.5}}>{this.props.t('company.emailSettingsDistributionListDescription')}</Typography>
					<TextField
						variant="outlined"
						label={this.props.t('company.emailSettingsDistributionListEmails')}
						value={companyEmailSettings.defaultPostSignDocumentEmailDistributionListDelimited || ''}
						onChange={this.onChangeDefaultPostSignDocumentEmailDistributionListDelimited}
						autoComplete="off"
						fullWidth
						disabled={this.props.companyBusy}
					/>

					<Typography sx={{mt: 1, mb: 0.5}}>{this.props.t('company.emailSettingsDistributionListEvidenceReportDescription')}</Typography>
					<TextField
						variant="outlined"
						label={this.props.t('company.emailSettingsDistributionListEmails')}
						value={companyEmailSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited || ''}
						onChange={this.onChangeDefaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited}
						autoComplete="off"
						fullWidth
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{ mt: 2 }}>
				<Typography sx={{ fontWeight: 700 }}>{this.props.t('company.emailSettingsSupport')}</Typography>
				<Box sx={{ ml: 2, mt: 1 }}>
				<TextField
					variant="outlined"
					label={this.props.t('company.emailSettingsSupportEmail')}
					value={companyEmailSettings.companySupportSettings.emailAddress || ''}
					onChange={this.onChangeSupportEmailAddress}
					autoComplete="off"
					disabled={this.props.companyBusy}
					sx={{ width: 300 }}
				/>
				</Box>
			</Box>

			<Box sx={{ mt: 4 }}>
				<Typography variant="h6">{this.props.t('company.emailSettingsLayout')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={companyEmailSettings.emailCustomLayout}
										   onChange={this.onChangeEmailCustomLayout}/>}
						label={this.props.t('company.emailSettingsLayoutCustomize')}
						disabled={this.props.companyBusy}
						sx={{mb: 1}}
					/>

					<Box sx={{display: 'flex'}}>
						<Box sx={{flexGrow: 1}}>
							<Typography
								sx={{
									fontWeight: 700,
								}}>{this.props.t('company.emailSettingsHeaderFooter')} </Typography>

							<Box sx={{display: 'flex', mt: 1, mb: 1}}>
								<Button variant="contained"
										onClick={this.onOpenDialogHeaderEdit}
										startIcon={<EditIcon/>}
										sx={{mr: 1}}
										disabled={this.props.companyBusy}
										id="btn-email-header-edit"
								>
									{this.props.t('company.emailSettingsHeaderEdit')}
								</Button>

								<Button variant="contained"
										onClick={this.onOpenDialogContentEdit}
										startIcon={<EditIcon/>}
										sx={{mr: 1}}
										disabled={this.props.companyBusy}
										id="btn-email-content-edit"
								>
									{this.props.t('company.emailSettingsContentEdit')}
								</Button>

								<Button variant="contained"
										onClick={this.onOpenDialogFooterEdit}
										startIcon={<EditIcon/>}
										sx={{mr: 1}}
										disabled={this.props.companyBusy}
										id="btn-email-footer-edit"
								>
									{this.props.t('company.emailSettingsFooterEdit')}
								</Button>
							</Box>
						</Box>

					</Box>

					<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
						<EmailCustomSettingsColorComponent
							busy={this.props.companyBusy}
							title={this.props.t('company.emailSettingsColorBackground')}
							color={companyEmailSettings?.emailCustomSettings?.backgroundColor}
							defaultColor={companyEmailSettings.emailCustomSettingsDefaults.backgroundColor}
							onChange={(color) => this.props.onCompanyChangeEmailSettingsItem('emailCustomSettings', 'backgroundColor', color)}
						/>

						<EmailCustomSettingsColorComponent
							busy={this.props.companyBusy}
							title={this.props.t('company.emailSettingsColorBackgroundContent')}
							color={companyEmailSettings?.emailCustomSettings?.contentBackgroundColor}
							defaultColor={companyEmailSettings.emailCustomSettingsDefaults.contentBackgroundColor}
							onChange={(color) => this.props.onCompanyChangeEmailSettingsItem('emailCustomSettings', 'contentBackgroundColor', color)}
						/>

						<EmailCustomSettingsColorComponent
							busy={this.props.companyBusy}
							title={this.props.t('company.emailSettingsColorButtonBackground')}
							color={companyEmailSettings?.emailCustomSettings?.buttonBackgroundColor}
							defaultColor={companyEmailSettings.emailCustomSettingsDefaults.buttonBackgroundColor}
							onChange={(color) => this.props.onCompanyChangeEmailSettingsItem('emailCustomSettings', 'buttonBackgroundColor', color)}
						/>

						<EmailCustomSettingsColorComponent
							busy={this.props.companyBusy}
							title={this.props.t('company.emailSettingsColorButtonText')}
							color={companyEmailSettings?.emailCustomSettings?.buttonTextColor}
							defaultColor={companyEmailSettings.emailCustomSettingsDefaults.buttonTextColor}
							onChange={(color) => this.props.onCompanyChangeEmailSettingsItem('emailCustomSettings', 'buttonTextColor', color)}
						/>
					</Box>

					{/*header editor*/}
					<EmailGenericFieldsDialog
						defaultFields={companyEmailSettings.emailCustomSettingsDefaults?.headers}
						fields={companyEmailSettings.emailCustomSettings?.headers}
						title={this.props.t('company.emailSettingsHeaderEdit')}
						open={this.state.editHeaderDialogOpen}
						onClose={this.onCloseEditHeaderDialog}
						onChange={this.onChangeEmailHeaders}
					/>

					{/*footer editor*/}
					<EmailGenericFieldsDialog
						defaultFields={companyEmailSettings.emailCustomSettingsDefaults?.footers}
						fields={companyEmailSettings.emailCustomSettings?.footers}
						title={this.props.t('company.emailSettingsFooterEdit')}
						disabledBottomRowModification={true}
						open={this.state.editFooterDialogOpen}
						onClose={this.onCloseEditFooterDialog}
						onChange={this.onChangeEmailFooters}
					/>

					{/*templates content editor*/}
					<EmailTemplatesFieldsDialog
						defaultFields={companyEmailSettings.emailCustomSettingsDefaults?.templatesContentFields}
						fields={companyEmailSettings.emailCustomSettings?.templatesContentFields}
						title={this.props.t('company.emailSettingsContentEdit')}
						open={this.state.editContentDialogOpen}
						onClose={this.onCloseEditContentDialog}
						onChange={this.onChangeEmailTemplatesContentFields}
					/>
				</Box>
			</Box>

			<Box sx={{ mt: 2 }}>
				<Button variant="contained"
						onClick={this.onSave}
						startIcon={<SaveIcon/>}
						disabled={this.props.companyBusy || !this.canContinue()}
						id="btn-settings-save">{this.props.t('save')}</Button>
			</Box>

			<PendingChangesDialog
				open={this.state.authenticatedDomainPendingChangesDialogOpen}
				onClose={this.onPendingChangesCloseDialog}
				busy={this.props.companyBusy}
				onConfirm={this.onPendingChangesConfirm}
				labelContent={this.props.t('company.emailSettingsAuthenticatedDomainPendingChanges')}
				labelConfirm={this.props.t('confirm')}
			/>
		</Box>
	}

	renderSendGridCustomComponent = (companyEmailSettings) => {
		const dataGridColumns = [
			{
				field: 'userName',
				headerName: this.props.t('company.emailSettingsServerUserName'),
				editable: false,
				flex: 1
			},
			{
				field: 'password',
				headerName: this.props.t('company.emailSettingsServerPassword'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => (<span>{'*'.repeat(cellValues.row.password.length)}</span>)
			},
			{
				field: 'isDefault',
				headerName: this.props.t('company.emailSettingsServerDefault'),
				editable: false,
				width: 100,
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.isDefault}
						disabled={true}
					/>
			},
			{
				field: 'domain',
				headerName: this.props.t('company.emailSettingsServerDomains'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				headerName: this.props.t('company.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.emailSettingsServerEdit')}
						onClick={() => this.onOpenEditEmailServerSettingsDialog(cellValues.row)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.emailSettingsServerDelete')}
						disabled={cellValues.row.systemValidator}
						onClick={() => this.onDeleteEmailServerSettings(cellValues.row)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];

		return <>
			<Typography sx={{fontWeight: 700, mt: 2, mb: 1}}>{this.props.t('company.emailSettingsSendGridServerSettings')}</Typography>
			<Box sx={{ml: 2}}>
				<DataGrid autoHeight
						  disableColumnSelector
						  disableColumnFilter
						  disableColumnMenu
						  disableRowSelectionOnClick
						  hideFooter

						  columns={dataGridColumns}

						  getRowId={row => row.id || row.internalId}

						  rows={companyEmailSettings.emailServerSettings}
						  rowCount={!!companyEmailSettings.emailServerSettings ? companyEmailSettings.emailServerSettings.length : 0}
						  density="compact"/>
				<Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.onOpenAddEmailServerSettingsDialog}
						startIcon={<AddIcon/>}
						id="btn-email-server-settings-add"
					>
						{this.props.t('company.emailSettingsServerAdd')}
					</Button>
				</Box>
			</Box>

			<CompanyEmailServerSettingsDialog
				open={this.state.emailServerSettingsDialogOpen}
				current={this.state.selectedServerSettings}
				onClose={this.onCloseEmailServerSettingsDialog}
				onConfirm={this.onChangeEmailServerSettings}
				/>
		</>
	}

	onPendingChangesCloseDialog = (e, reason) => {
		if (reason === 'backdropClick') {
			return;
		}

		this.setState({authenticatedDomainPendingChangesDialogOpen: false});
	}

	onPendingChangesConfirm = () => {
		this.onSave();

		this.setState({authenticatedDomainPendingChangesDialogOpen: false, authenticatedDomainPendingChangesSaved: true});
	}

	onChangeDefaultPostSignDocumentEmailDistributionListDelimited = (e) => {
		this.props.onCompanyChangeEmailSettingsItem('defaultPostSignDocumentEmailDistributionListDelimited', null, e.target.value);
	}

	onChangeDefaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited = (e) => {
		this.props.onCompanyChangeEmailSettingsItem('defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited', null, e.target.value);
	}

	onChangeSupportEmailAddress = (e) => {
		this.props.onCompanyChangeEmailSettingsItem('companySupportSettings', 'emailAddress', e.target.value);
	}

	onChangeEmailCustomLayout = (e, value) => {
		this.props.onCompanyChangeEmailSettingsItem('emailCustomLayout', null, value);
	}

	onChangeEmailHeaders = (emailHeaders) => {
		this.setState({editHeaderDialogOpen: false}, () => this.props.onCompanyChangeEmailSettingsItem("emailCustomSettings", 'headers', emailHeaders));
	}

	onChangeEmailFooters = (emailFooters) => {
		this.setState({editFooterDialogOpen: false}, () => this.props.onCompanyChangeEmailSettingsItem('emailCustomSettings', 'footers', emailFooters));
	}

	onChangeEmailTemplatesContentFields = (templatesContentFields) => {
		this.setState({editContentDialogOpen: false}, () => this.props.onCompanyChangeEmailSettingsItem("emailCustomSettings", 'templatesContentFields', templatesContentFields));
	}

	onOpenDialogHeaderEdit = () => {
		this.setState({editHeaderDialogOpen: true});
	}

	onCloseEditHeaderDialog = () => {
		this.setState({editHeaderDialogOpen: false});
	}

	onOpenDialogFooterEdit = () => {
		this.setState({editFooterDialogOpen: true});
	}

	onCloseEditFooterDialog = () => {
		this.setState({editFooterDialogOpen: false});
	}

	onOpenDialogContentEdit = () => {
		this.setState({editContentDialogOpen: true});
	}

	onCloseEditContentDialog = () => {
		this.setState({editContentDialogOpen: false});
	}

	onChangeEmailCommunicationType = (e) => {
		const emailSettings = {
			...this.props.companyEmailSettings,
			communicationType: e.target.value,
			...('SENDGRID_CUSTOM' === e.target.value && {
				port: 465,
				personalizeEmailsCompany: true
			})
		};
		this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
	}

	onChangePersonalizeCompanyEmails = (e, value) => {
		const emailSettings = {
			...this.props.companyEmailSettings,
			personalizeEmailsCompany: value,
			...(!value && {
				address: null,
				name: null
			})
		};
		this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
	}

	onChangePersonalizeEmails = (e, value) => {
		const emailSettings = {
			...this.props.companyEmailSettings,
			personalize: value
		};
		this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
	}

	onChangeAddress = (e) => {
		const emailSettings = {
			...this.props.companyEmailSettings,
			address: e.target.value
		};
		this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
	}

	onChangeName = (e) => {
		const emailSettings = {
			...this.props.companyEmailSettings,
			name: e.target.value
		};
		this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
	}

	onAuthenticatedDomainOpenDialog = () => {
		if (!this.props.companyEmailSettings.emailAuthenticatedDomainsValidationResult) {
			this.setState({authenticatedDomainPendingChangesDialogOpen: true});
		} else {
			this.setState({authenticatedDomainDialogOpen: true}, () => this.props.onCompanyEmailAuthenticatedDomainsFetch());
		}
	}

	onAuthenticatedDomainCloseDialog = () => {
		this.setState({authenticatedDomainDialogOpen: false});
	}

	onAuthenticatedDomainValidationOpenDialog = () => {
		this.setState({authenticatedDomainValidationDialogOpen: true}, () => this.props.onCompanyEmailAuthenticatedDomainValidate());
	}

	onAuthenticatedDomainValidationCloseDialog = () => {
		this.setState({authenticatedDomainValidationDialogOpen: false});
	}

	onOpenEditEmailServerSettingsDialog = (emailServerSettings) => {
		this.setState({selectedServerSettings: emailServerSettings, emailServerSettingsDialogOpen: true});
	}

	onOpenAddEmailServerSettingsDialog = () => {
		this.setState({selectedServerSettings: null, emailServerSettingsDialogOpen: true});
	}

	onChangeEmailServerSettings = (selectedServerSettings) => {
		const emailServerSettings = [...this.props.companyEmailSettings.emailServerSettings];
		// if the edited/new setting is now default, clear default of all other server settings
		if (selectedServerSettings.isDefault) {
			emailServerSettings.forEach(serverSettings => serverSettings.isDefault = false);
		}

		if (!!selectedServerSettings.id || !!selectedServerSettings.internalId) {
			const index = emailServerSettings.findIndex(search => search.id === selectedServerSettings.id);
			if (index < 0) {
				return;
			}

			emailServerSettings[index] = selectedServerSettings;
		} else {
			// set temporary id to identify the settings in the UI when no persisted id has been set
			selectedServerSettings.internalId = uuidv4();
			// new
			emailServerSettings.push(selectedServerSettings);
		}

		this.setState({emailServerSettingsDialogOpen: false}, () => {
			const emailSettings = {
				...this.props.companyEmailSettings,
				emailServerSettings
			};
			this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
		});
	}

	onDeleteEmailServerSettings =  (selectedServerSettings) => {
		const emailServerSettings = [...this.props.companyEmailSettings.emailServerSettings];
		const index = emailServerSettings.findIndex(search =>
			search.id === selectedServerSettings.id || (!selectedServerSettings.id && search.internalId === selectedServerSettings.internalId));
		if (index >= 0) {
			emailServerSettings.splice(index, 1);
			const emailSettings = {
				...this.props.companyEmailSettings,
				emailServerSettings
			};
			this.props.onCompanyChangeEmailSettings(emailSettings, this.canContinue(emailSettings));
		}
	}

	onCloseEmailServerSettingsDialog = () => {
		this.setState({emailServerSettingsDialogOpen: false});
	}

	canContinue = (newSettings) => {
		const settings = !!newSettings ? newSettings : this.props.companyEmailSettings;
		if (settings.personalizeEmailsCompany && (!settings.address?.trim() || !settings.name?.trim())) {
			return false;
		}

		if ('DEFAULT_HEADERS' === settings.communicationType ||
			'DEFAULT_SPF' === settings.communicationType) {
			return true;
		} else if ('SENDGRID_CUSTOM' === settings.communicationType) {
			return !!settings.emailServerSettings && !!settings.emailServerSettings.find(search => search.isDefault);
		}
		return false;
	}

	onSave = () => {
		const emailSettings = this.props.companyEmailSettings;
		const defaultPostSignDocumentEmailDistributionListDelimited =
			emailSettings.defaultPostSignDocumentEmailDistributionListDelimited.trim();
		if (!!defaultPostSignDocumentEmailDistributionListDelimited) {
			emailSettings.defaultPostSignDocumentEmailDistributionList = defaultPostSignDocumentEmailDistributionListDelimited.split(';');
		} else {
			emailSettings.defaultPostSignDocumentEmailDistributionList = [];
		}

		const defaultPostSignDocumentAndEvidenreReportEmailDistributionListDelimited =
			emailSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited.trim();
		if (!!defaultPostSignDocumentAndEvidenreReportEmailDistributionListDelimited) {
			emailSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionList = defaultPostSignDocumentAndEvidenreReportEmailDistributionListDelimited.split(';');
		} else {
			emailSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionList = [];
		}

		this.props.onCompanyUpdateEmailSettings(emailSettings);
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionBusy: state.session.busy,
			pendingChanges: state.session.pendingChanges,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyEmailSettings: state.company.emailSettings,
			companyEmailAuthenticatedDomains: state.company.emailAuthenticatedDomains,
			companyEmailAuthenticatedDomainsValidationSummaryList: state.company.emailAuthenticatedDomainsValidationSummaryList,
		}
	},
	dispatch => {
		return {
			onCompanyFetchEmailSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_EMAIL_SETTINGS'
				});
			},
			onCompanyUpdateEmailSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_EMAIL_SETTINGS',
					settings
				});
			},
			onCompanyChangeEmailSettingsItem: (key, subkey, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_EMAIL_SETTINGS_ITEM',
					key,
					subkey,
					value,
				});
			},
			onCompanyChangeEmailSettings: (emailSettings, savePossible) => {
				dispatch({
					type: 'COMPANY_CHANGE_EMAIL_SETTINGS',
					emailSettings,
					savePossible
				});
			},
			onCompanyEmailAuthenticatedDomainsFetch: () => {
				dispatch({
					type: 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_FETCH'
				});
			},
			onCompanyEmailAuthenticatedDomainValidate: () => {
				dispatch({
					type: 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_VALIDATE'
				});
			}
		}
	}
)(CompanyEmailSettingsComponent));
