import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router";
import {enUS, frFR, nlNL} from "@mui/material/locale";
import {enUS as enUSDataGrid, frFR as frFRDataGrid, nlNL as nlNLDataGrid} from "@mui/x-data-grid";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider, renderTimeViewClock} from "@mui/x-date-pickers";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LoadingComponent from "./common/LoadingComponent";
import CompanySettingsComponent from "./company/CompanySettingsComponent";
import UserOverviewComponent from "./user/UserOverviewComponent";
import UserGroupOverviewComponent from "./usergroup/UserGroupOverviewComponent";
import InfoComponent from "./misc/InfoComponent";
import NotFoundComponent from "./misc/NotFoundComponent";
import FolderOverviewComponent from "./folder/FolderOverviewComponent";
import DocumentGeneralOverviewComponent from "./document/DocumentGeneralOverviewComponent";
import DocumentSigningOverviewComponent from "./document/DocumentSigningOverviewComponent";
import DocumentUploadComponent from "./document/DocumentUploadComponent";
import IndexComponent from "./misc/IndexComponent";
import DocumentApprovalOverviewComponent from "./document/DocumentApprovalOverviewComponent";
import SigningComponent from "./signing/SigningComponent";
import UserProfileComponent from "./user/UserProfileComponent";
import EditorComponent from "./editor/EditorComponent";
import ApprovalComponent from "./approval/ApprovalComponent";
import TemplateOverviewComponent from "./template/TemplateOverviewComponent";
import SignerGroupOverviewComponent from "./signergroup/SignerGroupOverviewComponent";
import ApplicationSettingsComponent from "./application/ApplicationSettingsComponent";
import SessionLoginComponent from "./session/SessionLoginComponent";
import SessionResetPasswordComponent from "./session/SessionResetPasswordComponent";
import SessionForgotPasswordComponent from "./session/SessionForgotPasswordComponent";
import CompanyInsightsComponent from "./company/CompanyInsightsComponent";
import SigningForwardedComponent from "./signing/SigningForwardedComponent";
import SigningItsMeRedirectErrorComponent from "./signing/SigningItsMeRedirectErrorComponent";
import {MAP_DATE_FNS_LOCALE} from "./common/Constants";

const TemplateEditorComponent = () => {
	return <EditorComponent mode="TEMPLATE"/>;
}

const DocumentEditorComponent = () => {
	return <EditorComponent mode="DOCUMENT"/>;
}

class App extends Component {

	constructor(props, context) {
		super(props, context);

		// check if a CSRF token was transferred from another (sub)domain
		if (!!this.props.csrfToken) {
			localStorage.setItem('TOKEN_CSRF', this.props.csrfToken)
			this.props.history.push(this.props.path);
		}

		const postLoginPath = localStorage.getItem('post-login-path');
		if (!!postLoginPath && window.location.search.indexOf('state') > 0) {
			// we have a postLoginPath and a 'state' param in the URL, we can be pretty sure this is a callback after authentication
			localStorage.removeItem('post-login-path');
			this.postLogin = true;
			this.props.onSessionCreateHandleLoginCallback(postLoginPath);
		} else {
			this.postLogin = false;
		}
	}

	render() {
		if (this.postLogin) {
			return <LoadingComponent/>
		}

		const sessionInfo = this.props.sessionInfo;
		const theme = createTheme({
			palette: {
				primary: {
					main: '#' + (sessionInfo?.companyStyle?.primaryBackgroundColor || '000000'),
					contrastText: '#' + (sessionInfo?.companyStyle?.primaryTextColor || 'FFFFFF')
				},
				secondary: {
					main: '#' + (sessionInfo?.companyStyle?.secondaryBackgroundColor || '000000'),
					contrastText: '#' + (sessionInfo?.companyStyle?.secondaryTextColor || 'FFFFFF')
				},
				appbar: {
					main: '#FFFFFF',
					contrastText: '#000000'
				},
				background: {
					default: '#FFFFFF',
				}
			},
			typography: {
				fontFamily: 'Lato,"Trebuchet MS",Arial,Helvetica,sans-serif',
				button: {
					fontSize: '0.79rem'
				}
			},
			components: {
				MuiFab: {
					styleOverrides: {
						root: {
							width: '36px',
							height: '36px'
						}
					}
				},
				MuiDataGrid: {
					styleOverrides: {
						root: {
							"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
								outline: "none"
							}
						},
					},
					...(sessionInfo?.language === "nl" && nlNLDataGrid.components.MuiDataGrid),
					...(sessionInfo?.language === "fr" && frFRDataGrid.components.MuiDataGrid),
					...(sessionInfo?.language === "en" && enUSDataGrid.components.MuiDataGrid),
				},
				MuiDesktopDateTimePicker: {
					defaultProps: {
						viewRenderers: {
							hours: renderTimeViewClock,
							minutes: renderTimeViewClock,
							seconds: renderTimeViewClock,
						},
					},
				},
				...(sessionInfo?.language === "nl" && nlNL.components),
				...(sessionInfo?.language === "fr" && frFR.components),
				...(sessionInfo?.language === "en" && enUS.components),
			},
		});

		return <ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={MAP_DATE_FNS_LOCALE(sessionInfo)}>
				<CssBaseline/>
				<Switch>
					<Route path="/login/:data?" component={SessionLoginComponent}/>
					{/* approval */}
					<Route path="/approval/:data" component={ApprovalComponent}/>
					{/* admin settings */}
					<Route path="/admin" component={ApplicationSettingsComponent}/>
					{/* company stuff  */}
					<Route path="/company/insights" component={CompanyInsightsComponent}/>
					<Route path="/company/settings" component={CompanySettingsComponent}/>
					{/* user */}
					<Route path="/user/overview" component={UserOverviewComponent}/>
					<Route path="/user/profile" component={UserProfileComponent}/>
					{/* session */}
					<Route path='/password-reset/:payload' component={SessionResetPasswordComponent}/>
					<Route path='/forgot-password' component={SessionForgotPasswordComponent}/>
					{/* user groups */}
					<Route path="/user-group/overview" component={UserGroupOverviewComponent}/>
					{/* document */}
					<Route path="/document/general-overview" component={DocumentGeneralOverviewComponent}/>
					<Route path="/document/approval-overview" component={DocumentApprovalOverviewComponent}/>
					<Route path="/document/signing-overview" component={DocumentSigningOverviewComponent}/>
					<Route path="/document/upload" component={DocumentUploadComponent}/>
					{/* document */}
					<Route path="/editor/:data" render={DocumentEditorComponent} />
					{/* folder */}
					<Route path="/folder/overview" component={FolderOverviewComponent}/>
					{/* signer-groups */}
					<Route path="/signer-group/overview" component={SignerGroupOverviewComponent}/>
					{/* signing */}
					<Route path="/sign/itsme-redirect-error" component={SigningItsMeRedirectErrorComponent}/>
					<Route path="/sign/forwarded" component={SigningForwardedComponent}/>
					<Route path="/sign/:data" component={SigningComponent}/>
					{/* template */}
					<Route path="/template/overview" component={TemplateOverviewComponent}/>
					<Route path="/template/editor/:data" render={TemplateEditorComponent} />
					{/* misc */}
					<Route path="/info" component={InfoComponent}/>
					<Route path="/" component={IndexComponent}/>
					<Route component={NotFoundComponent}/>
				</Switch>
			</LocalizationProvider>
		</ThemeProvider>
	}

}

export default withRouter(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			csrfToken: state.router.location.query.csrf,
			path: state.router.location.pathname
		}
	},
	dispatch => {
		return {
			onSessionCreateHandleLoginCallback: (loginSavedPath) => {
				dispatch({
					type: 'SESSION_CREATE_HANDLE_LOGIN_CALLBACK',
					loginSavedPath
				})
			}
		}
	}
)(App));
