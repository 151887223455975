import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";

export const EditorSigningOrderGroupSettingsDialog = ({open, onClose, onConfirm, settings, signers, readOnly}) => {

	const {t} = useTranslation();

	const [minimalNumberOfSigners, setMinimalNumberOfSigners] = useState('');
	const [requiredSigners, setRequiredSigners] = useState([]);

	// reset on open
	useEffect(() => {
		if (open) {
			setMinimalNumberOfSigners('' + ((!!settings ? settings.minimalNumberOfSigners : (signers?.length)) || 0));
			setRequiredSigners((signers || []).filter(signer => signer.requiredInOrderGroup).map(signer => signer.id));
		}
	}, [open, settings, signers])

	const onChangeMinimalNumberOfSigners = useCallback((e) => {
		setMinimalNumberOfSigners(e.target.value);
	}, [setMinimalNumberOfSigners]);

	const minimalNumberOfSignersNumber = useMemo(() => {
		return parseInt(minimalNumberOfSigners, 10);
	}, [minimalNumberOfSigners]);

	const isValid = !isNaN(minimalNumberOfSignersNumber) &&
		minimalNumberOfSignersNumber > 0 &&
		minimalNumberOfSignersNumber >= requiredSigners.length &&
		minimalNumberOfSignersNumber <= (signers?.length);

	const onLocalConfirm = useCallback(() => {
		onConfirm({
			minimalNumberOfSigners: minimalNumberOfSignersNumber,
			requiredSigners
		});
	}, [minimalNumberOfSignersNumber, requiredSigners]);

	const onChangeSignerRequired = useCallback((id, checked) => {
		const newRequiredSigners = requiredSigners
			.filter(search => search !== id);
		if (checked) {
			newRequiredSigners.push(id);
		}
		setRequiredSigners(newRequiredSigners);
	}, [requiredSigners, setRequiredSigners]);

	const onLocalClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			onClose();
		}
	}

	return <Dialog open={open} onClose={onLocalClose} fullWidth maxWidth="sm">
		<DialogTitle>{t('editor.signingOrderGroupSettings')}</DialogTitle>
		<DialogContent>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center'}}>
				<Typography variant="body2">{t('editor.signingOrderGroupMinimalNumberOfSignersDescription')}</Typography>
				<TextField
					value={minimalNumberOfSigners}
					onChange={onChangeMinimalNumberOfSigners}
					fullWidth
					sx={{mt: 1}}
					size="small"
					type="number"
					label={t('editor.signingOrderGroupMinimalNumberOfSigners')}
					disabled={readOnly}
				/>
				<Typography variant="body2" sx={{mt: 1}}>{t('editor.signingOrderGroupRequiredSigners')}</Typography>
				{(signers || []).map(signer =>
					<FormControlLabel
						key={signer.id}
						control={<Checkbox checked={requiredSigners.indexOf(signer.id) >= 0} sx={{p: 0}}
										   onChange={(e, checked) => onChangeSignerRequired(signer.id, checked)}
										   disabled={readOnly} />}
						label={<Box sx={{ml: 1, display: 'flex', flexDirection: 'column'}}

					>
							{!!signer?.person ?
								<>
									<Typography variant="body2">{signer.person?.firstName + ' ' + signer.person?.lastName}</Typography>
									<Typography variant="body2">{signer.person?.email}</Typography>
								</>
								:
								<Typography variant="body2">{t('editor.signingPlaceholder') + ' ' + signer.pos}</Typography>
							}
						</Box>}
						sx={{m: 0}}
					/>
				)}
			</Box>
		</DialogContent>
		<DialogActions>
			<Button onClick={onLocalClose} id="btn-selection-cancel">{t('back')}</Button>
			<Button onClick={onLocalConfirm}
					variant="contained"
					id="btn-selection-confirm"
					disabled={!isValid || readOnly}
			>
				{t('confirm')}
			</Button>
		</DialogActions>
	</Dialog>
}

