import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Grid, Link, Typography} from "@mui/material";

class InfoFaqComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.faqHeader')}</Typography>
				{this.renderWithLink('info.faqContent')}
			</Grid>
		</Grid>
	}

	renderWithLink(key) {
		return this.props.t(key).split('$').map((v, index) => {
			if (v.indexOf('link') === 0) {
				const elements = v.split(';');
				return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
			} else
				return <span key={index}>{v}</span>
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(InfoFaqComponent));
