import React, {Component} from "react";
import {VALID_EMAIL_ADDRESS} from "./EditorUtils";
import {Box, Button, TextField} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditorAddUserDialog from "./EditorAddUserDialog";
import {withTranslation} from "react-i18next";
import {PlaylistRemove} from "@mui/icons-material";

class EditorPostActionsEmailDistributionListComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			userDialogOpen: false,
			emailToAdd: '',
		}
	}

	render() {
		const readOnly = this.props.readOnly;
		const postSignEmailDistributionList = this.props.postSignEmailDistributionList;
		const documentId = this.props.documentId;
		const emailToAddValid = VALID_EMAIL_ADDRESS(this.state.emailToAdd);

		return <Box>
			<TextField
				value={postSignEmailDistributionList.join(', ')}
				fullWidth
				disabled
				sx={{mt: 1}}
				size="small"
				rows={5}
				multiline
			/>

			<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
				<Button variant="contained"
						onClick={this.onRemoveDistributionList}
						startIcon={<PlaylistRemove/>}
						color="secondary"
						disabled={readOnly}
						id="btn-editor-postaction-list-remove"
				>
					{this.props.t('editor.postActionsDistributionListRemoveDistributionList')}
				</Button>

				<Button variant="contained"
						onClick={this.onOpenUserDialog}
						startIcon={<AddIcon/>}
						color="secondary"
						disabled={readOnly}
						sx={{ml: 1}}
						id="btn-editor-postaction-list-add-known-user"
				>
					{this.props.t('editor.postActionsDistributionListAddExistingUser')}
				</Button>
			</Box>


			<Box sx={{display: 'flex'}}>
				<TextField
					label={this.props.t('editor.postActionsDistributionListCustom')}
					value={this.state.emailToAdd}
					onChange={this.onChangeEmailToAdd}
					sx={{mt: 1}}
					size="small"
					fullWidth
					disabled={readOnly}
					autoComplete="off"
					onKeyUp={this.onKeyUp}
					error={!!this.state.emailToAdd && !emailToAddValid}
				/>

				<Button variant="contained"
						onClick={this.onAddEmail}
						startIcon={<AddIcon/>}
						color="secondary"
						disabled={!this.canAddEmail()}
						sx={{ml: 1, mt:1}}
						id="btn-editor-postaction-list-add-user"
				>
					{this.props.t('editor.postActionsDistributionListAdd')}
				</Button>
			</Box>

			<EditorAddUserDialog
				documentId={documentId}
				title={this.props.t('editor.postActionsDistributionListAddExistingUser')}
				open={this.state.userDialogOpen}
				onClose={this.onCloseUserDialog}
				onSelect={this.onSelectUsers}
			/>
		</Box>
	}

	canAddEmail = () => {
		return !this.props.readOnly && VALID_EMAIL_ADDRESS(this.state.emailToAdd);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canAddEmail()) {
			this.onAddEmail();
		}
	}

	onOpenUserDialog = () => {
		this.setState({userDialogOpen: true})
	}

	onCloseUserDialog = () => {
		this.setState({userDialogOpen: false})
	}

	onChangeEmailToAdd = (e) => {
		this.setState({emailToAdd: e.target.value});
	}

	onAddEmail = () => {
		const postSignEmailDistributionList = [...this.props.postSignEmailDistributionList];
		if (postSignEmailDistributionList.indexOf(this.state.emailToAdd) === -1) {
			postSignEmailDistributionList.push(this.state.emailToAdd);
		}

		this.setState({emailToAdd: ''},
			() => this.props.onChangePostSignEmailDistributionList(postSignEmailDistributionList));
	}

	onSelectUsers = (usersToAdd) => {
		const postSignEmailDistributionList = [...this.props.postSignEmailDistributionList];

		usersToAdd.forEach(userToAdd => {
			if (postSignEmailDistributionList.indexOf(userToAdd.email) === -1) {
				postSignEmailDistributionList.push(userToAdd.email);
			}
		});

		this.setState({userDialogOpen: false},
			() => this.props.onChangePostSignEmailDistributionList(postSignEmailDistributionList));
	}

	onRemoveDistributionList = () => {
		this.props.onChangePostSignEmailDistributionList([]);
	}

}

export default withTranslation()(EditorPostActionsEmailDistributionListComponent);
