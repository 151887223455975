import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Typography} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import ServerErrorComponent from "../common/ServerErrorComponent";

class ApplicationTestsComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Box sx={{ mt: 2 }}>
			<ServerErrorComponent serverError={this.props.companyServerError} />
			<Box sx={{ mt: 2 }}>
				<Typography sx={{ fontWeight: 700 }}>{this.props.t('application.testsMail')}</Typography>
				<Box sx={{ ml: 2, mt: 1 }}>
					{['en', 'fr', 'nl'].map(lang =>
						<Button key={lang}
								variant="contained"
								onClick={() => this.onTestMails(lang)}
								startIcon={<EmailIcon />}
								sx={{ mr: 1 }}
								disabled={this.props.companyBusy}
								id="btn-application-test-mails"
						>
							{this.props.t('lang', {lng: lang})}
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	}

	onTestMails = (lang) => {
		let language;
		switch (lang) {
			case 'en':
				language = 'ENGLISH';
				break;
			case 'fr':
				language = 'FRENCH';
				break;
			case 'nl':
				language = 'DUTCH';
				break;
		}
		// TODO-FIXME don't allow sending if certain time has not elapsed
		this.props.onCompanyTestsSendEmails({ language });
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
		}
	},
	dispatch => {
		return {
			onCompanyTestsSendEmails: (request) => {
				dispatch({
					type: 'COMPANY_TESTS_SEND_EMAILS',
					request
				});
			},

		}
	}
)(ApplicationTestsComponent));
