import React, {Component, Fragment} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ConfirmationDialog from "../common/ConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import AddIcon from "@mui/icons-material/Add";
import TemplateCreationDialog from "./TemplateCreationDialog";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LockIcon from "@mui/icons-material/Lock";


class TemplateOverviewComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // list
            filterValue: '',
            page: 0,
            pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
            sortModel: [],
            //create
            creationDialogOpen: false,
            // delete
            selectedIdForDeletion: -1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.templateActionSuccessfully && !prevProps.templateActionSuccessfully) {
            this.onTemplateFetchOverviewList();
        }
    }

    render() {
        const dataGridColumns = [
            {
                field: 'name',
                headerName: this.props.t('template.name'),
                editable: false,
                flex: 1,
                renderCell: (cellValues) => <Fragment>
                    {cellValues.row.collection && <FileCopyIcon fontSize="small" sx={{mr: 0.5}}/>}
                    {cellValues.row.name}
                </Fragment>
            },
            {
                field: 'creatorName',
                headerName: this.props.t('template.creatorName'),
                editable: false,
                sortable: true,
                flex: 1
            },
            {
                field: 'documentFolderName',
                headerName: this.props.t('template.folderName'),
                editable: false,
                sortable: true,
                flex: 1
            },
            {
                field: 'actions',
                headerName: this.props.t('template.actions'),
                editable: false,
                sortable: false,
                width: 120,
                renderCell: (cellValues) => <Fragment>
                    <IconButton
                        variant="contained"
                        color="primary"
                        title={cellValues.row.canEdit ? this.props.t('template.edit') : this.props.t('template.view')}
                        disabled={!cellValues.row.canEdit && !cellValues.row.canView}
                        onClick={() => this.onEditorOpen(cellValues.row.id)}
                    >
                        {cellValues.row.canEdit ? <EditIcon fontSize="small"/> : <VisibilityIcon fontSize="small"/>}
                    </IconButton>

                    <IconButton
                        variant="contained"
                        color="primary"
                        title={this.props.t('template.delete')}
                        disabled={!cellValues.row.canRemove}
                        onClick={() => this.onChangeSelectedIdForDeletion(cellValues.row.id)}
                    >
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Fragment>
            },
        ];

        return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
            <Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
                <Typography variant="h6" sx={{mb: 2}}>{this.props.t('template.overviewHeader')}</Typography>
                <ServerErrorComponent serverError={this.props.templateServerError}/>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                    <Button variant="contained"
                            onClick={this.onCreationDialogOpen}
                            startIcon={<AddIcon/>}
                            sx={{mr: 1}}
                            disabled={this.props.templateBusy}
                            id="btn-template-create"
                    >
                        {this.props.t('template.create')}
                    </Button>
                </Box>
                <DataGrid
                    autoHeight
                    disableColumnSelector
                    disableColumnFilter
                    columns={dataGridColumns}
                    slots={{toolbar: FlexibleToolbar}}
                    slotProps={{
                        toolbar: {
                            filterId: 'input-template-search-text',
                            filterValue: this.state.filterValue,
                            onChangeFilterValue: this.onFilterValueChange,
                        }
                    }}

                    loading={this.props.templateBusy}

                    pagination
                    paginationMode="server"
                    paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
                    onPaginationModelChange={this.onPaginationModelChange}
                    pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

                    sortingMode="server"
                    sortModel={this.state.sortModel}
                    onSortModelChange={this.onSortModelChange}

                    disableRowSelectionOnClick

                    rows={this.props.templateOverviewList}
                    rowCount={this.props.templateOverviewCount}
                    density="compact"
                />
            </Paper>

            <TemplateCreationDialog
                open={this.state.creationDialogOpen}
                onClose={() => this.setState({creationDialogOpen: false})}/>

            <ConfirmationDialog
                title={this.props.t('attention')}
                confirm={this.props.t('template.deleteTemplateConfirm')}
                open={this.state.selectedIdForDeletion !== -1}
                onClose={this.onResetSelectedIdForDeletion}
                onConfirm={this.onTemplateDelete}/>

        </AppContainer>
    }

    onCreationDialogOpen = () => {
        this.setState({creationDialogOpen: true});
    }

    onSessionCreated = () => {
        this.setState({
            pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
        }, () => this.onTemplateFetchOverviewList(true));
    }

    onEditorOpen = (selectedIdForSettings) => {
        this.props.history.push('/template/editor/id=' + selectedIdForSettings);
    }

    onChangeSelectedIdForDeletion = (selectedIdForDeletion) => {
        this.setState({selectedIdForDeletion});
    }

    onResetSelectedIdForDeletion = () => {
        this.setState({selectedIdForDeletion: -1});
    }

    onTemplateDelete = () => {
        const id = this.state.selectedIdForDeletion;
        this.setState({selectedIdForDeletion: -1}, () => this.props.onTemplateDelete(id));
    }

    onFilterValueChange = (e) => {
        this.setState({filterValue: e.target.value}, this.onTemplateFetchOverviewList)
    }

    onPaginationModelChange = ({page, pageSize}) => {
        this.setState({page, pageSize}, this.onTemplateFetchOverviewList)
    }

    onSortModelChange = (sortModel) => {
        this.setState({sortModel}, this.onTemplateFetchOverviewList);
    }

    onTemplateFetchOverviewList = (clearPreviousResult) => {
        // clearPreviousResult is used to prevent showing irrelevant results when switching views (e.g. when library templates already in cache)
        this.props.onTemplateFetchOverviewList({
            page: this.state.page,
            pageSize: this.state.pageSize,
            filterValue: this.state.filterValue,
            sortField: this.state.sortModel.length > 0 ? {
                name: this.state.sortModel[0].field,
                sortOrder: this.state.sortModel[0].sort.toUpperCase()
            } : null
        }, clearPreviousResult)
    }
}

export default withRouter(withTranslation()(connect(
    state => {
        return {
            sessionInfo: state.session.info,

            templateBusy: state.template.busy,
            templateServerError: state.template.serverError,
            templateOverviewList: state.template.overviewList,
            templateOverviewCount: state.template.overviewCount,
            templateActionSuccessfully: state.template.templateActionSuccessfully
        }
    },
    dispatch => {
        return {
            onTemplateFetchOverviewList: (request, clearPreviousResult) => {
                dispatch({
                    type: 'TEMPLATE_FETCH_OVERVIEW_LIST',
                    request,
                    clearPreviousResult
                });
            },
            onTemplateDelete: (id) => {
                dispatch({
                    type: 'TEMPLATE_DELETE',
                    id
                });
            },
        }
    }
)(TemplateOverviewComponent)))
