import React from 'react';
import {Typography} from "@mui/material";

export default ({variant, maxWidth, value}) => {
	return <Typography
		title={value}
		variant={variant || 'h6'}
		sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth}}
	>
		{value}
	</Typography>;
};