import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField
} from "@mui/material";
import {withTranslation} from "react-i18next";

const defaultState = {
	title: '',
	remark: '',
}

class UserProfileCapacityDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			if (!!this.props.current) {
				this.setState({
					...this.props.current
				})
			} else {
				this.setState(defaultState);
			}
		}
	}
	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t(!!this.props.current ? 'user.profileCapacityUpdate' : 'user.profileCapacityCreate')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('user.profileCapacityTitleHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('user.profileCapacityTitle')}
								required
								value={this.state.title}
								onChange={this.onChangeTitle}
								autoComplete="off"
								fullWidth
								autoFocus
								sx={{mb: 2}}
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('user.profileCapacityRemarkHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('user.profileCapacityRemark')}
								value={this.state.remark}
								onChange={this.onChangeRemark}
								autoComplete="off"
								fullWidth
							/>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose}
						id="btn-profile-capacity-cancel"
				>
					{this.props.t('cancel')}
				</Button>
				<Button variant="contained"
						disabled={!this.canContinue()}
						onClick={this.onChange}
						id="profile-capacity-change"
				>
					{this.props.t('ok')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeTitle = (e) => {
		this.setState({title: e.target.value});
	}

	onChangeRemark = (e) => {
		this.setState({remark: e.target.value});
	}

	canContinue = () => {
		return !!this.state.title.trim();
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onChange();
		}
	}

	onChange = () => {
		this.props.onChange({
			id: this.state.id,
			title: this.state.title,
			remark: this.state.remark,
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(UserProfileCapacityDialog));
