import React, {Component} from "react";
import {connect} from "react-redux";
import {add, differenceInDays, differenceInMonths, format, isValid, startOfDay, startOfMonth, sub} from "date-fns";
import {Bar} from "react-chartjs-2";
import {Alert, Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {MAP_DATE_FNS_LOCALE, ROWS_PER_PAGE_OPTIONS, SIGNATURE_TYPES} from "../common/Constants";
import {deepOrange, indigo, lightBlue, orange, purple, teal, yellow} from "@mui/material/colors";
import LoadingComponent from "../common/LoadingComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import ServerErrorComponent from "../common/ServerErrorComponent";

const STATS_CHART_DEFAULT_OPTIONS = {
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true
		}
	},
	maintainAspectRatio: false,
	animations: false,							// animations disabled to prevent lag when hiding multiple datasets in a single clickevent
	plugins: {
		legend: {
			display: true,
			position: 'bottom',
			title: {
				display: true,
				padding: {
					top: 15
				},
				font: {
					weight: 'bold'
				}
			},
			onHover: function(event, legendItem) {
				event.native.target.style.cursor = legendItem ? 'pointer' : 'default';
			},
			onLeave: function (e) {
				e.native.target.style.cursor = 'default';
			},
			onClick: function(e, legendItem, legend) {
				// override to handle hiding all datasets corresponding to the selected legend 'type' (signatureType, approved/rejected)
				const chart = legend.chart;
				const datasets = chart.data.datasets;
				const targetStack = legendItem.stack;
				let hide = chart.isDatasetVisible(legendItem.datasetIndex);
				legendItem.hidden = hide;

				// hide all other stacks of the same type
				for (let i = 0; i < datasets.length; i++){
					if (datasets[i].stack === targetStack) {
						if (hide) {
							chart.hide(i)
						} else {
							chart.show(i);
						}
					}
				}
			},
			labels: {
				// override to only show legend items when firstOfStack is true (so we only get 1 legend item, even though we show datasets for multiple folders)
				generateLabels: chart => chart.data.datasets.map((ds, i) => ({
						firstOfStack: chart.getDatasetMeta(i)._dataset?.firstOfStack,
						stack: chart.getDatasetMeta(i).stack,
						text: !!chart.getDatasetMeta(i)._dataset?.legendLabel ? chart.getDatasetMeta(i)._dataset?.legendLabel : ds.label,
						datasetIndex: i,
						fillStyle: chart.data.datasets[i].backgroundColor,
						strokeStyle: chart.data.datasets[i].backgroundColor,
						hidden: chart.getDatasetMeta(i).hidden
					})).filter(item => {
						return item.firstOfStack;
				})
				,
			}
		}
	}
};

const STATS_BACKGROUND_COLOR_SHADES = ['500', '600', '700', '200', '300', '400'];
const STATS_BACKGROUND_COLOR_HUES = [orange, lightBlue, teal, purple, yellow, indigo, deepOrange];
const STATS_SOURCES = ['SIGNATURES', 'APPROVALS'];
const STATS_SIGNING_ITSME_PARTNERCODE = ['ALL', 'DEFAULT', 'COMPANY'];
const STATS_SIGNING_ORIGIN = ['ALL', 'LOCAL', 'REMOTE'];
const STATS_TIME_PARTITIONS = ['MONTH', 'DAY'];

const COMPANY_FILTER_ALL = -1;
const FOLDER_FILTER_NONE = -1;
const FOLDER_FILTER_ALL = -2;
const SIGNATURE_TYPE_ARRAY = Object.keys(SIGNATURE_TYPES);

class CompanyStatisticsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			statsSource: 'SIGNATURES',
			statsTimePartition: 'MONTH',
			statsSigningOrigin: 'ALL',
			statsSigningPartnerCode: 'ALL',
			statsBeginDate: sub(startOfMonth(new Date()), {months: 12}),
			statsBeginDateError: false,
			statsEndDate: startOfMonth(new Date()),
			statsEndDateError: false,
			statsFolderFilter: [FOLDER_FILTER_NONE],
			filterFolders: false,
			statsCompanyFilter: COMPANY_FILTER_ALL,
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchStatsConfiguration();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!!this.props.companyStatsConfig && !prevProps.companyStatsConfig) {
			if (this.props.companyStatsConfig?.companyList.length === 1) {
				// if there is only 1 company (no child companies), automatically pass the current one as filter context
				this.setState({statsCompanyFilter: this.props.companyStatsConfig.companyList[0].id}, this.onFetchStatsSignatures)
			} else {
				this.onFetchStatsSignatures();
			}
		}
	}

	render() {
		const {statsSource, statsFolderFilter, statsCompanyFilter, statsTimePartition, statsSigningPartnerCode, statsSigningOrigin, statsBeginDate, statsBeginDateError, statsEndDate, statsEndDateError} = this.state;
		const {sessionInfo, companyStatsConfig, companyStatsApprovals, companyStatsSignatures} = this.props;
		const chartOptions = {...STATS_CHART_DEFAULT_OPTIONS,
			plugins: {
				...STATS_CHART_DEFAULT_OPTIONS.plugins,
				legend: {
					...STATS_CHART_DEFAULT_OPTIONS.plugins.legend,
					title: {
						...STATS_CHART_DEFAULT_OPTIONS.plugins.legend.title,
						text: this.props.t('company.statsLegendTitle_' + this.state.statsSource),
					}
				},
			}
		};

		let count = 0;
		let labelFormat;
		let views = [], openTo = '', inputFormat = '';
		if ('MONTH' === statsTimePartition) {
			count = differenceInMonths(statsEndDate, statsBeginDate) + 1;
			labelFormat = 'LLLL yyyy';
			views = ['year', 'month'];
			openTo = 'month';
			inputFormat = 'LLLL yyyy';
		} else if ('DAY' === statsTimePartition) {
			count = differenceInDays(statsEndDate, statsBeginDate) + 1;
			labelFormat = 'dd/LL/yyyy';
			views = ['year', 'month', 'day']
			openTo = 'day';
			inputFormat = 'dd/LL/yyyy';
		}

		const labels = [];
		const offsets = [];
		for (let i = 0; i < count; i++) {
			let duration;
			if ('MONTH' === statsTimePartition) {
				duration = { months: i };
			} else if ('DAY' === statsTimePartition) {
				duration = { days: i };
			} else {
				break;
			}
			const entryDate = add(statsBeginDate, duration);
			labels.push(format(entryDate, labelFormat, {locale: MAP_DATE_FNS_LOCALE(sessionInfo)}));
			offsets.push(format(entryDate, 'yyyy-LL-dd\'T\'HH:mm:ss'));
		}

		const includeFolders = !this.state.statsFolderFilter.includes(FOLDER_FILTER_NONE);
		const datasets = count > 0 ? this.buildDatasets(statsSource, companyStatsSignatures, count, offsets, companyStatsApprovals, includeFolders) : [];
		const folderFilterSelectionList = ([{id: FOLDER_FILTER_NONE, name: this.props.t('company.statsFolderFilter_NONE')}, {id: FOLDER_FILTER_ALL, name: this.props.t('company.statsFolderFilter_ALL')}])
											.concat((companyStatsConfig?.companyList.map(company => company.folderList)
												.reduce((pre, curr) => pre.concat(curr)) || []));

		let columns = [];
		if (!!companyStatsConfig?.companyList && companyStatsConfig.companyList.length > 1) {
			columns = columns.concat([{
				field: 'companyName',
				headerName: this.props.t('company.statsItsmeDetailsCompany'),
				editable: false,
				optional: true,
				flex: 1
			}]);
		}

		if (includeFolders) {
			columns = columns.concat([
				{
					field: 'folderName',
					headerName: this.props.t('company.statsItsmeDetailsFolder'),
					editable: false,
					optional: false,
					flex: 1
				}]);
		}

		columns = columns.concat([
			{
				field: 'itsmeSignFrequentSigner',
				headerName: this.props.t('company.statsItsmeDetailsFrequentSigner'),
				editable: false,
				optional: true,
				flex: 1,
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.itsmeSignFrequentSigner}
						disabled={true}
					/>
			},
			{
				field: 'count',
				headerName: this.props.t('company.statsItsmeDetailsCount'),
				editable: false,
				optional: true,
				flex: 1,
			},
			{
				field: 'itsmeSignUniqueUsers',
				headerName: this.props.t('company.statsItsmeDetailsUniqueSigners'),
				editable: false,
				optional: false,
				flex: 1,
			},
			{
				field: 'itsmeSignFrequentSignerRatio',
				headerName: this.props.t('company.statsItsmeDetailsRatio'),
				description: this.props.t('company.statsItsmeDetailsRatioTooltip'),
				editable: false,
				optional: false,
				flex: 1,
			}

		]);

		return <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
			<ServerErrorComponent serverError={this.props.companyServerError}/>

			<Box sx={{mt: 2}}>
				<Typography>{this.props.t('company.statsInfo')}</Typography>
			</Box>

			<Box sx={{display: 'flex', justifyContent: 'center', gap: 1, flexWrap: 'wrap', mt: 1, mb: 1}}>
				<Select
					size="small"
					value={statsSource}
					onChange={this.onChangeStatsSource}
				>
					{STATS_SOURCES.map(source =>
						<MenuItem key={source} value={source}>
							{this.props.t('company.statsSource_' + source)}
						</MenuItem>)}
				</Select>

				<FormControl size="small">
					<InputLabel id="signature-itsme-partnercode">{this.props.t('company.statsSigningItsmePartnerCode')}</InputLabel>
					<Select
						labelId="signature-itsme-partnercode"
						input={<OutlinedInput label={this.props.t('company.statsSigningItsmePartnerCode')} />}
						size="small"
						value={statsSigningPartnerCode}
						onChange={this.onChangeSigningItsmePartnerCode}
						disabled={statsSource !== 'SIGNATURES'}
						sx={{minWidth: '175px'}}
					>
						{STATS_SIGNING_ITSME_PARTNERCODE.map(type =>
							<MenuItem key={type} value={type}>
								{this.props.t('company.statsSigningItsmePartnerCode_' + type)}
							</MenuItem>)}
					</Select>
				</FormControl>

				<FormControl size="small">
					<InputLabel id="signature-signing-origin">{this.props.t('company.statsSigningOrigin')}</InputLabel>
					<Select
						labelId="signature-signing-origin"
						input={<OutlinedInput label={this.props.t('company.statsSigningOrigin')} />}
						size="small"
						value={statsSigningOrigin}
						onChange={this.onChangeSigningOrigin}
						disabled={statsSource !== 'SIGNATURES'}
						sx={{minWidth: '175px'}}
					>
						{STATS_SIGNING_ORIGIN.map(type =>
							<MenuItem key={type} value={type}>
								{this.props.t('company.statsSigningOrigin_' + type)}
							</MenuItem>)}
					</Select>
				</FormControl>


				{companyStatsConfig?.companyList && companyStatsConfig.companyList.length > 1 &&
					<FormControl size="small" >
						<InputLabel id="company-selection-label">{this.props.t('company.statsCompanyFilterTitle')}</InputLabel>
						<Select
							labelId="company-selection-label"
							input={<OutlinedInput label={this.props.t('company.statsCompanyFilterTitle')} />}
							size="small"
							value={statsCompanyFilter || ''}
							onChange={this.onChangeStatsCompanyFilter}
							sx={{minWidth: '200px'}}
						>
							{
								[{id: COMPANY_FILTER_ALL, name: this.props.t('company.statsCompanyFilter_ALL')}]
									.concat(companyStatsConfig?.companyList)
									.map(company =>
										<MenuItem key={company.id} value={company.id}>
											{company.name}
										</MenuItem>)}
						</Select>
					</FormControl>
				}

				<FormControl size="small">
					<InputLabel id="folder-selection-label">Folder</InputLabel>
					<Select
						labelId="folder-selection-label"
						multiple
						value={statsFolderFilter}
						onChange={this.onChangeFolderFilter}
						disabled={statsSigningOrigin === 'REMOTE'}
						input={<OutlinedInput label="Folder" />}
						renderValue={(folderIds) => folderFilterSelectionList?.filter((folder) => folderIds.includes(folder.id)).map(folder => folder.name).join(', ')}
						sx={{width: '200px'}}
					>
						{folderFilterSelectionList?.map((folder) => (
							<MenuItem key={folder.id} value={folder.id} divider={folder.id === FOLDER_FILTER_NONE || folder.id === FOLDER_FILTER_ALL}>
								<Checkbox size="small" checked={!!statsFolderFilter && statsFolderFilter.indexOf(folder.id) > -1} />
								<ListItemText primary={folder.name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Select
					size="small"
					value={statsTimePartition}
					onChange={this.onChangeStatsTimePartition}
				>
					{STATS_TIME_PARTITIONS.map(mode =>
						<MenuItem key={mode}
								  value={mode}>{this.props.t('company.statsMode_' + mode)}</MenuItem>)}
				</Select>
				<DatePicker
					slotProps={{
						textField: {
							size: 'small',
							autoComplete: 'off',
							error: statsBeginDateError,
							inputProps: {
								placeholder: ''
							}
						}
					}}
					label={this.props.t('company.statsBeginDate')}
					value={statsBeginDate}
					onChange={this.onChangeStatsBeginDate}
					views={views}
					openTo={openTo}
					format={inputFormat}
				/>
				<DatePicker
					slotProps={{
						textField: {
							size: 'small',
							autoComplete: 'off',
							error: statsEndDateError,
							inputProps: {
								placeholder: ''
							}
						}
					}}
					label={this.props.t('company.statsEndDate')}
					value={statsEndDate}
					onChange={this.onChangeStatsEndDate}
					views={views}
					openTo={openTo}
					format={inputFormat}
				/>
			</Box>

			<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
				<Typography variant="h6">{this.props.t('company.statsChart')}</Typography>
				<Box>
					<Button
						variant="contained"
						startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
						onClick={this.onExportStats}
						id="btn-company-statistics-export"
						sx={{marginRight: 'auto'}}
					>
						{this.props.t('export')}
					</Button>
				</Box>
			</Box>

			<Box sx={{height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				{!companyStatsConfig && <LoadingComponent/>}
				{!!companyStatsConfig && datasets.length > 0 && <Bar options={chartOptions} data={{labels, datasets}}/>}
				{!!companyStatsConfig && datasets.length === 0 && <Typography variant="body2">{this.props.t('company.statsNoData')}</Typography>}
			</Box>


			{statsSource === 'SIGNATURES' && <Box sx={{display: 'flex', mt: 2, flexDirection: 'column', gap: 1}}>
				<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
					<Typography variant="h6">{this.props.t('company.statsItsmeDetails')}</Typography>
					<Box>
						<Button
							variant="contained"
							startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
							onClick={this.onExportItsmeDetails}
							id="btn-company-itsme-details-export"
							sx={{marginRight: 'auto'}}
						>
							{this.props.t('export')}
						</Button>
					</Box>
				</Box>

				<Alert severity="info" sx={{mt: 1}}>{this.props.t('company.statsItsmeDetailsInfo')}</Alert>


				<DataGrid
					autoHeight
					disableColumnSelector
					disableColumnFilter
					disableColumnPinning
					columns={columns}

					loading={!this.props.companyStatsSignaturesItsme}
					pagination
					initialState={{
						pagination: { paginationModel: { pageSize: 25 } },
					}}
					paginationMode="client"
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="client"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableRowSelectionOnClick

					rows={this.props.companyStatsSignaturesItsme || []}
					rowCount={this.props.companyStatsSignaturesItsme?.length || 0}
					getRowId={(row) => row.companyId + '-' + row.folderId + '-' + row.itsmeSignFrequentSigner}
					density="compact"/>
			</Box>}
		</Box>;
	}


	buildDatasets(statsSource, companyStatsSignatures, count, offsets, companyStatsApprovals, includeFolders) {
		const datasets = [];

		if ('SIGNATURES' === statsSource) {
			// create a Map<SignatureType, Map<folderId, List<Stat>> so we can handle the colour coding and legend in a structured way
			// (we want to visualize multiple folders as separate stacked bars in a different colours, with the folder name visualized when hovering, and the legend should only visualize the signature type)
			let companyStatsMap = new Map();
			companyStatsSignatures?.forEach(stat => {
				if (!companyStatsMap.get(stat.signatureType)) {
					companyStatsMap.set(stat.signatureType, new Map())
				}
				const signatureTypeMapItem = companyStatsMap.get(stat.signatureType);
				if (!signatureTypeMapItem.get(stat.folderId)) {
					signatureTypeMapItem.set(stat.folderId, []);
				}
				signatureTypeMapItem.get(stat.folderId).push(stat);
			});

			for (const [signatureType, signatureTypeMap] of companyStatsMap) {
				let folderIdx = 0;
				for (const [folderId, statsList] of signatureTypeMap) {
					for (let i = 0; i < statsList.length; i++) {
						let stat = statsList[i];
						let dataset = datasets.find(search => search.stack === stat.signatureType && search.folderId === stat.folderId);
						if (!dataset) {
							// since we only want a single legend item per signature type, keep track if this is the first entry for the signature type in the dataset
							dataset = {
								stack: stat.signatureType,
								firstOfStack: folderIdx === 0 && i === 0,
								data: new Array(count).fill(0),
								legendLabel: this.props.t('signing.signingMethod_' + signatureType),
								label: this.props.t('signing.signingMethod_' + signatureType) + (includeFolders ? ' (' + stat.folderName + ')' : ''),
								backgroundColor: this.getBackgroundColor(SIGNATURE_TYPE_ARRAY.indexOf(signatureType), folderIdx)
							}
							datasets.push(dataset);
						}

						const offset = offsets.indexOf(stat.dateTime);
						if (offset >= 0) {
							dataset.data[offset] = stat.count;
						}
					}
					folderIdx++;
				}
			}

			// populate the dataset with all other signature types
			SIGNATURE_TYPE_ARRAY.forEach(signatureType => {
				if (!(datasets.find(search => search.stack === signatureType))) {
					datasets.push({
						stack: signatureType,
						firstOfStack: true,
						data: new Array(count).fill(0),
						label: this.props.t('signing.signingMethod_' + signatureType),
						backgroundColor: this.getBackgroundColor(SIGNATURE_TYPE_ARRAY.indexOf(signatureType), 0)
					})
				}
			})

			// sort by signature type to prevent flinching of the legend (order might change)
			datasets.sort((a, b) => SIGNATURE_TYPE_ARRAY.indexOf(a.stack) - SIGNATURE_TYPE_ARRAY.indexOf(b.stack));

		} else if ('APPROVALS' === statsSource) {
			// create a Map<approved, Map<folderId, List<Stat>> so we can handle the colour coding and legend in a structured way
			let companyStatsMap = new Map();
			companyStatsApprovals?.forEach(stat => {
				if (!companyStatsMap.get(stat.approved)) {
					companyStatsMap.set(stat.approved, new Map())
				}
				const approvedMapItem = companyStatsMap.get(stat.approved);
				if (!approvedMapItem.get(stat.folderId)) {
					approvedMapItem.set(stat.folderId, []);
				}
				approvedMapItem.get(stat.folderId).push(stat);
			});

			for (const [approved, approvedMapMap] of companyStatsMap) {
				let folderIdx = 0;
				for (const [folderId, statsList] of approvedMapMap) {
					for (let i = 0; i < statsList.length; i++) {
						let stat = statsList[i];
						let dataset = datasets.find(search => search.stack === stat.approved && search.folderId === stat.folderId);
						if (!dataset) {
							// since we only want a single legend item per approved/disapproved , keep track if this is the first entry for the type in the dataset
							dataset = {
								stack: stat.approved,
								firstOfStack: folderIdx === 0 && i === 0,
								data: new Array(count).fill(0),
								legendLabel: this.props.t(stat.approved ? 'approval.approvalRequestState_APPROVED' : 'approval.approvalRequestState_DECLINED'),
								label: this.props.t(stat.approved ? 'approval.approvalRequestState_APPROVED' : 'approval.approvalRequestState_DECLINED') + (includeFolders ? ' (' + stat.folderName + ')' : ''),
								backgroundColor: this.getBackgroundColor(approved ? 0 : 1, folderIdx)
							}
							datasets.push(dataset);
						}

						const offset = offsets.indexOf(stat.dateTime);
						if (offset >= 0) {
							dataset.data[offset] = stat.count;
						}
					}
					folderIdx++;
				}
			}

			[true, false].forEach(approved => {
				if (!(datasets.find(search => search.stack === approved))) {
					datasets.push({
						stack: approved,
						firstOfStack: true,
						data: new Array(count).fill(0),
						label: this.props.t(approved ? 'approval.approvalRequestState_APPROVED' : 'approval.approvalRequestState_DECLINED'),
						backgroundColor: this.getBackgroundColor(approved ? 0 : 1, 0)
					})
				}});
		}

		return datasets;
	}

	getBackgroundColor = (stackIndex, statIndex) => {
		const hueColor = STATS_BACKGROUND_COLOR_HUES[stackIndex % STATS_BACKGROUND_COLOR_HUES.length];
		return hueColor[STATS_BACKGROUND_COLOR_SHADES[statIndex % STATS_BACKGROUND_COLOR_SHADES.length]];
	}

	onChangeFolderFilter = (e) => {
		let filter = e.target.value;
		const previousFolderFilter = this.state.statsFolderFilter;
		const newValue = filter.filter(x => !previousFolderFilter.includes(x))[0];

		if (!newValue && previousFolderFilter.length === 1) {
			return;
		}

		// if the user selected 'ALL' or 'NONE', clear all other values
		if (FOLDER_FILTER_ALL === newValue) {
			filter = [FOLDER_FILTER_ALL];
		} else if (FOLDER_FILTER_NONE === newValue) {
			filter = [FOLDER_FILTER_NONE];
		} else if (previousFolderFilter.includes(FOLDER_FILTER_ALL) || previousFolderFilter.includes(FOLDER_FILTER_NONE)) {
			filter = [newValue];
		}

		this.setState({statsFolderFilter: filter}, this.onFetchStatsSignatures)
	}

	onChangeStatsCompanyFilter = (e) => {
		const statsCompanyFilter = e.target.value;
		this.setState({statsCompanyFilter}, this.onFetchStatsSignatures);
	}

	onChangeStatsSource = (e) => {
		const statsSource = e.target.value;
		this.setState({
			statsSource,
			...(statsSource === 'APPROVALS' && {statsSigningOrigin: 'ALL', statsSigningPartnerCode: 'ALL'})
		}, this.onFetchStatsSignatures);
	}

	onChangeSigningOrigin = (e) => {
		const statsSigningOrigin = e.target.value;
		this.setState({
			statsSigningOrigin,
			...(statsSigningOrigin === 'REMOTE' && {statsFolderFilter: [FOLDER_FILTER_NONE]})
		}, this.onFetchStatsSignatures);
	}

	onChangeSigningItsmePartnerCode = (e) => {
		const statsSigningPartnerCode = e.target.value;
		this.setState({
			statsSigningPartnerCode,
		}, this.onFetchStatsSignatures);
	}

	onChangeStatsTimePartition = (e) => {
		const statsTimePartition = e.target.value;

		if ('MONTH' === statsTimePartition) {
			const statsEndDate = startOfMonth(this.state.statsEndDate);
			const statsBeginDate = sub(statsEndDate, {months: 12})
			this.setState({statsTimePartition, statsBeginDate, statsEndDate}, this.onFetchStatsSignatures);
		} else if ('DAY' === statsTimePartition) {
			const statsEndDate = startOfDay(new Date());
			const statsBeginDate = sub(statsEndDate, {months: 1})
			this.setState({statsTimePartition, statsBeginDate, statsEndDate}, this.onFetchStatsSignatures);
		}
	}

	onChangeStatsBeginDate = (value) => {
		if (null === value || !isValid(value)) {
			this.setState({statsBeginDateError: true});
			return;
		}

		const {statsEndDate, statsTimePartition} = this.state;
		if ('MONTH' === statsTimePartition) {
			const delta = differenceInMonths(statsEndDate, value);
			if (delta < 1 || delta > 24) {
				this.setState({statsBeginDateError: true});
				return;
			}
		} else if ('DAY' === statsTimePartition) {
			const delta = differenceInDays(statsEndDate, value);
			if (delta < 1 || delta > 365) {
				this.setState({statsBeginDateError: true});
				return;
			}
		}

		this.setState({statsBeginDate: value, statsBeginDateError: false}, this.onFetchStatsSignatures);
	}

	onChangeStatsEndDate = (value) => {
		if (null === value || !isValid(value)) {
			this.setState({statsEndDateError: true});
			return;
		}

		const {statsBeginDate, statsTimePartition} = this.state;
		if ('MONTH' === statsTimePartition) {
			const delta = differenceInMonths(value, statsBeginDate);
			if (delta < 1 || delta > 24) {
				this.setState({statsEndDateError: true});
				return;
			}
		} else if ('DAY' === statsTimePartition) {
			const delta = differenceInDays(value, statsBeginDate);
			if (delta < 1 || delta > 365) {
				this.setState({statsEndDateError: true});
				return;
			}
		}

		this.setState({statsEndDate: value, statsEndDateError: false}, this.onFetchStatsSignatures);
	}

	createRequest = () =>  {
		const {statsBeginDate, statsEndDate, statsTimePartition, statsSigningOrigin, statsSigningPartnerCode} = this.state;
		const from = format(statsBeginDate, 'yyyy-LL-dd\'T\'HH:mm:ss');
		const to = format(add(statsEndDate, ('MONTH' === statsTimePartition ? {months: 1} : {days: 1})), 'yyyy-LL-dd\'T\'HH:mm:ss');
		const companyId = this.state.statsCompanyFilter === COMPANY_FILTER_ALL ? null : this.state.statsCompanyFilter;

		const noneFolderFilter = this.state.statsFolderFilter.includes(FOLDER_FILTER_NONE);
		const allFolderFilter = this.state.statsFolderFilter.includes(FOLDER_FILTER_ALL);
		const folderIds = noneFolderFilter || allFolderFilter ? null : this.state.statsFolderFilter;

		return {
			from,
			to,
			companyId: companyId,
			includeChildCompanies: companyId === null,
			companyPartition: !!companyId,
			folderPartition: !noneFolderFilter,
			folderIds: folderIds,
			timePartition: statsTimePartition,
			signingOrigin: statsSigningOrigin,
			itsmePartnerCodeFilter: statsSigningPartnerCode
		};
	}

	createItsmeRequest = () =>  {
		const request = this.createRequest();
		return {
			...request,
			timePartition: 'NONE',
			companyPartition: this.props.companyStatsConfig?.companyList?.length > 1
		};
	}

	onFetchStatsSignatures = () => {
		const statsSource = this.state.statsSource;

		if ('SIGNATURES' === statsSource) {
			this.props.onCompanyFetchStatsSignatures(this.createRequest());
			this.props.onCompanyFetchStatsSignaturesItsme(this.createItsmeRequest());
		} else if ('APPROVALS' === statsSource) {
			this.props.onCompanyFetchStatsApprovals(this.createRequest());
		}
	}

	onExportStats = () => {
		const statsSource = this.state.statsSource;

		if ('SIGNATURES' === statsSource) {
			this.props.onCompanyExportStatsSignatures(this.createRequest())
		} else if ('APPROVALS' === statsSource) {
			this.props.onCompanyExportStatsApprovals(this.createRequest())
		}
	}

	onExportItsmeDetails = () => {
		this.props.onCompanyExportItsmeDetails(this.createItsmeRequest())
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyStatsConfig: state.company.statsConfig,
			companyStatsApprovals: state.company.statsApprovals,
			companyStatsSignatures: state.company.statsSignatures,
			companyStatsSignaturesItsme: state.company.statsSignaturesItsme,
		}
	},
	dispatch => {
		return {
			onCompanyFetchStatsApprovals: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_STATS_APPROVALS',
					request
				})
			},
			onCompanyExportStatsApprovals: (request) => {
				dispatch({
					type: 'COMPANY_EXPORT_STATS_APPROVALS',
					request
				})
			},
			onCompanyFetchStatsSignatures: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_STATS_SIGNATURES',
					request
				})
			},
			onCompanyFetchStatsSignaturesItsme: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_STATS_SIGNATURES_ITSME',
					request
				})
			},
			onCompanyExportStatsSignatures: (request) => {
				dispatch({
					type: 'COMPANY_EXPORT_STATS_SIGNATURES',
					request
				})
			},
			onCompanyExportItsmeDetails: (request) => {
				dispatch({
					type: 'COMPANY_EXPORT_STATS_ITSME_DETAILS',
					request
				})
			},
			onCompanyFetchStatsConfiguration: () => {
				dispatch( {
					type: 'COMPANY_FETCH_STATS_CONFIG'
				})
			}
		}
	}
)(CompanyStatisticsComponent));
