import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {styled} from "@mui/material/styles";
import {connect} from "react-redux";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {ROWS_PER_PAGE_OPTIONS} from "../common/Constants";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-columnHeaders": {display: "none"},
    "& .MuiDataGrid-virtualScroller": {marginTop: "0!important"},
}));

const defaultState = {
    filterValue: '',
    page: 0,
    pageSize: 25,
    sortModel: [{field: 'firstName', sort: 'asc'}],

    selectedId: null,
    selected: false,
};

class EditorApplyTemplateDialog extends Component {

    constructor(props) {
        super(props);

        this.state = defaultState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.setState(defaultState, () => this.onFetchTemplateList(true));
        }
        if (!!this.props.templateCurrent && !prevProps.templateCurrent) {
            this.props.onSelect(this.props.templateCurrent);
        }
    }

    render() {
        const columns = [
            {
                field: 'name',
                editable: false,
                flex: 1
            },
            {
                field: 'creatorName',
                editable: false,
                sortable: false,
                flex: 1
            },
            {
                field: 'documentFolderName',
                editable: false,
                sortable: false,
                flex: 1
            },
        ];

        return <Dialog open={this.props.open}
                       onClose={this.onClose}
                       onKeyUp={this.onKeyUp}
                       disableRestoreFocus
                       fullWidth
                       maxWidth="md">
            <DialogTitle>{this.props.t('editor.templateApply')}</DialogTitle>
            <DialogContent>
                <ServerErrorComponent serverError={this.props.templateServerError} />

                <StyledDataGrid
                    disableColumnSelector
                    disableColumnFilter
                    keepNonExistentRowsSelected
                    checkboxSelection
                    hideFooterSelectedRowCount

                    rowSelectionModel={!!this.state.selectedId ? [this.state.selectedId] : []}
                    onRowSelectionModelChange={this.onChangeSelectedId}
                    columns={columns}
                    slots={{toolbar: FlexibleToolbar}}
                    slotProps={{
                        baseCheckbox: {
                            tabIndex: 0
                        },
                        toolbar: {
                            filterId: 'input-editor-template-search-text',
                            filterValue: this.state.filterValue,
                            onChangeFilterValue: this.onFilterValueChange,
                        }
                    }}

                    loading={this.props.templateBusy}

                    pagination
                    paginationMode="server"
                    paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
                    onPaginationModelChange={this.onPaginationModelChange}
                    pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

                    sortingMode="server"
                    sortModel={this.state.sortModel}
                    onSortModelChange={this.onSortModelChange}

                    rows={this.props.templateOverviewList}
                    rowCount={this.props.templateOverviewCount}

                    density="compact"
                    sx={{height: 500}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('cancel')}</Button>
                <Button variant="contained"
                        onClick={this.onSelect}
                        id="btn-selection-confirm"
                        disabled={!this.state.selectedId || this.state.selected}
                >
                    {this.props.t('select')}
                </Button>
            </DialogActions>
        </Dialog>
    }

    onKeyUp = (e) => {
        if (e.key === 'Enter' && !!this.state.selectedId) {
            this.onSelect();
        }
    }

    onChangeSelectedId = (selectedValues) => {
        const currentSelection = this.state.selectedId;
        const newValue = selectedValues.find(el => !currentSelection || el !== currentSelection);
        this.setState({selectedId: newValue});
    }

    onFilterValueChange = (e) => {
        this.setState({filterValue: e.target.value}, this.onFetchTemplateList);
    }

    onPaginationModelChange = ({page, pageSize}) => {
        this.setState({page, pageSize}, this.onFetchTemplateList);
    }

    onSortModelChange = (sortModel) => {
        this.setState({sortModel}, this.onFetchTemplateList);
    }

    onFetchTemplateList = (clearPreviousResult) => {
        this.props.onTemplateFetchOverviewList({
            page: this.state.page,
            pageSize: this.state.pageSize,
            filterValue: this.state.filterValue,
            folderId: this.props.folderId,
            sortField: this.state.sortModel.length > 0 ? {
                name: this.state.sortModel[0].field,
                sortOrder: this.state.sortModel[0].sort.toUpperCase()
            } : null
        }, clearPreviousResult)
    }

    onSelect = () => {
        this.setState({ selected: true },
            () => this.props.onTemplateFetch(this.state.selectedId));
    }

    onClose = (e, reason) => {
        if (reason !== 'backdropClick') {
            this.props.onClose();
        }
    }

}

export default withTranslation()(connect(
    state => {
        return {
            templateBusy: state.template.busy,
            templateServerError: state.template.serverError,
            templateOverviewList: state.template.overviewList,
            templateOverviewCount: state.template.overviewCount,
            templateCurrent: state.template.current,
        }
    },
    dispatch => {
        return {
            onTemplateFetchOverviewList: (request, clearPreviousResult) => {
                dispatch({
                    type: 'TEMPLATE_FETCH_OVERVIEW_LIST',
                    request,
                    clearPreviousResult
                });
            },
            onTemplateFetch: (id) => {
                dispatch({
                    type: 'TEMPLATE_FETCH',
                    id
                });
            },
        }
    }
)(EditorApplyTemplateDialog));
