import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Tab, Tabs, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FlexibleToolbar from "../common/FlexibleToolbar";
import ServerErrorComponent from "../common/ServerErrorComponent";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import {formatInTimeZone} from "date-fns-tz";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS,
	MOVE_DEPRECATED_CONFIGURATION
} from "../common/DataGridColumUtil";
import {DEBOUNCE} from "../common/DebounceUtil";

const COLUMN_CONFIG_KEY = 'doc-signing-overview';

class DocumentSigningOverviewComponent extends Component {

	constructor(props) {
		super(props);

		MOVE_DEPRECATED_CONFIGURATION("doc-signing-overview-columns", COLUMN_CONFIG_KEY)

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'documentName', sort: 'asc'}],
			// selection
			selectedIds: [],
			// tabs
			tab: 'WAIT_FOR_SIGNATURE',
			// download menu
			downloadMenuAnchorEl: null,
			// columns
			columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['documentName', 'documentDescription', 'documentFolderName', 'orderType', 'creatorName', 'approvers']),
		}

		this.possibleTabs = [
			'ALL',
			'WAIT_FOR_SIGNATURE',
			'DOWNLOAD'
		];
	}

	render() {
		const idsOfDocumentsThatCanBeSigned = this.props.documentSigningOverviewList
			.filter(entry => this.state.selectedIds.indexOf(entry.documentId) >= 0 && entry.signatureCount > 0)
			.map(entry => entry.documentId)
		;

		const columnConfiguration = this.state.columnConfiguration;
		const allPossibleDataGridColumns = [
			{
				field: 'documentName',
				headerName: this.props.t('document.name'),
				editable: false,
				optional: false,
				...COLUMN_WIDTH(columnConfiguration, 'documentName', {flex: 1}),
				renderCell: (cellValues) => <>
					{cellValues.row.documentCollection && <FileCopyIcon fontSize="small" sx={{mr:0.5}} />}
					{cellValues.row.documentName +
						(cellValues.row.signatureCount > 1 ? (' (' + cellValues.row.signatureCount + ')') : '')}
				</>
			},
			{
				field: 'requestedAt',
				headerName: this.props.t('document.requestedAt'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'requestedAt', {flex: 1}),
				valueGetter: (data) => !!data.value ? formatInTimeZone(data.value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'documentDescription',
				headerName: this.props.t('document.description'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'documentDescription', {flex: 1}),
			},
			{
				field: 'documentFolderName',
				headerName: this.props.t('document.folderName'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'documentFolderName', {flex: 1}),
			},
			{
				field: 'signingOrderType',
				headerName: this.props.t('document.signOrderType'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'signingOrderType', {flex: 1}),
				valueGetter: (data) => this.props.t('document.signingOrderType_' + data.value)
			},
			{
				field: 'creatorName',
				headerName: this.props.t('document.creator'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'creatorName', {flex: 1}),
			},
			{
				field: 'approvers',
				headerName: this.props.t('document.approvers'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'approvers', {flex: 1}),
				valueGetter: (data) => (data.value || []).join(', ')
			},
			{
				field: 'actions',
				headerName: this.props.t('document.actions'),
				editable: false,
				sortable: false,
				resizable: false,
				disableColumnMenu: true,
				disableReorder: true,
				optional: false,
				width: 90,
				renderCell: (cellValues) => {
					const canSign = cellValues.row.signatureCount > 0;
					const canDownload = cellValues.row.userCanDownload
					return <>
						<IconButton
							variant="contained"
							color="primary"
							title={this.props.t('document.download')}
							disabled={!canDownload}
							onClick={() => this.props.onDocumentSingleDownloadArtifacts(cellValues.row.documentId, ['DOCUMENT'])}
						>
							<DownloadIcon fontSize="small"/>
						</IconButton>
						<IconButton
							variant="contained"
							color="primary"
							title={canSign ? this.props.t('document.sign') : this.props.t('document.view')}
							onClick={() => this.onDocumentNavigateToSign([cellValues.row.documentId])}
						>
							{canSign ?
								<DriveFileRenameOutlineIcon fontSize="small"/> :
								<VisibilityIcon fontSize="small"/>}
						</IconButton>

					</>
				}
			},
		];

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('document.signingOverviewHeader')}</Typography>
				<ServerErrorComponent serverError={this.props.documentServerError}/>
				<Box sx={{mb: 1}}>
					<Tabs value={this.state.tab} onChange={this.onChangeTab}>
						{this.possibleTabs.map(tab =>
							<Tab key={tab} value={tab} label={this.props.t('document.signingOverviewTab_' + tab)}/>
						)}
					</Tabs>
				</Box>
				<Typography variant="body2"
							sx={{mt: 2, mb: 2}}>{this.props.t('document.downloadDisclaimer')}</Typography>
				<DataGrid
					autoHeight
					disableColumnSelector
					disableColumnFilter
					disableColumnPinning
					columns={filteredColumns}
					onColumnResize={this.onResizeColumn}
					onColumnOrderChange={this.onColumnOrderChange}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-document-signing-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
							possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
							columns: filteredColumns.map(column => column.field),
							onChangeColumns: this.onChangeExtraColumns,
							onResetColumnsWidth: this.onResetColumnsWidth,
						}
					}}

					loading={this.props.documentBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableRowSelectionOnClick
					checkboxSelection
					onRowSelectionModelChange={this.onRowSelectionModelChange}
					isRowSelectable={(params) => this.state.tab !== 'WAIT_FOR_SIGNATURE' || params.row.signingOrderType !== 'KIOSK'}

					getRowId={(row) => row.documentId}
					rows={this.props.documentSigningOverviewList}
					rowCount={this.props.documentSigningOverviewCount}
					density="compact"/>

				<Box sx={{mt: 2}}>
					{this.state.tab !== 'DOWNLOAD' && <Button
						variant="contained"
						startIcon={<DriveFileRenameOutlineIcon/>}
						disabled={0 === idsOfDocumentsThatCanBeSigned.length}
						onClick={() => this.onDocumentNavigateToSign(idsOfDocumentsThatCanBeSigned)}
						sx={{mr: 2}}
						id="btn-signing-bulk"
					>
						{this.props.t('document.signDocuments') + ' (' + idsOfDocumentsThatCanBeSigned.length + ')'}
					</Button>}
					{this.state.tab === 'DOWNLOAD' && <>
						<Button
							variant="contained"
							startIcon={<DownloadIcon/>}
							disabled={0 === this.state.selectedIds.length}
							onClick={this.onDownloadMenuOpen}
							id="btn-document-download"
						>
							{this.props.t('document.download') + ' (' + this.state.selectedIds.length + ')'}
						</Button>
						<Menu
							anchorEl={this.state.downloadMenuAnchorEl}
							open={!!this.state.downloadMenuAnchorEl}
							id="download-menu"
							onClose={this.onDownloadMenuClose}
							onClick={this.onDownloadMenuClose}
						>
							{[
								{
									label: this.props.t('document.downloadDocuments'),
									icon: <InsertDriveFileIcon fontSize="small"/>,
									types: ['DOCUMENT']
								},
								{
									label: this.props.t('document.downloadEvidenceReports'),
									icon: <AssignmentIcon fontSize="small"/>,
									types: ['EVIDENCE_REPORT']
								},
								{
									label: this.props.t('document.downloadAll'),
									icon: <FolderZipIcon fontSize="small"/>,
									types: ['DOCUMENT', 'EVIDENCE_REPORT']
								}
							].map((item, index) =>
								<MenuItem key={index}
										  onClick={() => this.props.onDocumentDownloadArtifacts(this.state.selectedIds, item.types)}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText>{item.label}</ListItemText>
								</MenuItem>)
							}
						</Menu>
					</>}
				</Box>
			</Paper>
		</AppContainer>
	}

	onSessionCreated = () => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onDocumentFetchSigningOverviewList());
	}

	onChangeTab = (e, value) => {
		this.setState({tab: value}, this.onDocumentFetchSigningOverviewList);
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, DEBOUNCE(this.onDocumentFetchSigningOverviewList, 300));
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onDocumentFetchSigningOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onDocumentFetchSigningOverviewList);
	}

	onRowSelectionModelChange = (selectedIds) => {
		this.setState({selectedIds});
	}

	onDocumentFetchSigningOverviewList = () => {
		this.props.onDocumentFetchSigningOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null,
			...(this.state.tab === 'WAIT_FOR_SIGNATURE' && {
				stateFilter: 'WAITING_FOR_SIGNATURE'
			})
		})
	}

	onDownloadMenuOpen = (e) => {
		this.setState({downloadMenuAnchorEl: e.currentTarget});
	}

	onDownloadMenuClose = (e) => {
		this.setState({downloadMenuAnchorEl: null});
	}

	onDocumentNavigateToSign = (ids) => {
		this.props.history.push('/sign/ids=' + ids
			.reduce((arr, id) => arr.indexOf(id) === -1 ? arr.concat([id]) : arr, [])
			.join('-'));
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			documentBusy: state.document.busy,
			documentServerError: state.document.serverError,
			documentSigningOverviewList: state.document.signingOverviewList,
			documentSigningOverviewCount: state.document.signingOverviewCount,
		}
	},
	dispatch => {
		return {
			onDocumentFetchSigningOverviewList: (request) => {
				dispatch({
					type: 'DOCUMENT_FETCH_SIGNING_OVERVIEW_LIST',
					request
				});
			},
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types
				});
			},
			onDocumentDownloadArtifacts: (ids, types) => {
				dispatch({
					type: 'DOCUMENT_DOWNLOAD_ARTIFACTS',
					ids,
					types
				});
			},
		}
	}
)(DocumentSigningOverviewComponent)));
