import React, {Component} from "react";
import {Redirect, withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AppContainer from "../common/AppContainer";
import {Paper, Typography} from "@mui/material";

class IndexComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('notFound.message')}</Typography>
			</Paper>
		</AppContainer>
	}

	onSessionCreated = () => {
		if (this.props.sessionInfo?.companyDocumentOverviewAccessible) {
			this.props.history.push('/document/general-overview');
		} else if (!this.props.sessionInfo?.guestAccess) {
			this.props.history.push('/document/signing-overview');
		} else if (!!this.props.sessionGuestPath) {
			this.props.history.push(this.props.sessionGuestPath);
		}
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionGuestPath: state.session.guestPath,
		}
	},
	dispatch => {
		return {}
	}
)(IndexComponent)));
