
export const TEMPLATE_OPTIONS = {
	CONFIDENTIALITY: 0,
	APPROVERS: 1,
	APPROVERS_MAIL: 2,
	SIGNERS: 3,
	SIGNERS_MAIL: 4,
	POSTSIGN_DISTRIBUTION_LIST_WITHOUT_EVIDENCE_REPORT: 5,
	POSTSIGN_DISTRIBUTION_LIST_WITH_EVIDENCE_REPORT: 6
};
