import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";

class CompanySwitchComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.onCompanyFetchSwitchList();
	}

	render() {
		if (!this.props.companySwitchList) {
			return <LoadingComponent />;
		}

		return <Box sx={{ mt: 2 }}>
			<ServerErrorComponent serverError={this.props.companyServerError} />

			<Typography variant="h6">{this.props.t('company.switchCurrentCompany')}</Typography>
			<Box sx={{ mb: 2, display: 'flex' }}>
				{this.renderCompanyBox({
					id: this.props.sessionInfo.companyId,
					name: this.props.sessionInfo.companyName,
					logoBase64: this.props.sessionInfo.companyStyle.logoBase64
				})}
			</Box>

			<Typography variant="h6">{this.props.t('company.switchToOtherCompany')}</Typography>
			<Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap' }}>
				{this.props.companySwitchList
					.filter(entry => entry.id !== this.props.sessionInfo.companyId)
					.map(this.renderCompanyBox)}
			</Box>
		</Box>
	}

	renderCompanyBox = (entry) => {
		return <Card key={entry.id} variant="outlined" sx={{ mr: 2, mt: 2 }}>
			{!!entry.logoBase64 && <CardMedia
				component="img"
				height="50"
				image={'data:image/png;base64,' + entry.logoBase64}
				sx={{ pl: 2, pr: 2, pt: 1 }}
			/>}
			<CardContent>
				<Typography sx={{ fontSize: 14 }} component="div">
					{entry.name}
				</Typography>
			</CardContent>
			{(entry.id !== this.props.sessionInfo.companyId) && <CardActions>
				<Button size="small" onClick={() => this.onSwitchCompany(entry.id)}
						disabled={this.props.sessionBusy || this.props.companyBusy} id="btn-company-change">
					{this.props.t('company.switchTo')}
				</Button>
			</CardActions>}
		</Card>;
	}

	onSwitchCompany = (id) => {
		this.props.onSessionSwitchCompany({ companyId: id });
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionBusy: state.session.busy,
			sessionInfo: state.session.info,
			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companySwitchList: state.company.switchList
		}
	},
	dispatch => {
		return {
			onCompanyFetchSwitchList: () => {
				dispatch({
					type: 'COMPANY_FETCH_SWITCH_LIST'
				});
			},
			onSessionSwitchCompany: (request) => {
				dispatch({
					type: 'SESSION_SWITCH_COMPANY',
					request
				});
			}
		}
	}
)(CompanySwitchComponent));
