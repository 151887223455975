import React, {Component} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class SigningRedirectDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			setTimeout(() => {
				window.location.assign(this.props.redirectUrl);
			}, this.props.redirectImmediate ? 0 : 5000);
		}
	}

	render() {
		return <Dialog open={this.props.open} onClose={this.onClose} fullWidth maxWidth="sm">
			<DialogTitle>{this.props.t('signing.redirectTitle')}</DialogTitle>
			<DialogContent>
				<Typography variant="body2">
					{this.props.t('signing.redirectDetails').split('$').map((v, index) => {
						if (v.indexOf('link') === 0) {
							const elements = v.split(';');
							return <Link key={index} href={this.props.redirectUrl}>{elements[1]}</Link>
						} else {
							return <span key={index}>{v}</span>
						}
					})}
				</Typography>
			</DialogContent>
			<DialogActions>
			</DialogActions>
		</Dialog>
	}

	onClose = (e, reason) => {}

}

export default withTranslation()(connect(
	state => {
		return {
		}
	},
	dispatch => {
		return {

		}
	}
)(SigningRedirectDialog));
