const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// signer group list for overview
	overviewList: [],
	overviewCount: 0,

	// signer group user list
	userList: [],
	userCount: 0,

	// signer group possible user list
	possibleUserList: [],
	possibleUserCount: 0,

	// action success responses
	signerGroupActionSuccessfully: false,
	createdSignerGroupId: null,

	// current signer settings
	settings: null,

	// current approvers/signers
	approversSigners: null,
};

const signerGroup = (state = defaultState, action) => {
	switch (action.type) {
		case 'SIGNERGROUP_ERROR':
			return {
				...state,
				serverError: action.serverError,
				busy: false
			}

		case 'SIGNERGROUP_FETCH_OVERVIEW_LIST':
			return {
				...state,
				serverError: null,
				busy: true
			}

		case 'SIGNERGROUP_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'SIGNERGROUP_FETCH_SETTINGS_SUCCESS': {
			const settings = action.data;

			return {
				...state,
				busy: false,
				serverError: null,
				settings,
			}
		}

		case 'SIGNERGROUP_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				signerGroupActionSuccessfully: false,
				createdSignerGroupId: null,
			}

		case 'SIGNERGROUP_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				signerGroupActionSuccessfully: true,
				createdSignerGroupId: action.createdSignerGroupId,
			}

		case 'SIGNERGROUP_DELETE':
		case 'SIGNERGROUP_UPDATE_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				signerGroupActionSuccessfully: false
			}

		case 'SIGNERGROUP_DELETE_SUCCESS':
		case 'SIGNERGROUP_UPDATE_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				signerGroupActionSuccessfully: true
			}

		case 'SIGNERGROUP_CHANGE_SETTING':
			const settings = {
				...state.settings
			};

			settings[action.key] = action.value;

			return {
				...state,
				settings
			}

		case 'SIGNERGROUP_FETCH_USER_LIST':
			return {
				...state,
				serverError: null,
				userListBusy: true,
				userList: [],
				userCount: 0
			}

		case 'SIGNERGROUP_FETCH_USER_LIST_SUCCESS':
			return {
				...state,
				serverError: null,
				userListBusy: false,
				userList: action.list,
				userCount: action.count
			}

		case 'SIGNERGROUP_ADD_USERS':
		case 'SIGNERGROUP_REMOVE_USER':
			return {
				...state,
				serverError: null,
				userListBusy: true,
			}

		case 'SIGNERGROUP_FETCH_POSSIBLE_USER_LIST':
			return {
				...state,
				serverError: null,
				possibleUserListBusy: true,
				possibleUserList: [],
				possibleUserCount: 0
			}

		case 'SIGNERGROUP_FETCH_POSSIBLE_USER_LIST_SUCCESS':
			return {
				...state,
				serverError: null,
				possibleUserListBusy: false,
				possibleUserList: action.list,
				possibleUserCount: action.count
			}

		case 'SIGNERGROUP_FETCH_APPROVERS_SIGNERS':
			return {
				...state,
				serverError: null,
				busy: true,
				approversSigners: null
			}

		case 'SIGNERGROUP_FETCH_APPROVERS_SIGNERS_SUCCESS':
			return {
				...state,
				serverError: null,
				busy: false,
				approversSigners: action.data
			}

		default:
			return state;
	}
}

export default signerGroup;