import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router"
import menu from "./menu";
import approval from "./approval";
import company from "./company";
import document from "./document";
import editor from "./editor";
import eid from "./eid";
import folder from "./folder";
import oidcInstance from "./oidcInstance";
import application from "./application";
import session from "./session";
import signerGroup from "./signerGroup";
import signing from "./signing";
import snackbar from "./snackbar";
import template from "./template";
import user from "./user";
import userGroup from "./userGroup";

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	application,
	approval,
	company,
	document,
	editor,
	eid,
	folder,
	oidcInstance,
	menu,
	session,
	signerGroup,
	signing,
	snackbar,
	template,
    user,
    userGroup,
});

export default createRootReducer
