import React, {Component, useState} from "react";
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {format, parse} from "date-fns";
import {FORM_FIELD_DATE_VALUE_FORMAT, FORM_FIELD_DATE_VISUAL_FORMAT, IS_MOBILE_DEVICE} from "../common/Constants";
import FormattedTextField from "../common/FormattedTextField";

const AutoOpenDatePicker = ({value, onChange, mobileDevice}) => {
	// automatically open the datepicker when on mobile
	const [open,setOpen] = useState(mobileDevice);

	return <DatePicker
		open={open}
		onClose={() => setOpen(false)}
		slotProps={{
			textField: {
				error: false,
				fullWidth: true,
				variant: 'outlined',
				autoComplete: 'off',
				onClick: () => {
					mobileDevice && setOpen(true);
				},
				inputProps: {
					placeholder: ''
				}
			},
			openPickerButton: {
				onClick: () => {
					setOpen(true);
				},
			}
		}}
		value={!!value ? parse(value, FORM_FIELD_DATE_VALUE_FORMAT, Date.now()) : null}
		onChange={onChange}
		format={FORM_FIELD_DATE_VISUAL_FORMAT}
	/>
}

class SigningFormFieldDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				value: this.props.formField.value || ''
			});
		}
	}

	render() {
		const {name, type, required, validatorFormat} = this.props.formField || {};

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="sm">
			<DialogTitle>{this.props.t('signing.formFieldsFill')}</DialogTitle>
			<DialogContent>
				<Box sx={{m:1}}>
					{('TEXT' === type || 'NUMERIC' === type) && <FormattedTextField
						variant="outlined"
						label={name}
						required={required}
						value={this.state.value}
						format={validatorFormat}
						onChange={this.onChangeText}
						autoComplete="off"
						fullWidth
						autoFocus
						type={'NUMERIC' === type ? 'number' : null}
					/>}
					{'CHECKBOX' === type && <FormControlLabel
						control={
							<Checkbox checked={'true' === this.state.value}
									  onChange={this.onChangeCheckBox}/>
						}
						label={name}
					/>}
					{'DATE' === type &&
						<AutoOpenDatePicker
							value={this.state.value}
							onChange={this.onChangeDateValue}
							mobileDevice={IS_MOBILE_DEVICE}
						/>}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					id="btn-form-field-dialog-cancel"
					onClick={this.onClose}
				>
					{this.props.t('cancel')}
				</Button>
				<Button
					id="btn-form-field-dialog-submit"
					variant="contained"
					onClick={this.onSubmit}
					disabled={false}
				>
					{this.props.t('ok')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeText = (e) => {
		this.setState({value: e.target.value});
	}

	onChangeCheckBox = (e, value) => {
		this.setState({value: value ? 'true' : 'false'});
	}

	onChangeDateValue = (input) => {
		let value;

		try {
			value = !!input ? format(input, FORM_FIELD_DATE_VALUE_FORMAT) : null
		} catch (ignored) {
		}

		this.setState({value});
	}

	onSubmit = () => {
		let { value } = this.state;

		// allow unchecked checkbox
		if ('CHECKBOX' === this.props.formField.type && !value) {
			value = 'false';
		}

		this.props.onChangeValue(value)
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(SigningFormFieldDialog);
