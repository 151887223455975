import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";

class CompanyAdminApiSettingsDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onCompanyFetchAdminApiSettings(this.props.companyId);
		}
	}

	render() {
		const companyAdminApiSettings = this.props.companyAdminApiSettings;
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.adminApiSettingsHeader')}</DialogTitle>
			{(!companyAdminApiSettings || this.props.busy) && <DialogContent><LoadingComponent/></DialogContent>}
			{companyAdminApiSettings && !this.props.busy && <DialogContent>
				{companyAdminApiSettings.enabled && <Box>
					<Typography>{this.props.t('company.adminApiSettingsAccessEnabled').replace('{0}', this.props.companyName)}</Typography>
					<Box sx={{mt: 2}}>
						<TextField
							variant="outlined"
							label={this.props.t('company.adminApiSettingsClientId')}
							value={companyAdminApiSettings.clientId || ''}
							autoComplete="off"
							fullWidth
							autoFocus
							disabled={true}
							sx={{mb: 2}}
						/>

						<Alert severity="info">
							{this.props.t('company.adminApiSettingsApiSecretMoved')}
						</Alert>
					</Box>
				</Box>
				}

				{!companyAdminApiSettings.enabled && <Box>
					<Typography>{this.props.t('company.adminApiSettingsAccessDisabled').replace('{0}', this.props.companyName)}</Typography>
				</Box>
				}

			</DialogContent>}
			<DialogActions>
				<Button onClick={this.onClose}
						id="btn-settings-cancel"
				>
					{this.props.t('company.adminApiSettingsClose')}
				</Button>
				<Button variant="contained"
						disabled={!companyAdminApiSettings}
						onClick={companyAdminApiSettings?.enabled ? this.onSaveAndDisableApi : this.onSaveAndEnableApi}
						id="btn-settings-confirm"
				>
					{companyAdminApiSettings?.enabled ? this.props.t('company.adminApiSettingsDisable') : this.props.t('company.adminApiSettingsEnable')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onSaveAndEnableApi = () => {
		this.props.onCompanySaveAndEnableApi(this.props.companyId);
	}

	onSaveAndDisableApi = () => {
		this.props.onCompanySaveAndDisableApi(this.props.companyId);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyAdminApiSettings: state.company.adminApiSettings,
			busy: state.company.busy,
		}
	},
	dispatch => {
		return {
			onCompanyFetchAdminApiSettings: (companyId) => {
				dispatch({
					type: 'COMPANY_FETCH_ADMIN_API_SETTINGS',
					companyId
				});
			},
			onCompanySaveAndEnableApi: (companyId) => {
				dispatch({
					type: 'COMPANY_UPDATE_ADMIN_API_SETTINGS',
					companyId: companyId,
					enabled: true
				});
			},
			onCompanySaveAndDisableApi: (companyId) => {
				dispatch({
					type: 'COMPANY_UPDATE_ADMIN_API_SETTINGS',
					companyId: companyId,
					enabled: false
				});
			},
		}
	}
)(CompanyAdminApiSettingsDialog));
