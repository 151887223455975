export default {
	lang: 'English',
	search: 'Search',
	previous: 'Previous',
	next: 'Next',
	cancel: 'Cancel',
	select: 'Select',
	confirm: 'Confirm',
	close: 'Close',
	ok: 'OK',
	back: 'Back',
	save: 'Save',
	page: 'Page',
	attention: 'Attention!',
	columns: 'Columns',
	export: 'Export',
	filter: 'Filter',
	clear: 'Clear',
	resetColumnsWidth: 'Reset width of columns',

	session: {
		admin: 'Administrator of',
		user: 'User of',
		loggedInAs: 'Logged in as',
		becomeRegisteredUser: 'Become registered user',
		becomeRegisteredUserDetails: ' Do you want to convert your guest access to a registered account? Guest access via links that were previously mailed to you will no longer work. You can consult the documents again after registering and logging in. After confirming you will receive an invitation mail and be redirected to the login page. Please follow the instructions in the invitation mail to complete registration.',
		welcome: 'Welcome',
		login: 'Login',
		email: 'E-mail',
		password: 'Password',
		alternativeLogins: 'OR',
		loginMethod_EID: 'Belgian eID',
		resetPassword: 'Change your password',
		resetPasswordUpdateSuccess: 'The password was updated successfully, click \'Next\' to go to the login screen.',
		forgotPassword: 'Forgot password?',
		forgotPasswordSuccess: 'If $email belongs to a registered user, an email with further instructions will be sent to this address.',
		forgotPasswordSuccessContinue: 'Click \'Next\' to continue to the login page.',
		pendingChanges: 'There are unsaved changes, are you sure you want to leave this page?',
		pendingChangesLeave: 'Leave, ignoring changes',
		pendingChangesSaveAndLeave: 'Save and leave',
	},

	language: {
		en: 'English',
		nl: 'Dutch',
		fr: 'French'
	},

	locale: {
		dateFormat: 'dd/mm/yyyy',
		datetimeFormat: 'dd/mm/yyyy hh:mm'
	},

	notFound: {
		message: 'The requested page could not be found'
	},

	changes: {
		saved: 'Changes saved',
		required: 'This field is required'
	},

	info: {
		manualHeader: 'Manual',
		manualContent: 'You can find the latest version of the manual $link;https://quill.dioss.com/documentation;here$',
		termsHeader: 'General Terms and Conditions',
		termsContent: 'You will find information on this subject $link;https://smartsolutions.dioss.com/en/products/quill/general-terms-and-conditions/;here$.',
		faqHeader: 'FAQ - Frequently Asked Questions',
		faqContent: 'You will find information on this subject $link;https://smartsolutions.dioss.com/en/products/quill/FAQ-quill/;here$.',
		supportHeader: 'Support',
		supportContent: 'For assistance, please contact $mail$',
		gdprHeader: 'GDPR - General Data Protection Regulation | Privacy statement',
		gdprContent: 'You will find information on this subject $link;https://smartsolutions.dioss.com/en/products/quill/privacy-statement/;here$.',
		isoEidasHeader: 'ISO certified | eIDAS compliant',
		isoHeader: 'Is Dioss Smart Solutions ISO27001 compliant and certified?',
		isoContent: 'ISO 27001 is a specification for an information security management system, it is a framework of policies and procedures that includes legal, physical and technical controls involved in our information risk management processes. We use it as a system to ensure that we conduct business in an organized and secure way and continuously improve our approach and level of security. Dioss Smart Solutions is ISO27001 compliant and certified. We are regularly audited by BSI, which is an accredited certification body.',
		eidasHeader: 'Does the Quill platform use eIDAS compliant signatures?',
		eidasContent: 'Yes, this platform is in compliance with the European eIDAS regulation.',
		platformHeader: 'Platform version',
		releaseNotesHeader: 'Release notes',
		releaseNotesContent: 'You will find information on this subject $link;https://smartsolutions.dioss.com/en/products/quill/release-notes/;here$.',

		tab_GENERAL: 'General',
		tab_FAQ: 'FAQ',
		tab_VERSION: 'Version'
	},

	application: {
		tab_COMPANY_OVERVIEW: 'Company Overview',
		tab_OIDC_SETTINGS: 'OIDC Configuration',
		tab_TESTS: 'Testing',
		tab_LOGGING: 'Logging',

		testsMail: 'Send test for every e-mail template in language',

		loggingHeader: 'Logging configuration',
		loggingLogger: 'Logger',
		loggingLevel: 'Level',
		loggingDelete: 'Delete',
		loggingAdd: 'Add',
	},

	tac: {
		title: 'General Terms and Conditions',
		message: 'There is a new version of the General Terms and Conditions that can be found $link;https://smartsolutions.dioss.com/en/products/quill/general-terms-and-conditions/;here$',
		agree: 'I agree',
	},

	outOfOffice: {
		warning: 'You are currently marked as out-of-office.',
	},

	company: {
		createName: 'Name of the company',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'The new company will be added to the platform.',

		header: 'Companies',
		create: 'Add new company',
		createChild: 'Add new subcompany',
		companyName: 'Company',
		actions: 'Actions',
		settings: 'Settings',
		delete: 'Delete',
		deleteConfirm: 'Are you sure you want to delete this company?',
		deleted: 'Company is deleted',
		child: 'Subcompany',
		parentCompany: 'Parent company',
		switch: 'Switch active company',

		adminSettingsHeader: 'Company settings',
		adminSettingsInactive: 'Inactive',
		name: 'Name',

		adminSettingsSignatureTypes: 'Signature types',
		adminSettingsMisc: 'Misc settings',
		adminSettingsPdfRecreatedShown: 'Show notice if the structure of PDF was fixed due to incompatibility issues.',
		adminSettingsEmail: 'E-mail settings',
		adminSettingsMailboxEnabled: 'This company may create mailboxes per folder',
		adminSettingsWhitelistedEmailDomains: 'White listed e-mail domains',
		adminSettingsCanManageChildCompanies: 'This company can manage subcompanies',
		adminSettingsCanRequestSensitiveSignerData: 'This company can request sensitive personal data for signer validation',
		adminSettingsUseEidMiddlewarePinInput: 'Use Dioss eID Middleware for eID pin input',
		adminSettingsItsmePartnerCode: 'itsme\u00AE frequent signer partner code',
		adminSettingsItsmePartnerCodeOverride: 'Use a company specific itsme\u00AE partner code',
		adminSettingsItsmePartnerCodeWarning: 'Attention: the itsme\u00AE partner code is only taken in account on document folders which have the setting explicitly enabled (default disabled)',
		adminSettingsFinish: 'Everything filled in?',
		adminSettingsFinishDescription: 'Press save to keep changes',

		adminSettingsDomain: 'Domain settings',
		adminSettingsDomainNone: 'None',
		adminSettingsDomainSubdomain: 'Subdomain',
		adminSettingsDomainCustomDomain: 'Custom domain',
		adminSettingsDomainSubdomainExample: 'Example URL:',
		adminSettingsDomainSubdomainValidation: 'Lowercase characters, numbers, - and _ are allowed',
		adminSettingsDomainCustomDomainValidation: 'Lowercase characters, numbers, ., - and _ are allowed',

		adminSettingsSignature_ELECTRONIC_WITH_WITNESS_SEAL: 'Allow electronic seal (AES) as a signature type',
		adminSettingsSignature_BELGIAN_EID: 'Allow Belgian eID (QES) as a signature type',
		adminSettingsSignature_ITSME: 'Allow qualified itsme® (QES) as a signature type',
		adminSettingsSignature_ITSME_ADVANCED: 'Allow advanced itsme® (AES) as a signature type',
		adminSettingsSignature_SMS_OTP: 'Allow OTP via SMS (AES) as a signature type',
		adminSettingsSignature_EMAIL_OTP: 'Allow OTP via e-mail (AES) as a signature type',
		adminSettingsSignature_HANDWRITTEN: 'Allow basic handwritten signature (SES) as a signature type',

		adminApiSettingsHeader: 'API settings',
		adminApiSettingsAccess: 'API access',
		adminApiSettingsAccessEnabled: '{0} has access to the API',
		adminApiSettingsAccessDisabled: '{0} does not have access to the API',
		adminApiSettingsDetails: 'API details',
		adminApiSettingsClientId: 'Client id',
		adminApiSettingsClose: 'Close',
		adminApiSettingsDisable: 'Disable',
		adminApiSettingsEnable: 'Enable',
		adminApiSettingsApiSecretMoved: 'The API client secret management moved to the company settings \'API keys\' section. Creating, editing and revoking API keys is now under the management of company and application admins and handled on the new location. The company\'s access to the API in general can still be disabled or enabled here as before.',


		settingsTab_GENERAL_SETTINGS: 'General',
		settingsTab_APIKEY_SETTINGS: 'API keys',
		settingsTab_CERTIFICATE_SETTINGS: 'Certificates',
		settingsTab_EMAIL_SETTINGS: 'E-mail',
		settingsTab_NOTIFICATION_SETTINGS: 'Notifications',
		settingsTab_STYLE_SETTINGS: 'Company style',
		settingsTab_FORMFIELD_SETTINGS: 'Form fields',
		settingsTab_OIDC_SETTINGS: 'OIDC configurations',
		settingsTab_DELEGATES: 'Delegates',
		settingsTab_CONNECTORS: 'Connectors',
		settingsTab_SWITCH: 'My companies',

		generalSettingsValue_ALWAYS_AVAILABLE: 'always available',
		generalSettingsValue_DEFAULT_AVAILABLE: 'default available',
		generalSettingsValue_DEFAULT_UNAVAILABLE: 'default unavailable',
		generalSettingsValue_NEVER_AVAILABLE: 'never available',
		generalSettingsFor: 'General settings for',
		generalSettingsSigningSettings: 'Signing settings',
		generalSettingsDocumentSettings: 'Document settings',
		generalSettingsSigningMethods: 'Signature types',
		generalSettingsSigningMethodsHelp: 'These settings let you determine which signature types can be chosen by document editors. \nThe "always available" setting makes it available for all documents and this can not be overruled on folder level. \nThe "never available" setting disables it for all documents and this can not be overruled on folder level. \nThe "default" settings determine availability for new folders, but allow the folder settings to override this for documents in the folder. \nA change to these settings does not affect already sent documents. \nIf a setting is disabled it means the signature type is not available for your company.',
		generalSettingsAllowSignature: 'Allow $SIGNATURE',
		generalSettingsSignature_ELECTRONIC_WITH_WITNESS_SEAL: 'Electronic seal (AES)',
		generalSettingsSignature_BELGIAN_EID: 'Belgian eID (QES)',
		generalSettingsSignature_ITSME: 'Qualified itsme\u00AE (QES)',
		generalSettingsSignature_ITSME_ADVANCED: 'Advanced itsme\u00AE (AES)',
		generalSettingsSignature_SMS_OTP: 'OTP via SMS (AES)',
		generalSettingsSignature_EMAIL_OTP: 'OTP via e-mail (AES)',
		generalSettingsSignature_HANDWRITTEN: 'basic handwritten signature (SES)',
		generalSettingsSignatureApplyToFolders: 'Apply to all folders',
		generalSettingsSigningPreferences: 'Signing preferences',
		generalSettingsSigningPreferencesHelp: 'These settings let you determine if a signatory can add a comment and/or reason to their signature. \nThe "always available" setting makes it available for all documents and this can not be overruled on folder level. \nThe "never available" setting disables it for all documents and this can not be overruled on folder level. \nThe "default" settings determine availability for new folders, but allow the folder settings to override this for documents in the folder.',
		generalSettingsSigningReason: 'Allow adding a reason during signing for all users (not visually depicted)',
		generalSettingsSigningReasonMandatoryLegalNotice: 'Make this a mandatory legal notice for the user to enter. This will also appear visually on the document.',
		generalSettingsSigningReasonLegalNotice: 'Legal notice',
		generalSettingsSigningRemark: 'Allow adding a comment during signing for all users (printed on document)',
		generalSettingsSigningAddSigningMethod: 'Add used signing method to each signature',
		generalSettingsSigningAddFullSigningInfo: 'Add full signing information',
		generalSettingsSigningAddImage: 'Add handwritten name/image to visual signature',
		generalSettingsSigningVisualSignatureFont: 'Visual signature and paraph font',
		generalSettingsSigningVisualSignatureFont_INDIEFLOWER: 'Indie Flower',
		generalSettingsSigningVisualSignatureFont_GREATVIBES: 'Great Vibes',
		generalSettingsSigningVisualSignatureFont_QWIGLEY: 'Qwigley',
		generalSettingsSigningVisualSignatureFont_ARTYSIGNATURE: 'Arty Signature',
		generalSettingsSigningVisualSignatureFontPreview: 'Font preview',
		generalSettingsApprovalPreferences: 'Approval preferences',
		generalSettingsApprovalShowSignerInfo: 'Show signer information in approval flow',
		generalSettingsApplicationOidcOnlyLogin: 'Login only via OIDC',
		generalSettingsUsers: 'User settings',
		generalSettingsUsersAllowGuestToBecomeRegistered: 'Allow guest users to become registered',
		generalSettingsExpiration: 'Automatic expiration of pending approvals and signatures',
		generalSettingsExpirationEnable: "Enable expiration",
		generalSettingsExpirationDays: "day(s) before incomplete approvals and signatures expire",
		generalSettingsAllowFoldersToDefineExpirations: "Allow folders to use different expiration settings",
		generalSettingsAllowDocumentsToDefineExpirations: "Allow documents to use different expiration settings",
		generalSettingsDocumentCleanup: 'Automatic document deletion',
		generalSettingsDocumentCleanupHelp: 'Documents are automatically deleted after a number of days have passed since their last modification.\nThe setting for completed documents is applied for documents with no remaining open signatures or that were closed.\nThe setting for unfinished documents is applied for documents in preparation or awaiting signatures or approvals.\nYou can allow folders to set different thresholds for their documents.',
		generalSettingsDocumentCleanupDaysProcessed: 'day(s) after a completed document is deleted',
		generalSettingsDocumentCleanupDaysPending: 'day(s) after an inactive unfinished document is deleted',
		generalSettingsDocumentCleanupAllowFolderSettings: 'Allow folders to use different deletion settings',
		generalSettingsDescription: 'Document description',
		generalSettingsDescriptionMandatory: 'Make description mandatory',
		generalSettingsDescriptionText: 'Default description',
		generalSettingsForwarding: 'Forwarding',
		generalSettingsDocumentForwardingAllowed: 'Allow forwarding of signature requests',
		generalSettingsDefaultSignatureFieldStyle: 'Default signature style',
		generalSettingsDownloading: 'Download',
		generalSettingsSignerNeedsSignatureToDownload: 'A fully placed signature is necessary for the signer to download a document',

		styleSettingsFor: 'Company style for',
		styleSettingsPrimary: 'Primary',
		styleSettingsSecondary: 'Secondary',
		styleSettingsMainColor: 'Main color',
		styleSettingsContrastColor: 'Contrast color',
		styleSettingsLogo: 'Logo',
		styleSettingsLogoChoose: 'Change logo',

		emailSettingsFor: 'E-mail settings for',
		emailSettingsDistributionList: 'Default settings for the post sign distribution list',
		emailSettingsDistributionListDescription: 'Document recipients',
		emailSettingsDistributionListEvidenceReportDescription: 'Document and evidence report recipients',
		emailSettingsDistributionListEmails: 'List of e-mail addresses separated by \';\'',
		emailSettingsCommunicationType: 'Email communication type',
		emailSettingsDescription_DEFAULT_HEADERS: 'Alias mode: use the Quill email server with the default application\'s address as \'from\' address. The \'sender\' and \'reply-to\' address can be used (see \'sender\')',
		emailSettingsDescription_DEFAULT_SPF: 'SPF authorized mode: use the Quill email server, but with the sender\'s or company email address as \'from\' address. Note: this requires setting the DNS records below and completing the SPF validation successfully',
		emailSettingsDescription_SENDGRID_CUSTOM: 'Custom SendGrid: use a custom SendGrid account and send emails via this configuration',
		emailSettingsSendGridServerSettings: 'SendGrid server settings',
		emailSettingsPersonalizeCompany: 'Use email address of company as sender',
		emailSettingsPersonalizeCompanyHeaders: '(as \'sender\' and \'reply-to\' header)',
		emailSettingsPersonalizeUser: 'Use email address of related user as sender where possible',
		emailSettingsPersonalizeUserHeaders: '(as \'sender\' and \'reply-to\' header)',
		emailSettingsSenderSettings: 'Sender',
		emailSettingsServerApplicability: 'Applicable for',
		emailSettingsServerApplicabilityDescription: 'Determine the applicability for this server configuration. If the configuration has specific domains defined, it will be used when sending from one of these domains. In other cases the default configuration will be used. One default configuration is required.',
		emailSettingsServerSettings: 'Server settings',
		emailSettingsServerDelete: 'Delete',
		emailSettingsServerEdit: 'Edit',
		emailSettingsServerAdd: 'Add',
		emailSettingsServerDefault: 'Default',
		emailSettingsServerDomains: 'Domains',
		emailSettingsServerDomainsLabel: 'List of domains separated by \';\'',
		emailSettingsServerUserName: 'Username',
		emailSettingsServerPassword: 'Password / API key',
		emailSettingsAuthenticatedDomainValidation: 'SPF validation',
		emailSettingsAuthenticatedDomainConfiguration: 'SPF domain configuration',
		emailSettingsAuthenticatedDomainDialog: 'SPF DNS records',
		emailSettingsAuthenticatedDomainDomain: 'Domain',
		emailSettingsAuthenticatedDomainRecordType: 'Type',
		emailSettingsAuthenticatedDomainRecordName: 'Name',
		emailSettingsAuthenticatedDomainRecordAliasFor: 'Alias for',
		emailSettingsAuthenticatedDomainValidationDialog: 'Validation',
		emailSettingsAuthenticatedDomainDnsRecordName: 'Name',
		emailSettingsAuthenticatedDomainDnsRecordValid: 'Valid',
		emailSettingsAuthenticatedDomainDnsRecordReason: 'Reason',
		emailSettingsAuthenticatedDomainCreate: 'Request domain configuration',
		emailSettingsAuthenticatedDomainView: 'View domain records',
		emailSettingsAuthenticatedDomainValidate: 'Validate domain records',
		emailSettingsAuthenticatedDomainValidationResultStatus: 'Status',
		emailSettingsAuthenticatedDomainValidationResult_VALID: 'Valid',
		emailSettingsAuthenticatedDomainValidationResult_INVALID: 'Invalid',
		emailSettingsAuthenticatedDomainValidationResult_PENDING: 'Not validated',
		emailSettingsAuthenticatedDomainValidationResult_IDLE: 'Not started',
		emailSettingsAuthenticatedDomainValidationResult_UNKNOWN: 'Unknown',
		emailSettingsAuthenticatedDomainPendingChanges: 'To continue in SPF authorized mode the current settings must be saved first. Do you want to continue?',
		emailSettingsAuthenticatedDomainNoWhitelistedDomains: 'No allowed domains detected to complete the SPF validation. Please contact Quill support to configure the allowed domains.',
		emailSettingsAuthenticatedDomainDmarcRecord: 'To maximise deliverability of emails it is advised to configure a DMARC record if this would not yet be the case. Example:',
		emailSettingsAddress: 'Company e-mail address',
		emailSettingsName: 'Name',
		emailSettingsSupport: 'Support',
		emailSettingsSupportEmail: 'Support e-mail address',
		emailSettingsApiKeys: 'API keys',
		emailSettingsApiKeysNew: 'Enter new value',
		emailSettingsApiKeySending: 'API key used for sending',
		emailSettingsApiKeySendingNotVisible: 'API key used for sending has been set but is hidden for security reasons',
		emailSettingsLayout: 'E-mail customization',
		emailSettingsLayoutCustomize: 'Apply custom settings',
		emailSettingsHeaderFooter: 'Header - Footer',
		emailSettingsHeaderEdit: 'Edit header',
		emailSettingsContentEdit: 'Edit content',
		emailSettingsFooterEdit: 'Edit footer',
		emailSettingsColorBackground: 'Background color',
		emailSettingsColorBackgroundContent: 'Content background color',
		emailSettingsColorButtonBackground: 'Button background color',
		emailSettingsColorButtonText: 'Button text color',
		emailSettingsColorDefault: 'Default',
		emailSettingsColorCompanyMain: 'Company style main color',
		emailSettingsColorCompanySecondary: 'Company style secondary color',
		emailSettingsColorCustom: 'Custom color',

		notificationSettingsFor: 'Notification settings for',
		notificationSettingsReminders: 'Reminders for pending approvals and signatures',
		notificationSettingsEnableReminders: 'Enable reminders',
		notificationSettingsRemindersDays: 'day(s) between sending of reminders for incomplete approvals and signatures',
		notificationSettingsDailyDigest: 'Daily digest',
		notificationSettingsDailyDigestTime: 'Sending time',
		notificationSettingsMinimizeCollectionInvites: 'Limit collection invitations',
		notificationSettingsMinimizeCollectionInvitesEnable: 'Wait on other approvals/signatures to reduce the number of collection invitations',
		notificationSettingsMinimizeCollectionInvitesGracePeriod: 'Maximum waiting time in minutes',
		notificationSettingsMinimizeCollectionInvitesGracePeriodHelp: 'Leave blank to wait indefinitely',
		notificationSettingsMinimizeCollectionFinishedNotifications: 'Limit document finished notifications within collections',
		notificationSettingsMinimizeCollectionFinishedNotificationsEnable: 'Send out document finished notifications only when the whole collection has been finished',

		generalSettingsFormFieldValidatorSettings: 'Form field validator',
		generalSettingsFormFieldValidatorTest: 'Test',
		generalSettingsFormFieldValidatorType_TEXT: 'Text',
		generalSettingsFormFieldValidatorType_CHECKBOX: 'Checkbox',
		generalSettingsFormFieldValidatorTestInput: 'Input',
		generalSettingsFormFieldValidatorTestInvalid: 'Input invalid',
		generalSettingsFormFieldValidatorTestValid: 'Input valid',
		generalSettingsFormFieldValidatorName: 'Name',
		generalSettingsFormFieldValidatorType: 'Type',
		generalSettingsFormFieldValidatorFormat: 'Format',
		generalSettingsFormFieldValidatorFormatDescription: 'You can use # as a placeholder for any digit (0-9). All other are characters are literals, the entry of literals is optional. E.g. the BE## #### #### #### format describes a Belgian IBAN',
		generalSettingsFormFieldValidatorActions: 'Actions',
		generalSettingsFormFieldValidatorAdd: 'Add',
		generalSettingsFormFieldValidatorEdit: 'Edit',
		generalSettingsFormFieldValidatorDelete: 'Delete',

		certificateSettingsFor: 'Certificate settings for',
		certificateSettingsDefaultConfig: 'Quill default',
		certificateSettingsConfigs: 'Key vault certificate configurations',
		certificateSettingsConfig: 'Key vault certificate configuration',
		certificateSettingsConfigHeader: 'Configuration name',
		certificateSettingsConfigVaultConnectionHeader: 'Key vault connection',
		certificateSettingsConfigCertificateHeader: 'Certificate',
		certificateSettingsConfigName: 'Name',
		certificateSettingsConfigVaultUrl: 'Vault URL',
		certificateSettingsConfigCertificateName: 'Certificate name',
		certificateSettingsConfigClientId: 'Client ID',
		certificateSettingsConfigClientSecret: 'Client secret',
		certificateSettingsConfigTenantId: 'Tenant ID',
		certificateSettingsConfigKeyId: 'Key ID',
		certificateSettingsConfigAdd: 'Add',
		certificateSettingsConfigEdit: 'Edit',
		certificateSettingsConfigDelete: 'Delete',
		certificateSettingsConfigUsage: 'Certificate usage',
		certificateSettingsInfo: 'You can manage certificates below which will be used when using signing type \'electronic seal\' or \'advanced itsme\u00AE\'.',
		certificateSettingsGuestUserCertificate: 'Signing certificate for guest users.',
		certificateSettingsRegisteredUserCertificate: 'Signing certificate for registered users.',

		apiKeySettingsHeaderFor: 'API key settings for',
		apiKeySettings: 'API key settings',
		apiKeySettingsValue: 'Key',
		apiKeySettingsAlert: 'Please note that the key cannot be visualized after this dialog has been closed.',
		apiKeySettingsAlmostExpireDay: 'Expires after 1 day',
		apiKeySettingsAlmostExpireDays: 'Expires after {{days}} days',
		apiKeySettingsCopy: 'Copy',
		apiKeySettingsCreate: 'Create API key',
		apiKeySettingsContactEmail: 'Contact e-mail',
		apiKeySettingsDescription: 'Description',
		apiKeySettingsDuration: 'Validity',
		apiKeySettingsDurationDuring: 'for a period of',
		apiKeySettingsDuration_DURATION_DAY: 'day',
		apiKeySettingsDuration_DURATION_DAYS: 'days',
		apiKeySettingsDuration_DURATION_MONTH: 'month',
		apiKeySettingsDuration_DURATION_MONTHS: 'months',
		apiKeySettingsDuration_DURATION_WEEK: 'week',
		apiKeySettingsDuration_DURATION_WEEKS: 'weeks',
		apiKeySettingsDuration_DURATION_YEAR: 'year',
		apiKeySettingsDuration_DURATION_YEARS: 'years',
		apiKeySettingsFromUntilHeader: 'Please specify the duration period of the key.',
		apiKeySettingsOptionalDescription: 'Description of the key',
		apiKeySettingsContactEmailHeader: 'Contact e-mail to notify when the key is about to expire, e.g. the company support inbox',
		apiKeySettingsState: 'Status',
		apiKeySettingsStateActive: 'Active',
		apiKeySettingsStateExpired: 'Expired',
		apiKeySettingsStateNotActive: 'Not active',
		apiKeySettingsValidFrom: 'Valid from',
		apiKeySettingsValidUntil: 'Valid until',
		apiKeySettingsRevoke: 'Revoke API key',
		apiKeySettingsRevokeConfirm: 'Are you sure you want to revoke this API key? This is irreversible.',

		switchCurrentCompany: 'Active company',
		switchToOtherCompany: 'Change company',
		switchTo: 'Change',

		insightsTab_STATISTICS: 'Statistics',
		insightsTab_ACTION_LOGS: 'Action logs',

		statsNoData: 'No data',
		statsSource_SIGNATURES: 'Signatures',
		statsLegendTitle_SIGNATURES: 'Signatures (select type to hide)',
		statsSource_APPROVALS: 'Approvals',
		statsLegendTitle_APPROVALS: 'Approvals (select type to hide)',
		statsMode_MONTH: 'Per month',
		statsMode_DAY: 'Per day',
		statsBeginDate: 'Begin',
		statsEndDate: 'End',
		statsCompanyFilterTitle: 'Company',
		statsCompanyFilter_ALL: 'All',
		statsFolderFilterTitle: 'Folder',
		statsFolderFilter_ALL: 'All (ungrouped)',
		statsFolderFilter_NONE: 'All (grouped)',
		statsSigningOrigin: 'Signing origin',
		statsSigningOrigin_ALL: 'All',
		statsSigningOrigin_LOCAL: 'Internal (local)',
		statsSigningOrigin_REMOTE: 'External (remote)',
		statsInfo: 'You can view and export approval,signature and itsme\u00AE statistics below. The filters apply on both the chart, detail data and exports.',
		statsChart: 'Chart',
		statsItsmeDetails: 'itsme\u00AE details',
		statsItsmeDetailsFolder: 'Folder',
		statsItsmeDetailsCompany: 'Bedrijf',
		statsItsmeDetailsFrequentSigner: 'itsme\u00AE frequent signer',
		statsItsmeDetailsCount: 'Signatures',
		statsItsmeDetailsUniqueSigners: 'Unique users',
		statsItsmeDetailsInfo: 'Warning: the number of unique itsme\u00AE users is only stored since v2.6. All itsme\u00AE signatures previous to this version are not taken in account for the unique signer statistics.',
		statsItsmeDetailsRatio: 'Ratio',
		statsItsmeDetailsRatioTooltip: 'Ratio number of signatures on number of unique signers',
		statsSigningItsmePartnerCode: 'itsme\u00AE frequent signer',
		statsSigningItsmePartnerCode_ALL: 'All (no filter)',
		statsSigningItsmePartnerCode_DEFAULT: 'Excluded',
		statsSigningItsmePartnerCode_COMPANY: 'Exclusively',

		actionLogPerformedAt: 'Timestamp',
		actionLogActorFullName: 'Actor',
		actionLogEvent: 'Event',
		actionLog_COMPANY_CREATED: 'Company {0} was added',
		actionLog_COMPANY_UPDATE_NAME: 'The name of the company was changed from {0} to {1}',
		actionLog_COMPANY_API_ENABLE: 'API access was enabled',
		actionLog_COMPANY_API_DISABLE: 'API access was granted',
		actionLog_COMPANY_API_KEY_CREATED: 'API key was created with a validity from {0} until {1}',
		actionLog_COMPANY_API_KEY_REVOKED: 'API key was revoked with a validity from {0} until {1}',
		actionLog_DOCUMENT_DELETE: 'Document {0} (id={1}) was removed',
		actionLog_FOLDER_CREATE: 'Folder {0} was created',
		actionLog_FOLDER_DELETE: 'Folder {0} was removed',
		actionLog_FOLDER_GROUP_PERMISSION_DELETE: 'Permission {0} was revoked for group {1} on folder {2}',
		actionLog_FOLDER_GROUP_PERMISSION_CREATE: 'Permission {0} was granted for group {1} on folder {2}',
		actionLog_FOLDER_USER_PERMISSION_DELETE: 'Permission {0} was revoked for user {1} on folder {2}',
		actionLog_FOLDER_USER_PERMISSION_CREATE: 'Permission {0} was granted for user {1} on folder {2}',
		actionLog_USER_INVITED: 'User with e-mail {0} was invited',
		actionLog_USER_INVITATION_RESEND: 'Invitation e-mail has been resend to {0}',
		actionLog_USER_GUEST_ADDED: 'User with e-mail {0} was added as guest',
		actionLog_USER_PROMOTED: 'User with e-mail {0} has role {1} granted',
		actionLog_USER_DEMOTED: 'User with e-mail {0} has role {1} revoked',
		actionLog_USER_DELETED: 'User with e-mail {0} was removed',
		actionLog_USER_EMAIL_CHANGED: 'User with email {0} now has {1} as email',
		actionLog_USER_FIRSTNAME_CHANGED: 'User with email {0} now has {2} as first name (was {1})',
		actionLog_USER_LASTNAME_CHANGED: 'User with email {0} now has {2} as last name (was {1})',
		actionLog_USER_NOTIFICATION_ADDED: 'Notification {1} has been activated for user with email {0}',
		actionLog_USER_NOTIFICATION_UPDATED: 'Notification {1} has been changed to {2} for user with email {0}',
		actionLog_USER_NOTIFICATION_REMOVED: 'Notification {1} has been deleted for user with email {0}',
	},

	delegates: {
		listsFor: 'Lists of delegates for',
		description: 'You can manage the lists for delegates here. Per list you can set the type (approval or signature) and also manage whether this is a list for everyone or a list for a specific user. Lists for specific users always take precedence over lists for everyone.',
		listCreate: 'Create list',
		listEdit: 'Edit list',
		count: 'Number of delegates',
		actions: 'Actions',
		create: 'Create list of delegates',
		edit: 'Edit list of delegates',
		type: 'Delegation type',
		type_DOCUMENT_APPROVAL: 'Approval',
		type_DOCUMENT_SIGNATURE: 'Signature',
		for: 'For specific user',
		forEveryone: 'Everyone',
		chooseFor: 'Choose specific user',
		deleteFor: 'Delete specific user',
		delegates: 'Delegates',
		addDelegates: 'Add delegates',
		order: 'Order',
		up: 'Up',
		down: 'Down',
		firstName: 'First name',
		lastName: 'Last name',
		email: 'E-mail',
		delete: 'Delete',
		listDelete: 'Delete list',
		listDeleteConfirm: 'Are you sure you want to delete this list?',
		deleted: 'List of delegates deleted',
	},

	user: {
		state_INVITED: 'Invited',
		state_REGISTERED: 'Registered',
		state_GUEST: 'Guest',
		state_GUEST_CONTACT: 'Contact in {{folder}}',
		state_REMOVED: 'Removed',
		state_API: 'API',

		header: 'Users',
		firstName: 'First name',
		lastName: 'Last name',
		guest: 'Guest',
		memo: 'Memo',
		email: 'E-mail',
		state: 'Status',
		defaultRemarkVisualSignature: 'Standard visual signature comment',
		actions: 'Actions',
		create: 'Invite',
		import: 'Import',
		delete: 'Delete',
		registerGuest: 'Convert to a registered user',
		resendInvitation: 'Resend invitation',
		deleted: 'User deleted',

		deleteConfirm: 'Are you sure you want to delete this user?',
		registerGuestConfirm: 'Are you sure you want to register this user?',

		createName: 'Name of the user',
		createEmail: 'E-mail address of the user',
		createLanguage: 'Language of the user',
		createAddType: 'Add as',
		createAddType_REGISTERED: 'Registered user',
		createAddType_GUEST: 'Guest',
		createAddType_CONTACT: 'Contact in {{folder}}',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'After the user has been invited, additional configuration can be done (when having the required permissions).',

		uploadAllowUpdate: 'Allow modifying existing users',
		uploadDownloadTemplate: 'Download example file',
		uploadInvalidFileExtension: 'Invalid file type. Please select a .csv file.',
		uploadFileLimitExceeded: 'File size exceeded. Please select a file with size less than 100MB.',
		uploadImportedUsersResult: 'Number of users successfully imported',
		uploadImportedErrors: 'Errors found while importing the provided file:',
		uploadParsingError: 'Invalid data format',
		uploadError_CANNOT_ADD_TO_COMPANY_GROUP: 'Unable to add this user to one or more of the specified user groups',
		uploadError_COMPANY_USER_GROUP_NOT_FOUND: 'Could not find one or more user groups specified for this user',
		uploadError_EMAIL_DUPLICATE: 'Email specified multiple times in the given file',
		uploadError_EMAIL_INVALID: 'Invalid email address',
		uploadError_INVALID_NOTIFICATION: 'One or more notification types do not exist',
		uploadError_CONVERT_REGISTERED_TO_GUEST: 'Registered user cannot be converted into a guest',
		uploadError_CONTACT_FOLDER_MISSING: 'Could not find a folder to create the contact in',
		uploadError_GUEST_AND_CONTACT_CONFLICT: 'User cannot be added as guest and contact at the same time',
		uploadError_PARSING_ERROR: 'Invalid data',
		uploadError_UNKNOWN: 'An unknown error occurred',
		uploadError_USER_ALREADY_EXISTS: 'User already exists',
		uploadError_USER_EXISTS_FOR_OTHER_COMPANIES: 'Cannot update users that are also registered with other companies',
		uploadError_NOT_ALLOWED: 'Action not allowed',

		outOfOfficeNotActive: 'Not active',
		outOfOfficeAlwaysActive: 'Always active',
		outOfOfficeConfiguredActive: 'Active',
		outOfOfficeConfiguredActiveFrom: 'From',
		outOfOfficeConfiguredActiveUntil: 'Until',

		settings: 'Settings',
		settingsGeneral: 'General',
		settingsVisualSignature: 'Signature',
		settingsVisualSignatureUpload: 'Upload new visual signature (max 1MB)',
		settingsVisualSignatureDelete: 'Delete visual signature',
		settingsVisualSignatureDescription: 'Changes to the visual signature will be applied immediately.',
		settingsOutOfOffice: 'Out-of-office',
		settingsLanguage: 'Language',
		settingsCommunication: 'Communication',
		settingsCommunicationReceiveEmail: 'User will receive e-mail for:',
		settingsInviteUserNotification: 'Document approval/sign requests',
		settingsReminderUserNotification: 'Reminders',
		settingsApprovalUserNotification: 'Document approved/declined',
		settingsSignatureUserNotification: 'Your document signed',
		settingsFullySignedUserNotification: 'Document fully signed',
		settingsClosedUserNotification: 'Document closed',
		settingsExpiredUserNotification: 'Document expired',
		settingsPermissions: 'Permissions',
		settingsPermissionsGuestDisabled: 'Note: permission settings not applicable for guests/contacts',
		settingsPermissionsUserIs: 'User is:',
		settingsPermissionsUserCanManage: 'User can manage:',
		settingsAdmin: 'Admin',
		settingsUserManager: 'Users',
		settingsUserGroupManager: 'User groups',
		settingsUserGroups: 'User group membership',
		settingsUserGroupsNoAccess: 'You don\'t have access to manage the user groups',
		settingsUserGroupName: 'Name',
		settingsUserGroupMember: 'Member',
		settingsUserGroupAdmin: 'Admin',
		settingsSignerGroupManager: 'Signer groups',
		settingsSignerGroups: 'Signer group membership',
		settingsSignerGroupsNoAccess: 'You don\'t have access to manage the signer groups',
		settingsSignerGroupName: 'Name',
		settingsSignerGroupMember: 'Member',
		settingsDocumentFolderManager: 'Document folders',
		settingsDocumentFolders: 'Folder permissions',
		settingsDocumentFoldersNoAccess: 'You don\'t have access to manage the document folders',
		settingsDocumentFolderName: 'Name',
		settingsOutOfOfficeManager: 'Out-of-office settings other users',
		settingsTemplateManager: 'Templates',
		settingsPermissionsUserCanView: 'User can view:',
		settingsActionLogViewer: 'Company action log',
		settingsPermissionsUserCanInvite: 'User can invite:',
		settingsInviteRegisteredUsers: 'Registered users',
		settingsInviteGuestUsers: 'Guests',
		settingsInviteContacts: 'Contacts',
		settingsFinish: 'Everything filled in?',
		settingsFinishDescription: 'Press save to keep changes',
		settingsSave: 'Save',

		settingsTab_PROFILE: 'Profile',
		settingsTab_SECURITY: 'Security',
		myProfileHeader: 'My user',
		profileHeader: 'User',
		profileSaveWarning: 'Please note, if you have made changes on this page, you must still save them in order not to lose them.',
		profileGuestSaveWarning: 'Please note, changes to the visual signature and initials will be saved immediately.',
		profilePersonalHeader: 'Personal information',
		profilePersonalFirstName: 'First name',
		profilePersonalLastName: 'Last name',
		profilePersonalMemo: 'Memo',
		profilePersonalEmail: 'E-mail',
		profilePersonalEidCardNumber: 'eID card number',
		profilePersonalDefaultRemarkVisualSignature: 'Standard visual signature comment',
		profileCapacities: 'Capacities',
		profileCapacityTitleHeader: 'Title of the capacity',
		profileCapacityTitle: 'Title',
		profileCapacityRemarkHeader: 'Default remark',
		profileCapacityRemark: 'Remark',
		profileCapacityActions: 'Actions',
		profileCapacityDelete: 'Delete capacity',
		profileCapacityAdd: 'Add',
		profileCapacityUpdate: 'Update capacity',
		profileCapacityCreate: 'Create capacity',
		profileVisualData: 'Visual signature/initials',
		profileItsmeInfo: 'Your itsme\u00AE identity was stored during a signing session. This lets you skip the identification step when signing with itsme\u00AE. You can remove this identity from your Quill account here.',
		profileItsmeForget: 'Forget itsme\u00AE identity',
		profileItsmeForgetSuccess: 'Your itsme\u00AE identity was successfully removed from your Quill account.',
		profileGeneralHeader: 'General settings',
		profileGeneralDefaultRowsPerPage: 'Default number of rows in tables',
		profileGeneralLanguage: 'Language',
		profileOutOfOfficeHeader: 'Out-of-office',
		profileOutOfOfficeNotActive: 'Not active',
		profileOutOfOfficeAlwaysActive: 'Always active',
		profileOutOfOfficeConfiguredActive: 'Active',
		profileOutOfOfficeConfiguredActiveFrom: 'From',
		profileOutOfOfficeConfiguredActiveUntil: 'to',
		profileCommunicationHeader: 'Communication settings',
		profileCommunicationSubHeader: 'Receive e-mails for:',
		profileCommunicationEmailInvite: 'Approval or sign requests invitations',
		profileCommunicationEmailReminder: 'Approval or sign requests reminders',
		profileCommunicationEmailFullySigned: 'Documents that are fully signed',
		profileCommunicationEmailSignaturePlaced: 'Signatures placed on a document you sent out',
		profileCommunicationEmailApproval: 'Documents that are approved or declined',
		profileCommunicationEmailClosed: 'Documents that are closed',
		profileCommunicationEmailExpired: 'Documents that expire',
		profileUpdatePasswordHeader: 'Change password',
		profileApiAccess: 'API access',
		profileApiAccessNotEnabled: 'API access is not enabled for this account, click below to enable it. Please note that your API key will only be visible once!',
		profileApiAccessEnable: 'Enable API access',
		profileApiAccessEnabled: 'API access is enabled for this account, click below to disable it.',
		profileApiAccessSecret: 'Secret',
		profileApiAccessSecretCopy: 'Copy',
		profileApiAccessDisable: 'Disable API access',
		password: 'Password',
		currentPassword: 'Current password',
		newPassword: 'New password',
		newPasswordConfirmation: 'New password confirmation',
		passwordConfirmation: 'Password confirmation',
		passwordRequirements: 'Your password must be at least 8 characters long',

		notification_ON: 'On',
		notification_OFF: 'Off',
		notification_DAILY_DIGEST: 'Daily digest',

		importNoHeader: 'Without header',
		importPasteOrDropFile: 'Paste user data here or drag and drop a CSV file (UTF-8)',
		importDownloadTemplate: 'Download example file',
		importInviteUsers: 'Invite new users',
		importLookupCreatedUsersResult: 'Number of users successfully created',
		importLookupFoundUsersResult: 'Number of users successfully found',
		importLookupUsersNotFound: 'Users that could not be found:',
	},

	userGroup: {
		header: 'User groups',
		name: 'Name',
		description: 'Description',
		actions: 'Actions',
		create: 'Create user group',
		delete: 'Delete',
		deleteConfirm: 'Are you sure you want to delete this user group?',
		deleted: 'User group deleted',

		createName: 'The name of the user group',
		createDescription: 'The description of the user group',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'After the creation of the user group, members can be added and configured.',

		settings: 'Settings',
		settingsNameDescription: 'User group name and description',
		settingsFinish: 'Everything filled in?',
		settingsFinishDescription: 'Press save to keep changes',
		settingsSave: 'Save',

		import: 'Import users',

		users: 'Members',
		userName: 'Name',
		userEmail: 'E-mail',
		userAdmin: 'Group admin',
		addUser: 'Add member',
		userRemove: 'Remove member',
		userRemoveConfirm: 'Are you sure you want to remove this member?',
		userState: 'Status',

		userSelect: 'Select member(s) to add',
		usersDescription: 'Add, remove and configure the members of this group. Note these changes are applied immediately.',
	},

	folder: {
		header: 'Folders',
		name: 'Name',
		description: 'Description',
		actions: 'Actions',
		settings: 'Settings',
		permissions: 'Permissions',
		delegates: 'Delegates',
		delete: 'Delete',
		deleteConfirm: 'Are you sure you want to delete this folder?',
		deleted: 'Folder deleted',

		create: 'Create folder',
		createName: 'The folder name',
		createDescription: 'The folder description',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'After the creation of the folder more settings and permissions can be applied. Settings and permissions have impact on all documents in the folder.',

		settingsNameDescription: 'Folder name and description',
		settingsSignatureTypes: 'Signature types',
		settingsSignatureType_ELECTRONIC_WITH_WITNESS_SEAL: 'Electronic seal (AES)',
		settingsSignatureType_BELGIAN_EID: 'Belgian eID (QES)',
		settingsSignatureType_ITSME: 'Qualified itsme\u00AE (QES)',
		settingsSignatureType_ITSME_ADVANCED: 'Advanced itsme\u00AE (AES)',
		settingsSignatureType_SMS_OTP: 'SMS OTP (AES)',
		settingsSignatureType_EMAIL_OTP: 'E-mail OTP (AES)',
		settingsSignatureType_HANDWRITTEN: 'Handwritten (SES)',
		settingsUseCompanyItsmePartnerCode: 'itsme\u00AE frequent signer folder',
		settingsBlocked: 'setting determined at company level',
		settingsReasonAndRemark: 'Motivation and remark',
		settingsReason: 'Signer can add motivational reason',
		settingsReasonLegalNoticeMandatory: 'Make this a mandatory legal notice for the user to enter. This will also appear visually on the document.',
		settingsReasonLegalNoticeText: 'Pre-filled value',
		settingsEmailDistributionList: 'Default settings for the post sign distribution list',
		settingsEmailDistributionLabel: 'List of e-mail addresses separated by \';\'',
		settingsEmailDocumentDistributionListDescription: 'Document recipients',
		settingsEmailDocumentAndEvidenceReportDistributionListDescription: 'Document and evidence report recipients',
		settingsEmailEvidenceReportIncludedInPostSignEmail: 'Include evidence report',
		settingsOverrideOnFolderLevelRemove: 'Click here to use the default company settings',
		settingsOverrideOnFolderLevelSet: 'Click here to override the default company settings',
		settingsRemark: 'Signer can add a remark',
		settingsMailbox: 'Mailbox',
		settingsMailboxEnabled: 'Activate a mailbox for this folder',
		settingsMailboxAddress: 'E-mail address for this mailbox',
		settingsFolderCleanup: 'Automatic document deletion',
		settingsFolderCleanupOverwrite: 'Overwrite company settings for this folder',
		settingsCleanupDaysProcessed: 'day(s) after a completed document is deleted',
		settingsCleanupDaysPending: 'day(s) after an inactive unfinished document is deleted',
		settingsFolderExpiration: 'Automatic document expiration',
		settingsExpirationOverride: 'Overwrite company settings for this folder',
		settingsExpirationEnable: 'Enable expiration of approval and sign requests',
		settingsSignatureExpirationDays: 'day(s) before an open approval and sign request expires',
		settingsFolderForwarding: 'Forwarding of approval and signature requests',
		settingsAllowForwarding: 'Allow forwarding',
		settingsForwardingEnabledByDefault: 'Enable forwarding by default',
		settingsReminders: 'Reminders',
		settingsRemindersOverwrite: 'Overwrite company settings for this folder',
		settingsRemindersIntervalInDays: 'day(s) between reminders. Use 0 to disable.',
		settingsMinimizeCollectionInvites: 'Limit collection invitations',
		settingsMinimizeCollectionInvitesFolder: 'Overwrite company settings for this folder',
		settingsMinimizeCollectionInvitesEnable: 'Wait on other approvals/signatures to reduce the number of collection invitations',
		settingsMinimizeCollectionInvitesGracePeriod: 'Maximum waiting time in minutes',
		settingsMinimizeCollectionInvitesGracePeriodHelp: 'Leave blank to wait indefinitely',
		settingsMinimizeCollectionFinishedNotifications: 'Limit collection finished notifications',
		settingsMinimizeCollectionFinishedNotificationsFolder: 'Overwrite company settings for this folder',
		settingsMinimizeCollectionFinishedNotificationsEnable: 'Send out document approved/signed notifications only when the whole collection is finished',
		settingsSignerNeedsSignatureToDownload: 'Download requires signer signature',
		settingsSignerNeedsSignatureToDownloadFolder: 'Overwrite company settings for this folder',
		settingsSignerNeedsSignatureToDownloadEnable: 'A fully placed signature is necessary for the signer to download a document in this folder',
		settingsPlaceholders: 'Lookup placeholders in documents',
		settingsPlaceholderValue: 'Keyword to search',
		settingsPlaceholderFieldType: 'Type of field',
		settingsPlaceholderFieldType_SIGNATURE: 'Signature field',
		settingsPlaceholderFieldType_PARAPH: 'Initials field',
		settingsPlaceholderFieldType_FORM: 'Form field',
		settingsPlaceholderFormFieldType: 'Type of form field',
		settingsPlaceholderFormFieldType_TEXT: 'Text',
		settingsPlaceholderFormFieldType_CHECKBOX: 'Checkbox',
		settingsPlaceholderFormFieldType_DATE: 'Date',
		settingsPlaceholderFormFieldType_NUMERIC: 'Numeric',
		settingsPlaceholderFormFieldType_ATTRIBUTE: 'Signer attribute',
		settingsPlaceholderAdd: 'Add',
		settingsUserUsage: 'Users allowed to approve/sign documents',
		settingsUserUsageRegisteredUser: 'Registered Users',
		settingsUserUsageGuest: 'Guests',
		settingsUserUsageContact: 'Contacts',
		settingsUserUsageContactHelp: 'Contacts are linked to a specific folder',
		settingsUserInvite: 'Users allowed to be invited',
		settingsUserInviteRegisteredUsers: 'Registered Users',
		settingsUserInviteGuests: 'Guests',
		settingsUserInviteContacts: 'Contacts',
		settingsMailRedirect: 'Enable redirect URL when revisiting sign link via mail',
		settingsMailRedirectEnabled: 'Mail redirect enabled',
		settingsSigningKioskMode: 'Signing in kiosk mode',
		settingsSigningKioskModeEnabled: 'Kiosk mode enabled',

		settingsFinish: 'Everything filled in?',
		settingsFinishDescription: 'Press save to keep changes',
		settingsSave: 'Save',

		permissionsGroup: 'Group permissions',
		permissionsGroupDescription: 'Granting a permission to a group grants it to all its users. This means that if a user is a member of a group that has a certain permission, the user is allowed that permission, even if the user is also a member of a group that doesn\'t have that permission.',
		permissionsGroupLabel: 'Group',
		permissionsGroupSelect: 'Select group(s) to add',
		permissionsEditFolderPermission: 'Edit folder',
		permissionsEditDocumentsPermission: 'Edit documents',
		permissionsViewDocumentsPermission: 'View documents',
		permissionsAddUserNoPermissions: 'Add user(s) without permissions',
		permissionsAddUserAllPermissions: 'Add user(s) with all permissions',
		permissionsAddGroupNoPermissions: 'Add group(s) without permissions',
		permissionsAddGroupAllPermissions: 'Add group(s) with all permissions',
		permissionsUser: 'User permissions',
		permissionsUserDescription: 'Preferably user permissions are granted via the group permissions above. If for some reason a user can not be added to an existing group or should have more permissions, they can be added here.',
		permissionsUserSelect: 'Select user(s) to add',
		permissionsFinish: 'All set?',
		permissionsFinishDescription: 'Press save to keep changes',
		permissionsSave: 'Save',
	},

	template: {
		name: 'Name',
		creatorName: 'Creator',
		folderName: 'Folder',
		actions: 'Actions',
		edit: 'Edit template',
		view: 'View template',
		delete: 'Delete template',
		deleted: 'Template deleted',
		select: 'Select template',
		overviewHeader: 'Document templates',
		deleteTemplateConfirm: 'Are you sure you want to remove the template?',
		createName: 'Template name',
		createFolder: 'Template folder',
		createType: 'Template type',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'Press save to create the template.',
		create: 'Create template',
		folder: 'Folder',
		type: 'Type',
		typeSingleDocNoPdf: 'Single document, without PDF',
		typeSingleDocPdf: 'Single document, with PDF',
		typeCollection: 'Collection',
		uploadDoc: 'Upload document',
		uploadErrorGeneralError: 'Error',
	},

	document: {
		name: 'Name',
		createdAt: 'Created at',
		changedAt: 'Changed at',
		requestedAt: 'Requested at',
		description: 'Description',
		folderName: 'Folder',
		status: 'Status',
		status_FUTURE: 'Pending',
		status_PREPARING: 'In preparation',
		status_WAITING_FOR_APPROVAL: 'Wait for approval',
		status_APPROVED: 'Approved',
		status_NOT_APPROVED: 'Not approved',
		status_WAITING_FOR_ACTION: 'Waiting for action',
		status_WAITING_FOR_SIGNATURES: 'Wait for signatures',
		status_WAITING_FOR_CHILD_DOCUMENTS: 'Wait for subdocuments',
		status_SIGNED: 'Signed',
		status_SIGNED_WITH_DECLINATIONS: 'Signed (with declinations)',
		status_CLOSED: 'Closed',
		status_DELETED: 'Deleted',
		status_DECLINED: 'Declined',
		status_ATTACHMENT: 'Attachment',
		status_ATTACHMENT_GENERIC: 'Generic attachment',
		status_EXPIRED: 'Expired',
		creator: 'Creator',
		approvers: 'Approvers',
		signers: 'Signers',
		actions: 'Actions',
		upload: 'Upload',
		createFromTemplate: 'From template',
		download: 'Download',
		downloadEvidenceReport: 'Download evidence report',
		downloadAttachment: 'Download attachment',
		downloadDocuments: 'Documents',
		downloadEvidenceReports: 'Evidence reports',
		downloadAll: 'Documents and evidence reports',
		downloadNoResult: 'No download result found',
		edit: 'Edit',
		editAll: 'Edit (in bulk)',
		view: 'View',
		reminder: 'Send reminder',
		deleteDocuments: 'Delete documents',
		deleteDocumentsConfirm: 'Are you sure you want to delete these documents? The documents will be deleted for all users.',
		signingOrderType_PARALLEL: 'Parallel',
		signingOrderType_SERIAL: 'Serial',
		signingOrderType_KIOSK: 'Kiosk',
		approvalOrderType_PARALLEL: 'Parallel',
		approvalOrderType_SERIAL: 'Serial',
		signOrderType: 'Signing order',
		approvalOrderType: 'Approval order',
		sign: 'Sign',
		signDocuments: 'Start signing (in bulk)',
		approve: 'Approve',
		approveDocuments: 'Start approving (in bulk)',
		allSelected: 'All {0} rows are selected. $link;Clear selection$',
		selectAll: '{0} rows selected. To select all {1} rows, $link;click here$',
		selectionCountSingle: '1 row selected',
		selectionCountMultiple: '{0} rows selected',

		downloadDisclaimer: 'Not all documents and evidence reports are available for download. This can be the case for documents for which you do not have the correct folder permission, for confidential documents, documents that are not fully signed or are still in preparation.',

		generalOverviewHeader: 'Documents',

		uploadHeader: 'Upload documents',
		uploadFolder: 'Folder',
		uploadTemplate: 'Template',
		uploadTemplateApply: 'Apply template after uploading',
		uploadFiles: 'Upload files',
		uploadSelectFiles: 'Select one or more files on your computer',
		uploadDragFilesHere: '...or drag and drop your file(s) here',
		uploadItemName: 'Name',
		uploadItemSize: 'Size',
		uploadItemState: 'Status',
		uploadItemState_NEW: 'New',
		uploadItemState_UPLOADING: 'Uploading...',
		uploadItemState_UPLOADED: 'Uploaded',
		uploadItemState_ERROR: 'Error',
		uploadItemState_SIZE_INVALID: 'Size invalid, maximum size is {0}',
		uploadItemDetails: 'Details',
		uploadItemDelete: 'Delete',
		uploadItemEdit: 'Start editing',
		uploadNoFilesYet: 'There are no files yet, press the button above, or drag and drop files above to get started',
		uploadDocDetected: 'Attention: doc(x) files are automatically converted to PDF format. It can happen that certain fonts/layouts are not converted properly. Therefore: always check the document carefully after conversion or use files in PDF format directly.',
		uploadNormal: 'Upload',
		uploadCollection: 'Upload collection',
		uploadAsCollection: 'Upload as collection',
		uploadOptionalCollectionName: 'Optional collection name',
		uploadGoToCollection: 'Go to collection',

		signingOverviewHeader: 'Documents for me',
		signingOverviewTab_ALL: 'All documents',
		signingOverviewTab_WAIT_FOR_SIGNATURE: 'Waiting for signature',
		signingOverviewTab_DOWNLOAD: 'Download documents',

		approvalOverviewHeader: 'Documents for me',
		approvalOverviewTab_ALL: 'All documents',
		approvalOverviewTab_WAIT_FOR_APPROVAL: 'Waiting for approval',
		approvalOverviewTab_DOWNLOAD: 'Download documents',

		createName: 'Document name',
		createFolder: 'Document folder',
		createTemplate: 'Library template',
		selectTemplate: 'Select template',
		create: 'Add',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'The document can be further configured after adding it.'
	},

	visualSignatureOrParaph: {
		signatureDraw: 'Draw a new visual signatature in the area below',
		signatureHeader: 'Signature',
		signatureCurrent: 'Current visual signature',
		paraphDraw: 'Draw a new visual initials in the area below',
		paraphHeader: 'Initials',
		paraphCurrent: 'Current visual initials',
		draw: 'Draw',
		clear: 'Clear',
		upload: 'Upload file (max 1MB)',
		delete: 'Remove',
	},

	approval: {
		header: 'Approve documents',
		instructions: '"Approve" gives approval to forward the selected document(s) to the signers. An electronic seal will be included in the document. "Decline..." will decline the selected document(s). This may stop the workflow.',
		view: 'View',
		download: 'Download',
		downloadAll: 'Download all',

		attachment: 'Attachment',
		approvalRequestState_NEW: 'New',
		approvalRequestState_WAITING: 'Waiting',
		approvalRequestState_APPROVED: 'Approved',
		approvalRequestState_DECLINED: 'Declined',
		approvalRequestState_CLOSED: 'Closed',
		approvalRequestState_EXPIRED: 'Expired',
		approvalRequestState_OBSOLETE: 'Obsolete',

		approve: 'Approve',
		declineDialog: 'Decline...',
		decline: 'Decline',
		declineHeader: 'Decline to approve',
		declineWhyHeader: 'Decline your approval request',
		declineWhyDetails: 'By confirming your decline will be registered. Depending on the settings of the document, this may end the workflow early. If not, other approvers can still approve the document. Please note that this action is irreversible.',
		declineReasonHeader: 'Provide your reason',
		declineReasonDetails: 'Briefly describe your reason for not approving the document. This field is mandatory.',
		declineReasonLabel: 'Reason',

		everythingApprovedDeclined: 'Everything was approved/declined',
		documentsDeclined: '{0} document(s) was(were) declined',
		documentsApproved: '{0} document(s) was(were) approved',
		approvers: 'Approvers',
		approversDescription: 'List of approvers for document:',
		signers: 'Signers',
		signersDescription: 'List of signers for document:',
	},

	signing: {
		signHeader: 'Sign documents',
		declineHeader: 'Decline documents',
		instructions: 'Here below you can find the document(s) that can be signed. The eye indicates which document is shown on the right. You can select the document to view by clicking on \'view\'.',

		attachment: 'Attachment',
		signRequestState_NEW: 'Preparing',
		signRequestState_WAITING_FOR_SIGNATURE: 'Waiting',
		signRequestState_SIGNED: 'Signed',
		signRequestState_DECLINED: 'Declined',
		signRequestState_CLOSED: 'Closed',
		signRequestState_EXPIRED: 'Expired',
		signRequestState_OBSOLETE: 'Obsolete',

		view: 'View',
		download: 'Download',
		downloadAll: 'Download all',
		location: 'Your signature will be placed here',
		locationParaph: 'Your initials will be placed here',

		signingMethodChoose: 'Choose a signing method',
		signingMethod_ELECTRONIC_WITH_WITNESS_SEAL: 'Electronic seal (AES)',
		signingMethod_BELGIAN_EID: 'Belgian eID signature (QES)',
		signingMethod_ITSME: 'itsme\u00AE signature (QES)',
		signingMethod_ITSME_ADVANCED: 'advanced itsme\u00AE signature (AES)',
		signingMethod_SMS_OTP: 'OTP via SMS signature (AES)',
		signingMethod_EMAIL_OTP: 'OTP via e-mail signature (AES)',
		signingMethod_HANDWRITTEN: 'Handwritten signature (SES)',
		signingMethodNoBulkSigning: 'Currently, this method doesn\'t support signing more than 70 documents at once. Only the first 70 documents will be signed',
		signingMethodDisabledDevice: 'This signing method is not possible on your device',
		signingMethodNoSameDocuments: 'This method does not support simultaneous signing of the same document. Only the first instance of the document will be signed.',

		acquiringLock: 'Locking documents',
		acquiringLockFailed: 'Not all documents could be locked; someone else is currently signing.',
		acquiringLockQueued: 'There are too many signing requests at this moment, to reduce the load you are placed in a queue.',
		acquiringLockQueuedPosition: 'Position in queue:',

		eidCardHeader: 'Insert your eID card in the card reader',
		eidCardNotFound: 'Please insert your eID card',
		eidCardFound: 'eID card found',
		eidEnterPin: 'Enter your PIN',
		eidMiddlewareNotRunning: 'You will need to install Dioss eID Middleware on your computer before continuing with the eID card authentication.',
		eidMiddlewareDownloadHere: 'Download Dioss eID Middleware here:',
		eidWrongPin: 'You have entered the wrong PIN',
		eidPinTimeoutOrCancel: 'You did not enter the PIN in time or it was cancelled',
		eidSignError: 'There was an error when reading out the eID card. We suggest to remove the card, and try again, or use a different reader.',
		eidExternalPinPad: 'Please enter pin on the card reader when prompted',
		eidExternalPinDialog: 'Please enter pin on the external pin input popup dialog when prompted',

		smsOtpAuthenticate: 'Authentication',
		smsOtpAuthenticateDescription: 'Please enter the last 4 digits of your mobile number, so we can verify your mobile number and send the one-time password via SMS',
		smsOtpAuthenticateLabel: 'Last 4 digits',
		smsOtpAuthenticateSend: 'Send verification code',
		smsOtpAuthenticateSendAgain: 'Send again',
		smsOtpAuthenticateSuccess: 'The verification code was sent successfully',
		smsOtpChallenge: 'Enter the one-time password that was received via SMS',
		smsOtpChallengeLabel: 'One-time password',

		emailOtpChallenge: 'You can send the one-time password via e-mail here and enter it',
		emailOtpAuthenticateSend: 'Send',
		emailOtpAuthenticateSendAgain: 'Send again',
		emailOtpChallengeLabel: 'One-time password',

		handwrittenSignature: 'Please draw or upload a handwritten signature',

		reasonHeader: 'Motivate your reason',
		reasonLabel: 'Reason',
		reasonLegalNoticeLabel: 'Legal notice',
		reasonLegalNoticeMandatoryText: 'For these documents, you must re-enter the mandatory legal notification below, which is added to the electronic signature and is visibly displayed on the document',
		reasonLegalNoticeText: 'Please enter the following',
		reasonLegalNoticeMandatory: 'You must enter a mandatory legal notification, this is added to the electronic signature and is visible on the document',
		reasonOptional: 'You may optionally enter a reason, it will then be added to the electronic signature and will not be visible on the document',
		reasonDisabledForSomeDocumentsContent: 'For the other documents the option to add a motivational reason is disabled. Your reason will not be applied on those documents.',

		remarkHeader: 'Write a comment on the document',
		remarkOptional: 'The comment will be visually added to the signature (e.g. read and approved). Entering a comment is optional.',
		remarkLabel: 'Remark',
		remarkDisabledForSomeDocumentsContent: 'For some of the selected documents the option to add a remark is disabled. Your remark will not be applied on those documents.',

		declineInfo: 'Declining your sign request(s)',
		declineInfoDetails: 'By confirming you wish to decline signing this(these) document(s), your sign request(s) will be left without a signature. It will be given a corresponding status. Depending on the document\'s settings this may close the remaining sign requests and finish the document\'s workflow. If not, other signers may still be allowed to place their signatures. Please be aware this action cannot be reversed.',
		declineReason: 'Provide your reason',
		declineReasonDetails: 'Briefly describe your motivation for choosing not to sign the document. This field is mandatory.',
		declineReasonLabel: 'Decline reason',
		declineAttachment: 'Attach file',

		itsmeSigningLabel: 'Signing with itsme®',
		itsmeSigningDetails: 'By signing with itsme® the signature will be created using an itsme® certificate uniquely identifying you. You will need an itsme® account and the itsme® app on your phone. If you have not yet set up your itsme® identity, please first visit this link to do so: $link;https://www.itsme.be/;www.itsme.be$',
		itsmeForgetUserCodeDetails: 'Your itsme® identity was stored during a previous session. This lets you skip the identification step. You can click the "forget itsme® identity" button to remove it. Itsme® will then ask you to identify again. You are given the option to save that new identity.',
		itsmeForgetUserCode: 'Forget itsme® identity',
		itsmeLinkUserCodeDetails: 'When signing with itsme® in Quill for the first time you will have to identify yourself. You can choose to have your itsme® identity remembered in Quill to skip this step for later signatures. It is possible to remove this link later. For signatures via an e-mail address shared by multiple signers it is advisable to leave the option turned off.',
		itsmeLinkUserCode: 'Remember my itsme® identity',
		itsmeDirections: 'You will be redirected to perform the itsme® signing. Please keep this window open until signing has been completed. Upon validating the signature you are redirected back to Quill and the signature will be placed on the document.',
		itsmeSessionBusyTitle: 'Signing with itsme® in progress',
		itsmeSessionBusyStarted: 'You will be redirected to perform the itsme® signing...',
		itsmeSessionBusySigning: 'Trying to retrieving your signature from itsme® and placing it on the document...',
		itsmeRedirectError: 'There was a problem handling the signing session, try again...',

		redirectTitle: 'Redirecting...',
		redirectDetails: 'You will be redirected in 5 seconds. If this doesn\'t work, please click $link;here$',

		sign: 'Sign',
		decline: 'Decline',
		forward: 'Forward',
		signingBusy: 'Signing...',
		decliningBusy: 'Declining...',

		everythingSignedDeclined: 'Everything was signed/declined',
		documentsDeclined: '{0} document(s) was(were) declined',
		documentsSigned: '{0} document(s) was(were) signed',
		approvers: 'Approvers',
		approversDescription: 'List of approvers for document:',

		capacities: 'Capacities',
		capacitiesInfo: 'You can select (or fill in) the capacity for each signature field via the table below. You can also leave it blank if you do not want to link any capacity to the digital signature.',
		capacitiesDocumentName: 'Document name',
		capacitiesPage: 'Page',
		capacitiesSelectedTitle: 'Selected capacity',
		capacitiesSelectedRemark: 'Selected remark',
		capacitiesContinue: 'Continue',

		forwardHeader: 'Forward documents',
		forwardSelectSignRequests: 'Select which requests should be forwarded',
		forwardSelectTargetUser: 'Choose the person to forward the requests to',
		forwardUseDelegate: 'Use default delegation rules',
		forwardUserDetails: 'Send to a specific person',
		forwardEmail: 'Email',
		forwardFirstName: 'First name',
		forwardLastName: 'Last name',
		forwardRemarkHeader: 'Message for the receiver of the request (optional)',
		forwardRemark: 'Remark',
		forwardSuccess: 'Requests successfully forwarded',

		formFields: 'Fillable fields on this document',
		formFieldsDocumentName: 'Name',
		formFieldsFill: 'Fill in',
		formFieldsAttribute_FIRST_NAME: 'First name',
		formFieldsAttribute_LAST_NAME: 'Last name',
		formFieldsAttribute_EMAIL: 'Email',
		formFieldsAttribute_MOBILE_NUMBER: 'Mobile number (SMS OTP)',
		formFieldsAttribute_MEMO: 'Memo',
		formFieldsGoTo: 'Go to field on page',
		formFieldsRequired: 'There are mandatory fields on (the) document(s) that still need to be fill in.',
		formFieldsValidValueRequired: 'There are fillable fields that have an invalid value.',
		formFieldsValueInvalid: 'Invalid value',

		required: 'Required*',

		visualSignatureSaveAsDefault: 'Save as default signature',
		visualParaphSaveAsDefault: 'Save as default initials',

		signingKioskHeader: 'Kiosk signers',
		signingKioskInstructions: 'The following users are part of the kiosk documents. Select a user to view and/or start the signing of the relevant document(s).',
		signingKioskPendingChangesLabel: 'You are about to leave the kiosk mode. Are you sure you want to continue?',
		signingKioskPendingChangesSaveAndLeaveLabel: 'Continue'
	},

	editor: {
		editDocumentHeader: 'Edit document',
		editTemplateHeader: 'Edit template',
		editCollectionHeader: 'Edit collection',
		editVirtualCollectionHeader: 'Edit multiple documents',

		collectionAllDocuments: 'All documents in ',

		actionLogs: 'Action logs',
		save: 'Save',
		send: 'Send',
		sign: 'Sign',
		sendAndSign: 'Send and sign',
		sent: 'Document has been sent',
		sendingFailed: 'Document could not be sent',
		sentButNothingToSign: 'Document has been sent. There is nothing to sign.',
		close: 'Revoke',
		restart: 'Restart',
		restarted: 'Document has been restarted',
		delete: 'Delete',
		closeConfirm: 'Are you sure you want to revoke request(s) to approve/sign this document? As a result this document will be closed for all approvers/signers.',
		documentDeleteConfirm: 'Are you sure you want to delete this document? The document will be deleted for all users.',
		templateDeleteConfirm: 'Are you sure you want to delete this template?',
		tab_OVERVIEW: 'Overview',
		tab_GENERAL: 'General',
		tab_APPROVERS: 'Approvers',
		tab_SIGNERS: 'Signers',
		tab_MULTI_SIGN: 'Multi-signers',
		tab_POST_ACTIONS: 'Post-sign actions',
		signingOrder_SERIAL: 'Serial',
		signingOrder_PARALLEL: 'Parallel',
		signingOrder_KIOSK: 'Kiosk',
		approveOrder_SERIAL: 'Serial',
		approveOrder_PARALLEL: 'Parallel',
		orderUp: 'Up',
		orderDown: 'Down',

		overviewApprovers: 'Approvers',
		overviewApproversDescription: 'List of approvers',
		overviewSigners: 'Signers',
		overviewSignersDescription: 'List of signers',
		overviewMultisigners: 'Multi-sign',
		overviewMultisignersDescription: 'List of signers',
		overviewDocuments: 'Documents',
		overviewDocumentsDescription: 'List of sub documents',

		generalAttachment: 'Document is an attachment',
		generalAttachmentHasIncompleteSignersWarning: 'This document has not been signed by all signers. If you configure this document as attachment, these signers will be lost.',
		generalDescription: 'Document description',
		generalDescriptionMandatory: 'The document description is mandatory',
		generalFolder: 'Folder',
		generalConfidentiality: 'Confidentiality',
		generalConfidentialitySet: 'This document will only be visible for the creator, signers and approvers.',
		generalConfidentialWarningWhenNotCreator: 'Please note that you are not the owner of the document; if you make the document confidential, you will no longer have access to this document.',
		generalPdf: 'PDF',
		generalPdfRecreated: 'There were some compatibility issues with the structure of this PDF document that have been fixed automatically.',
		generalPdfADetected: 'Detected PDF/A conformance',
		generalPdfADetectedNone: 'None',
		generalPdfADetectedNoneItsme: 'Please note that one or more signatures can be or have been placed with itsme\u00AE but the document is not PDF/A compliant. itsme\u00AE recommends that documents are PDF/A compliant.',
		generalMotivation: 'Motivation',
		generalOverrideLegalNoticeSettings: 'Override the default motivation settings',
		generalReasonAvailable: 'Signer can add motivational reason',
		generalReasonLegalNoticeMandatory: 'Make this a mandatory legal notice for the user to enter. This will also appear visually on the document.',
		generalReasonLegalNoticeText: 'Legal notice',
		generalExpiration: 'Expiration',
		generalExpirationOverride: 'Override the default expiration settings',
		generalExpirationEnable: 'Enable expiration of approvals and signatures',
		generalExpirationDays: 'day(s) before incomplete approvals and signatures expire',
		generalForwarding: 'Forwarding',
		generalAllowForwarding: 'Enable forwarding of requests',
		generalTemplatePages: 'Template pages',
		generalTemplateOptions: 'Template options',

		approvalOrder: 'Approval order',
		approvalList: 'List of approvers',
		approvalListEmpty: 'No approvers configured',
		approvalDelete: 'Delete this approver',
		approvalAddApprover: 'Add approver',
		approvalAddSignerGroup: 'Add approval group',
		approvalImportApprovers: 'Import approvers',
		approvalDeclineReason: 'Decline reason',
		approvalSeal: 'Approve with electronic seal',
		approvalRequired: 'Required approval',
		approvalNumberApproversToDecide: 'Number of approvers that need to decide (approve or decline)',
		approvalNumberApproversToDecideError: 'The number must be greater than or equal to the amount of required approvers, but less than or equal to the total number of approvers',
		approvalNumberApproversToApprove: 'Number of approvers that need to approve the document',
		approvalNumberApproversToApproveError: 'The number must be greater than or equal to the amount of required approvers, but less than or equal to the number of approvers that need to decide',
		approvalNumberSerial: 'In serial order, the number is not adjustable.',

		signingList: 'List of signers',
		signingListEmpty: 'No signers configured',
		signingPage: 'Page',
		signingSignatureSettings: 'Settings',
		signingDelete: 'Delete this signer',
		signingNotPlaced: 'Some signature fields have not yet been placed on the document, you can do this by dragging the signer\'s field to the correct location on a specific page.',
		signingAddSigner: 'Add signer',
		signingAddSignerGroup: 'Add signer group',
		signingImportSigners: 'Import signers',
		signingChangeSignatureTypes: 'Change the signature types',
		signingBulkSignatureTypes: 'Change the signature types for all signers',
		signingSignatureTypeError: 'Please choose signature type',
		signingSmsOtpNumberError: 'Choose number for SMS OTP',
		signingSmsOtpKnownNumber: 'Use the known number for this signer, this number ends with $SUFFIX.',
		signingSmsOtpEnterNumber: 'Use a new number, this number will be saved so it can be reused later',
		signingMethod_ELECTRONIC_WITH_WITNESS_SEAL: 'Electronic seal (AES)',
		signingMethod_BELGIAN_EID: 'Belgian eID signature (QES)',
		signingMethod_ITSME: 'Qualified itsme\u00AE signature (QES)',
		signingMethod_ITSME_ADVANCED: 'Advanced itsme\u00AE signature (AES)',
		signingMethod_SMS_OTP: 'OTP via SMS signature (AES)',
		signingMethod_EMAIL_OTP: 'OTP via e-mail signature (AES)',
		signingMethod_HANDWRITTEN: 'Handwritten signature (SES)',
		signingSignatureFieldOptions: 'Signature field options',
		signingSignatureFieldDelete: 'Delete',
		signingSignatureFieldSettings: 'Settings',
		signingParaphField: 'Initials field',
		signingParaphFieldDescription: 'Initials can be duplicated to other pages (without a visual signature) by right clicking on them and selecting \'duplicate to other pages\'.',
		signingParaphFieldPlaceOnDocument: 'Drag and drop on a page to place an initials field',
		signingParaphFieldDeleteAll: 'Delete all initials fields',
		signingParaphFieldDuplicate: 'Duplicate to other pages',
		signingExtraSignatureField: 'Extra visual signature fields',
		signingExtraSignatureFieldPage: 'Page',
		signingExtraSignatureFieldPlaceOnDocument: 'Drag and drop this field on a page to place an extra signature field.',
		signingFormField: 'Fillable fields',
		signingFormFieldPage: 'Page',
		signingFormFieldPlaceOnDocument: 'Drag and drop this field on a page to add a new fillable field to the document.',
		signingDistributeSignerInCollection: 'Set this field in all documents of this collection',
		signingCapacity: 'Signer capacity',
		signingPlaceholder: 'Signer placeholder',
		signingPlaceholderAdd: 'Add signer placeholder',
		signingChooseSigner: 'Select signer',
		signingOrderGroupSettings: 'Signers group settings',
		signingOrderGroupDrag: 'Drag to change order or to group signers together',
		signingOrderGroupDisband: 'Ungroup',
		signingOrderGroupMinimalNumberOfSignersDescription: 'Minimum number of signers. This number must be greater than or equal to the number of mandatory signers and must be at least 1.',
		signingOrderGroupMinimalNumberOfSigners: 'Number of signers',
		signingOrderGroupRequiredSigners: 'Required signers',
		signingFieldPlaceholder_SIGNATURE: 'Signature placeholder',
		signingFieldPlaceholder_PARAPH: 'Initials placeholder',
		signingFieldPlaceholder_FORM: 'Field placeholder',
		signingKioskMode: 'Kiosk mode',
		signingKioskModeConfirm: 'In kiosk mode there can only be 1 registered user added as signer, the number of guest users is unlimited. Approvers and multi-signers will automatically be discarded. Are you sure you want to continue?',
		signingKioskModeRegisteredUserError: 'There are multiple registered users added as signer to the kiosk document. There can only be 1 registered user (and multiple guest users) added in kiosk mode.',

		formFieldTypeDescription: 'Type of field',
		formFieldType_TEXT: 'Text',
		formFieldType_CHECKBOX: 'Checkbox',
		formFieldType_DATE: 'Date',
		formFieldType_NUMERIC: 'Numeric',
		formFieldType_ATTRIBUTE: 'Signer attribute',
		formFieldAttributeType_FIRST_NAME: 'First name',
		formFieldAttributeType_LAST_NAME: 'Last name',
		formFieldAttributeType_EMAIL: 'Email',
		formFieldAttributeType_MOBILE_NUMBER: 'Mobile number (SMS OTP)',
		formFieldAttributeType_MEMO: 'Memo',
		formFieldAttributeTypeUsage: 'The value of this attribute is automatically applied at signing time. The signer cannot change the value',
		formFieldAttributeTypeDescription: 'Type attribute',
		formFieldNameDescription: 'Name of the field',
		formFieldName: 'Name',
		formFieldValueDescription: 'Value of the field',
		formFieldValue: 'Value',
		formFieldOptions: 'Options',
		formFieldEditable: 'Editable by signer',
		formFieldRequired: 'Required',
		formFieldStyle: 'Style options',
		formFieldValidator: 'Validator',
		formFieldValueInvalid: 'Invalid value for validator',

		emailMessageHeaderSigners: 'Message in e-mail to signers',
		emailMessageHeaderApprovers: 'Message in e-mail to approvers',
		emailMessageDescriptionSigners: 'Each signer will receive an e-mail with the request to sign the document. The personalized message below in the signer\'s preferred language will be included in the e-mail. $signer acts as a placeholder and will be replaced by the actual name of the signer this mail will be sent to. $document will be replaced by the document name. Please edit the message if you prefer a different message.',
		emailMessageDescriptionApprovers: 'Each approver will receive an e-mail with the request to approve the document. The personalized message below in the approver\'s preferred language will be included in the e-mail. $approver acts as a placeholder and will be replaced by the actual name of the approver this mail will be sent to. $document will be replaced by the document name. Please edit the message if you prefer a different message.',
		emailMessageResetCurrentLang: 'Default message ($LANGUAGE)',
		emailMessageResetAll: 'Default message (every language)',

		multiSignDescription: 'For each signer defined below a new copy of the document will be created. The signer will need to sign in the sign field.',
		multiSignDefaultSignatureTypes: 'Select the default signing methods',
		multiSignPlaceSignatureField: 'Place the multi sign field on the document',
		multiSignDownload: 'Download',

		postActionsDocumentEmailDistributionList: 'Document post signing e-mail distribution list (without evidence report)',
		postActionsDocumentAndEvidenceReportEmailDistributionList: 'Document post signing e-mail distribution list (with evidence report)',
		postActionsDistributionListAddExistingUser: 'Add e-mail from known user',
		postActionsDistributionListCustom: 'Enter custom e-mail address',
		postActionsDistributionListAdd: 'Add',
		postActionsDistributionListAddEvidenceReport: 'Include evidence report in mail',
		postActionsDistributionListRemoveDistributionList: 'Clear list',

		actionLogPerformedAt: 'Timestamp',
		actionLogEvent: 'Event',
		actionLog_CREATED: 'Document created by {0}',
		actionLog_NAME_MODIFIED: 'Document name modified from {0} to {1} by {2}',
		actionLog_SAVED: 'Document saved by {0}',
		actionLog_VIEWED: 'Document viewed by {0}',
		actionLog_DOWNLOADED: '{1} downloaded by {0}',
		actionLog_DELEGATED_TO_APPROVER: 'Document referred from {0} to {1} for approval',
		actionLog_INVITED_TO_APPROVE: 'Approve invitation sent to {0} by {1}',
		actionLog_APPROVED: 'Document approved by {0}',
		actionLog_NOT_APPROVED: 'Document not approved by {0} with reason {1}',
		actionLog_APPROVER_REMOVED: 'Approver {0} has been removed from this document',
		actionLog_DELEGATED_TO_SIGNER: 'Document referred from {0} to {1} for signature',
		actionLog_INVITED: 'Sign invitation sent to {0} by {1}',
		actionLog_INVITED_FOR_KIOSK: '{0} invited for kiosk signing by {1}',
		actionLog_SIGNED: 'Document signed by {0} with {1}',
		actionLog_STOPPED: 'Document signing stopped by {0}',
		actionLog_RESTARTED: 'Document restarted by {0}',
		actionLog_DELETED: 'Document deleted by {0}',
		actionLog_SIGNING: 'Document signing started by {0}',
		actionLog_SIGNING_FAILED_USER_MISMATCH: 'Document signing failed: expected signer data for {0} not correct',
		actionLog_DECLINED: 'Signature declined by {0}',
		actionLog_REMINDER_SENT: 'Reminder to sign sent to {0} by {1}',
		actionLog_EXPIRED: 'Document has expired',
		actionLog_CONVERTED_TO_ATTACHMENT: 'Document converted to attachment when restarted by {0}',
		actionLog_CHILD_DOCUMENT_CREATED: 'Document {0} created based on {1}',
		actionLog_CHILD_DOCUMENT_SIGNED: 'Sub document {0} signed by {1} with {2}',
		actionLog_CHILD_DOCUMENT_DECLINED: 'Sub document {0} declined by {1}',
		actionLog_CHILD_DOCUMENT_APPROVED: 'Sub document {0} approved',
		actionLog_CHILD_DOCUMENT_NOT_APPROVED: 'Sub document {0} not approved',
		actionLog_CHILD_DOCUMENT_CONVERTED_TO_ATTACHMENT: 'Sub document {0} converted to attachment',
		actionLog_TEMPLATE_CREATED: 'Template created by {0}',
		actionLog_TEMPLATE_NAME_MODIFIED: 'Template renamed from {0} to {1} by {2}',
		actionLog_TEMPLATE_SAVED: 'Template saved by {0}',
		actionLog_DOCUMENT_REPLACED: 'Document has been replaced with {0} by {1}',
		actionLog_CHILD_DOCUMENT_REPLACED: 'Sub document {0} has been replaced with {1} by {2}',
		actionLog_CHILD_DOCUMENT_ADDED_TO_COLLECTION: 'Sub document {0} added by {1}',
		actionLog_CHILD_DOCUMENT_REMOVED_FROM_COLLECTION: 'Sub document {0} removed by {1}',

		downloadArtifact_DOCUMENT: "Document",
		downloadArtifact_EVIDENCE_REPORT: "Evidence report",

		templateApply: 'Apply template on document',
		templateSave: 'Save template from document',
		templateApplySignersApproversPlaced: 'Template settings have been applied on the document. Added {0} signer(s) and {1} approver(s)',
		templateNameDescription: 'Give the template a name',
		templateName: 'Name',
		templateNameSaveDescription: 'Save the template',
		templateNameSaveLabel: 'Saving a template does not change your existing document. So do not forget to save or send your current document.',
		templateSaved: 'Template was saved',

		templateOptions: 'Template options',
		templateOption: 'Option',
		templateOption_CONFIDENTIALITY: 'Confidentiality',
		templateOption_APPROVERS: 'Approvers',
		templateOption_APPROVERS_MAIL: 'E-mail message approvers',
		templateOption_SIGNERS: 'Signers',
		templateOption_SIGNERS_MAIL: 'E-mail message signers',
		templateOption_POSTSIGN_DISTRIBUTION_LIST_WITHOUT_EVIDENCE_REPORT: 'Post signing e-mail distribution list (without evidence report)',
		templateOption_POSTSIGN_DISTRIBUTION_LIST_WITH_EVIDENCE_REPORT: 'Post signing e-mail distribution list (with evidence report)',
		templateWarningApproversInCollectionChild: 'All existing approvers in sub-documents will be removed when applying approvers from a template on a collection',
		templateWarningSignersInCollectionChild: 'All existing signers in sub-documents will be removed, when applying signers from a template on a collection',

		changeDocumentTitle: 'Change document',
		changeDocumentUpload: 'Upload document',
		changeDocumentSignaturesWarning: 'The current document contains one or more signatures. These will be lost when uploading a new document.',

		documentCollectionOverview: 'Collection documents',
		documentChildAdd: 'Add document',
		documentNameEdit: 'Modify name',
		documentChange: 'Change document',
		documentChangePendingChanges: 'There are unsaved changes to this document. Are you sure you want to change the document?',
		documentChangePendingChangesIgnoreChanges: 'Change document, ignore changes',
		documentChangePendingChangesSave: 'Change document, save changes',
		documentRemove: 'Remove',

		fieldStyleTitle: 'Style settings',
		fieldStyleFontSize: 'Font size',
		fieldStyleBackgroundColor: 'Background color',
		fieldStyleBackgroundOpacity: 'Background opacity',
		fieldStyleAlignment: 'Text position',
		fieldStyleTextAlignHorizontalLeft: 'Left (horizontal)',
		fieldStyleTextAlignHorizontalCenter: 'Center (horizontal)',
		fieldStyleTextAlignHorizontalRight: 'Right (horizontal)',
		fieldStyleTextAlignVerticalTop: 'Top (vertical)',
		fieldStyleTextAlignVerticalCenter: 'Center (vertical)',
		fieldStyleTextAlignVerticalBottom: 'Bottom (vertical)',
	},

	signerGroup: {
		header: 'Signer groups',
		create: 'Create signer group',
		name: 'Name',
		description: 'Description',
		actions: 'Actions',

		createName: 'Name of the signer group',
		createDescription: 'Description of the signer group',
		createFinish: 'Everything filled in?',
		createFinishDescription: 'After the creation of the signer group, members can be added.',

		settings: 'Settings',
		settingsNameDescription: 'Signer group name and description',
		settingsFinish: 'Everything filled in?',
		settingsFinishDescription: 'Press save to keep changes',
		settingsSave: 'save',

		users: 'Members',
		userName: 'Name',
		userEmail: 'E-mail',
		userAdd: 'Add member',
		userRemove: 'Remove member',
		userRemoveConfirm: 'Are you sure you want to remove this member?',
		userState: 'Status',

		userSelect: 'Select member(s) to add',
		usersDescription: 'Add or remove members of this group. Note these changes are applied immediately.',

		delete: 'Delete',
		deleteConfirm: 'Are you sure you want to delete this signer group?',
		deleted: 'Signer group deleted',
	},

	oidcInstance: {
		global: 'global',
		company: 'company',
		oidcInstancesFor: 'OIDC configurations for',

		generalConfiguration: 'Global OIDC configurations',
		description: 'These generic configurations are used when no company specific OIDC configurations are applicable.',
		companyOverview: 'OIDC configuration overview by company',
		companyOverviewDescription: 'Overview of all OIDC configurations set by companies. Configuration is done on the settings page of the company.',
		settings: 'Settings',

		name: 'Name',
		companyName: 'Company',
		serverUrl: 'Server URL',
		clientId: 'Client ID',
		clientSecret: 'Client secret',
		autoCreate: 'Auto create',
		actions: 'Actions',

		create: 'Create',
		edit: 'Edit',
		delete: 'Delete',
		save: 'Save',

		createName: 'Name of the OIDC configuration',
		serverConfiguration: 'Server configuration',
		autoCreateDescription: 'Allow auto creation of new users',
		logo: 'Logo',
		logoDescription: 'Changes to the logo will be applied immediately. Will be visualized as a 24 x 24 px image.',
		logoUpload: 'Upload new logo (max 1MB)',
		logoDelete: 'Delete logo',

		createFinish: 'Everything filled in?',
		createFinishDescription: 'After creating the new OIDC configuration, additional configuration can be done.',
		settingsFinishDescription: 'Press save to keep changes',

		deleteConfirm: 'Are you sure you want to delete this OIDC configuration?',
		deleted: 'OIDC configuration deleted',
	},

	connector: {
		connectorsFor: 'Connectors for',
		add: 'Add connector',
		edit: 'Edit',
		delete: 'Delete',
		deleteConfirm: 'Are you sure you want to delete this connector?',
		connectorType_SFTP: 'SFTP',
		connectorType_SHAREPOINT: 'Sharepoint',
		connectorType_GOOGLE_DRIVE: 'Google Drive',

		sftpConfig: 'SFTP configuration',
		host: 'Host name',
		port: 'Port',
		password: 'Password',

		sharepointConfig: 'Sharepoint configuration',
		tenant: 'Tenant',
		siteId: 'Site ID',
		clientId: 'Client ID',
		clientSecret: 'Client secret',
		uploadFileNameDocumentIdPrefix: 'Prefix document ID',
		uploadFileNameDocumentIdPrefixDescription: 'Prefix filename of uploaded document with document ID',

		googleDriveConfig: 'Google Drive configuration',
		googleDriveServiceAccountJson: 'Service account JSON file',
		googleDriveFolder_UPLOAD_COMPLETED_DOCUMENTS: 'Google Drive folder ID to upload completed documents to',
		googleDriveFolder_DOWNLOAD_DOCUMENTS: 'Google Drive folder ID to download documents from',

		testConnection: 'Test connection',
		test_AUTHENTICATION_FAILED: 'Authentication failed',
		test_CONNECTION_REFUSED: 'Connection refused',
		test_CANNOT_CONNECT: 'Connection could not be established',
		test_CONNECTOR_NOT_FOUND: 'Connector not found',
		test_INVALID_CLIENT_ID: 'Invalid client ID',
		test_INVALID_CLIENT_SECRET: 'Invalid client secret',
		test_INVALID_CONFIG: 'Invalid configuration',
		test_INVALID_TENANT: 'Invalid tenant ID',
		test_SUCCESS: 'Connection successfully',
		test_TOO_MANY_ATTEMPTS: 'Too many test attempts, please try again in 1 minute',
		test_UNKNOWN_HOST: 'Host not found',

		actionType: 'Action type',
		folder: 'Folder',
		user: 'User',
		actions: 'Actions',
		lastDiscoveryAt: 'Last discovery',
		lastSyncAt: 'Last sync',
		restart: 'Restart',
		documentName: 'Document name',
		externalId: 'External ID',
		state: 'State',
		syncAttempts: 'Sync attempts',
		lastUpdateAt: 'Last update',
		addActivity: 'Add activity',
		actionType_DOWNLOAD_DOCUMENTS: 'Download documents',
		actionType_UPLOAD_COMPLETED_DOCUMENTS: 'Upload completed documents',

		activitySettings: 'Connector activity settings',
		activityActionType: 'Type of activity',
		activityFolder: 'Folder to apply to',
		activityFolderName: 'Folder name',
		activityFolderInfo: 'Specify which folder this activity should apply to or leave it blank to let it apply to all folders',
		activityUser: 'The user who will be linked to the documents. Below you can only select folders where the user can edit documents.',
		activityUserEmail: 'User Email',
		activityUserInfo: 'Ensure that the user has the right permissions for every folder this activity applies to',
		activityAdditionalConfiguration: 'Additional configuration',
		activitySettingsFinish: 'Everything filled in?',
		activitySettingsFinishDescription: 'Press save to keep changes',
		activityDelete: 'Delete activity',
		activityDeleteConfirm: 'Are you sure you want to delete this activity?',
		activityGrid: 'Connector activities',

		instanceAdd: 'Add connector',
		instanceAddInfo: 'You can have only one connector of each type at the same time.',

		directory: 'Directory',
		sftpDirectory_DOWNLOAD_DOCUMENTS: 'SFTP directory from which documents will be downloaded to Quill',
		sftpDirectory_UPLOAD_COMPLETED_DOCUMENTS: 'SFTP directory to which completed documents will be uploaded',
		sharepointSite_DOWNLOAD_DOCUMENTS: 'Sharepoint site from which documents will be downloaded to Quill',
		sharepointSite_UPLOAD_COMPLETED_DOCUMENTS: 'Sharepoint site to which documents will be uploaded',
		sharepointDrive: 'Drive',
		sharepointDriveDescription: 'Sharepoint drive (blank for root drive)',
		sharepointParentPath_DOWNLOAD_DOCUMENTS: 'Sharepoint folder from which documents will be downloaded to Quill. When left blank, all files under the root folder will be downloaded.',
		sharepointParentPath_UPLOAD_COMPLETED_DOCUMENTS: 'Sharepoint folder to which documents will be uploaded. If the folder does not exist yet, it will be created.',

		inventoryGrid: 'Items processed',
		inventoryState_AWAITING_SYNC: 'Awaiting sync',
		inventoryState_SYNCING: 'Syncing',
		inventoryState_SYNCED: 'Synced',
		inventoryState_ERROR: 'Error',
	},

	eid: {
		login: 'eID Login',
		userProfileCardNumber: 'To use this functionality, the eID card number should be set on the user profile.',
		cardHeader: 'Insert your eID card in the card reader',
		cardNotFound: 'Please insert your eID card.',
		enterPin: 'Enter your PIN',
		middleware: 'Dioss eID Middleware',
		middlewareNotRunning: 'You will need to install Dioss eID Middleware on your computer before continuing with the eID card authentication.',
		middlewareDownloadHere: 'Download Dioss eID Middleware here:',
		middlewareAdditionalSupport: 'Questions? Consult our $link;https://smartsolutions.dioss.com/en/products/eid-middleware/dioss-eid-middelware-faq;FAQ$',
		wrongPin: 'You have entered the wrong PIN. {0} tries left.',
		pinTimeoutOrCancel: 'You did not enter the PIN in time or it was cancelled.',
		externalPinPad: 'Please enter pin on the card reader when prompted.',
		externalPinDialog: 'Please enter pin on the external pin input popup dialog when prompted.',
		pincode: 'Pincode'
	},

	reminder: {
		success: 'Reminders have been sent',

		nothingToRemind: 'No requests that need reminding',
		notSentReason_TOO_SOON: 'Reminders can only be sent out once every hour',
		notSentReason_CANNOT_REMIND_REQUESTER: 'Cannot send reminders to yourself',
		notSentReason_OTHER: 'Something went wrong while sending out reminders',
	},

	mail: {
		success: 'Mail(s) sent',

		preview: 'Example',
		test: 'Send test',
		templateType: 'Type',
		fieldOverride: 'Override',

		templateSubject: 'Subject',
		templateHeaderTitle: 'Title',

		emailFieldsLinkEdit: 'Add / edit link',
		emailFieldsImageEdit: 'Add / edit image',
		emailFieldsRows: 'Rows',
		emailFieldsPlaceholder: 'Placeholder',
		emailFieldsPlaceholder_APPROVER: 'Approver',
		emailFieldsPlaceholder_ATTACHMENT_MAX_SIZE: 'Attachment max size',
		emailFieldsPlaceholder_COMPANY: 'Company',
		emailFieldsPlaceholder_COMPANY_LOGO: 'Company logo',
		emailFieldsPlaceholder_CONTENT_TABLE: 'Content table',
		emailFieldsPlaceholder_CREATOR: 'Creator',
		emailFieldsPlaceholder_CUSTOM_MESSAGES: 'Custom messages',
		emailFieldsPlaceholder_DAILY_DIGEST_TABLE: 'Daily digest table',
		emailFieldsPlaceholder_DATE: 'Date',
		emailFieldsPlaceholder_DEADLINE: 'Deadline',
		emailFieldsPlaceholder_DECLINE_REASON: 'Approval decline reason',
		emailFieldsPlaceholder_DECLINED_TABLE: 'Sign decline table',
		emailFieldsPlaceholder_DIOSS_LOGO: 'Dioss logo',
		emailFieldsPlaceholder_DOCUMENT: 'Document',
		emailFieldsPlaceholder_DOCUMENT_COUNT: 'Document count',
		emailFieldsPlaceholder_DOCUMENT_URL: 'Document URL',
		emailFieldsPlaceholder_OTP: 'OTP (One Time Password)',
		emailFieldsPlaceholder_RECEIVER: 'Receiver',
		emailFieldsPlaceholder_REJECT_REASON: 'Sign decline reason',
		emailFieldsPlaceholder_SIGNER: 'Signer',
		emailFieldsPlaceholder_TITLE: 'Title',
		emailFieldsPlaceholder_QUILL_LOGO: 'Quill logo',
		emailFieldsPlaceholder_USER_INVITE_LINK_EXPIRY: 'Invite link expiry',
		emailFieldsPlaceholder_USER_URL: 'User URL',
	},

	serverError: {
		GENERAL: 'General error',

		COMPANY_NOT_FOUND: 'Company not found',
		COMPANY_SUBDOMAIN_ALREADY_EXISTS: 'Company subdomain already exists',
		COMPANY_UNABLE_TO_DELETE: 'Company can\'t be deleted: there are documents, child companies or the request was done from this company\'s context',
		COMPANY_USER_GROUP_ALREADY_EXISTS: 'Group already exists',
		COMPANY_USER_GROUP_NOT_FOUND: 'Group not found',
		COMPANY_USER_NOT_FOUND: 'User not found',
		COMPANY_CHILD_EXISTS: 'One or more subcompanies already exist',
		COMPANY_IS_CHILD_COMPANY: 'Company is a subcompany',

		DOCUMENT_FOLDER_ALREADY_EXISTS: 'Folder already exists',
		DOCUMENT_FOLDER_NOT_FOUND: 'Folder not found',
		DOCUMENT_FORM_PROBLEM: 'There is an issue with the form on the document',
		DOCUMENT_INVALID_CONTENTS: 'The document has conflicting contents',
		DOCUMENT_INVALID_STATE: 'The document has an invalid state',
		DOCUMENT_MISSING_DESCRIPTION: 'The document has a missing description',
		DOCUMENT_NOT_FOUND: 'The document was not found',
		DOCUMENT_NO_ACTORS: 'There are no actors on the document',
		DOCUMENT_PAGE_INDEX_OUT_OF_BOUNDS: 'The document\'s page index is out of bounds',
		DOCUMENT_PASSWORD_PROTECTED: 'The document is password protected',
		DOCUMENT_READ_ONLY: 'The document is read only',
		DOCUMENT_REQUIRED_FORM_FIELD_MISSING: 'A required field has not been filled in',
		DOCUMENT_SIGNING_IN_PROGRESS: 'The document is locked and in process of signing',
		DOCUMENT_CONVERT_PROBLEM: 'Problem with the conversion of the document',

		DOCUMENT_TEMPLATE_NOT_FOUND: 'Document template not found',
		DOCUMENT_TEMPLATE_ALREADY_EXISTS: 'Document template already exists',

		EMAIL_ALREADY_IN_USE: 'The e-mail address is already in use',
		EMAIL_INVALID: 'Invalid e-mail address',
		EMAIL_CHALLENGE_INVALID: 'This e-mail link to change your password is no longer valid',

		EMAIL_DOMAIN_AUTHENTICATION_ERROR: 'Error processing authenticated email domain',

		NAME_INVALID: 'Invalid name',

		FOLDER_APPROVER_NOT_ALLOWED: 'This user isn\'t allowed to be an approver in this folder',
		FOLDER_SIGNER_NOT_ALLOWED: 'This user isn\'t allowed to be a signer in this folder',

		SIGNER_GROUP_NOT_FOUND: 'The signer group isn\'t found',

		APPROVAL_CONSTRAINT_VIOLATION: 'Approval constraint violation',
		APPROVAL_REASON_MISSING: 'Reason is missing',
		SIGNING_ITSME_PROBLEM: 'There is an itsme® problem',
		SIGNING_ITSME_SIGNING_TIMEOUT: 'A timeout occurred in the itsme® flow',
		SIGNING_ITSME_SESSION_NOT_FOUND: 'The itsme® session could not be found',
		SIGNING_ITSME_SERVICE_ERROR: 'An error occurred when applying the itsme® signature',
		SIGNING_ITSME_RESPONSE_ERROR: 'Required data is missing in the response coming from itsme®',
		SIGNING_ITSME_SIGNING_USER_TIMEOUT: 'Signing the document(s) with itsme® took too long, please try again',
		SIGNING_ITSME_SIGNING_USER_REJECTED: 'Please reject the document(s) in Quill, if this was a mistake then you can just retry the itsme® sign operation',
		SIGNING_ITSME_EXPIRED: 'The itsme® session expired, please try again',
		SIGNING_ITSME_BUSY: 'There is already an itsme® session busy, this will be automatically cleaned up after 5 minutes...',

		SIGNING_OTP_CHALLENGE_FAILED: 'Invalid OTP challenge',
		SIGNING_OTP_NUMBER_CHECK_FAILED: 'Invalid SMS number',
		SIGNING_SIGN_REQUEST_CLOSED: 'It isn\'t possible to sign this document anymore',
		SIGNATURE_PLACEHOLDER_NOT_FOUND_ERROR: 'Could not find the placeholder in the document',
		SIGNING_SIGN_INVALID_REQUEST: 'Invalid signing request, either the signature location or placeholder(s) must be specified',
		SIGNING_CERTIFICATE_MISSING: 'You can not sign with this card as it has no signing certificate (e.g kids eID)',
		SIGNING_CERTIFICATE_PROBLEM: 'Invalid signing certificate',
		SIGNING_CERTIFICATE_REVOKED: 'The signing certificate has been revoked',
		SIGNING_CERTIFICATE_RETRIEVAL_PROBLEM: 'Signing certificate could not be retrieved',
		SIGNING_GENERAL_PROBLEM: 'Problem occurred while signing',
		SIGNER_MISMATCH_ERROR: 'Signer data mismatch',
		SIGNATURE_PLACEHOLDER_PARSING_ERROR: 'Failed to parse the signature placeholder(s)',
		SIGNATURE_PLACEHOLDER_NOT_UNIQUE: 'There are duplicate signature placeholders in the document',

		USER_ALREADY_ADDED: 'This user was already added',
		USER_EXISTS_AS_GUEST: 'This user exists as guest',
		USER_NOT_FOUND: 'User not found',
		USER_NOT_FOUND_BY_EID: 'User not found. Make sure your current eID cart number matches the card number on the user profile.',
		USER_UPDATE_PROBLEM: 'Problem updating the user',
		USER_USED_BY_CONNECTOR_ACTIVITY: 'User is still in use by one or more connector activities.',
		USER_INVALID_DATA: 'The provided user data is invalid or incomplete',
		USER_INVALID_DATA_FORMAT: 'The provided user data is in the wrong format',
		USER_INVALID_NOTIFICATION: 'One or more notification types do not exist',

		PASSWORD_INVALID: 'Invalid password',
		PASSWORD_MISMATCH: 'Passwords don\'t match',
		PASSWORD_DOES_NOT_MEET_REQUIREMENTS: 'The password does not meet the requirements',
		PASSWORD_SHOULD_RESET: 'Password reset required. A password reset invite has been sent to your mailbox.',
		PASSWORD_RESET_EXPIRED: 'Password reset link has expired',

		LOGIN_FAILED: 'Login failed',
		OIDC_ERROR: 'Login failed',

		INVALID_URL: 'Invalid URL',
		NOT_ALLOWED: 'Action not allowed',
		OTP_INVALID_NUMBER: 'Invalid SMS number',
		SMS_SEND: 'Problem sending SMS',
		EID_NUMBER_ALREADY_IN_USE: 'This eID card number is already in use',
		SIGNATURE_TYPE_NOT_ALLOWED: 'Signature type not allowed',
		TIME_STAMP_EXCEPTION: 'Problem setting timestamp',
		WEBHOOK_PROCESSING: 'Problem processing webhook',

		IMAGE_INVALID_SIZE: 'Images may not exceed 1MB in size.',
		IMAGE_INVALID_MIME_TYPE: 'This file is not allowed, the image must be of type png or jpeg.',

		FORWARD_TARGET_NOT_FOUND: 'Could not find a user to forward to',
		FORWARD_NOT_POSSIBLE: 'Forwarding is not possible',
		ITSME_AUTHENTICATION_ERROR: 'A problem with itsme® authentication',

		REPORT_UNAVAILABLE: 'Report(s) currently not available. Please try again in a few moments.',
	},

	upload: {
		selectFile: 'Select file',
		fileName: 'Filename',
		dragFileHere: '..or drag and drop your file here',
		invalidSize: 'Size invalid, maximum size is {0}',
		unknownError: 'An unknown error occurred',
	},
};
