import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";

const USER_TYPE_REGISTERED = 'REGISTERED';
const USER_TYPE_GUEST = 'GUEST';
const USER_TYPE_CONTACT = 'CONTACT';

const USER_TYPE_FROM_PROPS_AND_STATE = (props, state) => {
	const options = [];
	options.push({type: USER_TYPE_REGISTERED, enabled: props.allowRegisteredUserCreation, selected: props.allowRegisteredUserCreation && !state.guest && !state.contact});
	options.push({type: USER_TYPE_GUEST, enabled: props.allowGuestCreation, selected: props.allowGuestCreation && state.guest && !state.contact});
	if (!!props.documentFolderId) {
		options.push({type: USER_TYPE_CONTACT, enabled: props.allowContactCreation, selected: props.allowContactCreation && state.contact});
	}
	return options;
}

const defaultState = {
	firstName: '',
	lastName: '',
	email: '',
	languageIsoCode: 'en',
	guest: false,
	contact: false,
	contactDocumentFolderId: null,
}

class UserCreationDialog extends Component {
	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			// reset the field values
			this.setState(defaultState);

			// if there is only one option enabled, auto select it
			const options = USER_TYPE_FROM_PROPS_AND_STATE(this.props, {}).filter(option => option.enabled);
			if (1 === options.length) {
				if (USER_TYPE_REGISTERED === options[0].type) {
					this.setState({...defaultState, guest: false, contact: false});
				} else if (USER_TYPE_GUEST === options[0].type) {
					this.setState({...defaultState, guest: true, contact: false});
				} else if (USER_TYPE_CONTACT === options[0].type) {
					this.setState({...defaultState, guest: true, contact: true});
				}
			} else if (!this.props.allowRegisteredUserCreation) {
				// if there's more than 1 option, but the default ('registered') cannot be selected, auto select one of the other options
				if (this.props.allowGuestCreation) {
					this.setState({...defaultState, guest: true, contact: false});
				} else {
					this.setState({...defaultState, guest: true, contact: true});
				}
			}
		}
	}

	render() {
		const options = USER_TYPE_FROM_PROPS_AND_STATE(this.props, this.state);
		const selectedOption = options.reduce((acc, val) => val.selected ? val.type : acc, '');

		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{this.props.t('user.create')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('user.createName')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('user.firstName')}
								required
								value={this.state.firstName}
								onChange={this.onChangeFirstName}
								autoComplete="off"
								fullWidth
								autoFocus
								sx={{mb: 2}}
								inputProps={{maxLength: 255}}
							/>

							<TextField
								variant="outlined"
								label={this.props.t('user.lastName')}
								required
								value={this.state.lastName}
								onChange={this.onChangeLastName}
								autoComplete="off"
								fullWidth
								inputProps={{maxLength: 255}}
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('user.createEmail')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('user.email')}
								required
								value={this.state.email}
								onChange={this.onChangeEmail}
								autoComplete="off"
								fullWidth
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('user.createLanguage')}</StepLabel>
						<StepContent>
							<FormControl fullWidth>
								<Select
									value={this.state.languageIsoCode}
									onChange={this.onChangeLanguage}
								>
									{['en', 'nl', 'fr'].map(lang =>
										<MenuItem key={lang}
												  value={lang}>{this.props.t('language.' + lang)}
										</MenuItem>)}
								</Select>
							</FormControl>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('user.createAddType')}</StepLabel>
						<StepContent>
							<RadioGroup
								value={selectedOption}
								onChange={this.onChangeUserType}
								name="user-creation-user-type-radio-group"
							>
								{options.map(option =>
									<FormControlLabel
										key={option.type}
										value={option.type}
										disabled={!option.enabled}
										control={<Radio />}
										label={this.props.t('user.createAddType_' + option.type, {folder: this.props.documentFolderName})}
									/>
								)}
							</RadioGroup>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('user.createFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('user.createFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={!this.canContinue()}
						onClick={this.onCreate}
						id="btn-create-confirm"
				>
					{this.props.t('user.create')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeFirstName = (e) => {
		this.setState({firstName: e.target.value});
	}

	onChangeLastName = (e) => {
		this.setState({lastName: e.target.value});
	}

	onChangeEmail = (e) => {
		this.setState({email: e.target.value});
	}

	onChangeLanguage = (e) => {
		this.setState({languageIsoCode: e.target.value});
	}

	onChangeUserType = (e) => {
		const type = e.target.value;
		if (USER_TYPE_REGISTERED === type) {
			this.setState({guest: false, contact: false});
		} else if (USER_TYPE_GUEST === type) {
			this.setState({guest: true, contact: false});
		} else if (USER_TYPE_CONTACT === type) {
			this.setState({guest: true, contact: true});
		}
	}

	canContinue = () => {
		return !!this.state.firstName.trim() && !!this.state.lastName.trim() && !!this.state.email.trim()
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		this.props.onCreate({
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			languageIsoCode: this.state.languageIsoCode,
			guest: this.state.guest,
			contact: this.state.contact,
			contactDocumentFolderId: this.props.documentFolderId
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(UserCreationDialog));
