import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import SaveIcon from "@mui/icons-material/Save";
import ServerErrorComponent from "../common/ServerErrorComponent";

class CompanyStyleSettingsComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.onCompanyFetchStyleSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		if (!this.props.companyStyleSettings) {
			return <LoadingComponent/>;
		}

		const styleSettings = this.props.companyStyleSettings;

		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('company.styleSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.styleSettingsPrimary')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Grid container>
						<Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
							<span>{this.props.t('company.styleSettingsMainColor')}</span>
							<TextField
								variant="outlined"
								size="small"
								type="color"
								value={'#' + styleSettings.primaryBackgroundColor}
								onChange={e => this.onChangeColor('primaryBackgroundColor', e)}
								sx={{ml: 1, width: 50}}
								disabled={this.props.companyBusy}
								autoComplete="off"
							/>
						</Grid>
						<Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
							<span>{this.props.t('company.styleSettingsContrastColor')}</span>
							<TextField
								variant="outlined"
								size="small"
								type="color"
								value={'#' + styleSettings.primaryTextColor}
								onChange={e => this.onChangeColor('primaryTextColor', e)}
								sx={{ml: 1, width: 50}}
								disabled={this.props.companyBusy}
								autoComplete="off"
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.styleSettingsSecondary')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Grid container>
						<Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
							<span>{this.props.t('company.styleSettingsMainColor')}</span>
							<TextField
								variant="outlined"
								size="small"
								type="color"
								value={'#' + styleSettings.secondaryBackgroundColor}
								onChange={e => this.onChangeColor('secondaryBackgroundColor', e)}
								sx={{ml: 1, width: 50}}
								disabled={this.props.companyBusy}
								autoComplete="off"
							/>
						</Grid>
						<Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
							<span>{this.props.t('company.styleSettingsContrastColor')}</span>
							<TextField
								variant="outlined"
								size="small"
								type="color"
								value={'#' + styleSettings.secondaryTextColor}
								onChange={e => this.onChangeColor('secondaryTextColor', e)}
								sx={{ml: 1, width: 50}}
								disabled={this.props.companyBusy}
								autoComplete="off"
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.styleSettingsLogo')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					{!!styleSettings.logoBase64 &&
						<Button variant="outlined" onClick={this.onChangeLogo} sx={{p: 1}} id="btn-company-style-logo-change">
							<img
								style={{width: '175px', maxHeight: '175px'}}
								src={'data:image/png;base64,' + styleSettings.logoBase64}
							/>
						</Button>}
					{!styleSettings.logoBase64 &&
						<Button color="secondary" variant="contained" onClick={this.onChangeLogo}
								disabled={this.props.companyBusy} id="btn-company-style-logo-change">
							{this.props.t('company.styleSettingsLogoChoose')}
						</Button>}
					<input type="file" id="file" accept=".png, .jpeg, .jpg" style={{display: 'none'}}></input>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Button variant="contained" onClick={this.onSave} id="btn-settings-save"
						startIcon={<SaveIcon/>}>{this.props.t('save')}</Button>
			</Box>
		</Box>
	}

	onChangeColor = (key, e) => {
		const value = e.target.value.replace('#', '');
		this.props.onCompanyChangeStyleSettingsItem(key, value);
	}

	onChangeLogo = () => {
		if (this.props.companyBusy) return;

		const fileEl = document.getElementById("file");
		fileEl.onchange = () => {
			const file = fileEl.files[0];
			if (!!file) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					const index = result.indexOf(',');
					if (index > 0) {
						this.props.onCompanyChangeStyleSettingsItem('logoBase64', result.substring(index + 1));
					}
				}
				reader.readAsDataURL(file);
			}
		};
		fileEl.click();
	}

	onSave = () => {
		this.props.onCompanyUpdateStyleSettings(this.props.companyStyleSettings);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyStyleSettings: state.company.styleSettings,
		}
	},
	dispatch => {
		return {
			onCompanyFetchStyleSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_STYLE_SETTINGS'
				});
			},
			onCompanyUpdateStyleSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_STYLE_SETTINGS',
					settings
				});
			},
			onCompanyChangeStyleSettingsItem: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_STYLE_SETTINGS_ITEM',
					key,
					value
				});
			}
		}
	}
)(CompanyStyleSettingsComponent));
