const defaultState = {
    // general state
    busy: false,
    serverError: false,

    // overviews
    overviewList: [],
    overviewCount: 0,

    // current template
    current: null,

    // indicate that the action on the template
    templateActionSuccessfully: false,
};

const template = (state = defaultState, action) => {
    switch (action.type) {
        case 'TEMPLATE_ERROR':
            return {
                ...state,
                busy: false,
                serverError: action.serverError
            }

        case 'TEMPLATE_FETCH_OVERVIEW_LIST':
            return {
                ...state,
                busy: true,
                serverError: null,
                ...action?.clearPreviousResult && {overviewList: [], overviewCount: 0},
            }

        case 'TEMPLATE_CLEAR_OVERVIEW_LIST':
            return {
                ...state,
                overviewList: [],
                overviewCount: 0,
            }

        case 'TEMPLATE_FETCH_OVERVIEW_LIST_SUCCESS':
            return {
                ...state,
                busy: false,
                overviewList: action.list,
                overviewCount: action.count
            }

        case 'TEMPLATE_FETCH':
            return {
                ...state,
                busy: true,
                serverError: null,
                current: null,
            }

        case 'TEMPLATE_FETCH_SUCCESS':
            return {
                ...state,
                busy: false,
                current: action.data,
            }

        case 'TEMPLATE_CHANGE_SETTING': {
            const current = {...state.current};
            current[action.key] = action.value;
            return {
                ...state,
                current
            }
        }

        case 'TEMPLATE_UPDATE':
        case 'TEMPLATE_DELETE':
            return {
                ...state,
                busy: true,
                serverError: null,
                templateActionSuccessfully: false,
            }

        case 'TEMPLATE_UPDATE_SUCCESS':
        case 'TEMPLATE_DELETE_SUCCESS':
            return {
                ...state,
                busy: false,
                serverError: null,
                templateActionSuccessfully: true,
            }

        case 'TEMPLATE_FETCH_CREATION_INFO_SUCCESS':
            return {
                ...state,
                busy: false,
                serverError: null,
                creationInfo: action.data
            }

        default:
            return state;
    }
}

export default template;
