import React from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, Box, Chip, IconButton, Radio, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from '@mui/icons-material/Warning';
import {SIGNATURE_TYPES} from "../common/Constants";
import {SIGNER_SMS_OTP_NUMBER_ERROR} from "./EditorUtils";

export const SignerSettingsComponent = ({document, signer, readOnly,
										 onSignerChangeSignatureType, onSignerChangeCapacityTitle,
										 onSignerChangeUseKnownOtpNumber, onSignerChangeOtpNumber}) => {

	const {t} = useTranslation();

	const hasKnownOtpNumberOption = signer.otpNumberKnown;
	const showKnownOtpNumberOption = (!readOnly && hasKnownOtpNumberOption) || (readOnly && signer.useKnownOtpNumber);
	const showChooseOtpNumber = !readOnly || (readOnly && !signer.useKnownOtpNumber);

	return <>
		<Box sx={{display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap'}}>
			{Object.keys(SIGNATURE_TYPES).map(type => {
				if (document.enabledSignatureTypes.indexOf(type) < 0) {
					return null;
				}
				const types = (signer?.signatureTypes || []);
				const enabled = types.indexOf(type) >= 0;
				const disabled = (readOnly && !document?.canUpdateSigningMethods)
					|| !document?.editPermission
					|| signer.signed
					|| (enabled && types.length === 1)
					|| ['SIGNED', 'DECLINED', 'CLOSED'].indexOf(signer.signRequestState) !== -1
					|| (signer.hasSignerVerificationData && ['BELGIAN_EID', 'ITSME'].indexOf(type) === -1);
				return <IconButton
					key={type}
					size="small"
					disabled={disabled}
					title={t('editor.signingMethod_' + type)}
					onClick={() => onSignerChangeSignatureType(signer.id, type, !enabled)}
				>
					<img src={SIGNATURE_TYPES[type].img}
						 style={{width: '30px', height: 'auto', ...(!enabled && {filter: 'grayscale(100%)', opacity: 0.5}) }}/>
				</IconButton>
			})}
			<Box sx={{flexGrow: 1}} />
			{!!signer?.person && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
				<Typography variant="body2">
					{t('editor.signingCapacity')}
				</Typography>
				<Autocomplete
					freeSolo
					options={signer?.person?.capacityTitles || []}
					inputValue={signer?.capacityTitle || ''}
					onInputChange={(e, newValue) => onSignerChangeCapacityTitle(signer.id, newValue)}
					renderInput={(params) =>
						<TextField
							{...params}
							variant="outlined"
							size="small"
						/>
					}
					sx={{width: '200px'}}
					disabled={readOnly}
				/>
			</Box>}
		</Box>

		{signer?.signatureTypes?.indexOf('SMS_OTP') >= 0 && !!signer?.person && <Box sx={{display: 'flex', flexDirection: 'column', mt: 1, mb: 1}}>
			{showKnownOtpNumberOption && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
				<Radio
					checked={!!signer.useKnownOtpNumber}
					onChange={(e, value) => onSignerChangeUseKnownOtpNumber(signer.id, true)}
					disabled={readOnly}
				/>
				<Typography variant="body2">
					{t('editor.signingSmsOtpKnownNumber').replace('$SUFFIX', signer.otpNumberSuggestionSuffix)}
				</Typography>
			</Box>}
			{showChooseOtpNumber && <Box sx={{display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap'}}>
				{(hasKnownOtpNumberOption || readOnly) && <Radio
					checked={!signer.useKnownOtpNumber}
					onChange={(e, value) => onSignerChangeUseKnownOtpNumber(signer.id, false)}
					disabled={readOnly}
				/>}
				<Typography variant="body2" sx={{flexBasis: '200px', flexGrow: 1, flexShrink: 1}}>
					{t('editor.signingSmsOtpEnterNumber')}
				</Typography>
				<TextField
					margin="none"
					required
					autoComplete="off"
					size="small"
					value={signer.otpNumber || ''}
					onChange={e => onSignerChangeOtpNumber(signer.id, e.target.value)}
					inputProps={{maxLength: 20}}
					type="tel"
					error={!signer.useKnownOtpNumber && SIGNER_SMS_OTP_NUMBER_ERROR(signer.otpNumber)}
					sx={{flexBasis: '200px'}}
					disabled={readOnly}
				/>
			</Box>}
		</Box>}
	</>

}

export const ParaphFieldsContainer = ({readOnly, signer, onParaphFieldRemove, onParaphFieldRemoveAll}) => {

	const {t} = useTranslation();

	return <Box>
		{signer?.paraphFields?.length > 0 && <>
			<Box sx={{display: 'flex'}}>
				<Box sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					gap: 1,
					mb: 1,
					mt: 1,
					maxHeight: '75px',
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						width: '10px',
						height: '10px',
						background: '#F1F1F1'
					},
					'&::-webkit-scrollbar-thumb': {
						background: '#C1C1C1'
					}
				}}>
					{[...(signer?.paraphFields || [])]
						.sort((a, b) => a.pageIndex - b.pageIndex)
						.map((field, index) =>
							<Chip key={index}
								  label={t('page') + ' ' + (field.pageIndex + 1)}
								  variant="outlined"
								  onDelete={() => onParaphFieldRemove(signer, index)}
								  disabled={readOnly}
							/>
						)}
				</Box>
				<Box sx={{flexGrow: 1}}/>
				<IconButton color="primary"
							title={t('editor.signingSignatureFieldDelete')}
							onClick={() => onParaphFieldRemoveAll(signer)}
							disabled={readOnly || signer.signed}
							size="small"
							sx={{alignSelf: 'flex-start', mt: 1}}
				>
					<DeleteIcon/>
				</IconButton>
			</Box>
		</>}
	</Box>
}

export const ExtraSignatureFieldsContainer = ({readOnly, signer, onExtraSignFieldRemove}) => {

	const {t} = useTranslation();
	const fields = signer?.extraSignatureFields || [];

	return fields.length > 0 && <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1, mt: 1}}>
		{fields
			.sort((a, b) => a.pageIndex - b.pageIndex)
			.map((field, index) =>
				<Chip key={index}
					  label={t('editor.signingExtraSignatureFieldPage') + ' ' + (field.pageIndex + 1)}
					  variant="outlined"
					  onDelete={() => onExtraSignFieldRemove(signer, index)}
					  disabled={readOnly}
				/>
			)}
	</Box>

}

export const FormFieldsContainer = ({readOnly, type, signer, onFormFieldRemove}) => {

	const {t} = useTranslation();
	const fields = signer?.formFields || [];

	return fields.length > 0 && <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1, mt: 1}}>
		{fields
			.filter(field => !type || field.type === type)
			.sort((a, b) => a.pageIndex - b.pageIndex)
			.map((field, index) =>
				<Chip key={index}
					  icon={(!field.editable && !field.value && field.type !== 'ATTRIBUTE') ? <WarningIcon fontSize="small"/> : undefined}
					  label={field.type === 'ATTRIBUTE' ? t('editor.formFieldAttributeType_' + field.attributeType) : field.name}
					  variant="outlined"
					  onDelete={() => onFormFieldRemove(field)}
					  disabled={readOnly}
				/>)
		}
	</Box>

}

