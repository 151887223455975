const defaultState = {
    // general state
    busy: false,
    serverError: false,

    // user list for overview
    overviewList: [],
    overviewCount: 0,

    // action success responses
    userActionSuccessfully: false,

    // current user settings
    settings: null,

    // created user
    createdUser: null,
}

const user = (state = defaultState, action) => {
    switch (action.type) {
        case 'USER_ERROR':
            return {
                ...state,
                serverError: action.serverError,
                signatureUpdateError: action.signatureUpdateError,
                busy: false
            }

        case 'USER_FETCH_OVERVIEW_LIST':
            return {
                ...state,
                serverError: null,
                busy: true
            }

        case 'USER_UPDATE_VISUAL_SIGNATURE':
            return {
                ...state,
                serverError: null,
                signatureUpdateError: null,
                busy: true
            }

        case 'USER_FETCH_OVERVIEW_LIST_SUCCESS':
            return {
                ...state,
                busy: false,
                serverError: null,
                overviewList: action.list,
                overviewCount: action.count,
            }

        case 'USER_CREATE':
            return {
                ...state,
                busy: true,
                serverError: null,
                userActionSuccessfully: false,
                createdUser: null,
            }

        case 'USER_CREATE_SUCCESS':
            return {
                ...state,
                busy: false,
                serverError: null,
                createdUser: action.createdUser,
                userActionSuccessfully: true
            }

        case 'USER_DELETE':
        case 'USER_UPDATE_SETTINGS':
        case 'USER_REGISTER_GUEST':
            return {
                ...state,
                busy: true,
                serverError: null,
                userActionSuccessfully: false
            }

        case 'USER_EXPORT_OVERVIEW':
        case 'USER_RESEND_INVITATION':
            return {
                ...state,
                busy: true,
                serverError: null
            }

        case 'USER_SUCCESS':
            return {
                ...state,
                busy: false,
                serverError: null,
                settingsServerError: null,
                userActionSuccessfully: true
            }

        case 'USER_FETCH_SETTINGS':
            return {
                ...state,
                busy: true,
                signatureUpdateError: null,
                settings: null,
                serverError: null
            }

        case 'USER_FETCH_SETTINGS_SUCCESS':
            return {
                ...state,
                busy: false,
                settings: action.data
            }

        case 'USER_UPDATE_VISUAL_SIGNATURE_SUCCESS':
            return {
                ...state,
                busy: false,
                settingsServerError: null,
                settings: {
                    ...state.settings,
                    visualSignature: action.visualSignature
                }
            }

        case 'USER_CHANGE_SETTING': {
            const settings = {
                ...state.settings
            };

            if (!!action.subkey) {
                settings[action.key] = {
                    ...state.settings[action.key]
                };
                settings[action.key][action.subkey] = action.value;
            } else {
                settings[action.key] = action.value;
            }

            return {
                ...state,
                settings
            }
        }

        default:
            return state;
    }
}


export default user;