import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Link,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	ThemeProvider,
	Typography
} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import EidPinInput from "../common/EidPinInput";
import LoadingComponent from "../common/LoadingComponent";
import mwPinInputEn from "../../img/mw-pin-input-en.png";
import mwPinInputNl from "../../img/mw-pin-input-nl.png";
import mwPinInputFr from "../../img/mw-pin-input-fr.png";
import i18n from "i18next";

const defaultState = {
	pinCode: null
}

class SessionLoginEidDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidMount() {
		this.props.onEidStartMiddleware();
	}

	componentWillUnmount() {
		this.props.onEidStopMiddleware();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const eidIssue = this.props.eid.error || this.props.eid.eidTimeout || this.props.eid.wrongPin;

		if (prevProps.loginBusy && !this.props.loginBusy && !eidIssue) {
			this.props.onClose();
		}
	}


	render() {
		const eidRunState = this.props.eid.runState;
		const hasError = this.props.eid.wrongPin || this.props.eid.eidTimeout || this.props.eid.error;
		const directPinInput = !this.props.eid.externalPinPad && !this.props.useMiddlewarePinInput;

		return <ThemeProvider theme={this.createTheme(this.props.style)}>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="sm">
				<DialogTitle>{this.props.t('eid.login')}</DialogTitle>
				<DialogContent>
					<Alert severity="info" sx={{mb: 1}}>{this.props.t('eid.userProfileCardNumber')}</Alert>

					<Stepper activeStep={-1} orientation="vertical">
						<Step active={eidRunState !== 'RUNNING'}>
							<StepLabel>{this.props.t('eid.middleware')}</StepLabel>
							<StepContent>
								{eidRunState === 'UNKNOWN' && <LoadingComponent/>}
								{eidRunState === 'NOT_RUNNING' && <Fragment>
										<Box>{this.props.t('eid.middlewareNotRunning')}</Box>
										{this.props.eid.versions.length > 0 && <div>
											<br/>
											{this.props.t('eid.middlewareDownloadHere')}
											<ul>
												{this.props.eid.versions.map((v, index) => <li key={index}><a
													href={v.download_url}>{v.os}</a></li>)}
											</ul>

											{this.renderFAQWithLink('eid.middlewareAdditionalSupport')}
										</div>}
								</Fragment>}
							</StepContent>
						</Step>

						<Step active={eidRunState === 'RUNNING' && !this.props.eid.inserted}>
							<StepLabel>{this.props.t('eid.cardHeader')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('eid.cardNotFound')}</Typography>
							</StepContent>
						</Step>
						<Step active={eidRunState === 'RUNNING' && this.props.eid.inserted}>
							<StepLabel>{this.props.t('eid.enterPin')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', mb: 1}}>
									{directPinInput &&
										<EidPinInput
											wrongPin={this.props.eid.wrongPin}
											disabled={this.props.loginBusy}
											onPinChanged={this.onPinChanged}
											maxPinLength={12}
											id="input-login-eid-text"
										/>
									}
									{!hasError && this.props.loginBusy && <Box sx={{ml: 2, mt: 'auto', mb: 'auto'}}><LoadingComponent /></Box>}
								</Box>

								{this.props.eid.externalPinPad && <Typography>{this.props.t('eid.externalPinPad')}</Typography>}
								{!this.props.eid.externalPinPad && this.props.useMiddlewarePinInput && <Box>
									<Typography>{this.props.t('eid.externalPinDialog')}</Typography>
									{this.renderLocalizedMiddlewarePinInputImage()}
									</Box>}

								{this.props.eid.wrongPin && <Alert severity="error">{this.props.t('eid.wrongPin').replace('{0}', this.props.eid.triesLeft)}</Alert>}
								{this.props.eid.eidTimeout && <Alert severity="error">{this.props.t('eid.pinTimeoutOrCancel')}</Alert>}
								{this.props.eid.error && <Alert severity="error">{this.props.eid.error}</Alert>}
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-login-eid-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							disabled={this.props.loginBusy || (directPinInput && (!this.state.pinCode || !this.state.pinCode.trim()))}
							onClick={this.onSubmit}
							id="btn-login-eid-confirm"
					>
						{this.props.t(!directPinInput ? 'next' : 'session.login')}
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	}

	renderLocalizedMiddlewarePinInputImage() {
		return <img
			src={(() => {
				switch (i18n.resolvedLanguage) {
					case 'nl':
						return mwPinInputNl;
					case 'fr':
						return mwPinInputFr;
					case 'en':
					default:
						return mwPinInputEn;
				}
			})()}
			alt="PIN input"
			style={{border: '1px solid #000'}}
		/>;
	}

	renderFAQWithLink(key) {
		return this.props.t(key).split('$').map((v, index) => {
			if (v.indexOf('link') === 0) {
				const elements = v.split(';');
				return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
			} else
				return <span key={index}>{v}</span>
		})
	}

	onPinChanged = (pinCode) => {
		this.setState({pinCode});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && ((this.props.eid.externalPinPad || this.props.useMiddlewarePinInput) || (!!this.state.pinCode && !!this.state.pinCode.trim()))) {
			this.onSubmit();
		}
	}

	onSubmit = () => {
		this.props.onEidLogin(this.state.pinCode, this.props.companyId);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	createTheme = (style) => {
		return createTheme({
			palette: {
				primary: {
					main: '#' + (style?.primaryBackgroundColor || '000000'),
					contrastText: '#' + (style?.primaryTextColor || 'FFFFFF')
				},
				secondary: {
					main: '#' + (style?.secondaryBackgroundColor || '000000'),
					contrastText: '#' + (style?.secondaryTextColor || 'FFFFFF')
				},
			},
			typography: {
				fontFamily: 'Lato,"Trebuchet MS",Arial,Helvetica,sans-serif'
			},
		});
	}

}

export default withTranslation()(connect(
	state => {
		return {
			eid: state.eid,
			loginBusy: state.session.loginBusy
		}
	},
	dispatch => {
		return {
			onEidStartMiddleware: () => {
				dispatch({
					type: 'EID_START_MIDDLEWARE',
				})
			},
			onEidStopMiddleware: () => {
				dispatch({
					type: 'EID_STOP_MIDDLEWARE',
				})
			},
			onEidLogin: (pinCode, companyId) => {
				dispatch({
					type: 'SESSION_LOGIN_EID',
					pinCode, companyId
				})
			}
		}
	}
)(SessionLoginEidDialog));
