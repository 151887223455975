import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import AppContainer from "../common/AppContainer";
import {Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Chip, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import PersonIcon from '@mui/icons-material/Person';
import {ReactComponent as Logo} from "../../img/quill_logo.svg";
import LoadingComponent from "../common/LoadingComponent";
import PdfViewerComponent from "../common/PdfViewerComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ApprovalDeclineDialog from "./ApprovalDeclineDialog";
import {ExpandMore} from "@mui/icons-material";
import FixedWidthHeaderComponent from "../common/FixedWidthHeaderComponent";

class ApprovalComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			viewIndex: 0,
			declineDialogOpen: false,
			approvalState: 'INIT',
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.approvalState !== 'INIT') {
			if (!!this.props.approvalDocumentsFinished &&
				this.props.approvalDocumentsFinished.length === this.props.approvalDocumentsRequested.length) {
				this.props.onSnackbarOpen(this.props.t(this.state.approvalState === 'APPROVE' ? 'approval.documentsApproved' : 'approval.documentsDeclined')
					.replace('{0}', this.props.approvalDocumentsRequested.length));
			}
		}
	}

	render() {
		return <AppContainer needsSession
							 onSessionCustomCreator={this.onSessionCustomCreator}
							 onSessionCreated={this.onSessionCreated}>
			{(!this.props.approvalData || !this.props.sessionInfo) && <Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{!this.props.approvalServerError && <LoadingComponent/>}
				{!!this.props.approvalServerError &&
					<ServerErrorComponent serverError={this.props.approvalServerError}/>}
			</Paper>}

			{(!!this.props.approvalData && !!this.props.sessionInfo) && <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2}}>
				<Box sx={{flexGrow: 1, width: 600, maxWidth: 900}}>
					{this.renderOverview()}
					{this.renderApprovers()}
					{this.renderSigners()}
				</Box>
				<Box sx={{width: 900, maxWidth: 900}}>
					{this.renderPdfViewer()}
				</Box>
			</Box>}
		</AppContainer>
	}

	renderOverview = () => {
		const documents = this.props.approvalData.documents;
		const documentsWaitingForApproval = documents.filter(doc => doc.approvalRequestState === 'WAITING');
		return <Paper variant="outlined" sx={{p: 2}}>
			<Typography variant="h6">{this.props.t('approval.header')}</Typography>
			<Typography variant="body">{this.props.t('approval.instructions')}</Typography>
			<Box sx={{mt: 1, display: 'flex', flexDirection: 'column', gap: 1}}>
				{documents.map((document, index) =>
					<Box key={document.documentId} sx={{display: 'flex', alignItems: 'center', maxWidth: 'inherit'}}>
						{/* name + visibility */}
						<Box sx={{display: 'flex', flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 0}}>
							<Typography variant="body2"
										title={document.documentName}
										sx={{wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'nowrap', overflow: 'hidden'}}
							>
								{document.documentName}
							</Typography>
							{index === this.state.viewIndex &&
								<VisibilityIcon fontSize="small" sx={{ml: 1, mr: 1}}/>}
						</Box>

						{/* status, hide when very small */}
						<Box sx={{display: {xs: 'none', sm: 'block'}, mr: 1, alignSelf: 'center'}}>
							<Chip
								label={<Typography variant="body2">
									{document.documentState === 'ATTACHMENT' || document.documentState === 'ATTACHMENT_GENERIC' ?
										this.props.t('approval.attachment') :
										this.props.t('approval.approvalRequestState_' + document.approvalRequestState)}
								</Typography>}
								size="small"/>
						</Box>

						{/* view + download */}
						<Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-end', flexShrink: 0}}>
							{documents.length > 1 && <Button
								variant="outlined" size="small"
								onClick={() => this.onChangeViewIndex(index)}
								id={"btn-approval-view-" + index}
								disabled={index === this.state.viewIndex || document.documentState === 'ATTACHMENT_GENERIC'}
							>
								{this.props.t('approval.view')}
							</Button>}
							<Button variant="outlined" size="small"
									onClick={() => this.props.onDocumentSingleDownloadArtifacts(document.documentId, ['DOCUMENT', 'ATTACHMENT', 'DECLINE_ATTACHMENT', 'PREVIOUS_VERSIONS'])}
									id={"btn-approval-download-" + index}>
							{this.props.t('approval.download')}
							</Button>
						</Box>
					</Box>)}
				{documents.length > 1 && <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<Button variant="outlined" size="small"
							onClick={this.onDownloadAll}
					>
						{this.props.t('approval.downloadAll')}
					</Button>
				</Box>}
			</Box>

			<Box sx={{mt: 2, display: 'flex', alignItems: 'center', maxWidth: 'inherit'}}>
				<Box sx={{display: {xs: 'block', md: 'none'}, mr: 2}}>
					<Logo style={{height: '54px'}}/>
				</Box>
				{documentsWaitingForApproval.length > 0 && <>
					<Box sx={{flexGrow: 1, display: 'flex', minWidth: '50px', alignItems: 'center', pr: 1}}>
						{!!this.props.approvalProgressDocumentIds && <LinearProgress
							variant="determinate"
							value={100 * this.props.approvalProgressDocumentIds.length / documentsWaitingForApproval.length}
							sx={{flexGrow: 1}}
						/>}
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-end'}}>
						<Button
							variant="contained"
							disabled={this.props.approvalBusy}
							startIcon={<TaskIcon/>}
							onClick={this.onApprovalApproveStart}
							id="btn-approval-approve"
						>
							{this.props.t('approval.approve')}
						</Button>
						<Button
							variant="contained"
							disabled={this.props.approvalBusy}
							startIcon={<ThumbDownAltIcon/>}
							onClick={this.onOpenDeclineDialog}
							id="btn-approval-decline"
						>
							{this.props.t('approval.declineDialog')}
						</Button>
					</Box>

				</>}
				{documentsWaitingForApproval.length === 0 && <>
					<Alert severity="info" sx={{flexGrow: 1}}>{this.props.t('approval.everythingApprovedDeclined')}</Alert>
				</>}
			</Box>

			{!!this.props.approvalServerError && <ServerErrorComponent serverError={this.props.approvalServerError}/>}

			<ApprovalDeclineDialog
				open={this.state.declineDialogOpen}
				onClose={this.onCloseDeclineDialog}
				onDeclineStart={this.onApprovalDeclineStart}
			/>
		</Paper>;
	}

	renderSigners = () => {
		const documents = this.props.approvalData.documents;
		const signers = documents[this.state.viewIndex]?.signers || [];
		if (signers.length === 0) return null;

		const defaultExpanded = (window.innerWidth > 1600);

		return <Accordion variant="outlined"
						  disableGutters={true}
						  defaultExpanded={defaultExpanded}
						  sx={{mt: 2,
							  '&:before': {
								  display: 'none',
							  }}}>
			<AccordionSummary  expandIcon={<ExpandMore />}>
				<Typography variant="h6">{this.props.t('approval.signers')}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 0}}>
				<Typography variant="body" sx={{
					wordWrap: 'break-word',
					overflowWrap: 'break-word',
					wordBreak: 'break-all'
				}}>{this.props.t('approval.signersDescription') + ' ' + documents[this.state.viewIndex].documentName}</Typography>
				<List dense={true}>
					{signers.map(signer => {
						return <ListItem disablePadding={true} key={signer.id}>
							<ListItemIcon  sx={{minWidth: '35px'}}><PersonIcon /></ListItemIcon>
							<ListItemText>
								<Typography>{signer.fullName}</Typography>
							</ListItemText>
						</ListItem>
					})}
				</List>
			</AccordionDetails>
		</Accordion>
	}

	renderApprovers = () => {
		const documents = this.props.approvalData.documents;
		const approvers = documents[this.state.viewIndex]?.approvers || [];
		if (approvers.length === 0) return null;

		const defaultExpanded = (window.innerWidth > 1600);

		return <Accordion variant="outlined"
				   disableGutters={true}
				   defaultExpanded={defaultExpanded}
				   sx={{mt: 2,
					   '&:before': {
						   display: 'none',
					   }}}>
			<AccordionSummary  expandIcon={<ExpandMore />}>
				<Typography variant="h6">{this.props.t('approval.approvers')}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 0}}>
				<Typography variant="body">{this.props.t('approval.approversDescription') + ' ' + documents[this.state.viewIndex].documentName}</Typography>
				<List dense={true}>
					{approvers.map(this.renderApprover)}
				</List>
			</AccordionDetails>
		</Accordion>
	}

	renderApprover = (approver) => {
		let icon = null;
		if ('APPROVED' === approver.approvalRequestState) {
			icon = <TaskIcon/>;
		} else if ('DECLINED' === approver.approvalRequestState) {
			icon = <ThumbDownAltIcon/>;
		}

		let text = this.props.t('approval.approvalRequestState_' + approver.approvalRequestState);
		if (approver.declineReason) {
			text += ": " + approver.declineReason;
		}

		return <ListItem disablePadding={true} key={approver.id}>
					<ListItemIcon  sx={{minWidth: '35px'}}>{icon}</ListItemIcon>
				<ListItemText primary={approver.person.fullName} secondary={text}/>
		</ListItem>;
	}

	renderPdfViewer = () => {
		const selectedDocument = this.props.approvalData.documents[this.state.viewIndex];

		const selectedDocumentPages = Array(selectedDocument.pageCount)
			.fill(null)
			.map((ign, index) => ({
				availableUrl: `/api/internal/document/${selectedDocument.documentId}/preview/${index}/available`,
				imageUrl: `/api/internal/document/${selectedDocument.documentId}/preview/${index}/image`,
			}));

		return <PdfViewerComponent
			key={selectedDocument.documentId}
			pages={selectedDocumentPages}
		>
			<FixedWidthHeaderComponent maxWidth={300} value={selectedDocument.documentName} />
		</PdfViewerComponent>
	}

	onSessionCustomCreator = () => {
		// this is called everytime, even though we have a session
		// the reason is that we can have a 'normal' session, but guest key
		// the guest key will need to have prio
		const data = this.props?.match?.params?.data;
		if (data.startsWith('ids=')) {
			// we could destroy current session and create new, but thats a bit stupid
			if (!this.props.sessionCreated) {
				if (window.innerWidth < 768) {
					this.props.onMenuClose();
				}
				this.props.onSessionCheck();
			} else {
				this.onSessionCreated();
			}
		} else if (data.startsWith('guestKey=')) {
			const guestApprovalKey = data.substring(9);
			if (this.props?.sessionGuestPath == null || !this.props?.sessionGuestPath.includes(guestApprovalKey)) {
				this.props.onMenuClose();
				this.props.onSessionDestroy();
				this.props.onSessionCreateGuest({approvalKey: guestApprovalKey});
			}
		} else if (data.startsWith('guestBulkKey=')) {
			const guestBulkApproveKey = data.substring(13);
			if (this.props?.sessionGuestPath == null || !this.props?.sessionGuestPath.includes(guestBulkApproveKey)) {
				this.props.onMenuClose();
				this.props.onSessionDestroy();
				this.props.onSessionCreateGuest({bulkApproveKey: guestBulkApproveKey});
			}
		}
	}

	onSessionCreated = () => {
		const data = this.props?.match?.params?.data;
		if (data.startsWith('ids=')) {
			this.props.onApprovalFetchData(data.substring(4));
		} else {
			this.props.onApprovalFetchData();
		}
	}

	onChangeViewIndex = (viewIndex) => {
		this.setState({viewIndex});
	}

	onApprovalApproveStart = () => {
		const ids = this.props.approvalData.documents
			.filter(doc => doc.approvalRequestState === 'WAITING')
			.map(doc => doc.documentId)
		;
		this.setState({declineDialogOpen: false, approvalState: 'APPROVE'},
			() => this.props.onApprovalApproveOrDeclineStart(ids, false));
	}

	onOpenDeclineDialog = () => {
		this.setState({declineDialogOpen: true});
	}

	onCloseDeclineDialog = () => {
		this.setState({declineDialogOpen: false});
	}

	onApprovalDeclineStart = (reason) => {
		const ids = this.props.approvalData.documents
			.filter(doc => doc.approvalRequestState === 'WAITING')
			.map(doc => doc.documentId)
		;
		this.setState({declineDialogOpen: false, approvalState: 'DECLINE'},
			() => this.props.onApprovalApproveOrDeclineStart(ids, true, reason));
	}

	onDownloadAll = () => {
		this.props.onDocumentDownloadArtifacts(this.props.approvalData.documents.map(doc => doc.documentId), ['DOCUMENT'])
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionGuestPath: state.session.guestPath,
			sessionCreated: state.session.created,

			approvalBusy: state.approval.busy,
			approvalServerError: state.approval.serverError,
			approvalData: state.approval.data,
			approvalProgressDocumentIds: state.approval.progressDocumentIds,
			approvalDocumentsFinished: state.approval.documentsFinished,
			approvalDocumentsRequested: state.approval.documentsRequested,
		}
	},
	dispatch => {
		return {
			onApprovalFetchData: (ids) => {
				dispatch({
					type: 'APPROVAL_FETCH_DATA',
					ids
				});
			},
			onApprovalApproveOrDeclineStart: (ids, declining, declineReason) => {
				dispatch({
					type: 'APPROVAL_APPROVE_OR_DECLINE_START',
					ids,
					declining,
					declineReason
				});
			},
			onDocumentDownloadArtifacts: (ids, types) => {
				dispatch({
					type: 'DOCUMENT_DOWNLOAD_ARTIFACTS',
					ids,
					types
				});
			},
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types
				});
			},
			onSessionCheck: () => {
				dispatch({
					type: 'SESSION_CHECK'
				})
			},
			onSessionDestroy: () => {
				dispatch({
					type: 'SESSION_DESTROY'
				})
			},
			onSessionCreateGuest: (request) => {
				dispatch({
					type: 'SESSION_CREATE_GUEST',
					request
				})
			},
			onMenuClose: () => {
				dispatch({
					type: 'MENU_CLOSE'
				})
			},
			onSnackbarOpen: (message) => {
				dispatch({
					type: 'SNACKBAR_OPEN',
					message
				})
			},
		}
	}
)(ApprovalComponent)));
