import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Alert, Paper} from "@mui/material";
import AppContainer from "../common/AppContainer";

class SigningItsMeRedirectErrorComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <AppContainer needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Alert severity="error">{this.props.t('signing.itsmeRedirectError')}</Alert>
			</Paper>
		</AppContainer>
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(SigningItsMeRedirectErrorComponent));
