export const EMAIL_LANGUAGES = [
	{value: 'ENGLISH', lang: 'en'},
	{value: 'DUTCH', lang: 'nl'},
	{value: 'FRENCH', lang: 'fr'},
];

export const DEFAULT_SIGN_EMAIL_HEADERS = {
	ENGLISH: 'Dear $signer,\n' +
		'\n' +
		'You are invited by {0} ({1}) to electronically sign document $document.',
	DUTCH: 'Beste $signer,\n' +
		'\n' +
		'U bent uitgenodigd door {0} ({1}) om document $document elektronisch te ondertekenen.',
	FRENCH: 'Cher/chère $signer,\n' +
		'\n' +
		'Vous êtes invité par {0} ({1}) à signer electroniquement le document $document.',
};

export const DEFAULT_APPROVE_EMAIL_HEADERS = {
	ENGLISH: 'Dear $approver,\n' +
		'\n' +
		'You are invited by {0} ({1}) to approve document $document.',
	DUTCH: 'Beste $approver,\n' +
		'\n' +
		'U bent uitgenodigd door {0} ({1}) om document $document goed te keuren.',
	FRENCH: 'Cher/chère $approver,\n' +
		'\n' +
		'Vous êtes invité par {0} ({1}) à approuvé le document $document.',
};
