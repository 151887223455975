import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DelegateListAddCreateDialog from "../delegate/DelegateListAddCreateDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";

class FolderDelegatesDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filterValue: '',
			filterValueUC: '',

			currentDelegateList: null, // null == create new
			addCreateDialogOpen: false,
			deleteDialogOpen: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onFolderFetchRelevantUsers();
			this.props.onFolderFetchDelegateLists(this.props.folderId);
		}
		if (this.props.folderDelegateActionSuccessFully && !prevProps.folderDelegateActionSuccessFully) {
			this.props.onFolderFetchDelegateLists(this.props.folderId);
		}
	}

	render() {
		const folderRelevantUsers = this.props.folderRelevantUsers;
		const folderDelegateLists = this.props.folderDelegateLists;
		const haveAllInfo = !!folderRelevantUsers && !!folderDelegateLists;

		const dataGridColumns = [
			{
				field: 'type',
				headerName: this.props.t('delegates.type'),
				editable: false,
				width: 200,
				valueGetter: (data) => this.props.t('delegates.type_' + data.value)
			},
			{
				field: 'forPerson',
				headerName: this.props.t('delegates.for'),
				editable: false,
				flex: 1,
				valueGetter: (data) => !!data.value ? (data.value?.firstName + ' ' + data.value?.lastName) : this.props.t('delegates.forEveryone')
			},
			{
				field: 'delegates',
				headerName: this.props.t('delegates.count'),
				editable: false,
				width: 200,
				valueGetter: (data) => data.value?.length || 0
			},
			{
				field: 'actions',
				headerName: this.props.t('delegates.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.listEdit')}
						onClick={() => this.onOpenDialogForEditing(cellValues.row)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.listDelete')}
						onClick={() => this.onOpenDeleteDialog(cellValues.row)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];

		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="lg">
				<DialogTitle>{this.props.t('folder.delegates')}</DialogTitle>
				{!haveAllInfo && <DialogContent><LoadingComponent/></DialogContent>}
				{haveAllInfo && <DialogContent>
					<ServerErrorComponent serverError={this.props.folderServerError} />

					<Box sx={{mt: 2, ml: 2}}>
						<Typography>{this.props.t('delegates.description')}</Typography>
						<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
							<Button variant="contained"
									onClick={this.onOpenDialogForAdding}
									startIcon={<AddIcon/>}
									sx={{mr: 1}}
									id="btn-folder-delegates-create"
							>
								{this.props.t('delegates.listCreate')}
							</Button>
						</Box>
						<DataGrid
							autoHeight
							disableColumnSelector
							disableColumnFilter
							disableRowSelectionOnClick
							pagination

							initialState={{
								pagination: {
									paginationModel: { pageSize: 10, page: 0 },
								},
							}}

							columns={dataGridColumns}
							slots={{toolbar: FlexibleToolbar}}
							slotProps={{
								toolbar: {
									filterId: 'input-folder-delegates-search-text',
									filterValue: this.state.filterValue,
									onChangeFilterValue: this.onFilterValueChange,
								}
							}}

							getRowId={(row) => row.type + (row?.forPerson?.id || '')}
							rows={folderDelegateLists.filter(list =>
								!this.state.filterValue ||
								(list?.forPerson?.firstName || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
								(list?.forPerson?.lastName || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
								(list?.forPerson?.email || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0
							)}
							pageSizeOptions={[10]}
							density="compact"
						/>
					</Box>
				</DialogContent>}
				<DialogActions>
					<Button onClick={this.onClose} id="btn-folder-delegates-cancel">{this.props.t('back')}</Button>
				</DialogActions>
			</Dialog>

			<DelegateListAddCreateDialog
				delegateList={this.state.currentDelegateList}
				relevantUsers={folderRelevantUsers}
				open={this.state.addCreateDialogOpen}
				onClose={this.onCloseAddCreateDialog}
				onSave={this.onSaveDelegateList}
			/>

			<ConfirmationDialog
				title={this.props.t('delegates.listDelete')}
				confirm={this.props.t('delegates.listDeleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onCloseDeleteDialog}
				onConfirm={this.onConfirmDeletion}
			/>
		</Fragment>;
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onOpenDialogForAdding = () => {
		this.setState({currentDelegateList: null, addCreateDialogOpen: true});
	}

	onOpenDialogForEditing = (currentDelegateList) => {
		this.setState({currentDelegateList, addCreateDialogOpen: true});
	}

	onCloseAddCreateDialog = () => {
		this.setState({addCreateDialogOpen: false});
	}

	onSaveDelegateList = (delegateList) => {
		this.setState({addCreateDialogOpen: false}, () => this.props.onFolderCreateUpdateDelegateList(this.props.folderId, delegateList));
	}

	onOpenDeleteDialog = (currentDelegateList) => {
		this.setState({currentDelegateList, deleteDialogOpen: true});
	}

	onCloseDeleteDialog = () => {
		this.setState({deleteDialogOpen: false});
	}

	onConfirmDeletion = () => {
		const delegateList = this.state.currentDelegateList;
		this.setState({currentDelegateList: null, deleteDialogOpen: false}, () => this.props.onFolderDeleteDelegateList(this.props.folderId, delegateList));
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			folderBusy: state.folder.busy,
			folderServerError: state.folder.serverError,
			folderRelevantUsers: state.folder.relevantUsers,
			folderDelegateLists: state.folder.delegateLists,
			folderDelegateActionSuccessFully: state.folder.delegateActionSuccessFully
		}
	},
	dispatch => {
		return {
			onFolderFetchRelevantUsers: () => {
				dispatch({
					type: 'FOLDER_FETCH_RELEVANT_USERS',
				});
			},
			onFolderFetchDelegateLists: (folderId) => {
				dispatch({
					type: 'FOLDER_FETCH_DELEGATE_LISTS',
					folderId
				});
			},
			onFolderCreateUpdateDelegateList: (folderId, delegateList) => {
				dispatch({
					type: 'FOLDER_CREATE_UPDATE_DELEGATE_LIST',
					folderId,
					delegateList
				})
			},
			onFolderDeleteDelegateList: (folderId, delegateList) => {
				dispatch({
					type: 'FOLDER_DELETE_DELEGATE_LIST',
					folderId,
					delegateList
				})
			}
		}
	}
)(FolderDelegatesDialog));
