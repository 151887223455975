import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {Box, Typography} from "@mui/material";
import EditorPostActionsEmailDistributionListComponent from "./EditorPostActionsEmailDistributionListComponent";

class EditorPostActionsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {}
	}

	render() {
		const rootDocument = this.props.editorEditInfo.document;
		const document = this.props.document;

		return <Box>
			<Typography sx={{
				fontWeight: 700,
				mt: 2
			}}>{this.props.t('editor.postActionsDocumentEmailDistributionList')}</Typography>
			<EditorPostActionsEmailDistributionListComponent
				documentId={document.id}
				readOnly={document.readOnly}
				postSignEmailDistributionList={document.postSignDocumentEmailDistributionList}
				onChangePostSignEmailDistributionList={this.props.onChangePostSignDocumentEmailDistributionList}
			/>

			<Typography sx={{
				fontWeight: 700,
				mt: 4
			}}>{this.props.t('editor.postActionsDocumentAndEvidenceReportEmailDistributionList')}</Typography>
			<EditorPostActionsEmailDistributionListComponent
				documentId={document.id}
				readOnly={document.readOnly}
				postSignEmailDistributionList={document.postSignDocumentAndEvidenceReportEmailDistributionList}
				onChangePostSignEmailDistributionList={this.props.onChangePostSignDocumentAndEvidenceReportEmailDistributionList}
			/>

		</Box>
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			editorEditInfo: state.editor.editInfo,
		}
	},
	dispatch => {
		return {}
	}
)(EditorPostActionsComponent)));
