import axios from "axios";
import UAParser from "ua-parser-js";
import {all, delay, put} from "redux-saga/effects";

function* fetchInternalLocal(method, url, data) {
    let response;
    if ('GET' === method) {
        response = yield axios.get(url);
    }
    else if ('POST' === method) {
        response = yield axios.post(url, data, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }});
    }
    if (!response || 200 !== response.status || !response.data || !response.data.status || 'SUCCESS' !== response.data.status.code) {
        const message = 'Local middleware returned error' + (!!response && !!response.data && !!response.data.status && !!response.data.status.code ? ': ' + response.data.status.code : '');
        throw { message, data: !!response ? response.data : undefined };
    }
    return response.data.response;
}

export function* eidMiddlewareGetLocalVersion() {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202');
}

export function* eidMiddlewareGetEidCards() {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202/v1/belpic');
}

export function* eidMiddlewareReadAuthenticationCertificate(eidCard) {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202/v1/belpic/' + eidCard.id + '/authentication-certificate');
}

export function* eidMiddlewareReadNonRepudiationCertificate(eidCard) {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202/v1/belpic/' + eidCard.id + '/non-repudiation-certificate');
}

export function* eidMiddlewareReadCitizenCertificate(eidCard) {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202/v1/belpic/' + eidCard.id + '/citizen-certificate');
}

export function* eidMiddlewareReadVerifiedIdentity(eidCard) {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202/v1/belpic/' + eidCard.id + '/verified-identity');
}

export function* eidMiddlewareReadRawIdentity(eidCard) {
    return yield fetchInternalLocal('GET', 'https://client.localmiddleware.be:20202/v1/belpic/' + eidCard.id + '/raw-identity');
}

export function* eidMiddlewareSign(eidCard, data) {
    return yield fetchInternalLocal("POST", "https://client.localmiddleware.be:20202/v1/belpic/" + eidCard.id + "/sign", data);
}

export function* eidMiddlewareAuthenticate(eidCard, data) {
    return yield fetchInternalLocal("POST", "https://client.localmiddleware.be:20202/v1/belpic/" + eidCard.id + "/authenticate", data);
}

export function *eidMiddlewareLoop() {

    // Get OS name & platform
    const osName = new UAParser().getOS().name;
    const platform = (() => {
        switch (osName) {
            case 'win32':
            case 'Windows':
                return 'Windows';
            case 'Mac OS':
                return 'Mac';
            case 'CentOS':
            case 'Debian':
            case 'Fedora':
            case 'FreeBSD':
            case 'Gentoo':
            case 'Linux':
            case 'Mint':
            case 'OpenBSD':
            case 'RedHat':
            case 'Slackware':
            case 'SUSE':
            case 'Ubuntu':
                return 'Linux';
            default:
                return osName;
        }
    })();

    // Try to lookup supported versions, can give problems or be empty, but continue
    let remoteSupportedVersions = [];
    try {
        const remoteResponse = yield axios.get('https://middleware.diossupdate.com/api/eid/download');
        remoteSupportedVersions = (remoteResponse.data.find(e => e.platform === platform) || { supported: []}).supported;
    } catch (e) {
        console.log('Problem getting latest middleware version', e);
    }

    // Start main loop
    let isMiddlewareRunningCorrectly = false;
    let legacySessionId;
    let readers = [];
    for (;;) {
        if (isMiddlewareRunningCorrectly) {
            // the new middle API doesn't have a good event mechanism
            // we need to poll eidMiddlewareGetEidCards to see what is happening
            // so we could add yield delay(XXX) in between
            // but large values give suboptimal user experience, small values are not debuggable
            // so to avoid that we use the event call from legacy API to wait between eidMiddlewareGetEidCards polls

            if (!legacySessionId) {
                try {
                    let response = yield axios.get('https://client.localmiddleware.be:20202/session');
                    legacySessionId = response.data.sessionId;
                    if (!legacySessionId) throw new Error('No session received');
                }
                catch (e) {
                    isMiddlewareRunningCorrectly = false;
                    // don't wait on version check loop to already show middleware not available screen
                    yield put({ type: 'MIDDLEWARE_NOT_RUNNING', versions: [] });
                    continue;
                }
            }

            // do polling on events
            let response;
            try {
                response = yield eidMiddlewareGetEidCards();
            }
            catch (e) {
                isMiddlewareRunningCorrectly = false;
                continue;
            }

            const currentReaders = response.belpicReaders;
            const newReaders = currentReaders.filter(currentReader => -1 === readers.findIndex(reader => reader.id === currentReader.id));
            const obsoleteReaders = readers.filter(reader => -1 === currentReaders.findIndex(currentReader => currentReader.id === reader.id));

            yield all(newReaders.map(reader => put({
                type: 'EID_CORRECT_CARD_INSERTED',
                eidCard: reader.card,
                readerName: reader.name,
                externalPinPad: reader.capabilities.indexOf('pinpad') >= 0
            })));

            yield all(obsoleteReaders.map(reader => put({
                type: 'EID_CORRECT_CARD_REMOVED',
                eidCard: reader.card
            })));

            readers = currentReaders;

            // so use the event call to delay
            try {
                yield axios.get(`https://client.localmiddleware.be:20202/events?sessionId=${legacySessionId}`); // default 30 seconds timeout
            } catch (e) {
                isMiddlewareRunningCorrectly = false;
                // don't wait on version check loop to already show middleware not available screen
                yield put({ type: 'MIDDLEWARE_NOT_RUNNING', versions: [] });
            }
        }
        else {
            legacySessionId = null;

            // check the availability / version correctness periodically
            try {
                const isCurrentVersionStrictlyLowerAsOther = (currentVer, otherVer) => {
                    const versionStrings = ['major_version', 'minor_version', 'release_version', 'build_number'];
                    let version = versionStrings.shift();
                    while (!!version) {
                        if (currentVer[version] < otherVer[version]) return true;
                        else if (currentVer[version] > otherVer[version]) return false;
                        // when equal check next version string
                        version = versionStrings.shift();
                    }
                    return false;
                }

                // get local installed version
                const installedVersion = yield eidMiddlewareGetLocalVersion();

                // if there are middleware versions available, check if we are out of date
                const remoteHigherVersions = remoteSupportedVersions.filter(ver => isCurrentVersionStrictlyLowerAsOther(installedVersion, ver))

                // check if we are running on minimal version since we are using new API
                if (isCurrentVersionStrictlyLowerAsOther(installedVersion, { major_version: 3, minor_version: 6, release_version: 0, build_number: 0 })) {
                    yield put({
                        type: 'MIDDLEWARE_VERSION_TO_OLD',
                        versions: remoteHigherVersions
                    });
                }
                // ok
                else {
                    isMiddlewareRunningCorrectly = true;

                    yield put({
                        type: 'MIDDLEWARE_OK',
                        versions: remoteHigherVersions
                    });

                    continue; // skip delay
                }
            }
            catch (e) {
                console.log('Problem checking for local middleware', e)
                yield put({
                    type: 'MIDDLEWARE_NOT_RUNNING',
                    versions: remoteSupportedVersions
                });
            }

            yield delay(3000);
        }
    }
}
