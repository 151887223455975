import React, {Component} from "react";
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {GENERATE_USER_IMPORT_TEMPLATE_DATA} from "./UserImportData";
import {saveAs} from "file-saver";
import UploadFileComponent from "../common/FileUploadComponent";

const defaultState = {
	item: null,
	allowUpdate: false,

	uploading: false,
	uploadProgress: 0,

	importResult: null,
	parsingError: false
};

class UserImportDialog extends Component {
	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		const importHasErrors = this.state.importResult?.userNotCreatedDetails?.length > 0;

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('user.import')}</DialogTitle>
			<DialogContent>
				<UploadFileComponent
					disabled={false}
					onAddFile={this.onAddFile}
					uploadProgress={this.state.uploadProgress}
					uploadFinished={!!this.state.importResult || !!this.state.parsingError}
					uploadedFile={this.state.item?.file}
					uploadBoxType={'static'}
					accepted={".csv"}
				/>
				<Box sx={{display: 'flex', alignItems: 'left'}}>
					<FormControlLabel
						control={<Checkbox checked={this.state.allowUpdate}
										   onChange={this.onChangeAllowUpdate}/>}
						label={this.props.t('user.uploadAllowUpdate')}
					/>
				</Box>
				<Link
					component="button"
					variant="body1"
					onClick={this.onDownloadTemplate}
					sx={{mb: 1}}
				>
					{this.props.t('user.uploadDownloadTemplate')}
				</Link>

				{this.hasInvalidFileExtension() &&
					<Alert severity="error">{this.props.t('user.uploadInvalidFileExtension')}</Alert>}
				{this.fileSizeLimitExceeded() &&
					<Alert severity="error">{this.props.t('user.uploadFileLimitExceeded')}</Alert>}

				{this.state.parsingError &&
					<Alert severity="error">{this.props.t('user.uploadParsingError')}</Alert>
				}
				{!!this.state.importResult && <Box>
					<Alert
						severity={importHasErrors ? "warning" : "success"}>
						<AlertTitle>
							{this.props.t('user.uploadImportedUsersResult') + ': ' + this.state.importResult.importedUsers.length + '/' + this.state.importResult.totalRecords}
						</AlertTitle>
						{importHasErrors && <>
							<Typography>{this.props.t('user.uploadImportedErrors')}</Typography>
							<List sx={{pt: 0, pb: 0, mt: 0, mb: 0}}>
								{this.state.importResult.userNotCreatedDetails.map(this.renderImportError)}
							</List>
						</>}
					</Alert>
				</Box>}
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-back">{this.props.t('back')}</Button>
				<Button variant="contained"
						disabled={!this.canContinue() || this.state.uploading}
						onClick={this.onUpload}
						id="btn-create-confirm"
				>
					{this.props.t('user.import')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onAddFile = (file) => {
		if (!file) {
			return;
		}

		const item = {
			file: file,
			name: file.name,
			size: file.size
		};

		this.setState({
			item: item,
			uploadProgress: 0,
			importResult: null,
			parsingError: false
		});
	}

	onChangeAllowUpdate = (e, value) => {
		this.setState({allowUpdate: value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !this.state.uploading && this.canContinue()) {
			this.onUpload();
		}
	}

	hasInvalidFileExtension = () => {
		return !!this.state.item?.name && this.state.item.name.split('.').pop() !== 'csv';
	}

	fileSizeLimitExceeded = () => {
		return !!this.state.item?.size && this.state.item.size >= 104857600;
	}

	canContinue = () => {
		return !!this.state.item?.file && !this.hasInvalidFileExtension() && !this.fileSizeLimitExceeded();
	}

	onUpload = () => {
		const xhr = new XMLHttpRequest();

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				this.onProgressUpdate(Math.round((event.loaded * 100) / event.total));
			}
		})

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (!xhr.responseText) {
						this.setState({
							parsingError: true
						});
						return;
					}

					const importUsersResponse = JSON.parse(xhr.responseText);
					this.setState({
						importResult: {
							importedUsers: importUsersResponse.importedUsers,
							existingUsers: importUsersResponse.existingUsers,
							totalRecords: importUsersResponse.totalRecords,
							userNotCreatedDetails: importUsersResponse.userNotCreatedDetails
						}
					});
				}
			}
		};

		xhr.onloadend = () => {
			this.setState({
				uploading: false
			});
		}

		xhr.open('POST', '/api/internal/user/import', true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		const formData = new FormData();
		formData.append('allowUpdate', this.state.allowUpdate);
		formData.append('files', this.state.item.file, encodeURIComponent(this.state.item.name));

		this.setState({
			uploading: true,
		})
		document.getElementById('file').value = null;

		xhr.send(formData);
	}

	onProgressUpdate = (progress) => {
		this.setState({uploadProgress: progress});
	}

	renderImportError = (importError) => {
		return <ListItem sx={{pt: 0, pb: 0}} key={importError.email}>
			<ListItemText>
				<Typography sx={{fontWeight: 'bold'}}>{importError.email}</Typography>
				<Typography>{this.props.t('user.uploadError_' + importError.error)}</Typography>
			</ListItemText>
		</ListItem>;
	}

	onDownloadTemplate = () => {
		const blob = new Blob([GENERATE_USER_IMPORT_TEMPLATE_DATA()], {type: "text/plain;charset=utf-8"});
		saveAs(blob, "User import template.csv");
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(UserImportDialog);
