const defaultState = {
	// general state
	busy: false,
	serverError: false,

	overviewList: [],
	overviewCount: 0,
	settings: null,

	// response variables
	actionSuccessFully: false,
	createdOidcInstanceId: null,
};

const oidcInstance = (state = defaultState, action) => {
	switch (action.type) {
		case 'OIDC_INSTANCE_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError,
				logoUpdateError: action.logoUpdateError,
			}

		case 'APPLICATION_OIDC_INSTANCE_SWITCH_VIEW':
			return {
				...state,
				busy: true,
				overviewList: null,
			}

		case 'COMPANY_OIDC_INSTANCE_FETCH_LIST':
		case 'APPLICATION_OIDC_INSTANCE_FETCH_LIST':
		case 'APPLICATION_OIDC_INSTANCE_COMPANY_FETCH_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'APPLICATION_OIDC_INSTANCE_UPDATE_LOGO_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				settings: {
					...state.settings,
					logoBase64: action.logoBase64
				}
			}

		case 'APPLICATION_OIDC_INSTANCE_UPDATE_LOGO':
		case 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO':
			return {
				...state,
				serverError: null,
				logoUpdateError: null,
				busy: true
			}

		case 'APPLICATION_OIDC_INSTANCE_FETCH_LIST_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_FETCH_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.data,
			}

		case 'APPLICATION_OIDC_FETCH_COMPANY_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS':
		case 'APPLICATION_OIDC_INSTANCE_FETCH_SETTINGS':
			return {
				...state,
				busy: true,
				logoUpdateError: null,
				settings: null
			}

		case 'APPLICATION_OIDC_INSTANCE_FETCH_SETTINGS_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				settings: action.data,
			}

		case 'OIDC_INSTANCE_CHANGE_SETTING':
			const settings = {
				...state.settings
			};
			if (null === action.value) {
				delete settings[action.key];
			} else {
				settings[action.key] = action.value;
			}

			return {
				...state,
				settings
			}

		case 'APPLICATION_OIDC_INSTANCE_DELETE':
		case 'APPLICATION_OIDC_INSTANCE_UPDATE_SETTINGS':
		case 'COMPANY_OIDC_INSTANCE_CREATE':
		case 'COMPANY_OIDC_INSTANCE_DELETE':
		case 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS':
		case 'APPLICATION_OIDC_INSTANCE_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				actionSuccessFully: false,
				createdOidcInstanceId: null
			}

		case 'APPLICATION_OIDC_INSTANCE_UPDATE_SETTINGS_SUCCESS':
		case 'APPLICATION_OIDC_INSTANCE_DELETE_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				logoUpdateError: null,
				actionSuccessFully: true
			}

		case 'APPLICATION_OIDC_INSTANCE_CREATE_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				logoUpdateError: null,
				actionSuccessFully: true,
				createdOidcInstanceId: action.createdOidcInstanceId
			}


		default:
			return state;
	}
}

export default oidcInstance;
