import React, {Component} from "react";
import {Box, CircularProgress} from "@mui/material";

export default class LoadingComponent extends Component {

    render() {
        return <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    }

}

