import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import ApplicationOidcInstanceOverviewComponent from "./ApplicationOidcInstanceOverviewComponent";
import ApplicationCompanyOidcInstanceOverviewComponent from "./ApplicationCompanyOidcInstanceOverviewComponent";

class ApplicationOidcSettingsComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedView: 'GLOBAL'
		}
	}

	render() {
		return<>
			<ToggleButtonGroup sx={{margin: 'auto', width: 'fit-content', display: 'block', mt: 1}} color="primary" exclusive onChange={this.onChangeView} value={this.state.selectedView}>
				<ToggleButton id="btn-settings-oidc-global" key="GLOBAL" value="GLOBAL">{this.props.t('oidcInstance.global')}</ToggleButton>
				<ToggleButton id="btn-settings-oidc-company" key="COMPANY" value="COMPANY">{this.props.t('oidcInstance.company')}</ToggleButton>
			</ToggleButtonGroup>

			{this.renderCurrentView()}
		</>
	}

	renderCurrentView = () => {
		switch (this.state.selectedView) {
			case 'GLOBAL':
				return <ApplicationOidcInstanceOverviewComponent/>;
			case 'COMPANY':
				return <ApplicationCompanyOidcInstanceOverviewComponent/>;
		}
	}

	onChangeView = (e, value) => {
		if (!value) return;

		this.setState({selectedView: value}, this.props.onOidcSettingsSwitchView);
	}
}

export default withTranslation()(connect(
	state => {
		return {
		}
	},
	dispatch => {
		return {
			onOidcSettingsSwitchView: () => {
				dispatch({
					type: 'APPLICATION_OIDC_INSTANCE_SWITCH_VIEW'
				})
			}
		}
	}
)(ApplicationOidcSettingsComponent));
