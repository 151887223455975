import React, {cloneElement, Component, createRef, useRef} from "react";
import {Box, Button, IconButton, InputAdornment, LinearProgress, Paper, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {useDrop} from "react-dnd";
import axios from "axios";
import LoadingComponent from "./LoadingComponent";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import {ReactComponent as Logo} from "../../img/excel.svg";

const DroppablePageWrapper = ({children, pageIndex, overlays}) => {
	const ref = useRef();
	const [{canDrop, isOver}, drop] = useDrop(() => ({
		accept: 'signer',
		drop: (item, monitor) => ({
			pageIndex,
			rect: (() => {
				const rect = ref?.current?.getBoundingClientRect();
				const offset = monitor.getSourceClientOffset();
				return {
					x: offset.x - (rect?.x || 0),
					y: offset.y - (rect?.y || 0),
					height: (rect?.height || 0),
					width: (rect?.width || 0)
				}
			})()
		}),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));

	// wrapping our ref with the drop target
	// this way we can access ref.current. functions to calculate the bounding client rectangle
	const wrappedRef = el => {
		ref.current = el;
		drop(ref);
	};

	const isActive = canDrop && isOver;
	const border = isActive ? '1px dashed #76bde9' : '1px solid #EFEFEF';
	return <Box ref={wrappedRef} sx={{width: '100%', height: 'auto', border, position: 'relative', overflow: 'hidden', mb: 1}}>
		{children}
		{overlays.map((overlay, overlayIndex) =>
			<Box
				key={'overlay' + overlayIndex}
				sx={{
					position: 'absolute',
					left: (overlay.relativeLocationX * 100) + '%',
					top: (overlay.relativeLocationY * 100) + '%',
					width: (overlay.relativeWidth * 100) + '%',
					height: (overlay.relativeHeight * 100) + '%',
				}}
			>
				{cloneElement(overlay.component, { pageindex: pageIndex })}
			</Box>
		)}
	</Box>
}

const DETERMINE_WITH_FACTOR = (componentRef) => {
	const DEFAULT_WIDTH = 900;

	return !!componentRef ? Math.round(componentRef.current.offsetWidth/DEFAULT_WIDTH * 100) / 100 : 1;
}


class PdfViewerComponent extends Component {

	constructor(props) {
		super(props);

		if (props.apiRef) {
			props.apiRef.current = {
				onScrollToPage: (page) => {
					this.onScrollToPage(page);
				}
			}
		}

		this.state = {
			previews: [],
			page: 1,
			widthFactor: 1
		}

		this.resizeListener = null;
		this.pdfViewerRef = createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		this.refreshPreviews();

		if (!!this.props.onResize) {
			const widthFactor = DETERMINE_WITH_FACTOR(this.pdfViewerRef);
			this.setState({widthFactor}, () => this.props.onResize(widthFactor));

			this.resizeListener = () => {
				if (!!this.pdfViewerRef && !!this.props.onResize) {
					const widthFactor = DETERMINE_WITH_FACTOR(this.pdfViewerRef);
					if (Math.abs(this.state.widthFactor - widthFactor) > Number.EPSILON) {
						this.setState({widthFactor}, () => this.props.onResize(widthFactor));
					}
				}
			};
			window.addEventListener("resize", this.resizeListener);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;

		if (!!this.resizeListener) {
			window.removeEventListener("resize", this.resizeListener);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.pages !== prevProps.pages) {
			if (this.state.page > this.props.pages.length) {
				this.setState({page: this.props.pages.length})
			}
			this.refreshPreviews();
		}
	}

	refreshPreviews = () => {
		const somePlaceholdersNew = this.props.pages?.reduce((res, page) => res || !!page.placeholder, false);
		const somePlaceholdersCurrent = this.state.previews?.reduce((res, preview) => res || !!preview.placeholder, false);

		let previews;
		if (this.props.pages.length !== this.state.previews.length || somePlaceholdersCurrent != somePlaceholdersNew) {

			previews = this.props.pages.map(page => ({
				...page,
				available: !!page.placeholder, // if placeholder, we consider it available
				placeholder: page.placeholder,
			}));
		} else {
			previews = this.state.previews.map((preview, index) => ({
				...this.props.pages[index],
				available: !!preview.placeholder || ((preview.availableUrl === this.props.pages[index].availableUrl) ? preview.available : false),
				placeholder: preview.placeholder,
			}));
		}

		let index = 0;
		while (index < previews.length && previews[index].available) {
			++index;
		}
		if (index < previews.length) {
			if (!!this.checkToken) {
				this.checkToken.cancel = true;
			}
			this.checkToken = { cancel: false };
			this.setState({previews}, () => this.checkPreviewAvailability(index, this.checkToken));
		} else {
			this.setState({previews});
		}
	}

	checkPreviewAvailability = (index, checkToken) => {
		axios.get(this.state.previews[index].availableUrl).then(res => {
			if (checkToken.cancel) return;
			if (!this._isMounted) return;
			if (res.status === 204) {
				setTimeout(() => this.checkPreviewAvailability(index, checkToken), 1000);
			} else {
				const indexForStateUpdate = index;
				this.setState((prevState) => {
					const previews = [...prevState.previews];
					previews[indexForStateUpdate].available = true;
					return {previews};
				})
				++index;
				while (index < this.state.previews.length &&
				this.state.previews[index].available) {
					++index;
				}
				if (index < this.state.previews.length) {
					setTimeout(() => this.checkPreviewAvailability(index, checkToken), 20);
				}
			}
		})
	}

	render() {
		const availableCount = this.state.previews.reduce((count, page) => count + (page.available ? 1 : 0), 0);
		const pageCount = this.state.previews.length;
		const checking = (availableCount < pageCount);

		return <Paper
			ref={this.pdfViewerRef}
			variant="outlined"
			sx={{
				p: 2,
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				userSelect: 'none',
				...(this.props.limitHeight && {maxHeight: '100%'}),
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					...(!this.props.limitHeight && {
						position: 'sticky',
						top: '62px',
						zIndex: 10,
						backgroundColor: 'white'
					})
				}}
			>
				<Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
					{this.props.children}
				</Box>
				<Box sx={{display: {xs: 'none', sm: 'flex'}}}>
					<IconButton onClick={this.onScrollFirstPage} disabled={checking || this.state.page <= 1}>
						<FirstPageIcon/>
					</IconButton>
					<IconButton onClick={this.onScrollPrevious} disabled={checking || this.state.page <= 1}>
						<NavigateBeforeIcon/>
					</IconButton>
					<TextField
						value={this.state.page}
						onChange={this.onChangePage}
						onKeyDown={this.onPageKeyPress}
						autoComplete="off"
						size="small"
						disabled={checking}
						sx={{width: 150}}
						inputProps={{style: { textAlign: 'right' }}}
						InputProps={{
							endAdornment: <InputAdornment position="end" sx={{pt: '1px'}}>{' / ' + pageCount}</InputAdornment>
						}}
					/>
					<IconButton onClick={this.onScrollNext}
								disabled={checking || this.state.page >= pageCount}
					>
						<NavigateNextIcon/>
					</IconButton>
					<IconButton onClick={this.onScrollLastPage}
								disabled={checking || this.state.page >= pageCount}
					>
						<LastPageIcon/>
					</IconButton>
				</Box>
			</Box>
			{checking && <Box sx={{display: 'flex'}}>
				<LinearProgress variant={0 === availableCount ? 'indeterminate' : 'determinate'}
								value={100 * availableCount / pageCount}
								sx={{flexGrow: 1}}/>
			</Box>}
			<Box sx={{overflowY: 'auto'}}>
				{this.state.previews.map((preview, index) => {
					if (preview.placeholder) {
						return this.renderPlaceholder(preview, index);
					} else if (!preview.available || !!preview.generating) {
						return this.renderLoader(preview, index);
					} else {
						return this.renderPreview(preview, index);
					}
				})}
			</Box>
		</Paper>
	}

	renderPlaceholder = (preview, index) => {
		const relevantOverlays = (this.props?.overlays || []).filter((overlay) => overlay.pageIndex === '*' || overlay.pageIndex === index);
		return <DroppablePageWrapper key={index} pageIndex={index} overlays={relevantOverlays}>
			<Box id={'preview' + index} sx={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				backgroundColor: 'white',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
				{/*source: https://icons8.com/icon/set/excel*/}
				{preview.logo === 'excel' &&
					<Button onClick={this.props.onPagePlaceholderClicked}>
						<Logo style={{width: '240', minWidth: '240'}}/>
					</Button>
				}
				{!preview.logo && <Typography variant="h1"
											  sx={{color: '#F2F2F2'}}>{this.props.t('page') + ' ' + (index + 1)}</Typography>}
			</Box>
		</DroppablePageWrapper>
	}

	renderPreview = (preview, index) => {
		const relevantOverlays = (this.props?.overlays || []).filter((overlay) => overlay.pageIndex === '*' || overlay.pageIndex === index);
		return <DroppablePageWrapper key={index} pageIndex={index} overlays={relevantOverlays}>
			<img id={'preview' + index} alt={'preview page' + index} src={preview.imageUrl} style={{maxWidth: '100%'}}/>
		</DroppablePageWrapper>;
	}

	renderLoader = (preview, index) => {
		return <Box key={index} id={'preview' + index} sx={{
			width: '100%',
			height: '100vh',
			display: 'flex',
			backgroundColor: 'white',
			justifyContent: 'center',
			border: '1px solid #EFEFEF',
		}}>
			<LoadingComponent/>
		</Box>
	}

	onChangePage = (e) => {
		const page = parseInt(e.target.value);
		this.setState({page: !page ? 0 : page});
	}

	onPageKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.onScrollToPage(this.state.page);
		}
	}

	onScrollFirstPage = () => {
		this.onScrollToPage(1);
	}

	onScrollPrevious = () => {
		this.onScrollToPage(this.state.page - 1);
	}

	onScrollNext = () => {
		this.onScrollToPage(this.state.page + 1);
	}

	onScrollLastPage = () => {
		this.onScrollToPage(this.state.previews?.length || 0);
	}

	onScrollToPage = (page) => {
		const {previews} = this.state;
		const index = Math.min(Math.max(0, page - 1), previews?.length || 0);
		if (previews?.length > index && previews[index].available) {
			this.setState({page: index + 1}, () => {
				const el = document.getElementById('preview' + index);
				el.scrollIntoView();
			});
		}
	}

}

export default withTranslation()(PdfViewerComponent);
