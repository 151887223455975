import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../common/ConfirmationDialog";
import GroupSelectUserDialog from "../common/GroupSelectUserDialog";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: 10,
	sortModel: [{field: 'fullName', sort: 'asc'}],

	addDialogOpen: false,
	removeDialogOpen: false,
};

class SignerGroupUsersDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.onSignerGroupFetchUserList(this.props.signerGroupId, this.createPageRequest());
		}
	}

	render() {
		const userList = this.props.userList;
		const userCount = this.props.userCount;

		const userColumns = [
			{
				field: 'fullName',
				headerName: this.props.t('signerGroup.userName'),
				editable: false,
				flex: 1
			},
			{
				field: 'email',
				headerName: this.props.t('signerGroup.userEmail'),
				editable: false,
				flex: 1
			},
			{
				field: 'state',
				headerName: this.props.t('signerGroup.userState'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => this.props.t('user.state_' + cellValues.row.state, {folder: cellValues.row.contactFolderName})
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				align: 'center',
				renderCell: (cellValues) =>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('signerGroup.userRemove')}
						disabled={this.props.userListBusy}
						onClick={() => this.onRemoveDialogOpen(cellValues.row.id)}>
						<DeleteIcon/>
					</IconButton>
			},
		];


		return <>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					fullWidth
					maxWidth="md">
				<DialogTitle>{this.props.t('signerGroup.users')}</DialogTitle>
				<DialogContent>

					<Typography>{this.props.t('signerGroup.usersDescription')}</Typography>

					<Box>
						<DataGrid
							disableColumnSelector
							columns={userColumns}
							slots={{toolbar: FlexibleToolbar}}
							slotProps={{
								toolbar: {
									filterId: 'input-signer-group-user-search-text',
									filterValue: this.state.filterValue,
									onChangeFilterValue: this.onFilterValueChange,
								}
							}}

							disableColumnFilter
							disableRowSelectionOnClick

							pagination
							paginationMode="server"
							paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
							onPaginationModelChange={this.onPaginationModelChange}
							pageSizeOptions={[10]}

							sortingMode="server"
							sortModel={this.state.sortModel}
							onSortModelChange={this.onSortModelChange}

							loading={this.props.userListBusy}
							rows={userList}
							rowCount={userCount}
							density="compact"
							autoHeight

							sx={{mt: 2}}
						/>

					</Box>
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
						<Button
							variant="contained"
							color="secondary"
							onClick={this.onOpenAddDialog}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							id="btn-signergroup-add-user"
						>
							{this.props.t('signerGroup.userAdd')}
						</Button>
					</Box>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-signergroup-users-cancel">{this.props.t('close')}</Button>
				</DialogActions>
			</Dialog>

			<GroupSelectUserDialog
				open={this.state.addDialogOpen}
				onFetchUserList={this.props.onSignerGroupFetchPossibleUserList}
				userList={this.props.possibleUserList}
				userCount={this.props.possibleUserCount}
				busy={this.props.possibleUserListBusy}
				onClose={this.onCloseAddDialog}
				onSelect={this.onSignerGroupAddUsers}
				title={this.props.t('signerGroup.userSelect')}
				multiselect/>

			<ConfirmationDialog
				title={this.props.t('signerGroup.userRemove')}
				confirm={this.props.t('signerGroup.userRemoveConfirm')}
				open={this.state.removeDialogOpen}
				onClose={this.onRemoveDialogClose}
				onConfirm={this.onSignerGroupRemoveUser}/>
		</>
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onSignerGroupFetchUserList);
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onSignerGroupFetchUserList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onSignerGroupFetchUserList);
	}

	onOpenAddDialog = () => {
		this.setState({addDialogOpen: true});
	}

	onCloseAddDialog = () => {
		this.setState({addDialogOpen: false});
	}

	onRemoveDialogOpen = (userId) => {
		this.setState({activeUserId: userId, removeDialogOpen: true});
	}

	onSignerGroupRemoveUser = () => {
		this.setState({removeDialogOpen: false},
			this.props.onSignerGroupRemoveUser(this.props.signerGroupId, this.state.activeUserId, this.createPageRequest()))
		;
	}

	onRemoveDialogClose = () => {
		this.setState({removeDialogOpen: false});
	}

	onSignerGroupAddUsers = (users) => {
		const userIds = users.map(user => user.id);
		this.setState({addDialogOpen: false}, this.props.onSignerGroupAddUsers(this.props.signerGroupId , userIds, this.createPageRequest()));
	}

	onSignerGroupFetchUserList = () => {
		this.props.onSignerGroupFetchUserList(this.props.signerGroupId, this.createPageRequest());
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	createPageRequest = () => {
		return {
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		};
	}

}

export default withTranslation()(connect(
	state => {
		return {
			userList: state.signerGroup.userList,
			userCount: state.signerGroup.userCount,
			userListBusy: state.signerGroup.userListBusy,
			possibleUserList: state.signerGroup.possibleUserList,
			possibleUserCount: state.signerGroup.possibleUserCount,
			possibleUserListBusy: state.signerGroup.possibleUserListBusy,
		}
	},
	dispatch => {
		return {
			onSignerGroupFetchUserList: (signerGroupId, request) => {
				dispatch({
					type: 'SIGNERGROUP_FETCH_USER_LIST',
					signerGroupId,
					request
				});
			}, onSignerGroupRemoveUser: (signerGroupId, userId, pageRequest) => {
				dispatch({
					type: 'SIGNERGROUP_REMOVE_USER',
					signerGroupId,
					userId,
					pageRequest
				});
			}, onSignerGroupAddUsers: (signerGroupId, userIds, pageRequest) => {
				dispatch({
					type: 'SIGNERGROUP_ADD_USERS',
					signerGroupId,
					userIds,
					pageRequest
				});
			}, onSignerGroupFetchPossibleUserList: (request) => {
				dispatch({
					type: 'SIGNERGROUP_FETCH_POSSIBLE_USER_LIST',
					request
				});
			}
		}
	}
)(SignerGroupUsersDialog));