import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import FormattedTextField from "../common/FormattedTextField";

const defaultStateGen = (props) => ({
	name: props.formFieldValidator?.name || '',
	formFieldType: props.formFieldValidator?.formFieldType || 'TEXT',
	format: props.formFieldValidator?.format || '',
	testValue: '',
})

class CompanyFormFieldValidatorDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultStateGen(this.props)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultStateGen(this.props));
		}
	}

	render() {
		return <>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					fullWidth
					maxWidth="md"
			>
				<DialogTitle>{!this.props.formFieldValidator ? this.props.t('company.generalSettingsFormFieldValidatorAdd') : this.props.t('company.generalSettingsFormFieldValidatorEdit')}</DialogTitle>
				<DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('company.generalSettingsFormFieldValidatorName')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', alignItems: 'center'}}>
									<TextField
										variant="outlined"
										size="small"
										required
										autoFocus={true}
										value={this.state.name}
										onChange={this.onChangeName}
										disabled={this.props.formFieldValidator?.systemValidator}
										fullWidth
									/>
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('company.generalSettingsFormFieldValidatorType')}</StepLabel>
							<StepContent>
								<FormControl fullWidth size="small">
									<Select
										value={this.state.formFieldType}
										onChange={this.onChangeType}
										disabled={this.props.formFieldValidator?.systemValidator}
									>
										{['TEXT'].map(type =>
											<MenuItem key={type}
													  value={type}>{this.props.t('company.generalSettingsFormFieldValidatorType_' + type)}
											</MenuItem>)}
									</Select>
								</FormControl>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('company.generalSettingsFormFieldValidatorFormat')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', alignItems: 'center'}}>
									<TextField
										variant="outlined"
										size="small"
										required
										value={this.state.format}
										onChange={this.onChangeFormat}
										disabled={this.props.formFieldValidator?.systemValidator}
										fullWidth
										autoComplete="off"
										sx={{mb: 1}}
									/>
								</Box>
								<Alert severity="info">
									{this.props.t('company.generalSettingsFormFieldValidatorFormatDescription')}
								</Alert>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('company.generalSettingsFormFieldValidatorTest')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', alignItems: 'center'}}>
									<FormattedTextField
										variant="outlined"
										size="small"
										required
										value={this.state.testValue}
										onChange={this.onChangeTestValue}
										format={this.state.format}
										fullWidth
									/>
								</Box>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-form-field-validator-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							onClick={this.onConfirm}
							disabled={!this.canContinue()}
							id="btn-form-field-validator-confirm"
					>
						{this.props.t('confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	}

	canContinue = () => {
		return (!this.props.formFieldValidator?.systemValidator) && (!!this.state.name && !!this.state.formFieldType && !!this.state.format);
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeType = (e, value) => {
		this.setState({formFieldType: e.target.value});
	}

	onChangeFormat = (e) => {
		this.setState({format: e.target.value, testValue: ''});
	}

	onChangeTestValue = (e) => {
		this.setState({testValue: e.target.value});
	}

	onConfirm = () => {
		this.props.onConfirm({
			id: this.props.formFieldValidator?.id,
			referenceId: !!this.props.formFieldValidator ? null : uuidv4(),
			name: this.state.name,
			formFieldType: this.state.formFieldType,
			format: this.state.format
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(CompanyFormFieldValidatorDialog));
