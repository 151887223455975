import React, {Component} from "react";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField
} from "@mui/material";
import {withTranslation} from "react-i18next";
import EditorFieldStyleComponent from "./EditorFieldStyleComponent";
import {FORM_FIELD_ATTRIBUTE_TYPES, FORM_FIELD_DATE_VALUE_FORMAT, FORM_FIELD_DATE_VISUAL_FORMAT, FORM_FIELD_TYPES, FORM_FIELD_UNSET_VALUE} from "../common/Constants";
import {DatePicker} from "@mui/x-date-pickers";
import {format, parse} from "date-fns";
import FormattedTextField from "../common/FormattedTextField";

const defaultState = {
	id: null,
	type: null,
	attributeType: null,
	name: '',
	value: '',
	editable: true,
	required: false,
	validatorId: null,
}

class EditorFormFieldSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
				...this.props.field
			});
		}
	}

	render() {
		const isAttribute = 'ATTRIBUTE' === this.state.type;
		const validator = this.props.validators?.filter(validator => validator.id === this.state.validatorId)?.at(0);
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="sm">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('editor.formFieldTypeDescription')}</StepLabel>
						<StepContent>
							<Select
								value={this.state.type || ''}
								fullWidth
								onChange={this.onChangeType}
							>
								{FORM_FIELD_TYPES.map(type =>
									<MenuItem key={type} value={type}>
										{this.props.t('editor.formFieldType_' + type)}
									</MenuItem>
								)}
							</Select>
							{isAttribute && <Alert severity="warning" sx={{mt: 1}}>{this.props.t('editor.formFieldAttributeTypeUsage')}</Alert>}
						</StepContent>
					</Step>
					{isAttribute && <Step active>
						<StepLabel>{this.props.t('editor.formFieldAttributeTypeDescription')}</StepLabel>
						<StepContent>
							<Select
								value={this.state.attributeType || ''}
								fullWidth
								onChange={this.onChangeAttributeType}
							>
								{FORM_FIELD_ATTRIBUTE_TYPES.map(type =>
									<MenuItem key={type} value={type}>
										{this.props.t('editor.formFieldAttributeType_' + type)}
									</MenuItem>
								)}
							</Select>
						</StepContent>
					</Step>}
					<Step active>
						<StepLabel>{this.props.t('editor.formFieldNameDescription')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('editor.formFieldName')}
								required
								value={this.state.name || ''}
								autoComplete="off"
								fullWidth
								autoFocus
								onChange={this.onChangeName}
							/>
						</StepContent>
					</Step>
					{!isAttribute && <Step active>
						<StepLabel>{this.props.t('editor.formFieldValueDescription')}</StepLabel>
						<StepContent>
							{('TEXT' === this.state.type || 'NUMERIC' === this.state.type) &&
								<FormattedTextField
									variant="outlined"
									label={this.props.t('editor.formFieldValue')}
									required={!this.state.editable && !this.props.template}
									value={this.state.value || ''}
									format={validator?.format}
									autoComplete="off"
									fullWidth
									onChange={this.onChangeValue}
									type={'NUMERIC' === this.state.type ? 'number' : undefined}
								/>
							}
							{'CHECKBOX' === this.state.type &&
								<FormControlLabel
									control={<Checkbox checked={'true' === this.state.value}
													   onChange={this.onChangeValueCheckbox}/>}
									label={this.props.t('editor.formFieldValue')}
								/>}
							{'DATE' === this.state.type &&
								<DatePicker
									slotProps={{
										textField: {
											error: false,
											fullWidth: true,
											variant: 'outlined',
											autoComplete: 'off',
											inputProps: {
												placeholder: ''
											}
										}
									}}
									value={!!this.state.value ? parse(this.state.value, FORM_FIELD_DATE_VALUE_FORMAT, Date.now()) : null}
									onChange={this.onChangeDateValue}
									format={FORM_FIELD_DATE_VISUAL_FORMAT}
								/>}
						</StepContent>
					</Step>}
					<Step active>
						<StepLabel>{this.props.t('editor.formFieldOptions')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									disabled={isAttribute}
									control={<Checkbox checked={this.state.editable} onChange={this.onChangeEditable}/>}
									label={this.props.t('editor.formFieldEditable')}
								/>
							</Box>
							<Box>
								<FormControlLabel
									disabled={isAttribute}
									control={<Checkbox checked={this.state.required} onChange={this.onChangeRequired}/>}
									label={this.props.t('editor.formFieldRequired')}
								/>
							</Box>
							<Box>
								<FormControl fullWidth sx={{mt: 1}}>
									<InputLabel id="formfield-validator">{this.props.t('editor.formFieldValidator')}</InputLabel>
									<Select
										labelId="formfield-validator"
										value={this.state.validatorId || ''}
										fullWidth
										displayEmpty
										disabled={this.state.type !== 'TEXT'}
										onChange={this.onChangeValidator}
										label={this.props.t('editor.formFieldValidator')}
									>
										<MenuItem value="">&nbsp;</MenuItem>

										{this.props.validators?.filter(validator => validator.formFieldType === this.state.type).map(validator =>
											<MenuItem key={validator.id} value={validator.id}>
												{validator.name}
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</Box>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('editor.formFieldStyle')}</StepLabel>
						<StepContent>
							<EditorFieldStyleComponent style={this.state.style} onChangeStyle={this.onChangeStyle}/>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-confirmation-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={this.isConfirmButtonDisabled()} onClick={this.onConfirm}
						id="btn-confirmation-confirm">{this.props.t('confirm')}</Button>
			</DialogActions>
		</Dialog>
	}

	isConfirmButtonDisabled = () => {
		const isAttribute = 'ATTRIBUTE' === this.state.type;
		const validator = this.props.validators?.filter(validator => validator.id === this.state.validatorId)?.at(0);
		const validatorValid = !validator || validator.format.length === this.state.value?.length;

		// Remark: cannot really change the ID, but we do not want to fire a confirm event if the field is not initialized.
		return !this.state.id ||
			!this.state.type ||
			!this.state.name ||
			// no attributeType (if type match)
			(!this.state.attributeType && isAttribute) ||
			// no value/invalid value + exceptions
			((FORM_FIELD_UNSET_VALUE(this.state.value) || !validatorValid) && !this.state.editable && !this.props.template && !isAttribute);
	}

	onChangeType = (e) => {
		const type = e.target.value;
		this.setState({type, value: '', validatorId: null, ...('ATTRIBUTE' === type ? {editable: false, attributeType: 'FIRST_NAME', required: true} : {})});
	}

	onChangeAttributeType = (e) => {
		this.setState({attributeType: e.target.value});
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeValue = (e) => {
		const value = e.target.value;
		this.setState({value});
	}

	onChangeDateValue = (value) => {
		this.setState({value: !!value ? format(value, FORM_FIELD_DATE_VALUE_FORMAT) : null});
	}

	onChangeValueCheckbox = (e) => {
		this.setState({value: e.target.checked ? 'true' : 'false'});
	}

	onChangeEditable = (e) => {
		this.setState({editable: e.target.checked});
	}

	onChangeRequired = (e) => {
		this.setState({required: e.target.checked});
	}

	onChangeStyle = (style) => {
		this.setState({style});
	}

	onChangeValidator = (e) => {
		this.setState({validatorId: e.target.value, value: ''});
	}

	onConfirm = () => {
		this.props.onConfirm({...this.state});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(EditorFormFieldSettingsDialog);
