import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tab,
	Tabs,
	TextField,
	Typography
} from "@mui/material";
import {DEFAULT_APPROVE_EMAIL_HEADERS, DEFAULT_SIGN_EMAIL_HEADERS, EMAIL_LANGUAGES} from "../common/EmailConstants";

export const EditorEmailMessageDialog = ({open, onClose, onUpdate, forSigning, sessionInfo, customEmailHeaders}) => {

	const {t} = useTranslation();
	const [language, setLanguage] = useState('ENGLISH');
	const [localEmailHeaders, setLocalEmailHeaders] = useState({...customEmailHeaders});

	// reset localEmailHeaders when opening/closing dialog
	useEffect(() => {
		if (!open) {
			setLocalEmailHeaders({});
		} else if (open) {
			setLocalEmailHeaders(customEmailHeaders);
		}
	}, [open, setLocalEmailHeaders, customEmailHeaders]);

	const defaultEmailHeader = useMemo(
		() => ((forSigning ? DEFAULT_SIGN_EMAIL_HEADERS : DEFAULT_APPROVE_EMAIL_HEADERS)[language] || '')
			.replace('{0}', sessionInfo?.userFullName)
			.replace('{1}', sessionInfo?.companyName),
		[forSigning, language]
	);

	const emailHeader = useMemo(
		() => localEmailHeaders?.[language] || defaultEmailHeader,
		[localEmailHeaders, language, defaultEmailHeader]
	);

	const resetCurrentLangLabel = useMemo(
		() => t('editor.emailMessageResetCurrentLang').replace('$LANGUAGE', t('language.' + EMAIL_LANGUAGES.find(e => e.value === language)?.lang)),
		[language]
	);

	const onChangeLanguage = useCallback((e, value) => {
		setLanguage(value);
	}, [setLanguage]);

	const onChangeEmailHeader = useCallback((e) => {
		const update = { ...localEmailHeaders };
		update[language] = e.target.value;
		setLocalEmailHeaders(update);
	}, [localEmailHeaders, setLocalEmailHeaders, language]);

	const onConfirm = useCallback(() => {
		onUpdate(localEmailHeaders);
	}, [localEmailHeaders]);

	const onResetCurrentLanguage = useCallback((language) => {
		const update = { ...localEmailHeaders };
		delete update[language]
		setLocalEmailHeaders({});
	}, [setLocalEmailHeaders]);

	const onResetAll = useCallback(() => {
		setLocalEmailHeaders({});
	}, [localEmailHeaders, setLocalEmailHeaders]);

	const onLocalClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			onClose();
		}
	}

	return <Dialog open={open} onClose={onLocalClose} fullWidth maxWidth="md">
		<DialogTitle>
			{forSigning ?
				t('editor.emailMessageHeaderSigners')
				:
				t('editor.emailMessageHeaderApprovers')
			}
		</DialogTitle>
		<DialogContent>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center'}}>
				<Typography variant="body2">
					{forSigning ?
						t('editor.emailMessageDescriptionSigners')
						:
						t('editor.emailMessageDescriptionApprovers')
					}
				</Typography>
				<Tabs value={language}
					  onChange={onChangeLanguage}
					  variant="scrollable"
				>
					{
						EMAIL_LANGUAGES.map(entry =>
							<Tab key={entry.value} value={entry.value}
								 label={t('language.' + entry.lang)}/>
						)
					}
				</Tabs>
				<TextField
					multiline
					rows={5}
					value={emailHeader}
					onChange={onChangeEmailHeader}
					fullWidth
					size="small"
					sx={{mt: 2}}
					inputProps={{maxLength: 500, style: {fontFamily: 'monospace', fontSize: 'smaller'}}}
				/>
				<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<Typography variant="body2">{emailHeader.length + "/500"}</Typography>
				</Box>
				<Box sx={{display: 'flex', gap: 1, alignItems: 'centered'}}>
					<Button variant="contained"
							onClick={onResetCurrentLanguage}
							color="secondary"
							size="small"
							editor="btn-editor-mail-current-language-reset"
					>
						{resetCurrentLangLabel}
					</Button>
					<Button variant="contained"
							onClick={onResetAll}
							color="secondary"
							size="small"
							editor="btn-editor-mail-all-reset"
					>
						{t('editor.emailMessageResetAll')}
					</Button>
				</Box>
			</Box>
		</DialogContent>
		<DialogActions>
			<Button onClick={onLocalClose} id="btn-cancel">{t('back')}</Button>
			<Button onClick={onConfirm} variant="contained"
					id="btn-confirm">{t('confirm')}</Button>
		</DialogActions>
	</Dialog>

}