import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Checkbox, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import ServerErrorComponent from "../common/ServerErrorComponent";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";

class ApplicationCompanyOidcInstanceOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),

			sortModel: [{field: 'companyName', sort: 'asc'}],
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onOidcInstanceFetchCompanyOverviewList());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.actionSuccessFully && !prevProps.actionSuccessFully) {
			this.props.onOidcInstanceFetchCompanyOverviewList();
		}
	}

	render() {
		const dataGridColumns = [
			{
				field: 'companyName',
				headerName: this.props.t('oidcInstance.companyName'),
				editable: false,
				width: 200,
			},
			{
				field: 'name',
				headerName: this.props.t('oidcInstance.name'),
				editable: false,
				width: 200,
			},
			{
				field: 'serverUrl',
				headerName: this.props.t('oidcInstance.serverUrl'),
				editable: false,
				flex: 1,
			},
			{
				field: 'clientId',
				headerName: this.props.t('oidcInstance.clientId'),
				editable: false,
				flex: 1,
			},
			{
				field: 'clientSecretLength',
				headerName: this.props.t('oidcInstance.clientSecret'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => (<span>{'*'.repeat(cellValues.row.clientSecretLength)}</span>)
			},
			{
				field: 'autoCreateAllowed',
				headerName: this.props.t('oidcInstance.autoCreate'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.autoCreateAllowed}
						disabled={true}
					/>
			},
		];

		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('oidcInstance.companyOverview')}</Typography>
			<ServerErrorComponent serverError={this.props.serverError} />
			<Box sx={{mt: 2, ml: 2}}>
				<Typography sx={{mb: 1}}>{this.props.t('oidcInstance.companyOverviewDescription')}</Typography>
				<DataGrid
					disableColumnSelector
					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}
					disableColumnFilter
					disableRowSelectionOnClick

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: 10}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={[10]}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					rows={this.props?.overviewList || []}
					rowCount={this.props.overviewCount}
					loading={this.props.busy}
					density="compact"
					autoHeight
				/>
			</Box>
		</Box>
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onOidcInstanceFetchCompanyOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onOidcInstanceFetchCompanyOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onOidcInstanceFetchCompanyOverviewList);
	}

	onOidcInstanceFetchCompanyOverviewList = () => {
		this.props.onOidcInstanceFetchCompanyOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			busy: state.oidcInstance.busy,
			serverError: state.oidcInstance.serverError,
			overviewList: state.oidcInstance.overviewList,
			overviewCount: state.oidcInstance.overviewCount,
		}
	},
	dispatch => {
		return {
			onOidcInstanceFetchCompanyOverviewList: (request) => {
				dispatch({
					type: 'APPLICATION_OIDC_INSTANCE_COMPANY_FETCH_LIST',
					request
				})
			},
		}
	}
)(ApplicationCompanyOidcInstanceOverviewComponent));
