import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";

class SignerGroupSettingsDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onSignerGroupFetchSettings(this.props.signerGroupId);
		}
	}

	render() {
		const signerGroupSettings = this.props.signerGroupSettings;

		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="md">
				<DialogTitle>{this.props.t('signerGroup.settings')}</DialogTitle>
				{!signerGroupSettings && <DialogContent><LoadingComponent/></DialogContent>}
				{signerGroupSettings && <DialogContent>
					<Typography>{signerGroupSettings.name}</Typography>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('signerGroup.settingsNameDescription')}</StepLabel>
							<StepContent>
								<Box id="input-signergroup-general-text-group">
									<TextField
										variant="outlined"
										label={this.props.t('signerGroup.name')}
										required
										value={signerGroupSettings.name}
										onChange={this.onChangeName}
										autoComplete="off"
										fullWidth
										autoFocus
										sx={{mb: 2}}
									/>
									<TextField
										variant="outlined"
										label={this.props.t('signerGroup.description')}
										value={signerGroupSettings.description || ''}
										onChange={this.onChangeDescription}
										autoComplete="off"
										fullWidth
									/>
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('signerGroup.settingsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('signerGroup.settingsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>}

				<DialogActions>
					<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							disabled={!signerGroupSettings || !signerGroupSettings.name?.trim()}
							onClick={this.onUpdate}
							id="btn-settings-confirm"
					>
						{this.props.t('signerGroup.settingsSave')}
					</Button>
				</DialogActions>
			</Dialog>

		</Fragment>
	}

	onChangeName = (e) => {
		this.props.onSignerGroupChangeSetting('name', e.target.value);
	}

	onChangeDescription = (e) => {
		this.props.onSignerGroupChangeSetting('description', e.target.value);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		this.props.onSignerGroupUpdateSettings(this.props.signerGroupSettings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			signerGroupSettings: state.signerGroup.settings,
		}
	},
	dispatch => {
		return {
			onSignerGroupFetchSettings: (signerGroupId) => {
				dispatch({
					type: 'SIGNERGROUP_FETCH_SETTINGS',
					signerGroupId
				});
			}, onSignerGroupChangeSetting: (key, value) => {
				dispatch({
					type: 'SIGNERGROUP_CHANGE_SETTING',
					key,
					value
				});
			}
		}
	}
)(SignerGroupSettingsDialog));
