import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {addWeeks, format, isValid, parseISO, startOfHour} from "date-fns";
import {Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {PatternFormat} from "react-number-format";
import {DateTimePicker} from "@mui/x-date-pickers";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import LoadingComponent from "../common/LoadingComponent";
import VisualSignatureOrParaphComponent from "../common/VisualSignatureOrParaphComponent";
import FlexibleToolbar from "../common/FlexibleToolbar";
import UserProfileCapacityDialog from "./UserProfileCapacityDialog";
import {withRouter} from "react-router";
import UserNotificationSettingComponent from "./UserNotificationSettingComponent";

const DATETIME_FORMAT = "yyyy-LL-dd'T'HH:mm";

class UserProfileSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filterValue: '',
			filterValueUC: '',

			capacityDialogOpen: false,
			capacityCurrent: null,
		}
	}

	componentDidMount() {
		this.props.onFetchUserProfile();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		if (!this.props.profile) {
			return <LoadingComponent/>;
		}

		if (this.props.sessionInfo.guestAccess) {
			return this.renderGuestAccessProfile();
		} else {
			return this.renderRegisteredUserProfile();
		}
	}

	renderGuestAccessProfile() {
		const { profile } = this.props;

		return <Box sx={{mt: 2}}>
			<Typography variant="h6">{this.props.t('user.myProfileHeader')}</Typography>
			<Box sx={{mt: 2}}>
				<Typography>{this.props.t('user.profileGuestSaveWarning')}</Typography>
			</Box>


			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('user.profileGeneralHeader')}</Typography>
				<Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2}}>
					<FormControl sx={{flex: '1 0 200px', maxWidth: '250px'}}>
						<InputLabel
							id="profile-language">{this.props.t('user.profileGeneralLanguage')}</InputLabel>
						<Select
							labelId="profile-language"
							label={this.props.t('user.profileGeneralLanguage')}
							value={profile.language}
							onChange={this.onChangeProfileLanguage}
						>
							{['nl', 'fr', 'en'].map(value => <MenuItem key={value}
																	   value={value}>{this.props.t('language.' + value)}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>
			</Box>


			<Box>
				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('user.profileVisualData')}</Typography>
				<Box sx={{mt: 1}}>
					<VisualSignatureOrParaphComponent type="signature"
													  disabled={this.props.profileBusy}
													  visualSignature={this.props.sessionInfo?.visualSignature}
													  onChangeVisualSignature={this.props.onSessionUpdateVisualSignature}/>
				</Box>
				<Box sx={{mt: 1}}>
					<VisualSignatureOrParaphComponent type="paraph"
													  disabled={this.props.profileBusy}
													  visualSignature={this.props.sessionInfo?.visualParaph}
													  onChangeVisualSignature={this.props.onSessionUpdateVisualParaph}/>
				</Box>
			</Box>


			{!!profile.itsmeUserCode && <Box sx={{mt: 2}}>
				<Typography>{this.props.t('user.profileItsmeInfo')}</Typography>
				<Button
					sx={{mt: 1}}
					color="secondary"
					variant="contained"
					onClick={this.props.onSessionForgetItsmeIdentity}
					disabled={this.props.profileBusy}
					id="btn-profile-itsme-forget"
				>
					{this.props.t('user.profileItsmeForget')}
				</Button>
			</Box>}

			<Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1}}>
				<Box sx={{mt: 2}}>
					<Button
						variant="contained"
						onClick={this.onBack}
						id="btn-settings-back"
					>
						{this.props.t('back')}
					</Button>
				</Box>

				<Box sx={{mt: 2}}>
					<Button
						variant="contained"
						onClick={this.onSave}
						startIcon={<SaveIcon/>}
						disabled={!this.canContinue()}
						id="btn-settings-save"
					>
						{this.props.t('save')}
					</Button>
				</Box>
			</Box>
		</Box>
	}


	renderRegisteredUserProfile() {
		const { profile } = this.props;

		const capacityColumns = [
			{
				field: 'title',
				headerName: this.props.t('user.profileCapacityTitle'),
				editable: false,
				flex: 1
			},
			{
				field: 'remark',
				headerName: this.props.t('user.profileCapacityRemark'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				headerName: this.props.t('user.profileCapacityActions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 120,
				renderCell: (cellValues) => <>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('user.profileCapacityUpdate')}
						onClick={() => this.onCapacityEdit(cellValues.row)}
					>
						<EditIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('user.profileCapacityDelete')}
						disabled={cellValues.row.usageCount > 0}
						onClick={() => this.onCapacityDelete(cellValues.row)}
					>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</>
			},
		];

		return <Box sx={{mt: 2}}>
			<Typography variant="h6">{this.props.t('user.myProfileHeader')}</Typography>
			<Box sx={{mt: 2}}>
				<Typography>{this.props.t('user.profileSaveWarning')}</Typography>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('user.profilePersonalHeader')}</Typography>
				<Box sx={{mt: 2}} id="input-user-profile-general-text-group">
					<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2}}>
						<TextField
							variant="outlined"
							label={this.props.t('user.profilePersonalFirstName')}
							required
							value={profile.firstName}
							onChange={this.onChangeProfileFirstName}
							disabled={this.props.profileBusy}
							autoComplete="off"
							helperText={profile.firstName === '' ? this.props.t('changes.required') : ''}
							error={profile.firstName === ''}
							sx={{flex: '1 0 300px'}}
							fullWidth
						/>
						<TextField
							variant="outlined"
							label={this.props.t('user.profilePersonalLastName')}
							required
							value={profile.lastName}
							onChange={this.onChangeProfileLastName}
							disabled={this.props.profileBusy}
							autoComplete="off"
							helperText={profile.lastName === '' ? this.props.t('changes.required') : ''}
							error={profile.lastName === ''}
							sx={{flex: '1 0 300px'}}
							fullWidth
						/>
						<TextField
							variant="outlined"
							sx={{flex: '1 0 150px'}}
							label={this.props.t('user.profilePersonalMemo')}
							value={profile.memo || ''}
							onChange={this.onChangeProfileMemo}
							disabled={this.props.profileBusy}
							autoComplete="off"
							fullWidth
						/>
					</Box>
					<Box sx={{mt: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2}}>
						<TextField
							variant="outlined"
							label={this.props.t('user.profilePersonalEmail')}
							value={profile.email}
							disabled
							autoComplete="off"
							sx={{flex: '2 0 300px'}}
							fullWidth
						/>
						<PatternFormat
							format="###-#######-##"
							mask="_"
							variant="outlined"
							customInput={TextField}
							label={this.props.t('user.profilePersonalEidCardNumber')}
							defaultValue={profile.eidCardNumber || ''}
							onValueChange={({value: v}, sourceInfo) => {
								if (sourceInfo.source === 'event') {
									this.onChangeEidCardNumber({
										target: {
											name,
											value: v
										}
									});
								}
							}}
							disabled={this.props.profileBusy}
							autoComplete="off"
							sx={{flex: '1 0 300px'}}
							fullWidth
						/>
					</Box>
					<Box sx={{mt: 2, display: 'flex', alignItems: 'center'}}>
						<TextField
							variant="outlined"
							label={this.props.t('user.profilePersonalDefaultRemarkVisualSignature')}
							value={profile.defaultRemarkVisualSignature || ''}
							onChange={this.onChangeProfileDefaultRemarkVisualSignature}
							disabled={this.props.profileBusy}
							autoComplete="off"
							sx={{flex: '1 0 300px'}}
							fullWidth
						/>
					</Box>
				</Box>
			</Box>

			<Box>
				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('user.profileVisualData')}</Typography>
				<Box sx={{mt: 1}}>
					<VisualSignatureOrParaphComponent type="signature"
													  disabled={this.props.profileBusy}
													  visualSignature={this.props.sessionInfo?.visualSignature}
													  onChangeVisualSignature={this.props.onSessionUpdateVisualSignature}/>
				</Box>
				<Box sx={{mt: 1}}>
					<VisualSignatureOrParaphComponent type="paraph"
													  disabled={this.props.profileBusy}
													  visualSignature={this.props.sessionInfo?.visualParaph}
													  onChangeVisualSignature={this.props.onSessionUpdateVisualParaph}/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('user.profileCapacities')}</Typography>
				<Box sx={{mt: 1}}>
					<DataGrid
						autoHeight
						disableColumnSelector
						disableColumnFilter
						disableRowSelectionOnClick
						pagination

						initialState={{
							pagination: {
								paginationModel: { pageSize: 10, page: 0 },
							},
						}}

						columns={capacityColumns}
						slots={{toolbar: FlexibleToolbar}}
						slotProps={{
							toolbar: {
								filterId: 'input-select-capacity-search-text',
								filterValue: this.state.filterValue,
								onChangeFilterValue: this.onFilterValueChange,
							}
						}}

						getRowId={(row) => row.title}
						rows={profile.capacities.filter(capacity =>
							capacity.title.toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
							capacity.remark.toUpperCase().indexOf(this.state.filterValueUC) >= 0)}
						pageSizeOptions={[10]}
						density="compact"
					/>
				</Box>
				<Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.onOpenCapacityDialog}
						startIcon={<AddIcon/>}
						id="btn-user-capacity-add"
					>
						{this.props.t('user.profileCapacityAdd')}
					</Button>
				</Box>
			</Box>

			{!!profile.itsmeUserCode && <Box sx={{mt: 2}}>
				<Typography>{this.props.t('user.profileItsmeInfo')}</Typography>
				<Button
					sx={{mt: 1}}
					color="secondary"
					variant="contained"
					onClick={this.props.onSessionForgetItsmeIdentity}
					disabled={this.props.profileBusy}
					id="btn-profile-itsme-forget"
				>
					{this.props.t('user.profileItsmeForget')}
				</Button>
			</Box>}

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('user.profileGeneralHeader')}</Typography>
				<Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2}}>
					<FormControl disabled={this.props.profileBusy} sx={{flex: '1 0 200px', maxWidth: '250px'}}>
						<InputLabel id="label-rows-per-page">{this.props.t('user.profileGeneralDefaultRowsPerPage')}</InputLabel>
						<Select
							labelId="label-rows-per-page"
							value={ROWS_PER_PAGE_SELECT(profile.defaultRowsPerTablePage)}
							label={this.props.t('user.profileGeneralDefaultRowsPerPage')}
							onChange={this.onChangeProfileDefaultRowsPerTablePage}
						>
							{ROWS_PER_PAGE_OPTIONS.map(option =>
								<MenuItem key={option} value={option}>{option}</MenuItem>
							)}
						</Select>
					</FormControl>
					<FormControl sx={{flex: '1 0 200px', maxWidth: '250px'}}>
						<InputLabel
							id="profile-language">{this.props.t('user.profileGeneralLanguage')}</InputLabel>
						<Select
							labelId="profile-language"
							label={this.props.t('user.profileGeneralLanguage')}
							value={profile.language}
							onChange={this.onChangeProfileLanguage}
						>
							{['nl', 'fr', 'en'].map(value => <MenuItem key={value}
																	   value={value}>{this.props.t('language.' + value)}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>
			</Box>
			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('user.profileOutOfOfficeHeader')}</Typography>
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<FormControl>
						<RadioGroup
							value={profile.selectedOutOfOfficeStatus}
							onChange={this.onChangeProfileOutOfOfficeStatus}
						>
							<FormControlLabel value="NOT_ACTIVE" control={<Radio/>}
											  label={this.props.t('user.profileOutOfOfficeNotActive')}/>
							<FormControlLabel value="ALWAYS_ACTIVE" control={<Radio/>}
											  label={this.props.t('user.profileOutOfOfficeAlwaysActive')}/>
							<FormControlLabel value="CONFIGURED_ACTIVE" control={<Radio/>}
											  label={this.props.t('user.profileOutOfOfficeConfiguredActive')}>
							</FormControlLabel>
						</RadioGroup>
					</FormControl>
				</Box>

				<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2}}>
					<DateTimePicker
						slotProps={{
							textField: {
								sx: {flex: '1 0 200px', maxWidth: '250px'},
								variant: 'outlined',
								autoComplete: 'off',
								inputProps: {
									placeholder: this.props.t('locale.datetimeFormat')
								}
							}
						}}
						label={this.props.t('user.profileOutOfOfficeConfiguredActiveFrom')}
						value={!!profile.outOfOfficeFrom ? parseISO(profile.outOfOfficeFrom) : null}
						onChange={this.onChangeProfileOutOfOfficeConfiguredActiveFrom}
						format={"dd/LL/yyyy HH:mm"}
						minutesStep={15}
						ampm={false}
						disabled={this.props.profileBusy || 'CONFIGURED_ACTIVE' !== profile.selectedOutOfOfficeStatus}
					/>
					<DateTimePicker
						slotProps={{
							textField: {
								variant: 'outlined',
								sx: {flex: '1 0 200px', maxWidth: '250px'},
								autoComplete: 'off',
								inputProps: {
									placeholder: this.props.t('locale.datetimeFormat')
								}
							}
						}}
						label={this.props.t('user.profileOutOfOfficeConfiguredActiveUntil')}
						value={!!this.props.profile.outOfOfficeUntil ? parseISO(this.props.profile.outOfOfficeUntil) : null}
						onChange={this.onChangeProfileOutOfOfficeConfiguredActiveUntil}
						format={"dd/LL/yyyy HH:mm"}
						minutesStep={15}

						ampm={false}
						disabled={this.props.profileBusy || 'CONFIGURED_ACTIVE' !== profile.selectedOutOfOfficeStatus}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('user.profileCommunicationHeader')}</Typography>
				<Typography sx={{mt: 1}}>{this.props.t('user.profileCommunicationSubHeader')}</Typography>
				<Box sx={{mt: 1}}>
					<UserNotificationSettingComponent value={profile.emailInviteNotification}
													  onChange={this.onChangeProfileEmailInvite}
													  label={this.props.t('user.profileCommunicationEmailInvite')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
					<UserNotificationSettingComponent value={profile.emailReminderNotification}
													  onChange={this.onChangeProfileEmailReminder}
													  label={this.props.t('user.profileCommunicationEmailReminder')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
					<UserNotificationSettingComponent value={profile.emailApprovalNotification}
													  onChange={this.onChangeProfileEmailApproval}
													  label={this.props.t('user.profileCommunicationEmailApproval')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
					<UserNotificationSettingComponent value={profile.emailFullySignedNotification}
													  onChange={this.onChangeProfileEmailFullySigned}
													  label={this.props.t('user.profileCommunicationEmailFullySigned')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
					<UserNotificationSettingComponent value={profile.emailSignaturePlacedNotification}
													  onChange={this.onChangeProfileEmailSignaturePlaced}
													  label={this.props.t('user.profileCommunicationEmailSignaturePlaced')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
					<UserNotificationSettingComponent value={profile.emailClosedNotification}
													  onChange={this.onChangeProfileEmailClosed}
													  label={this.props.t('user.profileCommunicationEmailClosed')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
					<UserNotificationSettingComponent value={profile.emailExpiredNotification}
													  onChange={this.onChangeProfileEmailExpired}
													  label={this.props.t('user.profileCommunicationEmailExpired')}
													  disabled={this.props.profileBusy}
					></UserNotificationSettingComponent>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Button
					variant="contained"
					onClick={this.onSave}
					startIcon={<SaveIcon/>}
					disabled={!this.canContinue() || !this.props.sessionPendingChanges}
					id="btn-settings-save"
				>
					{this.props.t('save')}
				</Button>
			</Box>

			<UserProfileCapacityDialog
				open={this.state.capacityDialogOpen}
				current={this.state.capacityCurrent}
				onClose={this.onCloseCapacityDialog}
				onChange={this.onCapacityChange}
			/>
		</Box>
	}

	onChangeProfileFirstName = (e) => {
		this.props.onChangeUserProfileData('firstName', e.target.value);
	}

	onChangeProfileLastName = (e) => {
		this.props.onChangeUserProfileData('lastName', e.target.value);
	}

	onChangeProfileMemo = (e) => {
		this.props.onChangeUserProfileData('memo', e.target.value);
	}

	onChangeEidCardNumber = (e) => {
		this.props.onChangeUserProfileData('eidCardNumber', e.target.value);
	}

	onChangeProfileDefaultRemarkVisualSignature = (e) => {
		this.props.onChangeUserProfileData('defaultRemarkVisualSignature', e.target.value);
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onOpenCapacityDialog = () => {
		this.setState({capacityCurrent: null, capacityDialogOpen: true});
	}

	onCloseCapacityDialog = () => {
		this.setState({capacityDialogOpen: false});
	}

	onCapacityChange = (capacity) => {
		const capacities = [...this.props.profile.capacities];
		if (!!capacity.id) {
			// existing capacity
			const index = capacities.findIndex(search => search.id === capacity.id);
			if (index >= 0) {
				// make sure the title isn't already present
				const otherIndex = capacities.findIndex(search =>
					(search.id !== capacity.id &&
						search.title.toLowerCase() === capacity.title.toLowerCase()));
				if (otherIndex === -1) {
					capacities[index] = capacity;
					this.props.onChangeUserProfileData('capacities', capacities);
				}
			}
		} else {
			// new
			const index = capacities.findIndex(search =>
				search.title.toLowerCase() === capacity.title.toLowerCase());
			if (index >= 0) {
				capacities[index] = capacity;
			} else {
				capacities.push(capacity);
			}
			this.props.onChangeUserProfileData('capacities', capacities);
		}
		this.setState({capacityDialogOpen: false});
	}

	onCapacityEdit = (capacity) => {
		this.setState({capacityCurrent: capacity, capacityDialogOpen: true});
	}

	onCapacityDelete = (capacity) => {
		const capacities = [...this.props.profile.capacities];
		const index = capacities.findIndex(search =>
			search.title.toLowerCase() === capacity.title.toLowerCase());
		if (index >= 0) {
			capacities.splice(index, 1);
			this.props.onChangeUserProfileData('capacities', capacities);
		}
	}

	onChangeProfileDefaultRowsPerTablePage = (e) => {
		this.props.onChangeUserProfileData('defaultRowsPerTablePage', e.target.value);
	}

	onChangeProfileLanguage = (e) => {
		this.props.onChangeUserProfileData('language', e.target.value);
	}

	onChangeProfileOutOfOfficeStatus = (e) => {
		this.props.onChangeUserProfileData('selectedOutOfOfficeStatus', e.target.value);
		if (e.target.value !== 'CONFIGURED_ACTIVE') {
			this.props.onChangeUserProfileData('outOfOfficeFrom', null);
			this.props.onChangeUserProfileData('outOfOfficeUntil', null);
		} else {
			let date = startOfHour(new Date());
			this.props.onChangeUserProfileData('outOfOfficeFrom', this.props.profile.outOfOfficeFrom || format(date, DATETIME_FORMAT));
			this.props.onChangeUserProfileData('outOfOfficeUntil', this.props.profile.outOfOfficeUntil || format(addWeeks(date, 1), DATETIME_FORMAT));
		}
	}

	onChangeProfileOutOfOfficeConfiguredActiveFrom = (date) => {
		let formattedDate;
		if (!!date && isValid(date)) {
			formattedDate = format(date, DATETIME_FORMAT);
		}
		this.props.onChangeUserProfileData('outOfOfficeFrom', formattedDate);
	}

	onChangeProfileOutOfOfficeConfiguredActiveUntil = (date) => {
		let formattedDate;
		if (!!date && isValid(date)) {
			formattedDate = format(date, DATETIME_FORMAT);
		}
		this.props.onChangeUserProfileData('outOfOfficeUntil', formattedDate);
	}

	onChangeProfileEmailInvite = (value) => {
		this.props.onChangeUserProfileData('emailInviteNotification', value);
	}

	onChangeProfileEmailReminder = (value) => {
		this.props.onChangeUserProfileData('emailReminderNotification', value);
	}

	onChangeProfileEmailApproval = (value) => {
		this.props.onChangeUserProfileData('emailApprovalNotification', value);
	}

	onChangeProfileEmailFullySigned = (value) => {
		this.props.onChangeUserProfileData('emailFullySignedNotification', value);
	}

	onChangeProfileEmailSignaturePlaced = (value) => {
		this.props.onChangeUserProfileData('emailSignaturePlacedNotification', value);
	}

	onChangeProfileEmailClosed = (value) => {
		this.props.onChangeUserProfileData('emailClosedNotification', value);
	}

	onChangeProfileEmailExpired = (value) => {
		this.props.onChangeUserProfileData('emailExpiredNotification', value);
	}

	canContinue = () => {
		return !this.props.profileBusy
			&& this.props.profile.firstName
			&& this.props.profile.lastName
			&& (this.props.profile.selectedOutOfOfficeStatus !== 'CONFIGURED_ACTIVE' ||
				(!!this.props.profile.outOfOfficeFrom && !!this.props.profile.outOfOfficeUntil));
	}

	onBack = () => {
		// go back to the guest access URL
		this.props.history.goBack();
	}

	onSave = () => {
		this.props.onUpdateUserProfile(this.props.profile);
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionPendingChanges: state.session.pendingChanges,

			profileBusy: state.session.busy,
			profile: state.session.profile,
		}
	},
	dispatch => {
		return {
			onFetchUserProfile: () => {
				dispatch({
					type: 'SESSION_FETCH_USER_PROFILE'
				});
			},
			onChangeUserProfileData: (key, value) => {
				dispatch({
					type: 'SESSION_UPDATE_USER_PROFILE_DATA',
					key: key,
					value: value
				});
			},
			onUpdateUserProfile: (profile) => {
				dispatch({
					type: 'SESSION_UPDATE_USER_PROFILE',
					request: profile
				});
			},
			onSessionForgetItsmeIdentity: () => {
				dispatch({
					type: 'SESSION_FORGET_ITSME_IDENTITY',
				})
			},
			onSessionUpdateVisualSignature: (visualSignature, mimeType, fileSize) => {
				dispatch({
					type: 'SESSION_UPDATE_VISUAL_SIGNATURE',
					visualSignature,
					mimeType,
					fileSize
				});
			},
			onSessionUpdateVisualParaph: (visualParaph, mimeType) => {
				dispatch({
					type: 'SESSION_UPDATE_VISUAL_PARAPH',
					visualParaph,
					mimeType
				});
			},
		}
	}
)(UserProfileSettingsComponent)));