import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Paper, Tab, Tabs, Typography} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import ServerErrorComponent from "../common/ServerErrorComponent";
import SftpConnectorComponent from "../connectors/SftpConnectorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ConnectorInstanceCreateDialog from "../connectors/ConnectorInstanceCreateDialog";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ConnectorInventoryGridComponent from "../connectors/ConnectorInventoryGridComponent";
import SharepointConnectorComponent from "../connectors/SharepointConnectorComponent";
import GoogleDriveConnectorComponent from "../connectors/GoogleDriveConnectorComponent";

const containsNonBlankValueForKey = (config, key) => {
	return config.some(entry => entry.key === key && entry.value?.trim().length > 0);
}

class CompanyConnectorSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			createDialogOpen: false,
			deleteDialogOpen: false,
			tab: (this.props.activeInstanceTypes?.length > 0) ? this.props.activeInstanceTypes[0] : null,
			testResult: null,
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchConnectorInstanceList();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.instanceActionSuccessFully && !prevProps.instanceActionSuccessFully) {
			this.props.onCompanyFetchConnectorInstanceList();
		}
		if ((!this.state.tab && this.props.activeInstanceTypes?.length > 0) ||
			this.props.activeInstanceTypes?.length !== prevProps.activeInstanceTypes?.length) {
			this.setState({tab: this.props.activeInstanceTypes[0]});
		}
		if (!prevProps.testResult && this.props.testResult) {
			this.setState({testResult: this.props.testResult});
		}
	}

	render() {
		const instanceList = this.props.instanceList;
		const selectedInstance = instanceList?.find(instance => instance.type === this.state.tab);

		if (!instanceList) {
			return <LoadingComponent/>;
		}

		return <Box sx={{mt: 2}}>
			<Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1, mb: 1, mr: 2}}>
				<Typography
					variant="h6">{this.props.t('connector.connectorsFor') + ' ' + this.props.sessionInfo.companyName}
				</Typography>
				<Button variant="contained"
						onClick={this.onOpenCreateDialog}
						startIcon={<AddIcon/>}
						sx={{justifyContent: 'flex-end', ml: 1}}
						disabled={this.props.busy}
						id="btn-connector-instance-create"
				>
					{this.props.t('connector.add')}
				</Button>
			</Box>

			<ServerErrorComponent serverError={this.props.serverError}/>

			<Box sx={{mt: 2, mr: 2}}>
				{this.props.activeInstanceTypes?.length > 0 && <Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
					{this.state.tab && <Fragment>
						<Tabs value={this.state.tab} onChange={this.onChangeTab}>
							{this.props.activeInstanceTypes.map(tab => <Tab
								key={tab}
								value={tab}
								label={<Box sx={{display: 'flex', alignItems: 'center'}}>
									{!this.hasValidConfig(tab) && <ReportProblemIcon color="error"/>}
									{this.props.t('connector.connectorType_' + tab)}
								</Box>}
							/>)}
						</Tabs>
						<Box sx={{mb: 2}}>
							{this.renderCurrentTab(selectedInstance)}
						</Box>
						<Box>
							{this.renderInventoryGrid(selectedInstance)}
						</Box>
						<Box sx={{mt: 2}}>
							<Button variant="contained" onClick={this.onOpenDeleteDialog} disabled={this.props.busy}
									startIcon={<DeleteIcon/>}
									id="btn-instance-delete">{this.props.t('connector.delete')}</Button>
						</Box>
						<ConfirmationDialog
							title={this.props.t('connector.delete')}
							confirm={this.props.t('connector.deleteConfirm')}
							open={this.state.deleteDialogOpen}
							onClose={this.onCloseDeleteDialog}
							onConfirm={this.onConfirmDeletion}
						/>
					</Fragment>}
				</Paper>}

				<ConnectorInstanceCreateDialog
					open={this.state.createDialogOpen}
					onClose={this.onCloseCreateDialog}
					onSave={this.onCreateConnectorInstance}
				/>

			</Box>
		</Box>
	}

	hasValidConfig = (instanceType) => {
		const config = this.props.instanceList?.find(instance => instance.type === instanceType)?.config || [];
		switch (instanceType) {
			case 'SFTP':
				return containsNonBlankValueForKey(config, 'host') &&
					containsNonBlankValueForKey(config, 'port') &&
					containsNonBlankValueForKey(config, 'user') &&
					containsNonBlankValueForKey(config, 'password');
			case 'SHAREPOINT':
				return containsNonBlankValueForKey(config, 'tenant') &&
					containsNonBlankValueForKey(config, 'clientId') &&
					containsNonBlankValueForKey(config, 'clientSecret');
			case 'GOOGLE_DRIVE':
				return containsNonBlankValueForKey(config, 'serviceAccountJson');
			default:
				return true;
		}
	}

	onOpenCreateDialog = () => {
		this.setState({createDialogOpen: true});
	}

	onCloseCreateDialog = () => {
		this.setState({createDialogOpen: false});
	}

	onCreateConnectorInstance = (connectorType) => {
		this.setState({createDialogOpen: false}, () => this.props.onCompanyCreateConnectorInstance(connectorType));
	}

	renderCurrentTab = (instance) => {
		if (instance) {
			switch (this.state.tab) {
				case 'SFTP':
					return <SftpConnectorComponent instance={instance}
												   busy={this.props.busy}
												   onChangeConnectorInstanceConfig={this.onChangeConnectorInstanceConfig}
												   onUpdateConnectorInstanceConfig={this.onUpdateConnectorInstanceConfig}
												   shouldSave={this.props.shouldSave}
												   hasValidConfig={this.hasValidConfig(instance.type)}
												   onTest={this.onTest}
												   testResult={this.state.testResult}
					/>;
				case 'SHAREPOINT':
					return <SharepointConnectorComponent instance={instance}
														 busy={this.props.busy}
														 onChangeConnectorInstanceConfig={this.onChangeConnectorInstanceConfig}
														 onUpdateConnectorInstanceConfig={this.onUpdateConnectorInstanceConfig}
														 shouldSave={this.props.shouldSave}
														 hasValidConfig={this.hasValidConfig(instance.type)}
														 onTest={this.onTest}
														 testResult={this.state.testResult}
					/>
				case 'GOOGLE_DRIVE':
					return <GoogleDriveConnectorComponent instance={instance}
														  busy={this.props.busy}
														  onChangeConnectorInstanceConfig={this.onChangeConnectorInstanceConfig}
														  onUpdateConnectorInstanceConfig={this.onUpdateConnectorInstanceConfig}
														  shouldSave={this.props.shouldSave}
														  hasValidConfig={this.hasValidConfig(instance.type)}
														  onTest={this.onTest}
														  testResult={this.state.testResult}
					/>
			}
		}
	}

	renderInventoryGrid = (instance) => {
		if (instance) {
			return <ConnectorInventoryGridComponent instanceId={instance.id}/>;
		}
	}

	onChangeTab = (e, requestedTab) => {
		this.setState({tab: requestedTab, testResult: null});
	}

	onChangeConnectorInstanceConfig = (instanceId, key, value) => {
		this.props.onCompanyChangeConnectorInstanceConfig(instanceId, key, value);
	}

	onUpdateConnectorInstanceConfig = (instance) => {
		this.props.onCompanyUpdateConnectorInstanceConfig(instance);
	}

	onOpenDeleteDialog = () => {
		this.setState({
			deleteDialogOpen: true
		});
	}

	onCloseDeleteDialog = () => {
		this.setState({
			deleteDialogOpen: false
		});
	}

	onConfirmDeletion = () => {
		const instanceId = this.props.instanceList?.find(instance => instance.type === this.state.tab).id;
		const nextTab = this.props.activeInstanceTypes?.find(instance => instance !== this.state.tab) || null;
		this.setState({
			tab: nextTab,
			deleteDialogOpen: false
		}, () => this.props.onCompanyDeleteConnectorInstance(instanceId));
	}

	onTest = (instance) => {
		this.props.onCompanyTestConnectorInstanceConfig(instance);
	}
}

export default withTranslation()(connect(
	state => {
		let activeInstanceTypes = [];
		if (state.company.connectorSettings?.instanceList?.length > 0) {
			activeInstanceTypes = state.company.connectorSettings?.instanceList.map(instance => instance.type);
		}

		return {
			sessionInfo: state.session.info,

			busy: state.company.busy,
			serverError: state.company.serverError,
			instanceList: state.company.connectorSettings?.instanceList,
			activeInstanceTypes,
			instanceActionSuccessFully: state.company.connectorInstanceActionSuccessFully,
			testResult: state.company.connectorTestResult,
		}
	},
	dispatch => {
		return {
			onCompanyFetchConnectorInstanceList: () => {
				dispatch({
					type: 'COMPANY_FETCH_CONNECTOR_INSTANCE_LIST'
				})
			},
			onCompanyChangeConnectorInstanceConfig: (instanceId, key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_CONNECTOR_INSTANCE_CONFIG',
					instanceId,
					key,
					value
				});
			},
			onCompanyCreateConnectorInstance: (connectorType) => {
				dispatch({
					type: 'COMPANY_CREATE_CONNECTOR_INSTANCE',
					request: {
						type: connectorType
					}
				});
			},
			onCompanyUpdateConnectorInstanceConfig: (instance) => {
				dispatch({
					type: 'COMPANY_UPDATE_CONNECTOR_INSTANCE_CONFIG',
					instanceId: instance.id,
					config: instance.config
				});
			},
			onCompanyDeleteConnectorInstance: (instanceId) => {
				dispatch({
					type: 'COMPANY_DELETE_CONNECTOR_INSTANCE',
					instanceId
				});
			},
			onCompanyTestConnectorInstanceConfig: (instance) => {
				dispatch({
					type: 'COMPANY_TEST_CONNECTOR_INSTANCE_CONFIG',
					request: {
						type: instance.type,
						config: instance.config
					}
				});
			},
		}
	}
)(CompanyConnectorSettingsComponent));
