const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// folder list for overview
	overviewList: [],
	overviewCount: 0,

	// indicate that the action on the folder was successful
	folderActionSuccessfully: false,

	// company settings relevant for folder updating
	companySettings: null,
	// relevant
	relevantGroups: null,
	relevantUsers: null,

	// current folder settings
	settings: null,

	// current folder permissions,
	permissions: null,

	// delegate lists
	delegateLists: null,
	delegateActionSuccessFully: false

};

const folder = (state = defaultState, action) => {
	switch (action.type) {
		case 'FOLDER_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'FOLDER_FETCH_OVERVIEW_LIST':
		case 'FOLDER_FETCH_COMPANY_SETTINGS':
		case 'FOLDER_FETCH_SETTINGS':
		case 'FOLDER_FETCH_RELEVANT_GROUPS':
		case 'FOLDER_FETCH_RELEVANT_USERS':
		case 'FOLDER_FETCH_PERMISSIONS':
		case 'FOLDER_FETCH_DELEGATE_LISTS':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'FOLDER_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'FOLDER_FETCH_COMPANY_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				companySettings: action.data,
			}

		case 'FOLDER_FETCH_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				settings: action.data,
			}

		case 'FOLDER_FETCH_DELEGATE_LISTS_SUCCESS':
			return {
				...state,
				busy: false,
				delegateLists: action.data,
			}

		case 'FOLDER_CREATE':
		case 'FOLDER_DELETE':
		case 'FOLDER_UPDATE_SETTINGS':
		case 'FOLDER_UPDATE_PERMISSIONS':
			return {
				...state,
				busy: true,
				serverError: null,
				folderActionSuccessfully: false
			}

		case 'FOLDER_CREATE_SUCCESS':
		case 'FOLDER_DELETE_SUCCESS':
		case 'FOLDER_UPDATE_SETTINGS_SUCCESS':
		case 'FOLDER_UPDATE_PERMISSIONS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				folderActionSuccessfully: true
			}

		case 'FOLDER_CREATE_UPDATE_DELEGATE_LIST':
		case 'FOLDER_DELETE_DELEGATE_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				delegateActionSuccessFully: false
			}

		case 'FOLDER_CREATE_UPDATE_DELEGATE_LIST_SUCCESS':
		case 'FOLDER_DELETE_DELEGATE_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				delegateActionSuccessFully: true
			}

		case 'FOLDER_CHANGE_SETTING': {
			const settings = {
				...state.settings
			};
			if (null === action.value) {
				delete settings[action.key];
			} else {
				settings[action.key] = action.value;
			}
			return {
				...state,
				settings
			}
		}

		case 'FOLDER_FETCH_RELEVANT_GROUPS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				relevantGroups: action.data
			}

		case 'FOLDER_FETCH_RELEVANT_USERS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				relevantUsers: action.data
			}

		case 'FOLDER_FETCH_PERMISSIONS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				permissions: action.data
			}


		case 'FOLDER_PERMISSIONS_CHANGE_GROUP':
			return {
				...state,
				permissions: {
					...state.permissions,
					groupsWithPermissions: state.permissions.groupsWithPermissions.map(group => {
						const permissions = (action.enabled && 'EDIT_DOCUMENTS' === action.permission) ?
							['EDIT_DOCUMENTS', 'VIEW_DOCUMENTS'] : [action.permission];
						return group.id === action.id ? {
							...group,
							permissions: group.permissions
								.filter(p => permissions.indexOf(p) === -1)
								.concat(action.enabled ? permissions : [])
						} : group
					})
				}
			}
		case 'FOLDER_PERMISSIONS_DELETE_GROUP':
			return {
				...state,
				permissions: {
					...state.permissions,
					groupsWithPermissions: state.permissions.groupsWithPermissions
						.filter(group => group.id !== action.id)
				}
			}
		case 'FOLDER_PERMISSIONS_ADD_GROUPS':
			return {
				...state,
				permissions: {
					...state.permissions,
					groupsWithPermissions: state.permissions.groupsWithPermissions
						.filter(group => !action.groups.some(find => find.id === group.id))
						.concat(action.groups)
				}
			}
		case 'FOLDER_PERMISSIONS_CHANGE_USER':
			return {
				...state,
				permissions: {
					...state.permissions,
					usersWithPermissions: state.permissions.usersWithPermissions.map(user => {
						const permissions = (action.enabled && 'EDIT_DOCUMENTS' === action.permission) ?
							['EDIT_DOCUMENTS', 'VIEW_DOCUMENTS'] : [action.permission];
						return user.id === action.id ? {
							...user,
							permissions: user.permissions
								.filter(p => permissions.indexOf(p) === -1)
								.concat(action.enabled ? permissions : [])
						} : user
					})
				}
			}
		case 'FOLDER_PERMISSIONS_DELETE_USER':
			return {
				...state,
				permissions: {
					...state.permissions,
					usersWithPermissions: state.permissions.usersWithPermissions
						.filter(users => users.id !== action.id)
				}
			}
		case 'FOLDER_PERMISSIONS_ADD_USERS':
			return {
				...state,
				permissions: {
					...state.permissions,
					usersWithPermissions: state.permissions.usersWithPermissions
						.filter(users => !action.users.some(find => find.id === users.id))
						.concat(action.users)
				}
			}

		default:
			return state;
	}
}

export default folder;
