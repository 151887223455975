import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepContent, StepLabel, Stepper, TextField} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {parseISO} from "date-fns";

const defaultState = {
	description: '',
}

class CompanyApiKeyUpdateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				description: this.props.apiKey.description || '',
				contactEmail: this.props.apiKey.contactEmail || ''
			});
		}
	}

	render() {
		const { apiKey } = this.props;
		if (!apiKey) {
			return;
		}

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.apiKeySettingsDuration')}</StepLabel>
						<StepContent>
							<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
								<DatePicker
									slotProps={{
										textField: {
											size: 'small',
											autoComplete: 'off',
											inputProps: {
												placeholder: this.props.t('locale.dateFormat')
											},
										},
									}}
									label={this.props.t('company.apiKeySettingsValidFrom')}
									value={parseISO(apiKey.validFrom)}
									views={['year', 'month', 'day']}
									openTo="day"
									format="dd/LL/yyyy"
									disabled={true}
									sx={{width: '150px'}}
								/>

								<DatePicker
									slotProps={{
										textField: {
											size: 'small',
											autoComplete: 'off',
											inputProps: {
												placeholder: this.props.t('locale.dateFormat')
											},
										},
									}}
									label={this.props.t('company.apiKeySettingsValidUntil')}
									value={parseISO(apiKey.validUntil)}
									views={['year', 'month', 'day']}
									openTo="day"
									format="dd/LL/yyyy"
									disabled={true}
									sx={{width: '150px'}}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.apiKeySettingsContactEmailHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.apiKeySettingsContactEmail')}
								value={this.state.contactEmail || ''}
								onChange={this.onChangeContactEmail}
								inputProps={{maxLength: 255}}
								autoComplete="off"
								fullWidth
								sx={{m: 0}}
							/>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.apiKeySettingsOptionalDescription')}</StepLabel>
						<StepContent>
								<TextField
									margin='normal'
									variant="outlined"
									label={this.props.t('company.apiKeySettingsDescription')}
									value={this.state.description}
									onChange={this.onChangeDescription}
									inputProps={{maxLength: 255}}
									autoComplete="off"
									fullWidth
									sx={{m: 0}}
								/>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-update-cancel">{this.props.t('close')}</Button>
				<Button variant="contained" onClick={this.onConfirm}
						id="btn-update-confirm">
					{this.props.t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeDescription = (e) => {
		this.setState({description: e.target.value})
	}

	onChangeContactEmail = (e) => {
		this.setState({contactEmail: e.target.value})
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onConfirm();
		}
	}

	onConfirm = () => {
		this.props.onUpdate({
			description: this.state.description,
			contactEmail: this.state.contactEmail
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	() => {
		return {
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanyApiKeyUpdateDialog));
