import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Alert, Box, Button, TextField} from "@mui/material";
import {withRouter} from "react-router";
import ServerErrorComponent from "../common/ServerErrorComponent";
import SessionThemedContainer from "./SessionThemedContainer";

class SessionForgotPasswordComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
		}
	}

	componentDidMount() {
		this.props.onFetchCompanyInfo();
	}

	render() {
		return <SessionThemedContainer style={this.props.style} title={this.props.t('session.forgotPassword')}>
			<Box component="form" noValidate onSubmit={this.onSubmit}>
				{!this.props.forgotPasswordRequestSuccess &&
					<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1}}>
						<TextField
							required
							id="email"
							label={this.props.t('user.email')}
							variant="outlined"
							type="email"
							autoComplete="email"
							value={this.state.email}
							onChange={this.onEmailChange}
							sx={{flex: 1, minWidth: '250px', mt: 0}}
						/>
					</Box>
				}

				{this.props.forgotPasswordRequestSuccess &&
					<Alert severity="success"
						   sx={{mt: 2}}>
						{this.props.t('session.forgotPasswordSuccess').replace('$email', this.state.email)}
						<br/><br/>
						{this.props.t('session.forgotPasswordSuccessContinue')}
					</Alert>
				}

				<Box sx={{display: 'flex', mt: 1, gap: 1, minWidth: '250px'}}>
					{!this.props.forgotPasswordRequestSuccess &&
						<Button
							sx={{flex: 1}}
							disabled={this.props.busy}
							variant="contained" color="primary" onClick={this.onBackClick}
						>
							{this.props.t('back')}
						</Button>
					}

					<Button
						type="submit" sx={{flex: 1}}
						disabled={this.props.busy || this.state.email === ''}
						variant="contained" color="primary"
					>
						{this.props.t('next')}
					</Button>
				</Box>
			</Box>

			{this.props.serverError &&
				<Box sx={{width: '100%'}}>
					<ServerErrorComponent serverError={this.props.t(this.props.serverError)}/>
				</Box>
			}
		</SessionThemedContainer>
	}

	onEmailChange = (e) => {
		this.setState({email: e.target.value})
	}

	onBackClick = (e)  => {
		this.props.history.push('/login');
	}

	onSubmit = (event) => {
		event.preventDefault();
		if (!this.props.forgotPasswordRequestSuccess) {
			this.props.onForgotPassword(this.state.email);
		} else {
			this.props.history.push('/login');
		}
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			busy: state.session.busy,
			serverError: state.session.serverError,
			forgotPasswordRequestSuccess: state.session.forgotPasswordRequestSuccess,
			style: state.session.loginInfo?.companyStyleSettings,
		}
	},
	dispatch => {
		return {
			onForgotPassword: (email) => {
				dispatch({
					type: 'SESSION_FORGOT_PASSWORD',
					email
				});
			},
			onFetchCompanyInfo: () => {
				dispatch({
					type: 'SESSION_FETCH_COMPANY_INFO',
					currentHostName: window.location.hostname
				})
			},
		}
	}
)(SessionForgotPasswordComponent)));
