import React, {Component} from "react";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SIGNATURE_TYPES} from "../common/Constants";

class EditorBulkSignatureTypesDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			signatureTypes: []
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				signatureTypes: [...this.props.possibleSignatureTypes]
			});
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="sm">
			<DialogTitle>{this.props.t('editor.signingBulkSignatureTypes')}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center'}}>
					{Object.keys(SIGNATURE_TYPES).map(type => {
						if (this.props.possibleSignatureTypes.indexOf(type) < 0) return null;

						const checked = this.state.signatureTypes.indexOf(type) >= 0;
						const disabled = checked && this.state.signatureTypes.length <= 1;
						return <FormControlLabel
							key={type}
							control={<Checkbox
								checked={checked}
								onChange={(e) => this.onChangeSignatureType(type, e.target.checked)}
								disabled={disabled}
								id={`input-editor-signers-signaturetype-bulk-checkbox-${type}`}
							/>}
							label={<Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
								<img src={SIGNATURE_TYPES[type].img}
									 style={{width: '30px', height: 'auto'}}/>
								<Typography variant="body2">
									{this.props.t('editor.signingMethod_' + type)}
								</Typography>
							</Box>}
						/>
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('back')}</Button>
				<Button onClick={this.onConfirm} variant="contained" id="btn-selection-confirm">{this.props.t('confirm')}</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeSignatureType = (type, checked) => {
		const signatureTypes = (this.state.signatureTypes|| [])
			.filter(search => search != type);
		if (checked) {
			signatureTypes.push(type);
		}
		this.setState({signatureTypes});
	}

	onConfirm = () => {
		this.props.onChangeSignatureTypes(this.state.signatureTypes);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(EditorBulkSignatureTypesDialog));
