import React, {Component} from "react";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import {connect} from "react-redux";
import {TEMPLATE_OPTIONS} from "./EditorConstants";

const defaultState = {
	filterValue: '',
	selectedIds: []
};

class EditorApplyTemplateOptionsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({selectedIds: Object.entries(TEMPLATE_OPTIONS).map(([key, value]) => value)});
		}
	}

	render() {
		const columns = [{
			field: 'text',
			headerName: this.props.t('editor.templateOption'),
			editable: false,
			sortable: false,
			flex: 1
		}];

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   disableRestoreFocus
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('editor.templateOptions')}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
					<DataGrid
						autoHeight
						keepNonExistentRowsSelected
						checkboxSelection
						disableColumnMenu

						rowSelectionModel={this.state.selectedIds}
						onRowSelectionModelChange={this.onChangeSelectedValues}
						columns={columns}
						slotProps={{
							baseCheckbox: {
								tabIndex: 0
							},
						}}

						pagination
						paginationMode="client"
						paginationModel={{page: 0, pageSize: 25}}
						pageSizeOptions={[25]}

						rows={Object.entries(TEMPLATE_OPTIONS).map(([key, value]) => ({id: value, text: this.props.t('editor.templateOption_' + key)}))}
						rowCount={Object.values(TEMPLATE_OPTIONS).length}

						density="compact"
					/>
					{!!this.props?.documentCollection && !!this.props?.hasChildApprovers && this.state.selectedIds.includes(TEMPLATE_OPTIONS.APPROVERS) &&
						<Alert severity="warning">{this.props.t('editor.templateWarningApproversInCollectionChild')}</Alert>
					}
					{!!this.props?.documentCollection && !!this.props?.hasChildSigners && this.state.selectedIds.includes(TEMPLATE_OPTIONS.SIGNERS) &&
						<Alert severity="warning">{this.props.t('editor.templateWarningSignersInCollectionChild')}</Alert>
					}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						onClick={this.onSelect}
						id="btn-selection-confirm"
						disabled={this.state.selectedIds.length === 0}
				>
					{this.props.t('select')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !!this.state.selectedId) {
			this.onSelect();
		}
	}

	onChangeSelectedValues = (selectedIds) => {
		this.setState({selectedIds});
	}

	onSelect = () => {
		this.props.onSelect(this.state.selectedIds);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(EditorApplyTemplateOptionsDialog));
