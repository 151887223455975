import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Modal,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const defaultState = {
	popupLogo: false,
	showSecret: false
};

class OidcInstanceUpdateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.open && this.props.open) {
			this.setState(defaultState);
		}
	}

	render() {
		const settings = this.props.settings;

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('oidcInstance.settings')}</DialogTitle>
			<DialogContent>
				<ServerErrorComponent serverError={this.props.logoUpdateError} />

				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('oidcInstance.createName')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('oidcInstance.name')}
								required
								value={settings?.name || ''}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								autoFocus
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('oidcInstance.serverConfiguration')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('oidcInstance.serverUrl')}
								value={settings?.serverUrl || ''}
								onChange={this.onChangeServerUrl}
								autoComplete="off"
								fullWidth
								sx={{mb: 2}}
							/>
							<TextField
								variant="outlined"
								label={this.props.t('oidcInstance.clientId')}
								value={settings?.clientId || ''}
								onChange={this.onChangeClientId}
								autoComplete="off"
								fullWidth
								sx={{mb: 2}}
							/>
							<TextField
								variant="outlined"
								label={this.props.t('oidcInstance.clientSecret')}
								value={settings?.clientSecret || ''}
								onChange={this.onChangeClientSecret}
								autoComplete="off"
								type={this.state.showSecret ? 'text' : 'password'}
								fullWidth
								InputProps={{
								endAdornment: <InputAdornment position="end">
												<IconButton
													onClick={this.onToggleShowClientSecret}
													edge="end">{this.state.showSecret ? <VisibilityOff /> : <Visibility />}
												</IconButton>
								</InputAdornment>
								}}
							/>
						</StepContent>
					</Step>

					{this.props.enableAutoCreateAllowed && <Step active>
						<StepLabel>{this.props.t('oidcInstance.autoCreateDescription')}</StepLabel>
						<StepContent>
							<FormControlLabel
								control={<Checkbox checked={settings?.autoCreateAllowed || false}
												   onChange={this.onChangeAutoCreate}/>}
								label={this.props.t('oidcInstance.autoCreate')}
							/>
						</StepContent>
					</Step>}

					<Step active>
						<StepLabel>{this.props.t('oidcInstance.logo')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('oidcInstance.logoDescription')}</Typography>

							{!settings?.logoBase64 &&
								<Button color="secondary"
										variant="contained"
										startIcon={<AddIcon/>}
										onClick={this.onUploadNewLogo}
										id="btn-oidc-instance-logo-upload">
									{this.props.t('oidcInstance.logoUpload')}
								</Button>
							}

							{!!settings?.logoBase64 &&
								<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
									<img
										style={{
											width: '80px',
											maxHeight: '80px',
											cursor: 'zoom-in',
											border: '1px solid black',
											borderRadius: 5
										}}
										src={'data:image/png;base64,' + settings.logoBase64}
										alt={this.props.t('oidcInstance.logo')}
										onClick={this.onLogoPopupOpen}
									/>

									<IconButton
										variant="contained"
										color="primary"
										size="large"
										title={this.props.t('oidcInstance.logoDelete')}
										onClick={() => this.props.onUpdateLogo(this.props.settings.id, null, null, null)}>
										<DeleteIcon/>
									</IconButton>
								</Box>
							}
							<input type="file" id="file" accept=".png, .jpeg, .jpg" style={{display: 'none'}}></input>

						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('oidcInstance.createFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('oidcInstance.settingsFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>

			{this.state.popupLogo && <Modal
				open={this.state.popupLogo}
				onClose={this.onLogoPopupClose}
				style={{display:'flex',alignItems:'center',justifyContent:'center'}}
			>
				<img
					style={{
						maxWidth: '40%',
						border: '1px solid black',
						borderRadius: 5
					}}
					src={'data:image/png;base64,' + settings.logoBase64}
					alt={this.props.t('oidcInstance.logo')}
					onClick={this.onLogoPopupClose}
				/>
			</Modal>
			}

			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!settings?.name?.trim()} onClick={this.onSave}
						id="btn-create-confirm">
					{this.props.t('oidcInstance.save')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onToggleShowClientSecret = (e) => {
		this.setState({showSecret: !this.state.showSecret})
	}

	onChangeName = (e) => {
		this.props.onChangeOidcInstanceSetting('name', e.target.value);
	}

	onChangeServerUrl = (e) => {
		this.props.onChangeOidcInstanceSetting('serverUrl', e.target.value);
	}

	onChangeClientId = (e) => {
		this.props.onChangeOidcInstanceSetting('clientId', e.target.value);
	}

	onChangeClientSecret = (e) => {
		this.props.onChangeOidcInstanceSetting('clientSecret', e.target.value);
	}

	onChangeAutoCreate = (e, value) => {
		this.props.onChangeOidcInstanceSetting('autoCreateAllowed', value);
	}

	onUploadNewLogo = () => {
		const fileEl = document.getElementById("file");
		fileEl.onchange = () => {
			const file = fileEl.files[0];
			if (!!file) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					const index1 = result.indexOf(';');
					const index2 = result.indexOf(',');
					if (index1 > 0 && index2 > index1) {
						this.props.onUpdateLogo(this.props.settings.id, result.substring(index2 + 1), result.substring(5, index1), file.size);
					}
				}
				reader.readAsDataURL(file);
			}
		};
		fileEl.click();
	}

	onLogoPopupOpen = () => {
		this.setState({popupLogo: true});
	}

	onLogoPopupClose = () => {
		this.setState({popupLogo: false});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !!this.props.settings.name.trim()) {
			this.onSave();
		}
	}

	onSave = () => {
		this.props.onSave(this.props.settings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			settings: state.oidcInstance.settings,
			logoUpdateError: state.oidcInstance.logoUpdateError
		}
	},
	dispatch => {
		return {
			onChangeOidcInstanceSetting: (key, value) => {
				dispatch({
					type: 'OIDC_INSTANCE_CHANGE_SETTING',
					key,
					value
				});
			},
		}
	}
)(OidcInstanceUpdateDialog));

