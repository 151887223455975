import React, {Component, Fragment} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import LoadingComponent from "../common/LoadingComponent";

class ConnectorInstanceCreateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = this.createState(props);
	}

	createState(props) {
		if (props.availableConnectorTypes?.length > 0) {
			return {
				selectedConnectorType: props.availableConnectorTypes[0]
			};
		} else return {
			selectedConnectorType: ''
		};
	}

	componentDidMount() {
		this.props.onFetchAvailableConnectorTypes();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.open && this.props.open) {
			this.setState(this.createState(this.props));
		}
		if (!prevProps.availableConnectorTypes && this.props.availableConnectorTypes?.length > 0) {
			this.setState({selectedConnectorType: this.props.availableConnectorTypes[0]});
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('connector.instanceAdd')}</DialogTitle>
			<DialogContent>
				{!this.props.availableConnectorTypes && <LoadingComponent/>}

				{!!this.props.availableConnectorTypes && <Fragment>
					<Typography sx={{mb: 2}}>{this.props.t('connector.instanceAddInfo')}</Typography>
					<Select
						size="small"
						value={this.state.selectedConnectorType}
						onChange={this.onChangeConnectorType}
					>
						{this.props.availableConnectorTypes.map(connectorType =>
							<MenuItem key={connectorType}
									  value={connectorType}>{this.props.t('connector.connectorType_' + connectorType)}</MenuItem>
						)}
					</Select>
				</Fragment>}
			</DialogContent>

			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={this.props.busy || !this.canSave()}
						onClick={this.onSave}
						id="btn-create-confirm"
				>
					{this.props.t('connector.add')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	canSave = () => {
		return !!this.state.selectedConnectorType && !this.doesInstanceAlreadyExistsForType(this.state.selectedConnectorType);
	}

	doesInstanceAlreadyExistsForType = (connectorType) => {
		return this.props.instanceList.some(instance => instance.type === connectorType);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canSave()) {
			this.onSave();
		}
	}

	onChangeConnectorType = (e) => {
		this.setState({
			...this.state,
			selectedConnectorType: e.target.value
		});
	}

	onSave = () => {
		this.props.onSave(this.state.selectedConnectorType);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			busy: state.company.busy,
			instanceList: state.company.connectorSettings?.instanceList,

			availableConnectorTypes: state.company.connectorSettings?.availableConnectorTypes,
		}
	},
	dispatch => {
		return {
			onFetchAvailableConnectorTypes: () => {
				dispatch({
					type: 'COMPANY_FETCH_AVAILABLE_CONNECTOR_TYPES'
				});
			},
		}
	}
)(ConnectorInstanceCreateDialog));

