import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../common/ConfirmationDialog";
import GroupSelectUserDialog from "../common/GroupSelectUserDialog";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: 10,
	sortModel: [{field: 'fullName', sort: 'asc'}],

	addDialogOpen: false,
	removeDialogOpen: false,
};

class UserGroupUsersDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open &&
			(!prevProps.open || (this.props.userGroupUserActionSuccessfully && !prevProps.userGroupUserActionSuccessfully))) {
			this.onUserGroupFetchUserList(this.props.userGroupId);
		}
	}

	render() {
		const userList = this.props.userList;
		const userCount = this.props.userCount;

		const userColumns = [
			{
				field: 'fullName',
				headerName: this.props.t('userGroup.userName'),
				editable: false,
				flex: 1
			},
			{
				field: 'email',
				headerName: this.props.t('userGroup.userEmail'),
				editable: false,
				flex: 1
			},
			{
				field: 'state',
				headerName: this.props.t('userGroup.userState'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) =>
					<span>{this.props.t('user.state_' + cellValues.row.state)}</span>
			},
			{
				field: 'admin',
				headerName: this.props.t('userGroup.userAdmin'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.admin}
						disabled={this.props.userListBusy}
						onChange={(e) => this.onChangeUserAdmin(cellValues.row.id, e.target.checked)}
					/>
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				align: 'center',
				renderCell: (cellValues) =>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('userGroup.userRemove')}
						disabled={this.props.userListBusy}
						onClick={() => this.onRemoveDialogOpen(cellValues.row.id)}
						id={"btn.usergroup.user.remove-" + cellValues.id}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
			},
		];


		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					fullWidth
					maxWidth="md">
				<DialogTitle>{this.props.t('userGroup.users')}</DialogTitle>
				<DialogContent>

					<Typography>{this.props.t('userGroup.usersDescription')}</Typography>

					<Box>
						<DataGrid
							disableColumnSelector
							columns={userColumns}
							slots={{toolbar: FlexibleToolbar}}
							slotProps={{
								toolbar: {
									filterId: 'input-user-group-user-search-text',
									filterValue: this.state.filterValue,
									onChangeFilterValue: this.onFilterValueChange,
								}
							}}

							disableColumnFilter
							disableRowSelectionOnClick

							pagination
							paginationMode="server"
							paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
							onPaginationModelChange={this.onPaginationModelChange}
							pageSizeOptions={[10]}

							sortingMode="server"
							sortModel={this.state.sortModel}
							onSortModelChange={this.onSortModelChange}

							loading={this.props.userListBusy}
							rows={userList}
							rowCount={userCount}
							density="compact"
							autoHeight

							sx={{mt: 2}}
						/>

					</Box>
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
						<Button
							variant="contained"
							color="secondary"
							onClick={this.onOpenAddDialog}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							id="btn-usergroup-user-add"
						>
							{this.props.t('userGroup.addUser')}
						</Button>
					</Box>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-usergroup-user-cancel">{this.props.t('close')}</Button>
				</DialogActions>
			</Dialog>

			<GroupSelectUserDialog
				open={this.state.addDialogOpen}
				onFetchUserList={this.props.onUserGroupFetchPossibleUserList}
				userList={this.props.possibleUserList}
				userCount={this.props.possibleUserCount}
				busy={this.props.possibleUserListBusy}
				onClose={this.onCloseAddDialog}
				onSelect={this.onUserGroupAddUsers}
				title={this.props.t('userGroup.userSelect')}
				multiselect/>

			<ConfirmationDialog
				title={this.props.t('userGroup.userRemove')}
				confirm={this.props.t('userGroup.userRemoveConfirm')}
				open={this.state.removeDialogOpen}
				onClose={this.onRemoveDialogClose}
				onConfirm={this.onUserGroupRemoveUser}/>
		</Fragment>
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onUserGroupFetchUserList);
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onUserGroupFetchUserList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onUserGroupFetchUserList);
	}

	onOpenAddDialog = () => {
		this.setState({addDialogOpen: true});
	}

	onCloseAddDialog = () => {
		this.setState({addDialogOpen: false});
	}

	onRemoveDialogOpen = (userId) => {
		this.setState({activeUserId: userId, removeDialogOpen: true});
	}

	onUserGroupRemoveUser = () => {
		this.setState({removeDialogOpen: false},
			() => this.props.onUserGroupRemoveUser(this.props.userGroupId, this.state.activeUserId));
	}

	onRemoveDialogClose = () => {
		this.setState({removeDialogOpen: false});
	}

	onUserGroupAddUsers = (users) => {
		const userIds = users.map(user => user.id);
		this.setState({addDialogOpen: false}, this.props.onUserGroupAddUsers(this.props.userGroupId , userIds));
	}

	onChangeUserAdmin = (userId, admin) => {
		this.props.onChangeUserAdmin(this.props.userGroupId, userId, admin);
	}

	onUserGroupFetchUserList = () => {
		this.props.onUserGroupFetchUserList(this.props.userGroupId, {
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			userList: state.userGroup.userList,
			userCount: state.userGroup.userCount,
			userListBusy: state.userGroup.userListBusy,
			userGroupUserActionSuccessfully: state.userGroup.userGroupUserActionSuccessfully,
			possibleUserList: state.userGroup.possibleUserList,
			possibleUserCount: state.userGroup.possibleUserCount,
			possibleUserListBusy: state.userGroup.possibleUserListBusy,
		}
	},
	dispatch => {
		return {
			onUserGroupFetchUserList: (userGroupId, request) => {
				dispatch({
					type: 'USERGROUP_FETCH_USER_LIST',
					userGroupId,
					request
				});
			},
			onChangeUserAdmin: (userGroupId, userId, admin) => {
				dispatch({
					type: 'USERGROUP_USER_UPDATE_SETTINGS',
					userGroupId,
					userId,
					admin,
				});
			},
			onUserGroupRemoveUser: (userGroupId, userId) => {
				dispatch({
					type: 'USERGROUP_REMOVE_USER',
					userGroupId,
					userId,
				});
			},
			onUserGroupAddUsers: (userGroupId, userIds) => {
				dispatch({
					type: 'USERGROUP_ADD_USERS',
					userGroupId,
					userIds
				});
			},
			onUserGroupFetchPossibleUserList: (request) => {
				dispatch({
					type: 'USERGROUP_FETCH_POSSIBLE_USER_LIST',
					request
				});
			}
		}
	}
)(UserGroupUsersDialog));