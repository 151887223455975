const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// current app info
	info: null,

	// log settings
	logSettings: null,
};

const application = (state = defaultState, action) => {
	switch (action.type) {
		case 'APPLICATION_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'APPLICATION_FETCH_INFO':
		case 'APPLICATION_FETCH_LOG_SETTINGS':
		case 'APPLICATION_UPDATE_LOG_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'APPLICATION_FETCH_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data,
			}

		case 'APPLICATION_FETCH_LOG_SETTINGS_SUCCESS':
		case 'APPLICATION_UPDATE_LOG_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				logSettings: action.data
			}

		default:
			return state;
	}
}

export default application;
