import React, {Component, Fragment, useEffect} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {styled} from "@mui/material/styles";
import {AppBar as MuiAppBar, Box, Button, Drawer, IconButton, Link, Snackbar, Toolbar} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faChartLine, faCircleUser, faRightFromBracket, faUserTie} from '@fortawesome/free-solid-svg-icons'
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TaskIcon from '@mui/icons-material/Task';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ReactComponent as Logo} from "../../img/quill_logo_horizontal.svg";
import DrawerMenuItem from "./DrawerMenuItem";
import GlobalSnackbar from "./GlobalSnackbar";
import LoadingComponent from "./LoadingComponent";
import ConfirmationDialog from "./ConfirmationDialog";
import {Engineering} from "@mui/icons-material";
import TacDialog from "./TacDialog";
import PendingChangesDialog from "./PendingChangesDialog";

const drawerWidth = 62;

const Main = styled('main', {
	shouldForwardProp: (prop) => prop !== 'open',
})(
	({theme, open}) => ({
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: '0px',
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: `${drawerWidth}px`,
		}),
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#f2f2f2'
	}),
);

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const InternalTokenExpirationHandler = ({tokenExpiryValidator, tokenExpiry, tokenExpiredAction}) => {
	useEffect(() => {
		window.addEventListener("focus", onFocus);
		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, []);

	const onFocus = () => {
		// check clientside if the session did not yet expire, to prevent the user from working locally on an outdated session
		if (tokenExpiryValidator(tokenExpiry)) {
			tokenExpiredAction();
		}
	};

	return <></>;
};


class AppContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			cookieConsentOpen: 'true' !== localStorage.getItem('cookie-consent-given'),
			outOfOfficeWarningConfirmed: 'true' === sessionStorage.getItem('outOfOfficeWarningConfirmed'),
			registerGuestDialogOpen: false,
			pendingChangesDialogOpen: false,
			pendingChangesPath: null,
			pendingChangesCustom: false,
			pendingChangesSaved: false,
		};
	}

	componentDidMount() {
		if (this.props.needsSession) {
			// if there is custom session creator, then call it everytime
			if (!!this.props.onSessionCustomCreator) {
				this.props.onSessionCustomCreator();
			} else if (!this.props.sessionCreated) {
				this.props.onSessionCheck();
			} else {
				if (!!this.props.onSessionCreated) {
					this.props.onSessionCreated();
				}
				this.props.onSessionRefreshInfo();
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.needsSession) {
			if (this.props.sessionCreated && !prevProps.sessionCreated) {
				if (!!this.props.onSessionCreated) {
					this.props.onSessionCreated();
				}
			}
			if (!this.props.sessionCreated && prevProps.sessionCreated) {
				if (!!this.props.onSessionCustomCreator) {
					this.props.onSessionCustomCreator();
				} else {
					this.props.onSessionCheck();
				}
			}
		}

		// save the pending changes before navigating
		if (this.state.pendingChangesSaved) {
			if (prevProps.hasPendingChanges && !this.props.hasPendingChanges) {
				// saved successfully
				this.setState({pendingChangesSaved: false}, this.onPendingChangesConfirm);
			} else if (!prevProps.saveHasError && this.props.saveHasError) {
				// error occurred
				this.setState({pendingChangesSaved: false}, this.onPendingChangesCloseDialog);
			}
		}

		// there is a custom navigation requested inside the page
		if (this.props.customNavigation) {
			if (this.props.hasPendingChanges) {
				// + pending changes => open the dialog if it is not done already
				if (!this.state.pendingChangesDialogOpen) {
					this.setState({pendingChangesDialogOpen: true, pendingChangesCustom: true});
				}
			} else {
				// + no changes, we can directly grant the request
				this.props.onCustomNavigationComplete();
			}
		}
	}

	render() {
		const sessionInfo = this.props.sessionInfo;

		if (this.props.needsSession && !sessionInfo) {
			return <LoadingComponent/>;
		}

		return <>
			<AppBar position="fixed" elevation={0} color="appbar" open={this.props.open}>
				<Toolbar variant="dense"
						 sx={{alignItems: 'flex-start', pt: '2px', pr: '0px!important', height: '60px', boxShadow: '1px 2px 1px #DDDDDD'}}>
					<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>

						{!this.props.open && <IconButton
							color="inherit"
							onClick={this.props.onMenuOpen}
							edge="start"
							id="btn-menu-show"
							sx={{mr: 2, minHeight: '60px'}}>
							<MenuIcon/>
						</IconButton>}

						<Box sx={{cursor: 'pointer'}} onClick={this.onHandleIndex} id="nav-index">
							{!!sessionInfo && !!sessionInfo?.companyStyle?.logoBase64 ?
								<img src={'data:image/png;base64,' + sessionInfo.companyStyle.logoBase64}
									 style={{maxHeight: '50px', maxWidth: '200px', marginTop: '4px', width: '100%'}}/>
								:
								<Logo style={{height: '54px'}}/>}
						</Box>

						{!!sessionInfo && !!sessionInfo?.companyStyle?.logoBase64 &&
							<Box sx={{display: {xs: 'none', md: 'block'}, ml: 2}}>
								<Logo style={{height: '54px'}}/>
							</Box>}

						<Box sx={{flexGrow: 1}}/>

						{!!sessionInfo && !sessionInfo.guestAccess && !!sessionInfo.companyId && <Link
							sx={{
								display: {xs: 'none', sm: 'flex'},
								lineHeight: 1,
								fontSize: '1em', ...((sessionInfo.companySettingsAccessible || sessionInfo.inMultipleCompanies) && {cursor: 'pointer'}),
								textDecoration: 'none',
							}}
							onClick={this.onHandleCompanySettings}
							id="nav-company-settings">
							<FontAwesomeIcon icon={faUserTie} color="lightgrey" size="2x"/>
							<Box sx={{ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
								<Box
									sx={{color: 'lightgrey'}}>{sessionInfo.companyAdmin ? this.props.t('session.admin') : this.props.t('session.user')}</Box>
								<Box sx={{fontWeight: 700}}>{sessionInfo.companyName}</Box>
							</Box>
						</Link>}

						{!!sessionInfo && sessionInfo.guestAccess && sessionInfo.guestCanBecomeRegistered && sessionInfo.registerHeaderShown &&
							<Link
								sx={{
									display: 'flex',
									lineHeight: 1,
									fontSize: '1em',
									cursor: 'pointer',
									ml: 2,
									textDecoration: 'none',
									minHeight: '60px',
									alignItems: 'center'
								}}
								onClick={this.onOpenRegisterGuestDialog}
								id="nav-user-register">
								<FontAwesomeIcon icon={faUserTie} color="lightgrey" size="2x"/>
								<Box sx={{ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
									<Box sx={{color: 'lightgrey'}}>{this.props.t('session.becomeRegisteredUser')}</Box>
								</Box>
							</Link>}

						{!!sessionInfo && !sessionInfo.guestAccess && <Link
							id="nav-profile"
							sx={{
								display: 'flex',
								lineHeight: 1,
								fontSize: '1em',
								cursor: 'pointer',
								ml: 2,
								textDecoration: 'none',
								minHeight: '60px',
								alignItems: 'center'
							}}
							onClick={this.onHandleUserProfile}>
							<FontAwesomeIcon icon={faCircleUser} color="lightgrey" size="2x"/>
							<Box sx={{ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
								<Box sx={{
									color: 'lightgrey',
									display: {xs: 'none', sm: 'block'}
								}}>{this.props.t('session.loggedInAs')}</Box>
								<Box sx={{fontWeight: 700}}>{!!sessionInfo.userFullName ? sessionInfo.userFullName : sessionInfo.email}</Box>
							</Box>
						</Link>}

						{!!sessionInfo && sessionInfo.guestAccess && sessionInfo.welcomeHeaderShown && <Box
							id="nav-profile"
							sx={{
								display: 'flex',
								lineHeight: 1,
								fontSize: '1em',
								cursor: 'pointer',
								ml: 2,
								textDecoration: 'none'
							}}
							onClick={this.onHandleUserProfile}>
							<FontAwesomeIcon icon={faCircleUser} color="lightgrey" size="2x"/>
							<Box sx={{ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
								<Box sx={{
									color: 'lightgrey',
									display: {xs: 'none', sm: 'block'}
								}}>{this.props.t('session.welcome')}</Box>
								<Box sx={{fontWeight: 700}}>{sessionInfo.userFullName}</Box>
							</Box>
						</Box>}

						<Box id="btn-logout"
							 sx={{display: 'flex', lineHeight: 1, fontSize: '1em', cursor: 'pointer', ml: 3, mr: 2}}
							 onClick={() => this.onTryNavigate('/logout')}>
							<FontAwesomeIcon icon={faRightFromBracket} size="2x"/>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
						backgroundColor: 'black',
						border: 0,
					},
				}}
				variant="persistent"
				anchor="left"
				open={this.props.open}>

				<DrawerHeader sx={{justifyContent: 'center'}}>
					<IconButton onClick={this.props.onMenuClose} sx={{color: '#808080'}}>
						<ChevronLeftIcon/>
					</IconButton>
				</DrawerHeader>

				{!!sessionInfo && <Box sx={{
					display: 'flex',
					flexDirection: 'column',
					overflowX: 'hidden',
					color: '#808080',
					mt: 'auto',
					mb: 'auto',
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': {
						display: 'none'
					}
				}}>
					{[{
						id: 'nav-menu-admin',
						visible: sessionInfo.companyOverviewAccessible,
						text: 'ADMIN',
						route: '/admin',
						icon: <Engineering/>
					}, {
						id: 'nav-menu-users',
						visible: sessionInfo.userOverviewAccessible,
						text: 'USERS',
						route: '/user/overview',
						icon: <PersonIcon/>
					}, {
						id: 'nav-menu-user-groups',
						visible: sessionInfo.userGroupOverviewAccessible,
						text: 'GROUPS',
						route: '/user-group/overview',
						icon: <GroupIcon/>
					}, {
						id: 'nav-menu-document-folders',
						visible: sessionInfo.documentFolderOverviewAccessible,
						text: 'FOLDERS',
						route: '/folder/overview',
						icon: <FolderIcon/>
					}, {
						id: 'nav-menu-signer-groups',
						visible: sessionInfo.signerGroupOverviewAccessible,
						text: 'SIGNER GROUPS',
						route: '/signer-group/overview',
						icon: <FontAwesomeIcon icon={faAddressBook} size="lg"/>
					}, {
						id: 'nav-menu-templates',
						visible: sessionInfo.templateOverviewAccessible,
						text: 'TEMPLATES',
						route: '/template/overview',
						selectionRoutes: ['/template/editor'],
						icon: <DescriptionIcon/>
					}, {
						id: 'nav-menu-documents',
						visible: sessionInfo.companyDocumentOverviewAccessible,
						text: 'DOCS',
						route: '/document/general-overview',
						selectionRoutes: ['/editor/', '/document/upload'],
						icon: <InsertDriveFileIcon/>
					}, {
						id: 'nav-menu-documents-signing',
						visible: !sessionInfo.guestAccess,
						text: 'SIGN',
						badge: sessionInfo.waitingForSignatureCount,
						route: '/document/signing-overview',
						selectionRoutes: ['/sign/'],
						icon: <DriveFileRenameOutlineIcon/>
					}, {
						id: 'nav-menu-documents-approval',
						visible: !sessionInfo.guestAccess,
						text: 'APPROVE',
						badge: sessionInfo.waitingForApprovalCount,
						route: '/document/approval-overview',
						selectionRoutes: ['/approval/'],
						icon: <TaskIcon/>
					}, {
						id: 'nav-menu-insights',
						visible: sessionInfo.companyStatisticsAccessible || sessionInfo.companyActionLogsAccessible,
						text: 'INSIGHTS',
						route: '/company/insights',
						icon: <FontAwesomeIcon icon={faChartLine} size="lg"/>
					}, {
						id: 'nav-menu-info',
						visible: true,
						text: 'INFO',
						route: '/info',
						icon: <HelpOutlineIcon/>
					}].filter(e => e.visible).map(e =>
						<DrawerMenuItem
							key={e.id}
							id={e.id}
							icon={e.icon}
							text={e.text}
							badge={e.badge}
							route={e.route}
							selectionRoutes={e.selectionRoutes}
							onClick={() => this.onTryNavigate(e.route)}
						/>
					)}
				</Box>}
			</Drawer>

			<Main
				open={this.props.open}
				sx={{
					...(this.props.limitHeight && {
						maxHeight: '100vh',
						height: '100vh',
					}),
					...(!this.props.limitHeight && {
						minHeight: '100vh',
					}),
				}}
			>
				<DrawerHeader/>
				<Box
					sx={{
						p: {xs: 1, md: 2},
						...(this.props.limitHeight && {
							height: 'calc(100vh - 65px)',
							maxHeight: 'calc(100vh - 65px)'
						}),
					}}
				>
					{this.props.children}
				</Box>
			</Main>

			<GlobalSnackbar/>
			<TacDialog/>

			{!!this.props.sessionInfo?.internalTokenExpiry && <InternalTokenExpirationHandler
				tokenExpiryValidator={this.isExpired}
				tokenExpiry={this.props.sessionInfo?.internalTokenExpiry}
				tokenExpiredAction={this.onTokenExpired}
			/>}

			{!!sessionInfo && <Snackbar
				open={this.props.sessionInfo.userOutOfOffice && !this.state.outOfOfficeWarningConfirmed}
				message={this.props.t('outOfOffice.warning')}
				action={<Button size="small" variant="contained" id="btn-out-of-office-confirm"
								onClick={this.onSessionConfirmOutOfOfficeWarning}>{this.props.t('confirm')}</Button>}
			/>}

			<ConfirmationDialog
				open={this.state.registerGuestDialogOpen}
				title={this.props.t('session.becomeRegisteredUser')}
				confirm={this.props.t('session.becomeRegisteredUserDetails')}
				onClose={this.onCloseRegisterGuestDialog}
				onConfirm={this.onRegisterGuest}
			/>

			<PendingChangesDialog
				open={this.state.pendingChangesDialogOpen}
				onClose={this.onPendingChangesCloseDialog}
				busy={this.props.saveIsBusy}
				saveDisabled={this.props.saveDisabled}
				confirmDisabled={this.props.confirmDisabled}
				onConfirm={this.onPendingChangesConfirm}
				onSaveAndConfirm={!!this.props.onSave ? this.onPendingChangesSaveAndConfirm : undefined}
				labelContent={!!this.props.pendingChangesLabel ? this.props.pendingChangesLabel : this.props.t('session.pendingChanges')}
				labelConfirm={this.props.t('session.pendingChangesLeave')}
				labelSaveAndConfirm={!!this.props.pendingChangesSaveAndLeaveLabel ? this.props.pendingChangesSaveAndLeaveLabel : this.props.t('session.pendingChangesSaveAndLeave')}
			/>
		</>
	}

	onTryNavigate = (path) => {
		const currentPath = this.props.history?.location?.pathname || '';
		if (path === currentPath) {
			return;
		}

		if (this.props.hasPendingChanges) {
			this.setState({pendingChangesDialogOpen: true, pendingChangesPath: path, pendingChangesCustom: false});
		} else {
			this.onNavigate(path);
		}
	}

	onNavigate = (path) => {
		const sessionExpired = this.props.sessionInfo?.internalTokenExpiry && this.isExpired(this.props.sessionInfo?.internalTokenExpiry);

		if (sessionExpired) {
			this.onTokenExpired();
		} if ('/logout' === path) { // don't replace this with an actual route
			this.props.onSessionLogOut();
		} else {
			this.props.history.push(path);
		}
	}

	isExpired = (expiryDate) => {
		return (Date.parse(expiryDate) - Date.now()) < 0;
	}

	onTokenExpired = () => {
		this.props.onSessionDestroy();
		this.props.history.push('/login')
	}

	onPendingChangesCloseDialog = (e, reason) => {
		if (reason === 'backdropClick') {
			return;
		}

		if (this.state.pendingChangesCustom) {
			this.props.onCustomNavigationCanceled();
		}
		this.setState({pendingChangesDialogOpen: false});
	}

	onPendingChangesConfirm = () => {
		const {pendingChangesPath, pendingChangesCustom} = this.state;
		if (pendingChangesCustom) {
			this.props.onCustomNavigationComplete();
			this.setState({pendingChangesDialogOpen: false});
		} else {
			this.setState({pendingChangesDialogOpen: false}, () => this.onNavigate(pendingChangesPath));
		}
	}

	onPendingChangesSaveAndConfirm = () => {
		this.setState({pendingChangesSaved: true}, this.props.onSave);
	}

	onHandleIndex = () => {
		this.onTryNavigate('/');
	}

	onHandleCompanySettings = () => {
		const {companySettingsAccessible, inMultipleCompanies} = this.props.sessionInfo;
		if (companySettingsAccessible || inMultipleCompanies) {
			this.onTryNavigate('/company/settings');
		}
	}

	onHandleUserProfile = () => {
		this.onTryNavigate('/user/profile');
	}

	onOpenRegisterGuestDialog = () => {
		this.setState({registerGuestDialogOpen: true});
	}

	onCloseRegisterGuestDialog = () => {
		this.setState({registerGuestDialogOpen: false});
	}

	onRegisterGuest = () => {
		this.setState({registerGuestDialogOpen: false}, this.props.onSessionRegisterGuest);
	}

	onSessionConfirmOutOfOfficeWarning = () => {
		this.setState({outOfOfficeWarningConfirmed: true}, () => sessionStorage.setItem("outOfOfficeWarningConfirmed", "true"));
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionCreated: state.session.created,
			open: state.menu.open
		}
	},
	dispatch => {
		return {
			onSessionCheck: () => {
				dispatch({
					type: 'SESSION_CHECK'
				})
			},
			onSessionRefreshInfo: () => {
				dispatch({
					type: 'SESSION_REFRESH_INFO'
				})
			},
			onSessionLogOut: () => {
				dispatch({
					type: 'SESSION_LOG_OUT'
				})
			},
			onSessionRegisterGuest: () => {
				dispatch({
					type: 'SESSION_REGISTER_GUEST'
				})
			},
			onMenuOpen: () => {
				dispatch({
					type: 'MENU_OPEN'
				})
			},
			onMenuClose: () => {
				dispatch({
					type: 'MENU_CLOSE'
				})
			},
			onSessionDestroy: () => {
				dispatch({
					type: 'SESSION_DESTROY'
				})
			}
		}
	}
)(AppContainer)));
