import React from "react";
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default (props) => {
	const titles = props.actions.map(op => op.title);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const firstItem = props.actions.at(0);

	const handleClick = () => {
		firstItem.action();
	};

	const handleMenuItemClick = (event, index) => {
		setOpen(false);
		props.actions[index].action();
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" color={props.color}>
				<Button startIcon={firstItem.icon}
						id={firstItem.id}
						onClick={handleClick}
						disabled={firstItem.disabled}>{firstItem.title}</Button>

				<Button
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="send action option"
					aria-haspopup="menu"
					disabled={props.actions.every(action => action.disabled)}
					onClick={handleToggle}
					id="btn-split-selector"
				>
					<ArrowDropDownIcon/>
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1000,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({TransitionProps, placement}) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{titles.map((option, index) => (
										<MenuItem
											key={option}
											disabled={props.actions[index].disabled}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}