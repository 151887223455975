import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import {FORM_FIELD_TYPES, SIGNATURE_TYPES} from "../common/Constants";
import HelpIcon from "@mui/icons-material/Help";

const PLACEHOLDER_FIELD_TYPES = ['SIGNATURE', 'PARAPH', 'FORM'];

const defaultState = {
	placeholderValue: '',
	placeholderFieldType: PLACEHOLDER_FIELD_TYPES[0],
	placeholderFormFieldType: FORM_FIELD_TYPES[0]
};

class FolderSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			...defaultState
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState
			});
			this.props.onFolderFetchCompanySettings();
			this.props.onFolderFetchSettings(this.props.folderId);
		}
	}

	render() {
		const folderCompanySettings = this.props.folderCompanySettings;
		const folderSettings = this.props.folderSettings;
		const haveAllInfo = !!folderCompanySettings && !!folderSettings;

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('folder.settings')}</DialogTitle>
			{!haveAllInfo && <DialogContent><LoadingComponent /></DialogContent>}
			{haveAllInfo && <DialogContent>
				<Typography>{folderSettings.name}</Typography>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('folder.settingsNameDescription')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('folder.name')}
								required
								value={folderSettings.name}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								autoFocus
								disabled={folderSettings.defaultFolder}
								sx={{ mb: 2 }}
							/>
							<TextField
								variant="outlined"
								label={this.props.t('folder.description')}
								value={folderSettings.description || ''}
								onChange={this.onChangeDescription}
								autoComplete="off"
								fullWidth
								disabled={folderSettings.defaultFolder}
							/>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsSignatureTypes')}</StepLabel>
						<StepContent>
							{Object.keys(SIGNATURE_TYPES)
								.map(type => folderCompanySettings.companySigningSettings.signatureSettings.find(setting => setting.type === type))
								.filter(setting => !!setting)
								.map(setting => {
								const blocked = setting.value === 'ALWAYS_AVAILABLE';
								const value = setting.value === 'ALWAYS_AVAILABLE' || folderSettings.enabledSignatureTypes.indexOf(setting.type) >= 0;
								return <Box key={setting.type}>
									{setting.value !== 'NEVER_AVAILABLE' && <FormControlLabel
										control={<Checkbox checked={value} sx={{p: 0.5}}
														   onChange={(e, value) => this.onChangeSignatureType(setting.type, value)}/>}
										label={this.props.t('folder.settingsSignatureType_' + setting.type) +
											(blocked ? (' (' + this.props.t('folder.settingsBlocked') + ')') : '')}
										disabled={blocked}
									/>}

									{setting.type === "ITSME" && (typeof folderSettings.useCompanyItsmePartnerCode === 'boolean') && <Box sx={{
										ml: 2
									}}>
										<FormControlLabel
											control={<Checkbox checked={folderSettings.useCompanyItsmePartnerCode}
															   disabled={folderSettings.enabledSignatureTypes.indexOf("ITSME") < 0}
															   sx={{p: 0.5}}
															   onChange={this.onChangeUseCompanyItsmePartnerCode}/>}
											label={this.props.t('folder.settingsUseCompanyItsmePartnerCode')}
										/>
									</Box>}
								</Box>
							})}
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsReasonAndRemark')}</StepLabel>
						<StepContent>
							{(() => {
								// reason shizzle
								const csReason = folderCompanySettings.companySigningSettings.signingReasonPreference;
								const blockedReason = csReason === 'ALWAYS_AVAILABLE' || csReason === 'NEVER_AVAILABLE';
								const valueReason = blockedReason ? csReason === 'ALWAYS_AVAILABLE' : folderSettings.reasonAvailable;

								const csRemark = folderCompanySettings.companySigningSettings.signingCommentPreference;
								const blockedRemark = csRemark === 'ALWAYS_AVAILABLE' || csRemark === 'NEVER_AVAILABLE';
								const valueRemark = blockedRemark ? csRemark === 'ALWAYS_AVAILABLE' : folderSettings.remarkAvailable;

								return <Fragment>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={valueReason}
															   sx={{p: 0.5}}
															   onChange={this.onChangeReasonAvailable}/>}
											label={this.props.t('folder.settingsReason') +
												(blockedReason ? (' (' + this.props.t('folder.settingsBlocked') + ')') : '')}
											disabled={blockedReason}
										/>
									</Box>

									{valueReason && folderSettings.hasOwnProperty('reasonLegalNoticeMandatory') && <Box sx={{ ml: 2, mt: 0.5 }}>
										<FormControlLabel
											control={<Checkbox checked={folderSettings.reasonLegalNoticeMandatory}
															   sx={{p: 0.5}}
															   onChange={this.onChangeReasonLegalNoticeMandatory}/>}
											label={this.props.t('folder.settingsReasonLegalNoticeMandatory')}
											sx={{
												verticalAlign: 'start',
												alignItems: 'start',
												'& .MuiCheckbox-root': {
													alignItems: 'start'
												},
												m: 0
											}}
										/>
										<TextField
											variant="outlined"
											label={this.props.t('folder.settingsReasonLegalNoticeText')}
											required
											value={folderSettings.reasonLegalNoticeText || ''}
											onChange={this.onChangeReasonLegalNoticeText}
											autoComplete="off"
											fullWidth
											sx={{ mt: 1 }}
											disabled={!folderSettings.reasonLegalNoticeMandatory}
										/>
										<Button
											variant="contained"
											color="secondary"
											sx={{ mt: 1, mb: 2 }}
											onClick={() => this.onChangeReasonLegalNoticeMandatory(null, null)}
											id="btn-folder-settings-legalnotice-mandatory-remove"
										>
											{this.props.t('folder.settingsOverrideOnFolderLevelRemove')}
										</Button>
									</Box>}

									{valueReason && !folderSettings.hasOwnProperty('reasonLegalNoticeMandatory') && <Box sx={{ ml: 2, mt: 0.5 }}>
										<FormControlLabel
											control={<Checkbox checked={folderCompanySettings.companySigningSettings.signingReasonLegallyRequired}
															   sx={{ p: 0 }} />}
											label={this.props.t('folder.settingsReasonLegalNoticeMandatory')}
											disabled
											sx={{
												verticalAlign: 'start',
												alignItems: 'start',
												'& .MuiCheckbox-root': {
													alignItems: 'start'
												},
												m: 0
											}}
										/>
										<TextField
											variant="outlined"
											label={this.props.t('folder.settingsReasonLegalNoticeText')}
											disabled
											value={folderCompanySettings.companySigningSettings.signingReasonLegalText || ''}
											autoComplete="off"
											fullWidth
											sx={{ mt: 1 }}
										/>
										<Button
											variant="contained"
											color="secondary"
											sx={{ mt: 1, mb: 2 }}
											onClick={() => this.onChangeReasonLegalNoticeMandatory(null, true)}
											id="btn-folder-settings-legalnotice-mandatory-set"
										>
											{this.props.t('folder.settingsOverrideOnFolderLevelSet')}
										</Button>
									</Box>}

									<Box>
										<FormControlLabel
											control={<Checkbox checked={valueRemark}
															   sx={{ p: 0.5 }}
															   onChange={this.onChangeRemarkAvailable}/>}
											label={this.props.t('folder.settingsRemark') +
												(blockedReason ? (' (' + this.props.t('folder.settingsBlocked') + ')') : '')}
											disabled={blockedReason}
										/>
									</Box>
								</Fragment>
							})()}

						</StepContent>
					</Step>

					{folderCompanySettings.mailboxEnabled && <Step active>
						<StepLabel>{this.props.t('folder.settingsMailbox')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={folderSettings.mailboxEnabled}
													   sx={{ p: 0.5 }}
													   onChange={this.onChangeMailboxEnabled}/>}
									label={this.props.t('folder.settingsMailboxEnabled')}
								/>
							</Box>
							{folderSettings.mailboxEnabled && <Box>
								<TextField
									variant="outlined"
									label={this.props.t('folder.settingsMailboxAddress')}
									value={folderSettings.mailboxAddressIfEnabled || ''}
									autoComplete="off"
									fullWidth
									disabled
									sx={{ mt: 2 }}
								/>
							</Box>}
						</StepContent>
					</Step>}

					<Step active>
						<StepLabel>{this.props.t('folder.settingsEmailDistributionList')}</StepLabel>
						<StepContent>
							{folderSettings.companyPostSignEmailDistSettingOverridden && <>
								<Box>
									<Typography sx={{mb: 0.5}}>{this.props.t('folder.settingsEmailDocumentDistributionListDescription')}</Typography>

									<TextField
										variant="outlined"
										label={this.props.t('folder.settingsEmailDistributionLabel')}
										value={folderSettings.defaultPostSignDocumentEmailDistributionListDelimited || ''}
										onChange={this.onChangeDefaultPostSignDocumentEmailDistributionListDelimited}
										autoComplete="off"
										fullWidth
										disabled={this.props.companyBusy}
									/>

									<Typography sx={{mt: 1, mb: 0.5}}>{this.props.t('folder.settingsEmailDocumentAndEvidenceReportDistributionListDescription')}</Typography>
									<TextField
										variant="outlined"
										label={this.props.t('folder.settingsEmailDistributionLabel')}
										value={folderSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited || ''}
										onChange={this.onChangeDefaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited}
										autoComplete="off"
										fullWidth
										disabled={this.props.companyBusy}
									/>
								</Box>
								<Button
								variant="contained"
								color="secondary"
								sx={{mt: 1, mb: 2}}
								onClick={() => this.onOverridePostSignEmailDistributionSetting(false)}
								id="btn-folder-settings-postsign-email-override-clear"
								>
							{this.props.t('folder.settingsOverrideOnFolderLevelRemove')}
								</Button>
							</>
							}
							{!folderSettings.companyPostSignEmailDistSettingOverridden && <>
								<Box disabled>
									<Typography sx={{opacity: 0.38, mb: 0.5}}>{this.props.t('folder.settingsEmailDocumentDistributionListDescription')}</Typography>
									<TextField
										variant="outlined"
										label={this.props.t('folder.settingsEmailDistributionLabel')}
										value={folderCompanySettings.companyEmailSettings.defaultPostSignDocumentEmailDistributionListDelimited || ''}
										onChange={this.onChangeDefaultPostSignEmailDistributionListDelimited}
										autoComplete="off"
										fullWidth
										disabled
									/>

									<Typography sx={{opacity: 0.38, mt: 1, mb: 0.5}}>{this.props.t('folder.settingsEmailDocumentAndEvidenceReportDistributionListDescription')}</Typography>
									<TextField
										variant="outlined"
										label={this.props.t('folder.settingsEmailDistributionLabel')}
										value={folderCompanySettings.companyEmailSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited || ''}
										onChange={this.onChangeDefaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited}
										autoComplete="off"
										fullWidth
										disabled
									/>
								</Box>
								<Button
								variant="contained"
								color="secondary"
								sx={{mt: 1, mb: 2}}
								onClick={() => this.onOverridePostSignEmailDistributionSetting(true)}
								id="btn-folder-settings-postsign-email-override"
								>
							{this.props.t('folder.settingsOverrideOnFolderLevelSet')}
								</Button>
							</>
							}
						</StepContent>
					</Step>

					{folderSettings.allowChangingDaysForDocumentCleanup &&
						<Step active>
							<StepLabel>{this.props.t('folder.settingsFolderCleanup')}</StepLabel>
							<StepContent>
								<Box>
									<FormControlLabel
										control={<Checkbox checked={folderSettings.cleanupSettingsOverwritten}
														   sx={{p: 0.5}}
														   onChange={this.onChangeCleanupSettingsOverwritten}/>}
										label={this.props.t('folder.settingsFolderCleanupOverwrite')}
										disabled={this.props.companyBusy}
									/>
								</Box>
								{folderSettings.cleanupSettingsOverwritten && <Box sx={{mt: 1}}>
									<Box sx={{display: 'flex', alignItems: 'center'}}>
										<TextField
											variant="outlined"
											size="small"
											value={folderSettings.cleanupOfProcessedDocumentsInDays || '0'}
											onChange={this.onChangeCleanupOfProcessedDocumentsInDays}
											sx={{mr: 1, width: 100}}
											disabled={this.props.companyBusy}
											type="number"
										/>
										<span>{this.props.t('folder.settingsCleanupDaysProcessed')}</span>
									</Box>
									<Box sx={{display: 'flex', alignItems: 'center'}}>
										<TextField
											variant="outlined"
											size="small"
											value={folderSettings.cleanupOfPendingDocumentsInDays || '0'}
											onChange={this.onChangeCleanupOfPendingDocumentsInDays}
											sx={{mr: 1, width: 100}}
											disabled={this.props.companyBusy}
											type="number"
										/>
										<span>{this.props.t('folder.settingsCleanupDaysPending')}</span>
									</Box>
								</Box>}
							</StepContent>
						</Step>
					}

					{folderSettings.allowChangingDaysForSignatureExpiration &&
						<Step active>
							<StepLabel>{this.props.t('folder.settingsFolderExpiration')}</StepLabel>
							<StepContent>
								<Box>
									<FormControlLabel
										control={<Checkbox checked={folderSettings.overwriteExpirationSettings || false}
														   sx={{p: 0.5}}
														   onChange={this.onChangeOverrideExpirationSettings}/>}
										label={this.props.t('folder.settingsExpirationOverride')}
										disabled={this.props.companyBusy}
									/>
								</Box>
								{folderSettings.overwriteExpirationSettings && <Box sx={{ml: 2, mt: 0.5}}>
									<Box>
										<FormControlLabel
											control={<Checkbox
												checked={folderSettings.enableExpirationSettings || false}
												sx={{ p: 0.5 }}
												onChange={this.onChangeEnableExpirationSettings}/>}
											label={this.props.t('folder.settingsExpirationEnable')}
											disabled={this.props.companyBusy}
											sx={{m: 0}}/>
									</Box>
									<Box sx={{display: 'flex', alignItems: 'center'}}>
										<TextField
											variant="outlined"
											size="small"
											value={folderSettings.daysForSignatureExpiration}
											onChange={this.onChangeExpirationOfSignatureInDays}
											sx={{mr: 1, width: 100}}
											disabled={this.props.companyBusy || !folderSettings.enableExpirationSettings}
										/>
										<span>{this.props.t('folder.settingsSignatureExpirationDays')}</span>
									</Box>
								</Box>}
							</StepContent>
						</Step>
					}

					{folderSettings.companyForwardingEnabled &&
						<Step active>
							<StepLabel>{this.props.t('folder.settingsFolderForwarding')}</StepLabel>
							<StepContent>
								<Box>
									<FormControlLabel
										control={<Checkbox checked={folderSettings.allowForwarding}
														   sx={{ p: 0.5 }}
														   onChange={this.onChangeAllowForwarding}/>}
										label={this.props.t('folder.settingsAllowForwarding')}
									/>
								</Box>
								<Box>
									<FormControlLabel
										control={<Checkbox checked={folderSettings.forwardingEnabledByDefault}
														   disabled={!folderSettings.allowForwarding}
														   sx={{ p: 0.5 }}
														   onChange={this.onChangeForwardingEnabledByDefault}/>}
										label={this.props.t('folder.settingsForwardingEnabledByDefault')}
									/>
								</Box>
							</StepContent>
						</Step>
					}

					<Step active>
						<StepLabel>{this.props.t('folder.settingsReminders')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox
										checked={typeof folderSettings.reminderIntervalInDays === 'number'}
										sx={{ p: 0.5 }}
										onChange={this.onChangeReminderIntervalInDaysOverwrite}/>}
									label={this.props.t('folder.settingsRemindersOverwrite')}
								/>
								{typeof folderSettings.reminderIntervalInDays === 'number' && <Box sx={{display: 'flex', mt: 1, alignItems: 'center'}}>
									<TextField
										variant="outlined"
										size="small"
										value={typeof folderSettings.reminderIntervalInDays === 'number' ? folderSettings.reminderIntervalInDays : ''}
										onChange={this.onChangeReminderIntervalInDays}
										sx={{mr: 1, width: 100}}
										disabled={this.props.companyBusy}
										type="number"
									/>
									<Typography>{this.props.t('folder.settingsRemindersIntervalInDays')}</Typography>
								</Box>}
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsMinimizeCollectionInvites')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox
										checked={typeof folderSettings.collectionInvitationsMinimize === 'boolean'}
										sx={{ p: 0.5 }}
										onChange={this.onChangeCollectionInvitationsMinimizeFolder}/>}
									label={this.props.t('folder.settingsMinimizeCollectionInvitesFolder')}
								/>
								{typeof folderSettings.collectionInvitationsMinimize === 'boolean' && <>
									<FormControlLabel
										control={<Checkbox
											checked={!!folderSettings.collectionInvitationsMinimize}
											sx={{ p: 0.5 }}
											onChange={this.onChangeCollectionInvitationsMinimize}/>}
										label={this.props.t('folder.settingsMinimizeCollectionInvitesEnable')}
									/>
									{!!folderSettings.collectionInvitationsMinimize && <>
										<TextField
											variant="outlined"
											type="number"
											label={this.props.t('folder.settingsMinimizeCollectionInvitesGracePeriod')}
											value={typeof folderSettings.collectionInvitationsGraceTimeMinutes === 'number' ? folderSettings.collectionInvitationsGraceTimeMinutes : ''}
											onChange={this.onChangeCollectionInvitationsGraceTimeMinutes}
											sx={{mr: 1, width: 300}}
										/>
										<Typography variant="body2">{this.props.t('folder.settingsMinimizeCollectionInvitesGracePeriodHelp')}</Typography>
									</>}
								</>}
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsMinimizeCollectionFinishedNotifications')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox
										checked={typeof folderSettings.collectionFinishedNotificationsMinimize === 'boolean'}
										sx={{ p: 0.5 }}
										onChange={this.onChangeCollectionFinishedNotificationsMinimizeFolder}/>}
									label={this.props.t('folder.settingsMinimizeCollectionFinishedNotificationsFolder')}
								/>
								{typeof folderSettings.collectionFinishedNotificationsMinimize === 'boolean' && <>
									<FormControlLabel
										control={<Checkbox
											checked={!!folderSettings.collectionFinishedNotificationsMinimize}
											sx={{ p: 0.5 }}
											onChange={this.onChangeCollectionFinishedNotificationsMinimize}/>}
										label={this.props.t('folder.settingsMinimizeCollectionFinishedNotificationsEnable')}
									/>
								</>}
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsSignerNeedsSignatureToDownload')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox
										checked={typeof folderSettings.signerNeedsSignatureToDownload === 'boolean'}
										sx={{ p: 0.5 }}
										onChange={this.onChangeSignerNeedsSignatureToDownloadFolder}/>}
									label={this.props.t('folder.settingsSignerNeedsSignatureToDownloadFolder')}
								/>
								{typeof folderSettings.signerNeedsSignatureToDownload === 'boolean' && <>
									<FormControlLabel
										control={<Checkbox
											checked={!!folderSettings.signerNeedsSignatureToDownload}
											sx={{ p: 0.5 }}
											onChange={this.onChangeSignerNeedsSignatureToDownload}/>}
										label={this.props.t('folder.settingsSignerNeedsSignatureToDownloadEnable')}
									/>
								</>}
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsPlaceholders')}</StepLabel>
						<StepContent>
							<Box>
								{(folderSettings?.placeholderLookups?.length > 0) && <Box sx={{pt: 1, pb: 1, mb: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: '100px', overflowY: 'auto'}}>
									{folderSettings?.placeholderLookups?.map((placeholder, index) =>
										<Chip key={index}
											  label={placeholder.placeholder}
											  variant="outlined"
											  onDelete={() => this.onDeletePlaceholder(index)} />)
									}
								</Box>}
								<Box sx={{display: 'flex', gap: 1, mt: 1}}>
									<TextField
										variant="outlined"
										size="small"
										label={this.props.t('folder.settingsPlaceholderValue')}
										value={this.state.placeholderValue}
										onChange={this.onChangePlaceholderValue}
										sx={{flexGrow: 1}}
									/>
									<FormControl sx={{flexGrow: 1}} size="small">
										<InputLabel id="placeholder-field-type-label">{this.props.t('folder.settingsPlaceholderFieldType')}</InputLabel>
										<Select
											labelId="placeholder-field-type-label"
											value={this.state.placeholderFieldType}
											label={this.props.t('folder.settingsPlaceholderFieldType')}
											onChange={this.onChangePlaceholderFieldType}
										>
											{PLACEHOLDER_FIELD_TYPES.map(type => <MenuItem key={type} value={type}>{this.props.t('folder.settingsPlaceholderFieldType_' + type)}</MenuItem>)}
										</Select>
									</FormControl>
									{'FORM' === this.state.placeholderFieldType && <FormControl sx={{flexGrow: 1}} size="small">
										<InputLabel id="placeholder-form-field-type-label">{this.props.t('folder.settingsPlaceholderFormFieldType')}</InputLabel>
										<Select
											labelId="placeholder-form-field-type-label"
											value={this.state.placeholderFormFieldType}
											label={this.props.t('folder.settingsPlaceholderFormFieldType')}
											onChange={this.onChangePlaceholderFormFieldType}
										>
											{FORM_FIELD_TYPES.map(type => <MenuItem key={type} value={type}>{this.props.t('folder.settingsPlaceholderFormFieldType_' + type)}</MenuItem>)}
										</Select>
									</FormControl>}
									<Button variant="contained"
											color="secondary"
											disabled={!this.state.placeholderValue}
											onClick={this.onAddPlaceholder}
											id="btn-placeholder-add"
									>
										{this.props.t('folder.settingsPlaceholderAdd')}
									</Button>
								</Box>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsUserUsage')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox
										checked={!!folderSettings?.userUsage?.canUseRegisteredUser}
										sx={{p: 0.5}}
										onChange={this.onChangeUserUsageRegisteredUsed}/>}
									label={this.props.t('folder.settingsUserUsageRegisteredUser')}
								/>
								<FormControlLabel
									control={<Checkbox
										checked={!!folderSettings?.userUsage?.canUseGuest}
										sx={{p: 0.5}}
										onChange={this.onChangeUserUsageGuest}/>}
									label={this.props.t('folder.settingsUserUsageGuest')}
								/>
								<FormControlLabel
									control={<Checkbox
										checked={!!folderSettings?.userUsage?.canUseContact}
										sx={{p: 0.5}}
										onChange={this.onChangeUserUsageContact}/>}
									label={<>
										{this.props.t('folder.settingsUserUsageContact')}
										<Tooltip title={this.props.t('folder.settingsUserUsageContactHelp')}>
											<HelpIcon fontSize="small" sx={{ml: 0.5}}/>
										</Tooltip>
									</>}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsUserInvite')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox
										checked={!!folderSettings?.userInvite?.canInviteRegisteredUsers}
										sx={{p: 0.5}}
										onChange={this.onChangeUserInviteRegisteredUsers}/>}
									label={this.props.t('folder.settingsUserInviteRegisteredUsers')}
									disabled={!folderSettings?.userUsage?.canUseRegisteredUser}
								/>
								<FormControlLabel
									control={<Checkbox
										checked={!!folderSettings?.userInvite?.canInviteGuests}
										sx={{p: 0.5}}
										onChange={this.onChangeUserInviteGuests}/>}
									label={this.props.t('folder.settingsUserInviteGuests')}
									disabled={!folderSettings?.userUsage?.canUseGuest}
								/>
								<FormControlLabel
									control={<Checkbox
										checked={!!folderSettings?.userInvite?.canInviteContacts}
										sx={{p: 0.5}}
										onChange={this.onChangeUserInviteContacts}/>}
									label={this.props.t('folder.settingsUserInviteContacts')}
									disabled={!folderSettings?.userUsage?.canUseContact}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsMailRedirect')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={folderSettings.mailRedirectEnabled}
													   sx={{ p: 0.5 }}
													   onChange={this.onChangeMailRedirectEnabled}/>}
									label={this.props.t('folder.settingsMailRedirectEnabled')}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsSigningKioskMode')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={folderSettings.kioskSigningOrderTypeEnabled}
													   sx={{ p: 0.5 }}
													   onChange={this.onChangeKioskSigningOrderTypeEnabled}/>}
									label={this.props.t('folder.settingsSigningKioskModeEnabled')}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('folder.settingsFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('folder.settingsFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>}

			<DialogActions>
				<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={!haveAllInfo || !folderSettings.name?.trim()}
						onClick={this.onUpdate}
						id="btn-settings-confirm"
				>
					{this.props.t('folder.settingsSave')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeName = (e) => {
		this.props.onFolderChangeSetting('name', e.target.value);
	}

	onChangeDescription = (e) => {
		this.props.onFolderChangeSetting('description', e.target.value);
	}

	onChangeSignatureType = (type, enabled) => {
		const types = this.props.folderSettings.enabledSignatureTypes
			.filter(t => t !== type);
		if (enabled) types.push(type);
		this.props.onFolderChangeSetting('enabledSignatureTypes', types);
		if (type === "ITSME" && !enabled && this.props.folderSettings.useCompanyItsmePartnerCode) {
			this.props.onFolderChangeSetting('useCompanyItsmePartnerCode', false);
		}
	}

	onChangeMailboxEnabled = (e, value) => {
		this.props.onFolderChangeSetting('mailboxEnabled', value);
	}

	onChangeUseCompanyItsmePartnerCode = (e, value) => {
		this.props.onFolderChangeSetting('useCompanyItsmePartnerCode', value);
	}

	onChangeReasonAvailable = (e, value) => {
		this.props.onFolderChangeSetting('reasonAvailable', value);
	}

	onChangeReasonLegalNoticeMandatory = (e, value) => {
		this.props.onFolderChangeSetting('reasonLegalNoticeMandatory', value);
	}

	onChangeReasonLegalNoticeText = (e) => {
		this.props.onFolderChangeSetting('reasonLegalNoticeText', e.target.value);
	}

	onChangeRemarkAvailable = (e, value) => {
		this.props.onFolderChangeSetting('remarkAvailable', value);
	}

	onChangeCleanupSettingsOverwritten = (e, value) => {
		this.props.onFolderChangeSetting('cleanupSettingsOverwritten', value);
	}

	onChangeCleanupOfProcessedDocumentsInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onFolderChangeSetting('cleanupOfProcessedDocumentsInDays', !value ? 0 : value);
	}

	onChangeCleanupOfPendingDocumentsInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onFolderChangeSetting('cleanupOfPendingDocumentsInDays', !value ? 0 : value);
	}

	onChangeOverrideExpirationSettings = (e, value) => {
		this.props.onFolderChangeSetting('overwriteExpirationSettings', value);
	}

	onChangeEnableExpirationSettings = (e, value) => {
		this.props.onFolderChangeSetting('enableExpirationSettings', value);
	}

	onChangeExpirationOfSignatureInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onFolderChangeSetting('daysForSignatureExpiration', !value ? 0 : value);
	}

	onChangeDefaultPostSignDocumentEmailDistributionListDelimited = (e) => {
		this.props.onFolderChangeSetting('defaultPostSignDocumentEmailDistributionListDelimited', e.target.value);
	}

	onChangeDefaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited = (e) => {
		this.props.onFolderChangeSetting('defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited', e.target.value);
	}

	onChangeAllowForwarding = (e, value) => {
		if (!value) {
			this.props.onFolderChangeSetting('forwardingEnabledByDefault', false);
		}
		this.props.onFolderChangeSetting('allowForwarding', value);
	}

	onChangeForwardingEnabledByDefault = (e, value) => {
		this.props.onFolderChangeSetting('forwardingEnabledByDefault', value);
	}

	onOverridePostSignEmailDistributionSetting = (value) => {
		if (!value) {
			// clear the overriden values
			this.props.onFolderChangeSetting('defaultPostSignDocumentEmailDistributionListDelimited', null);
			this.props.onFolderChangeSetting('defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited', null);
		} else {
			// initialize the overriden values with the ones from company level
			this.props.onFolderChangeSetting('defaultPostSignDocumentEmailDistributionListDelimited', this.props.folderCompanySettings.companyEmailSettings.defaultPostSignDocumentEmailDistributionListDelimited);
			this.props.onFolderChangeSetting('defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited', this.props.folderCompanySettings.companyEmailSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited);
		}
		this.props.onFolderChangeSetting('companyPostSignEmailDistSettingOverridden', value);
	}

	onChangeReminderIntervalInDaysOverwrite = (e, value) => {
		this.props.onFolderChangeSetting('reminderIntervalInDays', value ? 0 : null);
	}

	onChangeReminderIntervalInDays = (e) => {
		const value = parseInt(e.target.value, 10);
		if (!isNaN(value) && value > 0) {
			this.props.onFolderChangeSetting('reminderIntervalInDays', value);
		} else {
			this.props.onFolderChangeSetting('reminderIntervalInDays', 0);
		}
	}

	onChangeCollectionInvitationsMinimizeFolder = (e, value) => {
		if (!value) {
			this.props.onFolderChangeSetting('collectionInvitationsGraceTimeMinutes', null);
		}
		this.props.onFolderChangeSetting('collectionInvitationsMinimize', value ? false : null);
	}

	onChangeCollectionInvitationsMinimize = (e, value) => {
		if (!value) {
			this.props.onFolderChangeSetting('collectionInvitationsGraceTimeMinutes', null);
		}
		this.props.onFolderChangeSetting('collectionInvitationsMinimize', value);
	}

	onChangeCollectionInvitationsGraceTimeMinutes = (e) => {
		const value = parseInt(e.target.value, 10);
		if (!isNaN(value) && value > 0) {
			this.props.onFolderChangeSetting('collectionInvitationsGraceTimeMinutes', value);
		} else {
			this.props.onFolderChangeSetting('collectionInvitationsGraceTimeMinutes', null);
		}
	}

	onChangeCollectionFinishedNotificationsMinimizeFolder = (e, value) => {
		this.props.onFolderChangeSetting('collectionFinishedNotificationsMinimize', value ? false : null);
	}

	onChangeCollectionFinishedNotificationsMinimize = (e, value) => {
		this.props.onFolderChangeSetting('collectionFinishedNotificationsMinimize', value);
	}

	onChangeSignerNeedsSignatureToDownloadFolder =  (e, value) => {
		this.props.onFolderChangeSetting('signerNeedsSignatureToDownload', value ? false : null);
	}

	onChangeSignerNeedsSignatureToDownload = (e, value) => {
		this.props.onFolderChangeSetting('signerNeedsSignatureToDownload', value);
	}

	onChangePlaceholderValue = (e) => {
		this.setState({placeholderValue: e.target.value});
	}

	onChangePlaceholderFieldType = (e) => {
		this.setState({placeholderFieldType: e.target.value});
	}

	onChangePlaceholderFormFieldType = (e) => {
		this.setState({placeholderFormFieldType: e.target.value});
	}

	onAddPlaceholder = () => {
		const placeholders = (this.props.folderSettings?.placeholderLookups || [])
			.filter(search => search.placeholder !== this.state.placeholderValue);

		placeholders.push({
			placeholder: this.state.placeholderValue,
			fieldType: this.state.placeholderFieldType,
			formFieldType: 'FORM' === this.state.placeholderFieldType ? this.state.placeholderFormFieldType : undefined,
		});

		this.props.onFolderChangeSetting('placeholderLookups', placeholders);
	}

	onDeletePlaceholder = (index) => {
		const placeholders = [...(this.props.folderSettings?.placeholderLookups || [])];
		placeholders.splice(index, 1);
		this.props.onFolderChangeSetting('placeholderLookups', placeholders);
	}

	onChangeUserUsageRegisteredUsed = (e, value) => {
		this.props.onFolderChangeSetting('userUsage', {
			...this.props.folderSettings.userUsage,
			canUseRegisteredUser: value
		});
		if (!value) {
			this.props.onFolderChangeSetting('userInvite', {
				...this.props.folderSettings.userInvite,
				canInviteRegisteredUsers: false
			});
		}
	}

	onChangeUserUsageGuest = (e, value) => {
		this.props.onFolderChangeSetting('userUsage', {
			...this.props.folderSettings.userUsage,
			canUseGuest: value
		});
		if (!value) {
			this.props.onFolderChangeSetting('userInvite', {
				...this.props.folderSettings.userInvite,
				canInviteGuests: false
			});
		}
	}

	onChangeUserUsageContact = (e, value) => {
		this.props.onFolderChangeSetting('userUsage', {
			...this.props.folderSettings.userUsage,
			canUseContact: value
		});
		if (!value) {
			this.props.onFolderChangeSetting('userInvite', {
				...this.props.folderSettings.userInvite,
				canInviteContacts: false
			});
		}
	}

	onChangeUserInviteRegisteredUsers = (e, value) => {
		this.props.onFolderChangeSetting('userInvite', {
			...this.props.folderSettings.userInvite,
			canInviteRegisteredUsers: value
		});
	}

	onChangeUserInviteGuests = (e, value) => {
		this.props.onFolderChangeSetting('userInvite', {
			...this.props.folderSettings.userInvite,
			canInviteGuests: value
		});
	}

	onChangeUserInviteContacts = (e, value) => {
		this.props.onFolderChangeSetting('userInvite', {
			...this.props.folderSettings.userInvite,
			canInviteContacts: value
		});
	}

	onChangeMailRedirectEnabled = (e, value) => {
		this.props.onFolderChangeSetting('mailRedirectEnabled', value);
	}

	onChangeKioskSigningOrderTypeEnabled = (e, value) => {
		this.props.onFolderChangeSetting('kioskSigningOrderTypeEnabled', value);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		const defaultPostSignDocumentEmailDistributionListDelimited =
			this.props.folderSettings.defaultPostSignDocumentEmailDistributionListDelimited && this.props.folderSettings.defaultPostSignDocumentEmailDistributionListDelimited.trim();
		if (!!defaultPostSignDocumentEmailDistributionListDelimited) {
			this.props.folderSettings.defaultPostSignDocumentEmailDistributionList = defaultPostSignDocumentEmailDistributionListDelimited.split(';');
		} else {
			this.props.folderSettings.defaultPostSignDocumentEmailDistributionList = [];
		}

		const defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited =
			this.props.folderSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited && this.props.folderSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited.trim();
		if (!!defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited) {
			this.props.folderSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionList = defaultPostSignDocumentAndEvidenceReportEmailDistributionListDelimited.split(';');
		} else {
			this.props.folderSettings.defaultPostSignDocumentAndEvidenceReportEmailDistributionList = [];
		}

		this.props.onFolderUpdateSettings(this.props.folderSettings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			folderSettings: state.folder.settings,
			folderCompanySettings: state.folder.companySettings,
		}
	},
	dispatch => {
		return {
			onFolderFetchCompanySettings: () => {
				dispatch({
					type: 'FOLDER_FETCH_COMPANY_SETTINGS',
				});
			},
			onFolderFetchSettings: (folderId) => {
				dispatch({
					type: 'FOLDER_FETCH_SETTINGS',
					folderId
				});
			},
			onFolderChangeSetting: (key, value) => {
				dispatch({
					type: 'FOLDER_CHANGE_SETTING',
					key,
					value
				});
			}
		}
	}
)(FolderSettingsDialog));
