import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tab, Tabs} from "@mui/material";
import EmailTemplateFieldsComponent from "./EmailTemplateFieldsComponent";
import PreviewIcon from "@mui/icons-material/Preview";
import MailIcon from "@mui/icons-material/Mail";
import {EMAIL_LANGUAGES} from "../common/EmailConstants";
import LoadingComponent from "../common/LoadingComponent";

const DEFAULT_STATE = {
	language: 'ENGLISH',
	template: null,
	templateNames: null,
	customTemplateFields: null,
	previewDialogOpen: false,
};

const EmailPreviewDialog = ({contentHtml, onClose}) => {

	const {t} = useTranslation();

	const onKeyUp = (e) => {
		if (e.key === 'Enter' || e.key === 'Esc') {
			onClose();
		}
	}

	return <Dialog open onClose={onClose} maxWidth="md" fullWidth onKeyUp={onKeyUp}>
		<DialogTitle>{t('mail.preview')}</DialogTitle>
		{!contentHtml && <LoadingComponent/>}
		{!!contentHtml &&
			<iframe height="800px" srcDoc={contentHtml}/>}
		<DialogContent>
			<DialogActions>
				<Button onClick={onClose}
						id="btn-preview-close"
				>
					{t('close')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}

class EmailTemplatesFieldsDialog extends Component {

	constructor(props) {
		super(props);

		this.convertHtmlRef = createRef();
		this.state = {
			...DEFAULT_STATE,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			const templateNames = Object.keys(this.props.defaultFields);
			const customTemplateFields = {...this.props.fields};
			const template = templateNames.length > 0 ? templateNames[0] : null;

			this.setState({
				DEFAULT_STATE,
				templateNames,
				customTemplateFields,
				template,
			});
		}
	}

	render() {
		const customTemplate = this.state.customTemplateFields && this.state.customTemplateFields[this.state.template];
		const templateMap = !!customTemplate ? customTemplate : this.props.defaultFields[this.state.template];
		const custom = !!customTemplate;

		return <Dialog open={this.props.open} onClose={this.onClose} fullWidth maxWidth="xl">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
					<Box sx={{mb: 1, mt: 1, display: 'flex'}}>
						<FormControl size="small">
							<InputLabel
								id="type-label">{this.props.t('mail.templateType')}</InputLabel>
							<Select
								value={this.state.template}
								onChange={this.onChangeTemplate}
								size="small"
								label="Type"
								labelId="type-label"
							>
								{!!this.state.templateNames && this.state.templateNames
									.sort()
									.map((value, index) =>
										<MenuItem key={index} value={value}>
											{value}
										</MenuItem>
									)}
							</Select>
						</FormControl>

						<FormControlLabel
							control={<Checkbox checked={custom}
											   onChange={this.onChangeTemplateOverride}/>}
							label={this.props.t('mail.fieldOverride')}
							sx={{ml: 1}}
						/>
					</Box>

					<Box sx={{display: 'flex'}}>
						<Box sx={{alignSelf: 'center', mr: 1}}>
							<Button variant="contained" onClick={this.onPreviewEmail} startIcon={<PreviewIcon/>} id="btn-preview-email">{this.props.t('mail.preview')}</Button>
						</Box>

						<Box sx={{alignSelf: 'center'}}>
							<Button variant="contained" onClick={this.onSendTestEmail} startIcon={<MailIcon/>} id="btn-send-test-email">{this.props.t('mail.test')}</Button>
						</Box>
					</Box>
				</Box>

				<Box>
					<Tabs value={this.state.language}
						onChange={this.onChangeLanguage}
						  variant="scrollable"
						  scrollButtons
						  sx={{mb: 2}}
					>
						{EMAIL_LANGUAGES.map(entry => <Tab key={entry.value} value={entry.value}
														   label={this.props.t('language.' + entry.lang)}/>)}
					</Tabs>
				</Box>

				<EmailTemplateFieldsComponent
					templateId={this.state.template + '-' + (custom ? 'override' : 'default')}
					templateMap={templateMap}
					disabled={!custom}
					convertHtmlRef={this.convertHtmlRef}
					language={this.state.language}
				/>

				{this.state.previewDialogOpen && <EmailPreviewDialog
					onClose={this.onClosePreviewDialog}
					contentHtml={this.props.emailPreview}
				/>}

			</DialogContent>
			<DialogActions>
				<Button onClick={this.props.onClose}
						id="btn-email-text-cancel"
				>
					{this.props.t('cancel')}
				</Button>
				<Button variant="contained"
						onClick={this.onConfirm}
						id="btn-email-text-confirm"
				>
					{this.props.t('ok')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	getUpdatedCustomTemplateFields = () => {
		// convert the editor's current state in html
		const selectedTemplateOverriden = !!this.state.customTemplateFields[this.state.template];
		return {
			...this.state.customTemplateFields,
			...(selectedTemplateOverriden && {[this.state.template]: this.convertHtmlRef.current.convert()})
		};
	}

	onChangeTemplateOverride = (e, override) => {
		let customTemplateFields;
		const template = this.state.template;
		const defaultFields = this.props.defaultFields[template];

		if (override) {
			customTemplateFields = {
				...this.state.customTemplateFields,
				[template]: defaultFields
			}
		} else {
			customTemplateFields = {...this.state.customTemplateFields}
			delete customTemplateFields[template];
		}
		this.setState({customTemplateFields});
	}

	onChangeTemplate = (e) => {
		const template = e.target.value;
		// before switching to another template, first persist the editor's html to the state
		const customTemplateFields = this.getUpdatedCustomTemplateFields();

		this.setState({customTemplateFields, template});
	}

	onChangeLanguage = (e, language) => {
		this.setState({language});
	}

	onClosePreviewDialog = (e) => {
		this.setState({previewDialogOpen: false});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onSendTestEmail = () => {
		this.props.onCompanyTestsSendEmail({
				language: this.state.language,
				mailTemplate: this.state.template,
				emailCustomSettings: {
					...this.props.emailCustomSettings,
					templatesContentFields: this.getUpdatedCustomTemplateFields()
				}
			}
		);
	}

	onPreviewEmail = () => {
		this.setState({previewDialogOpen: true}, () =>
			this.props.onCompanyPreviewEmail({
					language: this.state.language,
					mailTemplate: this.state.template,
					emailCustomSettings: {
						...this.props.emailCustomSettings,
						templatesContentFields: this.getUpdatedCustomTemplateFields()
					}
				}
			));
	}

	onConfirm = () => {
		const updatedCustomTemplateFields = this.getUpdatedCustomTemplateFields();
		this.props.onChange(updatedCustomTemplateFields);
	}
}

export default withTranslation()(connect(
	state => {
		return {
			emailCustomSettings: state.company.emailSettings.emailCustomSettings,
			emailPreview: state.company.emailSettings.emailPreview,
		}
	},
	dispatch => {
		return {
			onCompanyTestsSendEmail: (request) => {
				dispatch({
					type: 'COMPANY_TEST_SEND_EMAIL',
					request,
				});
			},onCompanyPreviewEmail: (request) => {
				dispatch({
					type: 'COMPANY_PREVIEW_EMAIL',
					request,
				});
			},
		}
	}
)(EmailTemplatesFieldsDialog));
