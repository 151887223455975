import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const UploadBox = styled(Box)(({theme}) => ({
	width: '100%',
	height: '10em',
	border: '1px dashed grey',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'repeating-linear-gradient(-45deg, #FFFFFF, #FFFFFF 10px, #F2F2F2 10px, #F2F2F2 20px)',
	"&:hover": {
		background: 'repeating-linear-gradient(-45deg, #F0F0FF, #F0F0FF 10px, #E2E2F2 10px, #E2E2F2 20px)',
	},
}));

export default UploadBox;