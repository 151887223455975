import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Box, Button, LinearProgress, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadBox from "./UploadBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const inputPropsTextField = {
	width: '100%',
	style: {fontFamily: 'monospace', fontSize: 'smaller'},
	wrap: 'off',
};

class FileUploadComponent extends Component {

	render() {
		return <>
			<Box sx={{display: 'flex', gap: 2, mt: 1}}>
				<TextField
					variant="outlined"
					label={this.props.t('upload.fileName')}
					disabled={true}
					size="small"
					value={this.props.uploadedFile?.name || ''}
					onDrop={this.onDrop}
					sx={{flex: 1}}
				/>
				<Button
					variant="contained"
					startIcon={<AddIcon/>}
					size="small"
					onClick={this.onSelectFile}
					disabled={this.props.disabled}
					id="btn-upload-select"
				>
					{this.props.t('upload.selectFile')}
				</Button>
			</Box>

			{true !== this.props.uploadFinished && <LinearProgress
				variant={this.props.uploadProgress >= (100 - Number.EPSILON) ? 'indeterminate' : 'determinate'}
				value={this.props.uploadProgress}
				sx={{
					mt: 1,
					visibility: !!this.props.uploadedFile ? '' : 'hidden'
				}}
			/>}

			{this.renderUploadBox(this.props.uploadBoxType)}

			<input type="file" id="file" accept={this.props.accepted} style={{display: 'none'}}
				   onChange={this.onChangeFile}/>
		</>
	}

	renderUploadBox = (uploadBoxType) => {
		if (uploadBoxType === 'static') {
			return <UploadBox
				onDragEnter={this.onStopDrag}
				onDragOver={this.onStopDrag}
				onDrop={this.onDrop}
				sx={{
					mt: 2,
					height: '5em',
					opacity: this.props.disabled ? 0.5 : 1
				}}
			>
				<CloudUploadIcon sx={{opacity: this.props.disabled ? 0.5 : 1}}
								 fontSize="large"/>
				<Typography
					sx={{opacity: this.props.disabled ? 0.5 : 1}}>{this.props.t('upload.dragFileHere')}
				</Typography>
			</UploadBox>
		} else if (uploadBoxType === 'textField') {
			return <TextField
				multiline={true}
				minRows={5}
				maxRows={20}
				placeholder={this.props.textFieldPlaceholder}
				value={this.props.textFieldData}
				onChange={this.onEditTextField}
				onDragEnter={this.onStopDrag}
				onDragOver={this.onStopDrag}
				onDrop={this.onDrop}
				inputProps={inputPropsTextField}
				sx={{
					width: '100%',
					mt: 2,
				}}/>
		}
	}

	onDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();

		this.props.onAddFile(e?.dataTransfer?.files[0]);
	}

	onStopDrag = (e) => {
		e.stopPropagation();
		e.preventDefault();
	}

	onSelectFile = () => {
		document.getElementById('file').click();
	}

	onChangeFile = () => {
		this.props.onAddFile(document.getElementById('file').files[0]);
	}

	onEditTextField = (e) => {
		this.props.onChangeText(e.target.value);
	}
}

export default withTranslation()(FileUploadComponent);