import {SvgIcon} from "@mui/material";

export default () => {
	return <SvgIcon>
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492.137 492.137">
			<g>
				<path d="M153.147,77.396c38.885-38.88,98.156-45.054,143.668-18.795l-11.98,11.979c-3.447,3.439-4.475,8.597-2.615,13.094
		c1.858,4.465,6.225,7.409,11.1,7.399l53.076-0.022c6.627,0,12.012-5.389,12.012-12.003V25.991c0-4.891-2.918-9.254-7.393-11.105
		c-4.52-1.852-9.667-0.826-13.099,2.607l-17.144,17.15C261.856-4.1,181.702,2.403,129.927,54.176
		c-6.418,6.414-6.418,16.806,0,23.221C136.34,83.809,146.729,83.809,153.147,77.396z"/>
				<path d="M346.797,414.734c-38.886,38.878-98.158,45.054-143.668,18.792l11.98-11.978c3.446-3.44,4.475-8.594,2.616-13.094
		c-1.864-4.465-6.227-7.406-11.102-7.399l-53.076,0.024c-6.626,0-12.011,5.388-12.011,12.002v53.057
		c0,4.891,2.917,9.253,7.393,11.105c4.518,1.852,9.667,0.826,13.099-2.607l17.143-17.15c25.223,16.582,54.332,24.887,83.452,24.887
		c38.89,0,77.775-14.809,107.395-44.419c6.417-6.415,6.417-16.807,0-23.22C363.603,408.318,353.215,408.318,346.797,414.734z"/>
					<path d="M188.888,331.977c3.273-3.293,5.083-7.664,5.083-12.291V135.952c0-9.583-7.808-17.384-17.396-17.384H17.4
		c-9.598,0-17.4,7.801-17.4,17.384v220.209c0,9.598,7.803,17.398,17.4,17.398h122.708c4.647,0,9.014-1.813,12.297-5.108
		L188.888,331.977z M159.189,153.352v148.951h-19.081c-9.588,0-17.4,7.801-17.4,17.383v19.074H34.78V153.352H159.189z"/>
					<path d="M474.736,118.568H315.541c-9.588,0-17.381,7.801-17.381,17.384v220.209c0,9.598,7.793,17.398,17.381,17.398h122.713
		c4.648,0,9.01-1.813,12.298-5.108l36.496-36.475c3.273-3.293,5.089-7.664,5.089-12.291V135.952
		C492.137,126.369,484.324,118.568,474.736,118.568z M457.336,153.352v148.951h-19.082c-9.593,0-17.386,7.801-17.386,17.383v19.074
		h-87.927V153.352H457.336z"/>
				</g>
			</svg>
		</SvgIcon>
	}