import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";

class ConfirmationDialog extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Dialog open={this.props.open} onClose={this.onClose} onKeyUp={this.onKeyUp} fullWidth maxWidth="sm">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>{this.props.confirm}</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-confirmation-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" onClick={this.props.onConfirm} id="btn-confirmation-confirm">{this.props.t('confirm')}</Button>
			</DialogActions>
		</Dialog>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !!this.props.onConfirm) {
			this.props.onConfirm();
		}
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(ConfirmationDialog);
