import React, {Component} from "react";
import {Alert, AlertTitle, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Link, List, ListItem, ListItemText, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {GENERATE_USER_IMPORT_TEMPLATE_DATA, GENERATE_USER_IMPORT_WITH_FIXED_CONTACTS_FOLDER_DATA} from "../user/UserImportData";
import {saveAs} from "file-saver";
import FileUploadComponent from "../common/FileUploadComponent";

const defaultState = {
	item: null,
	dataCsv: '',

	hasCsvHeader: true,
	inviteUsers: false,

	uploading: false,
	uploadProgress: 0,

	parsingError: false,
	lookupResult: null,
	importResult: null
};

class UserImportLookupDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({...defaultState, inviteUsers: this.props.canInviteUsers});
		}
	}

	render() {
		const usersNotFoundOrCreated = this.state.importResult?.userNotCreatedDetails
			?.filter(detail => detail.error !== 'USER_ALREADY_EXISTS' && detail.error !== 'CONVERT_REGISTERED_TO_GUEST');
		const hasUsersNotFoundOrCreated = usersNotFoundOrCreated?.length > 0;
		const usersNotFound = this.state.lookupResult?.emailsNotFound?.length > 0;

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<FileUploadComponent
					disabled={false}
					onAddFile={this.onAddFile}
					uploadProgress={this.state.uploadProgress}
					uploadFinished={!!this.state.lookupResult || !!this.state.importResult || !!this.state.parsingError}
					uploadedFile={this.state.item?.file}
					uploadBoxType={'textField'}
					textFieldPlaceholder={this.props.t("user.importPasteOrDropFile")}
					onChangeText={this.onChangeData}
					textFieldData={this.state.dataCsv}
					accepted={".csv,.txt"}
				/>

				<Box sx={{display: 'flex', alignItems: 'left'}}>
					{this.props.canInviteUsers &&
						<FormControlLabel
							control={<Checkbox checked={this.state.inviteUsers}
											   onChange={this.onChangeInviteUsers}/>}
							label={this.props.t('user.importInviteUsers')}
							disabled={this.state.uploading || !this.state.hasCsvHeader}
						/>}
					<FormControlLabel
						control={<Checkbox checked={!this.state.hasCsvHeader}
										   onChange={this.onChangeHasCsvHeader}/>}
						label={this.props.t('user.importNoHeader')}
						disabled={this.state.uploading}
					/>
				</Box>
				<Link
					component="button"
					variant="body1"
					onClick={() => this.onDownloadTemplate(this.props.allowContacts)}
				>
					{this.props.t('user.importDownloadTemplate')}
				</Link>

				{!!this.state.lookupResult && <Box>
					<Alert
						severity={usersNotFound ? "warning" : "success"}>
						<AlertTitle>
							{this.props.t('user.importLookupFoundUsersResult') + ': ' + this.state.lookupResult.existingUsers.length + '/' + this.state.lookupResult.totalRecords}
						</AlertTitle>
						{usersNotFound && <>
							<Typography>{this.props.t('user.importLookupUsersNotFound')}</Typography>
							<List sx={{pt: 0, pb: 0, mt: 0, mb: 0}}>
								{this.state.lookupResult.emailsNotFound.map(this.renderEmailNotFound)}
							</List>
						</>}
					</Alert>
				</Box>}

				{!!this.state.importResult && <Box>
					<Alert
						severity={hasUsersNotFoundOrCreated ? "warning" : "success"}>
						<AlertTitle>
							{this.props.t('user.importLookupCreatedUsersResult') + ': ' + this.state.importResult.importedUsers.length + '/' + this.state.importResult.totalRecords}
						</AlertTitle>
						<AlertTitle>
							{this.props.t('user.importLookupFoundUsersResult') + ': ' + this.state.importResult.existingUsers.length + '/' + this.state.importResult.totalRecords}
						</AlertTitle>
						{hasUsersNotFoundOrCreated && <>
							<Typography>{this.props.t('user.uploadImportedErrors')}</Typography>
							<List>{usersNotFoundOrCreated.map(this.renderImportError)}</List>
						</>}
					</Alert>
				</Box>}

				{this.hasInvalidFileExtension() &&
					<Alert severity="error">{this.props.t('user.uploadInvalidFileExtension')}</Alert>}

				{this.state.parsingError &&
					<Alert severity="error">{this.props.t('user.uploadParsingError')}</Alert>
				}

				{<ServerErrorComponent serverError={this.props.userServerError}/>}

			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-back">{this.props.t('back')}</Button>
				<Button variant="contained"
						disabled={!this.canContinue() || this.state.uploading}
						onClick={this.onUpload}
						id="btn-create-confirm"
				>
					{this.props.t('user.import')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeData = (data) => {
		this.setState({dataCsv: data});
	}

	onAddFile = (file) => {
		if (!file) {
			return;
		}

		const item = {
			file: file,
			name: file.name
		};

		this.setState({
			item: item,
			parsingError: false,
			uploadProgress: 0,
		}, () => {
			if (!this.hasInvalidFileExtension()) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					this.onChangeData(result);
				};
				reader.readAsText(file);
			}
		});
	}

	hasInvalidFileExtension = () => {
		const fileExtension = this.state.item?.name?.split('.').pop();
		return !!this.state.item?.name && !(fileExtension === 'csv' || fileExtension === 'txt');
	}

	canContinue = () => {
		return !!this.state.dataCsv && this.state.dataCsv.trim().length > 0;
	}

	onUpload = () => {
		const xhr = new XMLHttpRequest();

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				this.onProgressUpdate(Math.round((event.loaded * 100) / event.total));
			}
		})

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (!xhr.responseText) {
						this.setState({
							parsingError: true
						});
						return;
					}

					const response = JSON.parse(xhr.responseText);

					if (response.hasOwnProperty('importedUsers')) {
						this.setState({
							importResult: {
								importedUsers: response.importedUsers,
								existingUsers: response.existingUsers,
								totalRecords: response.totalRecords,
								userNotCreatedDetails: response.userNotCreatedDetails
							}
						});
						this.props.onSelect([...response.importedUsers, ...response.existingUsers], false);
					} else {
						this.setState({
							lookupResult: {
								existingUsers: response.existingUsers,
								totalRecords: response.totalRecords,
								emailsNotFound: response.emailsNotFound
							}
						});
						this.props.onSelect(response.existingUsers, false);
					}
				}
			}
		};

		xhr.onloadend = () => {
			this.setState({
				uploading: false
			});
		}

		const formData = new FormData();
		formData.append('files', new Blob([this.state.dataCsv], {type: 'text/csv;charset=UTF-8'}), 'import_users_blob.csv');

		if (!this.state.inviteUsers) {
			xhr.open('POST', '/api/internal/user/lookup', true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			formData.append('hasCsvHeader', this.state.hasCsvHeader);
		} else {
			xhr.open('POST', '/api/internal/user/import', true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			formData.append('allowUpdate', 'false');
			formData.append('folderId', this.props.folderId);
		}

		this.setState({
			uploading: true,
			parsingError: false,
			lookupResult: null,
			importResult: null
		})
		document.getElementById('file').value = null;

		xhr.send(formData);
	}

	onProgressUpdate = (progress) => {
		this.setState({uploadProgress: progress});
	}

	onChangeHasCsvHeader = (e, value) => {
		// no header implies lookup (no invite)
		const inviteUsers = value ? false : this.state.inviteUsers;
		this.setState({hasCsvHeader: !value, inviteUsers});
	}

	onChangeInviteUsers = (e, inviteUsers) => {
		this.setState({inviteUsers});
	}

	renderEmailNotFound = (email) => {
		return <ListItem sx={{pt: 0, pb: 0}} key={email}>
			<ListItemText>
				<Typography>{email}</Typography>
			</ListItemText>
		</ListItem>;
	}

	renderImportError = (importError) => {
		return <ListItem sx={{pt: 0, pb: 0}} key={importError.email}>
			<ListItemText>
				<Typography sx={{fontWeight: 'bold'}}>{importError.email}</Typography>
				<Typography>{this.props.t('user.uploadError_' + importError.error)}</Typography>
			</ListItemText>
		</ListItem>;
	}

	onDownloadTemplate = (allowContacts) => {
		const blob = new Blob([allowContacts ? GENERATE_USER_IMPORT_WITH_FIXED_CONTACTS_FOLDER_DATA() : GENERATE_USER_IMPORT_TEMPLATE_DATA()], {type: 'text/plain;charset=utf-8'});
		saveAs(blob, "User import template.csv");
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(UserImportLookupDialog);
