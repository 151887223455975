import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Checkbox, IconButton, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import LoadingComponent from "../common/LoadingComponent";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";
import FlexibleToolbar from "../common/FlexibleToolbar";
import CompanyOidcInstanceCreateDialog from "../oidc/OidcInstanceCreateDialog";
import CompanyOidcInstanceUpdateDialog from "../oidc/OidcInstanceUpdateDialog";

class CompanyOidcComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filterValue: '',
			filterValueUC: '',

			activeOidcInstanceId: null,
			createDialogOpen: false,
			updateDialogOpen: false,
			deleteDialogOpen: false,

			sortModel: [{field: 'name', sort: 'asc'}],
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchOidcInstanceList();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.actionSuccessFully && !prevProps.actionSuccessFully) {
			// if a new configuration has just been created, automatically show the settings dialog
			if (this.props.createdOidcInstanceId && !prevProps.createdOidcInstanceId) {
				this.props.onCompanyFetchOidcInstanceList();
				this.onOpenDialogForEditing(this.props.createdOidcInstanceId);
			} else {
				this.props.onCompanyFetchOidcInstanceList();
			}
		}
	}

	render() {
		const overviewList = this.props.overviewList;

		if (!overviewList) {
			return <LoadingComponent/>;
		}
		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('oidcInstance.name'),
				editable: false,
				width: 200,
			},
			{
				field: 'serverUrl',
				headerName: this.props.t('oidcInstance.serverUrl'),
				editable: false,
				flex: 1,
			},
			{
				field: 'clientId',
				headerName: this.props.t('oidcInstance.clientId'),
				editable: false,
				flex: 1,
			},
			{
				field: 'clientSecretLength',
				headerName: this.props.t('oidcInstance.clientSecret'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => (<span>{'*'.repeat(cellValues.row.clientSecretLength)}</span>)
			},
			{
				field: 'autoCreateAllowed',
				headerName: this.props.t('oidcInstance.autoCreate'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.autoCreateAllowed}
						disabled={true}
					/>
			},
			{
				field: 'actions',
				headerName: this.props.t('oidcInstance.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('oidcInstance.edit')}
						onClick={() => this.onOpenDialogForEditing(cellValues.row.id)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('oidcInstance.delete')}
						onClick={() => this.onOpenDeleteDialog(cellValues.row.id)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];

		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('oidcInstance.oidcInstancesFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.serverError} />
			<Box sx={{mt: 2, ml: 2}}>
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
					<Button variant="contained"
							onClick={this.onOpenDialogForAdding}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={this.props.busy}
							id="btn-oidc-instance-create"
					>
						{this.props.t('oidcInstance.create')}
					</Button>
				</Box>
				<DataGrid
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick
					pagination

					initialState={{
						sorting: {
							sortModel: this.state.sortModel
						},
						pagination: {
							paginationModel: { pageSize: 10, page: 0 },
						},
					}}

					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					rows={overviewList.filter(instance =>
						!this.state.filterValue ||
						(instance?.name || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
						(instance?.serverUrl || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
						(instance?.clientId || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0
					)}
					pageSizeOptions={[10]}
					loading={this.props.busy}
					density="compact"
					autoHeight
				/>

				<CompanyOidcInstanceCreateDialog
					open={this.state.createDialogOpen}
					onClose={this.onCloseCreateDialog}
					onSave={this.onCompanyCreateOidcInstance}
				/>

				<CompanyOidcInstanceUpdateDialog
					open={this.state.updateDialogOpen}
					onClose={this.onCloseUpdateDialog}
					onSave={this.onCompanyUpdateOidcInstance}
					onUpdateLogo={this.props.onCompanyUpdateOidcInstanceLogo}
					oidcInstance={this.state.activeOidcInstance}
					enableAutoCreateAllowed={true}
				/>

				<ConfirmationDialog
					title={this.props.t('oidcInstance.delete')}
					confirm={this.props.t('oidcInstance.deleteConfirm')}
					open={this.state.deleteDialogOpen}
					onClose={this.onCloseDeleteDialog}
					onConfirm={this.onConfirmDeletion}
				/>
			</Box>
		</Box>
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onOpenDialogForAdding = () => {
		this.setState({createDialogOpen: true});
	}

	onCloseCreateDialog = () => {
		this.setState({oidcInstance: null, createDialogOpen: false});
	}

	onOpenDialogForEditing = (activeOidcInstanceId) => {
		this.setState({activeOidcInstanceId, updateDialogOpen: true}, () => this.props.onCompanyFetchOidcInstanceSettings(activeOidcInstanceId));
	}

	onCloseUpdateDialog = () => {
		this.setState({activeOidcInstanceId: null, updateDialogOpen: false});
	}

	onCompanyCreateOidcInstance = (oidcInstance) => {
		this.setState({createDialogOpen: false}, () => this.props.onCompanyCreateOidcInstance(oidcInstance));
	}

	onCompanyUpdateOidcInstance = (settings) => {
		const activeOidcInstanceId = this.state.activeOidcInstanceId;
		this.setState({updateDialogOpen: false, activeOidcInstanceId: null}, () => this.props.onCompanyUpdateOidcInstance(activeOidcInstanceId, settings));
	}

	onOpenDeleteDialog = (activeOidcInstanceId) => {
		this.setState({deleteDialogOpen: true, activeOidcInstanceId});
	}

	onCloseDeleteDialog = () => {
		this.setState({deleteDialogOpen: false});
	}

	onConfirmDeletion = () => {
		const activeOidcInstanceId = this.state.activeOidcInstanceId;
		this.setState({deleteDialogOpen: false, activeOidcInstanceId: null}, () => this.props.onCompanyDeleteOidcInstance(activeOidcInstanceId));
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			busy: state.oidcInstance.busy,
			serverError: state.oidcInstance.serverError,
			overviewList: state.oidcInstance.overviewList,
			actionSuccessFully: state.oidcInstance.actionSuccessFully,
			createdOidcInstanceId: state.oidcInstance.createdOidcInstanceId
		}
	},
	dispatch => {
		return {
			onCompanyFetchOidcInstanceList: () => {
				dispatch({
					type: 'COMPANY_OIDC_INSTANCE_FETCH_LIST'
				})
			},
			onCompanyCreateOidcInstance: (settings) => {
				dispatch({
					type: 'COMPANY_OIDC_INSTANCE_CREATE',
					settings
				})
			},
			onCompanyFetchOidcInstanceSettings: (oidcInstanceId) => {
				dispatch({
					type: 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS',
					oidcInstanceId
				});
			},
			onCompanyUpdateOidcInstance: (oidcInstanceId, settings) => {
				dispatch({
					type: 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS',
					oidcInstanceId,
					settings
				})
			},
			onCompanyUpdateOidcInstanceLogo: (oidcInstanceId, logoBase64, mimeType, fileSize) => {
				dispatch({
					type: 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO',
					oidcInstanceId,
					logoBase64,
					mimeType,
					fileSize
				});
			},
			onCompanyDeleteOidcInstance: (oidcInstanceId) => {
				dispatch({
					type: 'COMPANY_OIDC_INSTANCE_DELETE',
					oidcInstanceId
				})
			}
		}
	}
)(CompanyOidcComponent));
