import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Step, StepContent, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import ServerErrorComponent from "../common/ServerErrorComponent";

const MAX_REMARK_LENGTH = 255;

const defaultState = {
	useDelegate: true,
	targetEmail: '',
	targetFirstName: '',
	targetLastName: '',
	remark: '',
	displayError: false
}

class SigningForwardDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultState;
		this.state.selectedSignRequestIds = this.getSignRequestIds();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
				selectedSignRequestIds: this.getSignRequestIds()
			});
		}
	}

	getSignRequestIds() {
		return new Set((this.props.signRequests || []).map((signRequest) => signRequest.signRequestId));
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('signing.forwardHeader')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('signing.forwardSelectSignRequests')}</StepLabel>
						<StepContent>
							<FormGroup>
								{this.props.signRequests.map((signRequest) =>
									<FormControlLabel key={signRequest.signRequestId}
													  control={<Checkbox defaultChecked
																		 onChange={(e, value) => this.onSignRequestChecked(signRequest.signRequestId, value)}/>}
													  label={signRequest.capacityTitle ? signRequest.name + ' (' + signRequest.capacityTitle + ')' : signRequest.name}
													  sx={{m: 0}}>
									</FormControlLabel>
								)}
							</FormGroup>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('signing.forwardSelectTargetUser')}</StepLabel>
						<StepContent>
							<FormControlLabel
								control={<Checkbox onChange={this.onUseDelegate} checked={this.state.useDelegate}/>}
								label={this.props.t('signing.forwardUseDelegate')}
							/>
							{!this.state.useDelegate && <>
								<Typography>{this.props.t('signing.forwardUserDetails')}</Typography>
								<TextField
									variant="outlined"
									label={this.props.t('signing.forwardFirstName')}
									required={!this.state.useDelegate}
									value={this.state.targetFirstName}
									onChange={this.onChangeTargetFirstName}
									autoComplete="off"
									fullWidth
									sx={{mb: 2, mt: 2}}
								/>
								<TextField
									variant="outlined"
									label={this.props.t('signing.forwardLastName')}
									required={!this.state.useDelegate}
									value={this.state.targetLastName}
									onChange={this.onChangeTargetLastName}
									autoComplete="off"
									fullWidth
									sx={{mb: 2}}
								/>
								<TextField
									variant="outlined"
									label={this.props.t('signing.forwardEmail')}
									required={!this.state.useDelegate}
									value={this.state.targetEmail}
									onChange={this.onChangeTargetEmail}
									autoComplete="off"
									fullWidth
								/>
							</>}
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('signing.forwardRemarkHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('signing.forwardRemark')}
								value={this.state.remark}
								onChange={this.onChangeRemark}
								autoComplete="off"
								fullWidth
							/>
							<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
								<Typography variant="body2">{this.state.remark.length + "/" + MAX_REMARK_LENGTH}</Typography>
							</Box>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			{this.state.displayError &&
				<Box sx={{mx: 2}}>
					<ServerErrorComponent serverError={this.props.serverError}/>
				</Box>}
			<DialogActions>
				<Button variant="outlined" onClick={this.onClose} id="btn-forward-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={!this.canForward() && !this.props.busy}
						onClick={this.onForward}
						id="btn-forward-forward"
				>
					{this.props.t('signing.forward')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onSignRequestChecked = (id, checked) => {
		const selectedSignRequestIds = this.state.selectedSignRequestIds;
		if (checked) {
			selectedSignRequestIds.add(id);
		} else {
			selectedSignRequestIds.delete(id);
		}
		this.setState(selectedSignRequestIds);
	}

	onChangeTargetFirstName = (e) => {
		this.setState({targetFirstName: e.target.value});
	}

	onChangeTargetLastName = (e) => {
		this.setState({targetLastName: e.target.value});
	}

	onChangeTargetEmail = (e) => {
		this.setState({targetEmail: e.target.value});
	}

	onChangeRemark = (e) => {
		let remark = e.target.value;
		if (remark.length > MAX_REMARK_LENGTH) {
			remark = remark.substring(0, MAX_REMARK_LENGTH);
		}
		this.setState({remark});
	}

	onUseDelegate = (e, value) => {
		const useDelegate = value;
		this.setState({useDelegate});
	}

	canForward = () => {
		if (!this.state.selectedSignRequestIds || this.state.selectedSignRequestIds.size < 1) {
			return false;
		}
		return this.state.useDelegate || (!!this.state.targetFirstName.trim() && !!this.state.targetLastName.trim() && !!this.state.targetEmail.trim());
	}

	onForward = () => {
		this.props.onForward({
			toDelegate: this.state.useDelegate,
			email: this.state.targetEmail,
			firstName: this.state.targetFirstName,
			lastName: this.state.targetLastName,
			signRequestIds: Array.from(this.state.selectedSignRequestIds),
			remark: this.state.remark
		}, this.props.isGuest);
		this.setState({displayError: true})
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			signRequests: (state.signing.data?.documents || [])
				.filter(doc => doc.signRequestState === 'WAITING_FOR_SIGNATURE')
				.filter(doc => doc.forwardingEnabled),
			busy: state.signing.busy,
			serverError: state.signing.serverError,
		}
	},
	dispatch => {
		return {
			onForward: (request, isGuest) => {
				dispatch({
					type: 'SIGNING_FORWARD',
					request,
					isGuest
				});
			}
		}
	}
)(SigningForwardDialog));
