import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import FlexibleToolbar from "../common/FlexibleToolbar";
import FolderCreationDialog from "./FolderCreationDialog";
import FolderSettingsDialog from "./FolderSettingsDialog";
import FolderPermissionsDialog from "./FolderPermissionsDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import FolderDelegatesDialog from "./FolderDelegatesDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";

class FolderOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'name', sort: 'asc'}],

			// dialogs
			creationDialogOpen: false,
			updateDialogOpen: false,
			permissionDialogOpen: false,
			delegatesDialogOpen: false,
			deleteDialogOpen: false,

			// active folder
			activeFolderId: null
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.folderActionSuccessfully && !prevProps.folderActionSuccessfully) {
			this.onFolderFetchOverviewList();
		}
	}

	render() {
		const canManageFolders =
			this.props.sessionInfo?.companyAdmin ||
			this.props.sessionInfo?.companyFolderManager;

		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('folder.name'),
				editable: false,
				flex: 1
			},
			{
				field: 'description',
				headerName: this.props.t('folder.description'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				headerName: this.props.t('folder.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 170,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.settings')}
						onClick={() => {
							this.setState({activeFolderId: cellValues.row.id});
							this.onUpdateDialogOpen();
						}}>
						<SettingsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.permissions')}
						onClick={() => {
							this.setState({activeFolderId: cellValues.row.id});
							this.onPermissionDialogOpen();
						}}>
						<ManageAccountsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.delegates')}
						onClick={() => {
							this.setState({activeFolderId: cellValues.row.id});
							this.onDelegatesDialogOpen();
						}}>
						<RoomPreferencesIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.delete')}
						disabled={cellValues.row.defaultFolder || !canManageFolders}
						onClick={() => {
							this.setState({activeFolderId: cellValues.row.id});
							this.onDeleteDialogOpen();
						}}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</Fragment>)
			},
		];

		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('folder.header')}</Typography>
				{!this.state.delegatesDialogOpen && <ServerErrorComponent serverError={this.props.folderServerError} />}
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={this.props.folderBusy || !canManageFolders}
							id="btn-folder-create"
					>
						{this.props.t('folder.create')}
					</Button>
				</Box>
				<DataGrid
					autoHeight
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick

					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-folder-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					loading={this.props.folderBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					rows={this.props.folderOverviewList}
					rowCount={this.props.folderOverviewCount}
					density="compact"/>
			</Paper>

			<FolderCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onFolderCreate}/>

			<FolderSettingsDialog
				folderId={this.state.activeFolderId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onFolderUpdateSettings={this.onFolderUpdateSettings}/>

			<FolderPermissionsDialog
				folderId={this.state.activeFolderId}
				open={this.state.permissionDialogOpen}
				onClose={this.onPermissionDialogClose}
				onFolderUpdatePermissions={this.onFolderUpdatePermissions}/>

			<FolderDelegatesDialog
				folderId={this.state.activeFolderId}
				open={this.state.delegatesDialogOpen}
				onClose={this.onDelegatesDialogClose}/>

			<ConfirmationDialog
				title={this.props.t('folder.delete')}
				confirm={this.props.t('folder.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onDeleteFolder}/>

		</AppContainer>
	}

	onSessionCreated = () => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onFolderFetchOverviewList());
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onFolderCreate = (request) => {
		this.setState({creationDialogOpen: false}, () => this.props.onFolderCreate(request));
	}

	onUpdateDialogOpen = () => {
		this.setState({updateDialogOpen: true});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onFolderUpdateSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onFolderUpdateSettings(this.state.activeFolderId, settings));
	}

	onPermissionDialogOpen = () => {
		this.setState({permissionDialogOpen: true});
	}

	onPermissionDialogClose = () => {
		this.setState({permissionDialogOpen: false});
	}

	onFolderUpdatePermissions = (settings) => {
		this.setState({permissionDialogOpen: false}, () => this.props.onFolderUpdatePermissions(this.state.activeFolderId, settings));
	}

	onDelegatesDialogOpen = () => {
		this.setState({delegatesDialogOpen: true});
	}

	onDelegatesDialogClose = () => {
		this.setState({delegatesDialogOpen: false});
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onDeleteFolder = () => {
		const folderId = this.state.activeFolderId;
		this.setState({activeFolderId: null, deleteDialogOpen: false}, () => this.props.onDeleteFolder(folderId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onFolderFetchOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onFolderFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onFolderFetchOverviewList);
	}

	onFolderFetchOverviewList = () => {
		this.props.onFolderFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			folderBusy: state.folder.busy,
			folderServerError: state.folder.serverError,
			folderOverviewList: state.folder.overviewList,
			folderOverviewCount: state.folder.overviewCount,
			folderActionSuccessfully: state.folder.folderActionSuccessfully,
		}
	},
	dispatch => {
		return {
			onFolderFetchOverviewList: (request) => {
				dispatch({
					type: 'FOLDER_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onFolderCreate: (request) => {
				dispatch({
					type: 'FOLDER_CREATE',
					request
				});
			},
			onDeleteFolder: (folderId) => {
				dispatch({
					type: 'FOLDER_DELETE',
					folderId,
				});
			},
			onFolderUpdateSettings: (folderId, settings) => {
				dispatch({
					type: 'FOLDER_UPDATE_SETTINGS',
					folderId,
					settings
				});
			},
			onFolderUpdatePermissions: (folderId, permissions) => {
				dispatch({
					type: 'FOLDER_UPDATE_PERMISSIONS',
					folderId,
					permissions
				});
			}
		}
	}
)(FolderOverviewComponent));
