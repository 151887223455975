import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {styled} from "@mui/material/styles";
import {connect} from "react-redux";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {ROWS_PER_PAGE_OPTIONS} from "../common/Constants";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
	"& .MuiDataGrid-columnHeaders": {display: "none"},
	"& .MuiDataGrid-virtualScroller": {marginTop: "0!important"},
}));

const defaultState = {
	filterValue: '',
	page: 0,
	pageSize: 25,
	sortModel: [{field: 'name', sort: 'asc'}],

	selectedId: null,
	selected: false,
};

class EditorAddSignerGroupDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState, this.onFetchSignerGroups);
		}
		if (!!this.props.signerGroupApproversSigners && !prevProps.signerGroupApproversSigners) {
			this.props.onSelect(this.props.signerGroupApproversSigners, true);
		}
	}

	render() {
		const columns = [
			{
				field: 'name',
				editable: false,
				flex: 1
			},
			{
				field: 'description',
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				flex: 1
			}
		];

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   disableRestoreFocus
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<ServerErrorComponent serverError={this.props.signerGroupServerError} />

				<StyledDataGrid
					disableColumnSelector
					disableColumnFilter
					keepNonExistentRowsSelected
					checkboxSelection
					hideFooterSelectedRowCount

					rowSelectionModel={!!this.state.selectedId ? [this.state.selectedId] : []}
					onRowSelectionModelChange={this.onChangeSelectedId}
					columns={columns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-editor-signer-group-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					loading={this.props.signerGroupBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					rows={this.props.signerGroupOverviewList}
					rowCount={this.props.signerGroupOverviewCount}

					density="compact"
					sx={{height: 500}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						onClick={this.onSelect}
						id="btn-selection-confirm"
						disabled={!this.canContinue()}
				>
					{this.props.t('select')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	canContinue = () => {
		return !!this.state.selectedId && !this.state.selected;
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onSelect();
		}
	}

	onChangeSelectedId = (selectedIds) => {
		const currentSelection = this.state.selectedId;
		const newValue = selectedIds.find(el => !currentSelection || el !== currentSelection);
		this.setState({selectedId: newValue});
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onFetchSignerGroups)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onFetchSignerGroups)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onFetchSignerGroups);
	}

	onFetchSignerGroups = () => {
		this.props.onSignerGroupFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

	onSelect = () => {
		this.setState({ selected: true },
			() => this.props.onSignerGroupFetchApproversSigners(this.state.selectedId, this.props.folderId));
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			signerGroupBusy: state.signerGroup.busy,
			signerGroupServerError: state.signerGroup.serverError,
			signerGroupOverviewList: state.signerGroup.overviewList,
			signerGroupOverviewCount: state.signerGroup.overviewCount,
			signerGroupApproversSigners: state.signerGroup.approversSigners,
		}
	},
	dispatch => {
		return {
			onSignerGroupFetchOverviewList: (request) => {
				dispatch({
					type: 'SIGNERGROUP_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onSignerGroupFetchApproversSigners: (signerGroupId, folderId) => {
				dispatch({
					type: 'SIGNERGROUP_FETCH_APPROVERS_SIGNERS',
					signerGroupId,
					folderId
				});
			},
		}
	}
)(EditorAddSignerGroupDialog));
