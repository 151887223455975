import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import AppContainer from "../common/AppContainer";
import InfoGeneralComponent from "./InfoGeneralComponent";
import InfoFaqComponent from "./InfoFaqComponent";
import InfoVersionComponent from "./InfoVersionComponent";

class InfoComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.tabs.length > 0) {
			this.setState({tab: this.props.tabs.at(0)});
		}
	}

	render() {
		return <AppContainer needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <Fragment>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.tabs.map(tab => <Tab key={tab} value={tab}
														 label={this.props.t('info.tab_' + tab)}/>)}
					</Tabs>
					<Box sx={{mt: 2, mb: 2}}>
						{this.renderCurrentTab()}
					</Box>
				</Fragment>}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case 'GENERAL':
				return <InfoGeneralComponent/>;
			case 'FAQ':
				return <InfoFaqComponent/>;
			case 'VERSION':
				return <InfoVersionComponent/>;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({tab});
	}
}

export default withTranslation()(connect(
	state => {
		return {
			tabs: ['GENERAL', 'FAQ', 'VERSION']
		}
	},
	dispatch => {
		return {}
	}
)(InfoComponent));
