import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";

const defaultState = {
	name: '',
	description: '',
}

class SignerGroupCreationDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('signerGroup.create')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('signerGroup.createName')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('signerGroup.name')}
								required
								value={this.state.name}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								autoFocus
								id="input-signergroup-name-text"
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('signerGroup.createDescription')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('signerGroup.description')}
								value={this.state.description}
								onChange={this.onChangeDescription}
								autoComplete="off"
								fullWidth
								id="input-signergroup-description-text"
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('signerGroup.createFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('signerGroup.createFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={!this.state.name.trim()}
						onClick={this.onCreate}
						id="btn-create-confirm"
				>
					{this.props.t('signerGroup.create')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeDescription = (e) => {
		this.setState({description: e.target.value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !!this.state.name.trim()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		this.props.onCreate({name: this.state.name, description: this.state.description});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(SignerGroupCreationDialog));
