const defaultState = {
	runState: 'UNKNOWN',
	versions: [],
	inserted: false,
	externalPinPad: false,
	card: null,
	loginBusy: false,
	eidTimeout: false,
	wrongPin: false,
	triesLeft: undefined,
};

const eid = (state = defaultState, action) => {
	switch (action.type) {
		case 'EID_CORRECT_CARD_INSERTED':
			return {
				...state,
				inserted: true,
				externalPinPad: action.externalPinPad,
				card: action.eidCard,
				error: null,
			};

		case 'EID_CORRECT_CARD_REMOVED':
			return {
				...state,
				inserted: false,
				card: null
			};

		case 'EID_STOP_MIDDLEWARE': {
			return {
				...state,
				wrongPin: false,
				eidTimeout: false,
				error: null
			}
		}

		case 'EID_WRONG_PIN': {
			return {
				...state,
				wrongPin: true,
				triesLeft: action.triesLeft
			}
		}

		case 'EID_PIN_TIMEOUT_OR_CANCEL': {
			return {
				...state,
				wrongPin: false,
				eidTimeout: true
			}
		}

		case 'EID_ERROR': {
			return {
				...state,
				error: action.serverError
			}
		}

		case 'MIDDLEWARE_OK': {
			return {
				...state,
				versions: action.versions,
				runState: 'RUNNING',
				error: null
			}
		}

		case 'MIDDLEWARE_ERROR': {
			return {
				...state,
				error: action.serverError,
			}
		}

		case 'MIDDLEWARE_NOT_RUNNING':
		case 'MIDDLEWARE_VERSION_TO_OLD': {
			return {
				...state,
				versions: action.versions,
				runState: 'NOT_RUNNING'
			}
		}
		default:
			return state;
	}
}

export default eid;
