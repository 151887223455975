import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class TacDialog extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const sessionInfo = this.props.sessionInfo;
		if (!sessionInfo) {
			return null;
		}
		return <Dialog open={!sessionInfo.termsAndConditionsAccepted} onClose={this.onClose} fullWidth maxWidth="sm">
			<DialogTitle>{this.props.t('tac.title')}</DialogTitle>
			<DialogContent>{
				this.props.t('tac.message').split('$').map((v, index) => {
					if (v.indexOf('link') === 0) {
						const elements = v.split(';');
						return <Link color="primary" key={index} target="_blank"
									 href={elements[1]}>{elements[2]}</Link>
					} else
						return <span key={index}>{v}</span>
				})
			}</DialogContent>
			<DialogActions>
				<Button id="btn-tac-confirm"
						variant="contained"
						onClick={this.props.onSessionAcceptTermsAndConditions}>
					{this.props.t('tac.agree')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onClose = () => {}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
		}
	},
	dispatch => {
		return {
			onSessionAcceptTermsAndConditions: () => {
				dispatch({
					type: 'SESSION_ACCEPT_TERMS_AND_CONDITIONS'
				})
			},
		}
	}
)(TacDialog));