import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Grid, Link, Typography} from "@mui/material";

class InfoGeneralComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {

		return <Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.manualHeader')}</Typography>
				{this.renderWithLink('info.manualContent')}
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.termsHeader')}</Typography>
				{this.renderWithLink('info.termsContent')}
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.gdprHeader')}</Typography>
				{this.renderWithLink('info.gdprContent')}
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.isoEidasHeader')}</Typography>
				<Typography sx={{fontWeight: 600, mt: 1}}>{this.props.t('info.isoHeader')}</Typography>
				<Box>{this.props.t('info.isoContent')}</Box>
				<Typography sx={{fontWeight: 600, mt: 1}}>{this.props.t('info.eidasHeader')}</Typography>
				<Box>{this.props.t('info.eidasContent')}</Box>
			</Grid>
			{this.props.companySupportEmailAddress && <Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.supportHeader')}</Typography>
				{this.renderEmail('info.supportContent', this.props.companySupportEmailAddress)}
			</Grid>}
		</Grid>
	}

	renderWithLink(key) {
		return this.props.t(key).split('$').map((v, index) => {
			if (v.indexOf('link') === 0) {
				const elements = v.split(';');
				return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
			} else
				return <span key={index}>{v}</span>
		})
	}

	renderEmail(key, mailAddress) {
		return this.props.t(key).split('$').map((v, index) => {
			if (v.indexOf('mail') === 0) {
				return <Link key={index} href={'mailto:' + mailAddress} target="_blank">{mailAddress}</Link>;
			} else {
				return <span key={index}>{v}</span>
			}
		});
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companySupportEmailAddress: state.session?.info?.companySupportEmailAddress || '',
		}
	},
	dispatch => {
		return {}
	}
)(InfoGeneralComponent));
