import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Box, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import {AlignVerticalBottom, AlignVerticalCenter, AlignVerticalTop} from "@mui/icons-material";

const FONT_SIZE_MIN = 6;
const FONT_SIZE_MAX = 32 // anything larger might no longer fit inside the signature field

class EditorFieldStyleComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const backgroundColor = '#' + this.props.style?.backgroundColor || 'FFFFFF';
		const opacityPercentage = Math.round(((this.props.style?.backgroundOpacity || 0)) * 100);
		return <>
			<Box sx={{display: 'flex', flexDirection: 'column', mb: 1, minWidth: '160px'}}>
				<Typography variant="body2">
					{this.props.t('editor.fieldStyleFontSize')}
				</Typography>
				<TextField
					value={this.props.style?.fontSize}
					type="number"
					size="small"
					inputProps={{min: FONT_SIZE_MIN, max: FONT_SIZE_MAX}}
					sx={{width: 65}}
					onChange={e => this.onChangeStyleProperty('fontSize', e.target.value)}
				/>
			</Box>
			<Box sx={{display: 'flex'}}>
				<Box sx={{display: 'flex', flexDirection: 'column', mb: 1, minWidth: '160px'}}>
					<Typography variant="body2">
						{this.props.t('editor.fieldStyleBackgroundColor')}
					</Typography>
					<TextField
						variant="outlined"
						size="small"
						type="color"
						value={backgroundColor}
						sx={{width: 50}}
						onChange={this.onChangeBackgroundColor}
					/>
				</Box>
				<Box sx={{display: 'flex', flexDirection: 'column', mb: 1}}>
					<Typography variant="body2">
						{this.props.t('editor.fieldStyleBackgroundOpacity')}
					</Typography>
					<TextField
						value={opacityPercentage}
						size="small"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">%</InputAdornment>,
						}}
						sx={{width: 100}}
						onChange={this.onChangeOpacity}
					/>
				</Box>
			</Box>
			<Box>
				<Typography variant="body2" sx={{mr: 1, minWidth: '160px'}}>
					{this.props.t('editor.fieldStyleAlignment')}
				</Typography>

				<ToggleButtonGroup
					value={this.props.style?.horizontalAlignment || 'LEFT'}
					size="small"
					exclusive
					onChange={(e, value) => this.onChangeStyleProperty('horizontalAlignment', value)}
					sx={{minWidth: '160px'}}
				>
					<ToggleButton value='LEFT'
						title={this.props.t('editor.fieldStyleTextAlignHorizontalLeft')}>
						<FormatAlignLeftIcon/>
					</ToggleButton>
					<ToggleButton value='CENTER'
								  title={this.props.t('editor.fieldStyleTextAlignHorizontalCenter')}>
						<FormatAlignCenterIcon />
					</ToggleButton>
					<ToggleButton value='RIGHT'
								  title={this.props.t('editor.fieldStyleTextAlignHorizontalRight')}>
						<FormatAlignRightIcon/>
					</ToggleButton>
				</ToggleButtonGroup>
				<ToggleButtonGroup
					value={this.props.style?.verticalAlignment || 'TOP'}
					size="small"
					exclusive
					onChange={(e, value) => this.onChangeStyleProperty('verticalAlignment', value)}
				>
					<ToggleButton value='TOP'
								  title={this.props.t('editor.fieldStyleTextAlignVerticalTop')}>
						<AlignVerticalTop/>
					</ToggleButton>
					<ToggleButton value='CENTER'
								  title={this.props.t('editor.fieldStyleTextAlignVerticalCenter')}>
						<AlignVerticalCenter/>
					</ToggleButton>
					<ToggleButton value='BOTTOM'
								  title={this.props.t('editor.fieldStyleTextAlignVerticalBottom')}>
						<AlignVerticalBottom/>
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
		</>
	}

	onChangeStyleProperty = (property, value) => {
		if (!value) {
			return;
		}

		const style = {...this.props.style};
		style[property] = value;
		this.props.onChangeStyle(style);
	}

	onChangeBackgroundColor = e => {
		const color = e.target.value.replace('#', '');
		const style = {
			...this.props.style,
			backgroundColor: color,
		};
		this.props.onChangeStyle(style);
	}

	onChangeOpacity = e => {
		const opacity = Math.min(Math.max(e.target.value * 0.01, 0.0), 1.0);
		const style = {
			...this.props.style,
			backgroundOpacity: opacity,
		};
		this.props.onChangeStyle(style);
	}
}

export default withTranslation()(EditorFieldStyleComponent);