import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SelectEntityWithNameDialog from "../common/SelectEntityWithNameDialog";
import SelectUserDialog from "../common/SelectUserDialog";

class FolderPermissionsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			groupsToAddDialogOpen: false,
			groupsToAddWithAllPermissions: false,
			groupFilterValue: '',

			usersToAddDialogOpen: false,
			usersToAddWithAllPermissions: false,
			userFilterValue: '',
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onFolderFetchRelevantGroups();
			this.props.onFolderFetchRelevantUsers();
			this.props.onFolderFetchPermissions(this.props.folderId);
		}
	}

	render() {
		const folderRelevantGroups = this.props.folderRelevantGroups;
		const folderRelevantUsers = this.props.folderRelevantUsers;
		const folderPermissions = this.props.folderPermissions;
		const haveAllInfo = !!folderRelevantGroups && !!folderRelevantUsers && !!folderPermissions;

		const groupColumns = [
			{
				field: 'name',
				headerName: this.props.t('folder.permissionsGroupLabel'),
				editable: false,
				flex: 1
			},
			{
				field: 'editFolder',
				headerName: this.props.t('folder.permissionsEditFolderPermission'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('EDIT_FOLDER') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeGroup(cellValues.row.id, 'EDIT_FOLDER', e.target.checked)}
					/>
			},
			{
				field: 'editDocuments',
				headerName: this.props.t('folder.permissionsEditDocumentsPermission'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('EDIT_DOCUMENTS') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeGroup(cellValues.row.id, 'EDIT_DOCUMENTS', e.target.checked)}
					/>
			},
			{
				field: 'viewDocuments',
				headerName: this.props.t('folder.permissionsViewDocumentsPermission'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('VIEW_DOCUMENTS') >= 0}
						disabled={cellValues.row.permissions.indexOf('EDIT_DOCUMENTS') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeGroup(cellValues.row.id, 'VIEW_DOCUMENTS', e.target.checked)}
					/>
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.permissionsDelete')}
						onClick={() => this.props.onFolderPermissionsDeleteGroup(cellValues.row.id)}
						disabled={!cellValues.row.removalAllowed}
					>
						<DeleteIcon/>
					</IconButton>
			},
		];

		const userColumns = [
			{
				field: 'name',
				headerName: this.props.t('folder.name'),
				editable: false,
				flex: 1
			},
			{
				field: 'editFolder',
				headerName: this.props.t('folder.permissionsEditFolderPermission'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('EDIT_FOLDER') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeUser(cellValues.row.id, 'EDIT_FOLDER', e.target.checked)}
					/>
			},
			{
				field: 'editDocuments',
				headerName: this.props.t('folder.permissionsEditDocumentsPermission'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('EDIT_DOCUMENTS') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeUser(cellValues.row.id, 'EDIT_DOCUMENTS', e.target.checked)}
					/>
			},
			{
				field: 'viewDocuments',
				headerName: this.props.t('folder.permissionsViewDocumentsPermission'),
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('VIEW_DOCUMENTS') >= 0}
						disabled={cellValues.row.permissions.indexOf('EDIT_DOCUMENTS') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeUser(cellValues.row.id, 'VIEW_DOCUMENTS', e.target.checked)}
					/>
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				width: 150,
				align: 'center',
				renderCell: (cellValues) =>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.permissionsDelete')}
						onClick={() => this.props.onFolderPermissionsDeleteUser(cellValues.row.id)}>
						<DeleteIcon/>
					</IconButton>
			},
		];

		return <>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="lg">
				<DialogTitle>{this.props.t('folder.permissions')}</DialogTitle>
				{!haveAllInfo && <DialogContent><LoadingComponent/></DialogContent>}
				{haveAllInfo && <DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('folder.permissionsGroup')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('folder.permissionsGroupDescription')}</Typography>
								<Box>
									<DataGrid
										autoHeight
										disableColumnSelector
										disableColumnFilter
										disableRowSelectionOnClick
										pagination

										initialState={{
											pagination: {
												paginationModel: { pageSize: 10, page: 0 },
											},
										}}

										columns={groupColumns}

										slots={{toolbar: FlexibleToolbar}}
										slotProps={{
											toolbar: {
												filterId: 'input-folder-permission-group-search-text',
												filterValue: this.state.groupFilterValue,
												onChangeFilterValue: this.onGroupFilterValueChange,
											}
										}}

										rows={folderPermissions.groupsWithPermissions
											.filter(group => group.name.toUpperCase().indexOf(this.state.groupFilterValue.toUpperCase()) >= 0)}
										pageSizeOptions={[10]}
										density="compact"
										sx={{mt: 2}}
									/>
								</Box>
								<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onOpenGroupsToAddDialogWithNoPermissions}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-folder-permissions-add-groups-without-permissions"
									>
										{this.props.t('folder.permissionsAddGroupNoPermissions')}
									</Button>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onOpenGroupsToAddDialogWithAllPermissions}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-folder-permissions-add-groups-with-permissions"
									>
										{this.props.t('folder.permissionsAddGroupAllPermissions')}
									</Button>
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('folder.permissionsUser')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('folder.permissionsUserDescription')}</Typography>
								<Box>
									<DataGrid
										initialState={{
											pagination: {
												paginationModel: { pageSize: 10, page: 0 },
											},
										}}

										disableColumnSelector
										columns={userColumns}
										slots={{toolbar: FlexibleToolbar}}
										slotProps={{
											toolbar: {
												filterId: 'input-folder-permission-user-search-text',
												filterValue: this.state.userFilterValue,
												onChangeFilterValue: this.onUserFilterValueChange,
											}
										}}

										disableColumnFilter
										disableRowSelectionOnClick

										rows={folderPermissions.usersWithPermissions
											.filter(user => user.name.toUpperCase().indexOf(this.state.userFilterValue.toUpperCase()) >= 0)}
										pageSizeOptions={[10]}
										density="compact"
										autoHeight

										sx={{mt: 2}}
									/>
								</Box>
								<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onOpenUsersToAddDialogWithNoPermissions}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-folder-permissions-add-users-without-permissions"
									>
										{this.props.t('folder.permissionsAddUserNoPermissions')}
									</Button>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onOpenUsersToAddDialogWithAllPermissions}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-folder-permissions-add-users-with-permissions"
									>
										{this.props.t('folder.permissionsAddUserAllPermissions')}
									</Button>
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('folder.permissionsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('folder.permissionsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>}

				<DialogActions>
					<Button onClick={this.onClose} id="btn-folder-permission-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							disabled={!haveAllInfo}
							onClick={this.onUpdate}
							id="btn-folder-permission-confirm"
					>
						{this.props.t('folder.permissionsSave')}
					</Button>
				</DialogActions>
			</Dialog>

			<SelectEntityWithNameDialog open={this.state.groupsToAddDialogOpen}
										onClose={this.onCloseGroupsToAddDialog}
										onSelect={this.onAddGroups}
										title={this.props.t('folder.permissionsGroupSelect')}
										rows={folderRelevantGroups}/>

			<SelectUserDialog open={this.state.usersToAddDialogOpen}
							  onClose={this.onCloseUsersToAddDialog}
							  onSelect={this.onAddUsers}
							  title={this.props.t('folder.permissionsUserSelect')}
							  users={folderRelevantUsers || []}
							  multiselect/>
		</>
	}

	// -- groups

	onOpenGroupsToAddDialogWithNoPermissions = () => {
		this.setState({groupsToAddDialogOpen: true, groupsToAddWithAllPermissions: false});
	}

	onOpenGroupsToAddDialogWithAllPermissions = () => {
		this.setState({groupsToAddDialogOpen: true, groupsToAddWithAllPermissions: true});
	}

	onCloseGroupsToAddDialog = () => {
		this.setState({groupsToAddDialogOpen: false});
	}

	onAddGroups = (selectedIds) => {
		const permissions = this.state.groupsToAddWithAllPermissions ?
			['EDIT_FOLDER', 'EDIT_DOCUMENTS', 'VIEW_DOCUMENTS'] : [];
		const groups = this.props.folderRelevantGroups
			.filter(group => selectedIds.indexOf(group.id) >= 0)
			.map(group => ({...group, permissions, removalAllowed: !group.defaultGroup}));
		this.props.onFolderPermissionsAddGroups(groups);
		this.setState({groupsToAddDialogOpen: false});
	}

	onGroupFilterValueChange = (e) => {
		this.setState({groupFilterValue: e.target.value});
	}

	// -- users

	onOpenUsersToAddDialogWithNoPermissions = () => {
		this.setState({usersToAddDialogOpen: true, usersToAddWithAllPermissions: false});
	}

	onOpenUsersToAddDialogWithAllPermissions = () => {
		this.setState({usersToAddDialogOpen: true, usersToAddWithAllPermissions: true});
	}

	onCloseUsersToAddDialog = () => {
		this.setState({usersToAddDialogOpen: false});
	}

	onAddUsers = (selectedIds) => {
		const permissions = this.state.usersToAddWithAllPermissions ?
			['EDIT_FOLDER', 'EDIT_DOCUMENTS', 'VIEW_DOCUMENTS'] : [];
		const users = this.props.folderRelevantUsers
			.filter(user => selectedIds.indexOf(user.id) >= 0)
			.map(user => ({...user, name: user.firstName + ' ' + user.lastName, permissions}));
		this.props.onFolderPermissionsAddUsers(users);
		this.setState({usersToAddDialogOpen: false});
	}

	onUserFilterValueChange = (e) => {
		this.setState({userFilterValue: e.target.value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		this.props.onFolderUpdatePermissions(this.props.folderPermissions);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			folderRelevantGroups: state.folder.relevantGroups,
			folderRelevantUsers: state.folder.relevantUsers,
			folderPermissions: state.folder.permissions,
		}
	},
	dispatch => {
		return {
			onFolderFetchRelevantGroups: () => {
				dispatch({
					type: 'FOLDER_FETCH_RELEVANT_GROUPS',
				});
			},
			onFolderFetchRelevantUsers: () => {
				dispatch({
					type: 'FOLDER_FETCH_RELEVANT_USERS',
				});
			},
			onFolderFetchPermissions: (folderId) => {
				dispatch({
					type: 'FOLDER_FETCH_PERMISSIONS',
					folderId
				});
			},
			onFolderPermissionsChangeGroup: (id, permission, enabled) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_CHANGE_GROUP',
					id,
					permission,
					enabled
				});
			},
			onFolderPermissionsDeleteGroup: (id) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_DELETE_GROUP',
					id,
				});
			},
			onFolderPermissionsAddGroups: (groups) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_ADD_GROUPS',
					groups
				});
			},
			onFolderPermissionsChangeUser: (id, permission, enabled) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_CHANGE_USER',
					id,
					permission,
					enabled
				});
			},
			onFolderPermissionsDeleteUser: (id) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_DELETE_USER',
					id,
				});
			},
			onFolderPermissionsAddUsers: (users) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_ADD_USERS',
					users
				});
			}
		}
	}
)(FolderPermissionsDialog));
