import React, {Component} from "react";
import {Alert, Box} from "@mui/material";
import {withTranslation} from "react-i18next";

class ServerErrorComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const serverError = this.props.serverError;
		if (!!serverError) {
			return <Box sx={{ mt: 1, mb: 1 }}>
				<Alert severity="error">{this.props.t('serverError.' + serverError)}</Alert>
			</Box>
		} else {
			return null;
		}
	}

}

export default withTranslation()(ServerErrorComponent);
