import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import ServerErrorComponent from "../common/ServerErrorComponent";

const defaultState = {
	declineReason: '',
}

class ApprovalDeclineDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			...defaultState,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
			});
		}
	}

	render() {
		return <Dialog open={this.props.open} onClose={this.onClose} fullWidth maxWidth="sm">
			<DialogTitle>{this.props.t('approval.declineHeader')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active={true}>
						<StepLabel>{this.props.t('approval.declineWhyHeader')}</StepLabel>
						<StepContent>
							<Typography variant="body2">{this.props.t('approval.declineWhyDetails')}</Typography>
						</StepContent>
					</Step>
					<Step active={true}>
						<StepLabel>{this.props.t('approval.declineReasonHeader')}</StepLabel>
						<StepContent>
							<Typography variant="body2">{this.props.t('approval.declineReasonDetails')}</Typography>
							<TextField
								required
								fullWidth
								label={this.props.t('approval.declineReasonLabel')}
								inputProps={{maxLength: 150}}
								id="input-approval-decline-reason-text"
								value={this.state.declineReason}
								onChange={this.onDeclineReasonChange}
								autoComplete="off"
								size="small"
								sx={{mt: 1}}

							/>
							<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
								<Typography variant="body2">{this.state.declineReason.length + "/150"}</Typography>
							</Box>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose}
						id="btn-approval-decline-cancel">
					{this.props.t('cancel')}
				</Button>
				<Button
					variant="contained"
					onClick={this.onDeclineStart}
					id="btn-approval-decline-confirm"
					disabled={this.state.declineReason.length === 0}
				>
					{this.props.t('approval.decline')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onDeclineReasonChange = (e) => {
		const declineReason = e.target.value;
		this.setState({declineReason});
	}

	onDeclineStart = () => {
		this.props.onDeclineStart(this.state.declineReason)
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(ApprovalDeclineDialog));
