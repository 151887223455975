import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import {withTranslation} from "react-i18next";
import AppContainer from "../common/AppContainer";
import CompanyStatisticsComponent from "./CompanyStatisticsComponent";
import CompanyActionLogsComponent from "./CompanyActionLogsComponent";

class CompanyInsightsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.eligibleTabs.length > 0) {
			this.setState({tab: this.props.eligibleTabs.at(0)});
		}
	}

	render() {
		return <AppContainer needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <Fragment>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.eligibleTabs.map(tab =>
							<Tab key={tab} value={tab} label={this.props.t('company.insightsTab_' + tab)} />)}
					</Tabs>
					<Box sx={{mt: 1}}>
						{this.renderCurrentTab()}
					</Box>
				</Fragment>}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case 'STATISTICS':
				return <CompanyStatisticsComponent />;
			case 'ACTION_LOGS':
				return <CompanyActionLogsComponent />;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({ tab });
	}

}

export default withTranslation()(connect(
	state => {
		const eligibleTabs = [];
		if (state.session?.info?.companyStatisticsAccessible) {
			eligibleTabs.push('STATISTICS');
		}
		if (state.session?.info?.companyActionLogsAccessible) {
			eligibleTabs.push('ACTION_LOGS');
		}
		return {
			sessionInfo: state.session.info,
			eligibleTabs
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanyInsightsComponent));
