import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {addDays, addMonths, addWeeks, addYears, endOfDay, format, isValid} from "date-fns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {DatePicker} from "@mui/x-date-pickers";

const defaultState = {
	validFrom: new Date(),
	description: null,
	contactEmail: null,
	durationValue: 1,
	durationFormat: 'DURATION_YEAR',
}

const DURATIONS = [
	'DURATION_DAY',
	'DURATION_WEEK',
	'DURATION_MONTH',
	'DURATION_YEAR'
]

class CompanyApiKeyCreateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		const { apiKey } = this.props;

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.apiKeySettingsDuration')}</StepLabel>
						<StepContent>
							<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
								<DatePicker
									slotProps={{
										textField: {
											size: 'small',
											autoComplete: 'off',
											error: this.state.validFromError,
											inputProps: {
												placeholder: this.props.t('locale.dateFormat')
											},
										},
									}}
									autoFocus
									label={this.props.t('company.apiKeySettingsValidFrom')}
									value={this.state.validFrom}
									onChange={this.onChangeValidFrom}
									views={['year', 'month', 'day']}
									openTo="day"
									format="dd/LL/yyyy"
									disabled={!!apiKey}
									sx={{width: '150px'}}
								/>

								<Typography variant="body" sx={{ml: 1, mr: 1}}>{this.props.t('company.apiKeySettingsDurationDuring')}</Typography>

								<TextField
									variant="outlined"
									value={this.state.durationValue}
									onChange={this.onChangeDurationValue}
									autoComplete="off"
									type="number"
									size="small"
									disabled={!!apiKey}
									sx={{width: '75px'}}
								/>

								<Select
									size="small"
									value={this.state.durationFormat}
									onChange={this.onChangeDurationFormat}
									disabled={!!apiKey}
								>
									{DURATIONS.map(duration =>
										<MenuItem key={duration} value={duration}>{this.props.t('company.apiKeySettingsDuration_' + duration + (this.state.durationValue > 1 ? 'S' : ''))}</MenuItem>)}
								</Select>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.apiKeySettingsContactEmailHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.apiKeySettingsContactEmail')}
								value={this.state.contactEmail || ''}
								onChange={this.onChangeContactEmail}
								inputProps={{maxLength: 255}}
								autoComplete="off"
								fullWidth
								disabled={!!apiKey}
								sx={{m: 0}}
							/>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.apiKeySettingsOptionalDescription')}</StepLabel>
						<StepContent>
								<TextField
									variant="outlined"
									label={this.props.t('company.apiKeySettingsDescription')}
									value={this.state.description || ''}
									onChange={this.onChangeDescription}
									inputProps={{maxLength: 255}}
									autoComplete="off"
									fullWidth
									disabled={!!apiKey}
									sx={{m: 0}}
								/>
						</StepContent>
					</Step>


					{apiKey &&
						<Step active>
							<StepLabel>{this.props.t('company.apiKeySettingsValue')}</StepLabel>
							<StepContent>
								<Box sx={{mt: 1, display: 'flex'}}>
									<TextField
										variant="outlined"
										label={this.props.t('company.apiKeySettingsValue')}
										value={apiKey.value || ''}
										autoComplete="off"
										fullWidth
										autoFocus
										disabled={true}
										sx={{mb: 2}}
										InputProps={{
											endAdornment: <IconButton color="primary"
																	  title={this.props.t('company.apiKeySettingsCopy')}
																	  onClick={this.onCopyKey}
																	  size="small"
											>
												<ContentCopyIcon/>
											</IconButton>
										}}
									/>

								</Box>

								<Alert severity="info">{this.props.t('company.apiKeySettingsAlert')}</Alert>

							</StepContent>
						</Step>
					}
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('close')}</Button>
				{!apiKey && <Button variant="contained" disabled={!this.canContinue() || !!apiKey} onClick={this.onCreate}
						id="btn-create-confirm">
					{this.props.t('company.apiKeySettingsCreate')}
				</Button>}
			</DialogActions>
		</Dialog>
	}

	onChangeValidFrom = (value) => {
		if (null === value || !isValid(value)) {
			return;
		}

		this.setState({validFrom: value});
	}


	onChangeDurationValue = (e) => {
		const durationValue = e.target.value;

		if (durationValue < 1) {
			return;
		}

		if (this.maxDurationReached(durationValue, this.state.durationFormat)) {
			return;
		}


		this.setState({durationValue});
	}

	maxDurationReached = (durationValue, durationFormat) => {
		if (durationFormat === 'DURATION_YEAR' && durationValue > 2) {
			return true;
		} else if (durationFormat === 'DURATION_MONTH' && durationValue > 12) {
			return true;
		} else if (durationFormat === 'DURATION_WEEK' && durationValue > 52) {
			return true;
		} else if (durationFormat === 'DURATION_DAY' && durationValue > 365) {
			return true;
		}

		return false;
	}

	onChangeDurationFormat = (e) => {
		const durationFormat = e.target.value;
		let durationValue = this.state.durationValue;
		if (this.maxDurationReached(durationValue, durationFormat)) {
			if (durationFormat === 'DURATION_YEAR') {
				durationValue = 2;
			} else if (durationFormat === 'DURATION_MONTH') {
				durationValue = 12;
			} else if (durationFormat === 'DURATION_WEEK') {
				durationValue = 52;
			} else if (durationFormat === 'DURATION_DAY') {
				durationValue = 365;
			}
		}

		this.setState({durationValue, durationFormat});
	}

	onChangeDescription = (e) => {
		this.setState({description: e.target.value})
	}

	onChangeContactEmail = (e) => {
		this.setState({contactEmail: e.target.value})
	}

	canContinue = () => {
		return !!this.state.validFrom && !!this.state.durationValue && !!this.state.durationFormat;
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		const {validFrom, durationValue, durationFormat} = this.state;

		let validUntil;
		if (durationFormat === 'DURATION_YEAR') {
			validUntil = addYears(validFrom, durationValue);
		} else if (durationFormat === 'DURATION_MONTH') {
			validUntil = addMonths(validFrom, durationValue);
		} else if (durationFormat === 'DURATION_WEEK') {
			validUntil = addWeeks(validFrom, durationValue);
		} else if (durationFormat === 'DURATION_DAY') {
			validUntil = addDays(validFrom, durationValue);
		}

		this.props.onCreate({
			validFrom: format((validFrom), 'yyyy-LL-dd\'T\'HH:mm:ss'),
			validUntil: format(endOfDay(validUntil), 'yyyy-LL-dd\'T\'HH:mm:ss'),
			description: this.state.description,
			contactEmail: this.state.contactEmail
		});
	}

	onCopyKey = () => {
		navigator?.clipboard?.writeText(this.props.apiKey.value);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	() => {
		return {
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanyApiKeyCreateDialog));
