import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import AppContainer from "../common/AppContainer";
import ConfirmationDialog from "../common/ConfirmationDialog";
import SignerGroupCreationDialog from "./SignerGroupCreationDialog";
import SignerGroupSettingsDialog from "./SignerGroupSettingsDialog";
import SignerGroupUsersDialog from "./SignerGroupUsersDialog";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
	sortModel: [{field: 'name', sort: 'asc'}],

	// dialogs
	creationDialogOpen: false,
	updateDialogOpen: false,
	usersDialogOpen: false,
	deleteDialogOpen: false,

	// active row element
	activeSignerGroupId: null
};

class SignerGroupOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.signerGroupActionSuccessfully && !prevProps.signerGroupActionSuccessfully) {
			// if a new signer group has just been created, automatically show the users dialog
			if (this.props.createdSignerGroupId && !prevProps.createdSignerGroupId) {
				this.onSignerGroupFetchOverviewList();
				this.onUsersDialogOpen(this.props.createdSignerGroupId);
			}
			else {
				this.onSignerGroupFetchOverviewList();
			}
		}
	}

	render() {
		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('signerGroup.name'),
				editable: false,
				flex: 1
			},
			{
				field: 'description',
				headerName: this.props.t('signerGroup.description'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				headerName: this.props.t('signerGroup.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 150,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('signerGroup.settings')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.setState({activeSignerGroupId: cellValues.row.id});
							this.onUpdateDialogOpen();
						}}>
						<SettingsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('signerGroup.users')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.onUsersDialogOpen(cellValues.row.id, cellValues.row.name);
						}}>
						<ManageAccountsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('signerGroup.delete')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.setState({activeSignerGroupId: cellValues.row.id});
							this.onDeleteDialogOpen();
						}}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</Fragment>)
			},
		];


		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('signerGroup.header')}</Typography>
				{<ServerErrorComponent serverError={this.props.signerGroupServerError} />}
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={this.props.signerGroupBusy}
							id="btn-signergroup-create"
					>
						{this.props.t('signerGroup.create')}
					</Button>
				</Box>
				<DataGrid
					autoHeight
					disableColumnSelector
					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-signer-group-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange
						}
					}}

					loading={this.props.signerGroupBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableColumnFilter
					disableRowSelectionOnClick

					rows={this.props.signerGroupOverviewList}
					rowCount={this.props.signerGroupOverviewCount}
					density="compact"/>
			</Paper>

			<SignerGroupCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onSignerGroupCreate}/>

			<SignerGroupSettingsDialog
				signerGroupId={this.state.activeSignerGroupId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onSignerGroupUpdateSettings={this.onSignerGroupUpdateSettings}/>

			<SignerGroupUsersDialog
				signerGroupId={this.state.activeSignerGroupId}
				signerGroupName={this.state.activeSignerGroupName}
				open={this.state.usersDialogOpen}
				onClose={this.onUsersDialogClose}/>

			<ConfirmationDialog
				title={this.props.t('signerGroup.delete')}
				confirm={this.props.t('signerGroup.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onDeleteSignerGroup}/>

		</AppContainer>
	}

	onSessionCreated = () => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onSignerGroupFetchOverviewList());
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onSignerGroupCreate = (request) => {
		this.setState({creationDialogOpen: false}, () => this.props.onSignerGroupCreate(request));
	}

	onUpdateDialogOpen = () => {
		this.setState({updateDialogOpen: true});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onUsersDialogOpen = (activeSignerGroupId, activeSignerGroupName) => {
		this.setState({usersDialogOpen: true, activeSignerGroupId: activeSignerGroupId, activeSignerGroupName: activeSignerGroupName});
	}

	onUsersDialogClose = () => {
		this.setState({usersDialogOpen: false});
	}

	onSignerGroupUpdateSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onSignerGroupUpdateSettings(this.state.activeSignerGroupId, settings));
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onDeleteSignerGroup = () => {
		const signerGroupId = this.state.activeSignerGroupId;
		this.setState({activeSignerGroupId: null, deleteDialogOpen: false}, () => this.props.onSignerGroupDelete(signerGroupId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onSignerGroupFetchOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onSignerGroupFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onSignerGroupFetchOverviewList);
	}

	onSignerGroupFetchOverviewList = () => {
		this.props.onSignerGroupFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			signerGroupBusy: state.signerGroup.busy,
			signerGroupServerError: state.signerGroup.serverError,
			signerGroupOverviewList: state.signerGroup.overviewList,
			signerGroupOverviewCount: state.signerGroup.overviewCount,
			signerGroupActionSuccessfully: state.signerGroup.signerGroupActionSuccessfully,
			createdSignerGroupId: state.signerGroup.createdSignerGroupId,
		}
	},
	dispatch => {
		return {
			onSignerGroupFetchOverviewList: (request) => {
				dispatch({
					type: 'SIGNERGROUP_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onSignerGroupCreate: (request) => {
				dispatch ({
					type: 'SIGNERGROUP_CREATE',
					request
				})
			},
			onSignerGroupDelete: (signerGroupId) => {
				dispatch({
					type: 'SIGNERGROUP_DELETE',
					signerGroupId
				})
			},
			onSignerGroupUpdateSettings: (signerGroupId, settings) => {
				dispatch({
					type: 'SIGNERGROUP_UPDATE_SETTINGS',
					signerGroupId,
					settings
				})
			}
		}
	}
)(SignerGroupOverviewComponent));