import React, {Component} from 'react';
import {TextField} from "@mui/material";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";


const defaultState = { pin: '', visualPin: '' };

class EidPinInput extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.pin !== prevState.pin) {
			this.setState({ visualPin: '\u2022'.repeat(this.state.pin.length) });
		}
	}

	render() {
		return (
			<div id="eid-pin">
				<TextField value={this.state.visualPin}
						   onChange={this.onChange}
						   onKeyDown={this.onKeyDown}
						   label={this.props.t('eid.pincode')}
						   autoComplete="off"
						   autoFocus
						   required
						   id={this.props.id}
						   disabled={this.props.disabled}
						   error={this.props.wrongPin}
						   inputProps={{maxLength: this.props.maxPinLength || 12}}
				/>
			</div>
		);
	}

	onChange = (e) => {
		// the value contains a number of bullets (\u2022) possibly followed by a new digit
		// or not in case of delete backspace, ...
		const value = e.target.value || '';
		// contains the actual pin
		let pin = this.state.pin;
		// if the value length is bigger than pin length, add at the back (only allow digits, this will also filter out bullets)
		if (value.length > pin.length) {
			pin = pin + value.replace(/\D/g, '');
			// else subtract so that pin length = value.length
		} else {
			pin = pin.substring(0, value.length);
		}
		this.setState({pin: pin});
		this.props.onPinChanged(pin);
	}

	onKeyDown = (e) => {
		if (e.key === 'Enter' && !!this.props.onEnter) {
			this.props.onEnter();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
		}
	},
	dispatch => {
		return {}
	}
)(EidPinInput));