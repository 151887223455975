const defaultState = {
	open: (window.screen?.width || 768) >= 768,
}

const menu = (state = defaultState, action) => {
	switch (action.type) {
		case 'MENU_OPEN':
			return {
				...state,
				open: true
			};

		case 'MENU_CLOSE':
			return {
				...state,
				open: false
			}

		default:
			return state;
	}
}

export default menu;
