import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AppContainer from "../common/AppContainer";
import {Typography} from "@mui/material";

class SigningForwardedComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <AppContainer>
			<Typography>{this.props.t('signing.forwardSuccess')}</Typography>
		</AppContainer>
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(SigningForwardedComponent));
