import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import EditorFieldStyleComponent from "./EditorFieldStyleComponent";

class EditorFieldStyleDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: null,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				style: this.props.style,
			});
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="sm">
			<DialogTitle>{this.props.t('editor.fieldStyleTitle')}</DialogTitle>
			<DialogContent>
				<EditorFieldStyleComponent style={this.state.style} onChangeStyle={this.onChangeStyle}/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.props.onClose} id="btn-confirmation-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						onClick={this.onConfirm}
						id="btn-confirmation-confirm">{this.props.t('confirm')}</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeStyle = (style) => {
		this.setState({style});
	}

	onConfirm = () => {
		this.props.onConfirm(this.state.style);
	}
}

export default withTranslation()(EditorFieldStyleDialog);

