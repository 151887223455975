import React, {Component} from "react";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Paper, Tab, Tabs} from "@mui/material";
import {withTranslation} from "react-i18next";
import ApplicationCompanyOverviewComponent from "./ApplicationCompanyOverviewComponent";
import ApplicationOidcSettingsComponent from "./ApplicationOidcSettingsComponent";
import ApplicationTestsComponent from "./ApplicationTestsComponent";
import ApplicationLoggingComponent from "./ApplicationLoggingComponent";

class ApplicationSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.eligibleTabs.length > 0) {
			this.setState({tab: this.props.eligibleTabs.at(0)});
		}
	}

	render() {
		return <AppContainer needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.eligibleTabs.map(tab => <Tab key={tab} value={tab}
																 label={this.props.t('application.tab_' + tab)}/>)}
					</Tabs>

					{this.renderCurrentTab()}
				</>}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case 'COMPANY_OVERVIEW':
				return <ApplicationCompanyOverviewComponent/>;
			case 'OIDC_SETTINGS':
				return <ApplicationOidcSettingsComponent/>;
			case 'TESTS':
				return <ApplicationTestsComponent/>;
			case 'LOGGING':
				return <ApplicationLoggingComponent />;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({ tab });
	}
}

export default withTranslation()(connect(
	state => {
		const eligibleTabs = [];
		eligibleTabs.push('COMPANY_OVERVIEW');

		if (state.session?.info?.applicationAdmin) {
			eligibleTabs.push('OIDC_SETTINGS');
			eligibleTabs.push('TESTS');
			eligibleTabs.push('LOGGING');
		}

		return {
			sessionInfo: state.session.info,
			eligibleTabs
		}
	},
	dispatch => {
		return {
		}
	}
)(ApplicationSettingsComponent));
