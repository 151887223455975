import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";

class UserGroupSettingsDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onUserGroupFetchSettings(this.props.userGroupId);
		}
	}

	render() {
		const userGroupSettings = this.props.userGroupSettings;

		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="md">
				<DialogTitle>{this.props.t('userGroup.settings')}</DialogTitle>
				{!userGroupSettings && <DialogContent><LoadingComponent/></DialogContent>}
				{userGroupSettings && <DialogContent>
					<Typography>{userGroupSettings.name}</Typography>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('userGroup.settingsNameDescription')}</StepLabel>
							<StepContent>
								<Box id="input-usergroup-general-text-group">
									<TextField
										variant="outlined"
										label={this.props.t('userGroup.name')}
										required
										value={userGroupSettings.name}
										onChange={this.onChangeName}
										autoComplete="off"
										fullWidth
										autoFocus
										sx={{mb: 2}}
									/>
									<TextField
										variant="outlined"
										label={this.props.t('userGroup.description')}
										value={userGroupSettings.description || ''}
										onChange={this.onChangeDescription}
										autoComplete="off"
										fullWidth
									/>
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('userGroup.settingsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('userGroup.settingsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>}

				<DialogActions>
					<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							disabled={!userGroupSettings || !userGroupSettings.name?.trim()}
							onClick={this.onUpdate}
							id="btn-settings-confirm"
					>
						{this.props.t('userGroup.settingsSave')}
					</Button>
				</DialogActions>
			</Dialog>

		</Fragment>
	}

	onChangeName = (e) => {
		this.props.onUserGroupChangeSetting('name', e.target.value);
	}

	onChangeDescription = (e) => {
		this.props.onUserGroupChangeSetting('description', e.target.value);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		this.props.onUserGroupUpdateSettings(this.props.userGroupSettings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			userGroupSettings: state.userGroup.settings,
		}
	},
	dispatch => {
		return {
			onUserGroupFetchSettings: (userGroupId) => {
				dispatch({
					type: 'USERGROUP_FETCH_SETTINGS',
					userGroupId
				});
			}, onUserGroupChangeSetting: (key, value) => {
				dispatch({
					type: 'USERGROUP_CHANGE_SETTING',
					key,
					value
				});
			}, onChangeUserAdmin: (userId, admin) => {
				dispatch({
					type: 'USERGROUP_CHANGE_USER_SETTING_ADMIN',
					userId,
					admin
				});
			}
		}
	}
)(UserGroupSettingsDialog));
