import React, {Component} from "react";
import {Alert, Box} from "@mui/material";
import {withTranslation} from "react-i18next";

class DocumentReminderFeedbackComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const result = this.props.reminderResult;
		if (!!result && !result.reminderSent) {
			return <Box sx={{mt: 1, mb: 1}}>
				<Alert severity="warning">
					{!!result.reminderNotSentReason ? this.props.t('reminder.notSentReason_' + result.reminderNotSentReason) : this.props.t('reminder.nothingToRemind')}
				</Alert>
			</Box>
		} else {
			return null;
		}
	}

}

export default withTranslation()(DocumentReminderFeedbackComponent);
