import React, {Component} from "react";
import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import ConnectorActivityGridComponent from "./ConnectorActivityGridComponent";
import SaveIcon from "@mui/icons-material/Save";

class GoogleDriveConnectorComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		const config = {};
		this.props.instance.config.forEach(entry => config[entry.key] = entry.value);

		const activityConfigOptions = [
			{
				name: 'driveFolderId',
				label: this.props.t('connector.folder'),
				description: (action) => this.props.t('connector.googleDriveFolder_' + action),
				required: false
			},
		];

		return <Box sx={{mt: 1}}>
			<Box sx={{mb: 2, mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('connector.googleDriveConfig')}</Typography>

				<Box sx={{mt: 2, display: 'flex', flexDirection: 'column'}}>
					<TextField
						variant="outlined"
						value={config.serviceAccountJson || ''}
						label={this.props.t('connector.googleDriveServiceAccountJson')}
						onChange={this.onChangeServiceAccountJson}
						autoComplete="off"
						type="text"
						sx={{
							mb: 1,
							'& textarea': {
								fontFamily: 'monospace'
							}
						}}
						fullWidth
						multiline
						rows={5}
						disabled={this.props.busy}
						required={true}
						error={!config.serviceAccountJson || config.serviceAccountJson.trim().length < 1}
					/>
				</Box>

				<Box sx={{mt: 2}}>
					<Button variant="contained" onClick={this.onTest}
							disabled={this.props.busy || !this.props.hasValidConfig}
							sx={{mr: 2}}
							id="btn-settings-test">
						{this.props.t('connector.testConnection')}
					</Button>
					<Button variant="contained" onClick={this.onSave} disabled={this.props.busy} startIcon={<SaveIcon/>}
							id="btn-settings-save">
						{this.props.t('save')}
					</Button>
				</Box>

				{!!this.props.testResult && <Box sx={{mt: 2}}>
					<Alert severity={this.props.testResult.success ? "success" : "error"}>
						{this.props.testResult.success ? this.props.t('connector.test_SUCCESS') : this.props.t('connector.test_' + this.props.testResult.error)}
					</Alert>
				</Box>}

			</Box>

			<ConnectorActivityGridComponent instanceId={this.props.instance.id}
											availableActions={this.props.instance.actions}
											activityConfigOptions={activityConfigOptions}
											busy={this.props.busy}/>

		</Box>
	}

	onChangeServiceAccountJson = (e) => {
		this.props.onChangeConnectorInstanceConfig(this.props.instance.id, 'serviceAccountJson', e.target.value);
	}

	onSave = () => {
		this.props.onUpdateConnectorInstanceConfig(this.props.instance);
	}

	onTest = () => {
		this.props.onTest(this.props.instance);
	}

}

export default withTranslation()(GoogleDriveConnectorComponent);