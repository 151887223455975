import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, Checkbox, IconButton, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import ConnectorActivityDialog from "./ConnectorActivityDialog";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {ROWS_PER_PAGE_OPTIONS} from "../common/Constants";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {formatInTimeZone} from "date-fns-tz";

class ConnectorActivityGridComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [],
			filterValue: '',

			createDialogOpen: false,
			updateDialogOpen: false,
			deleteDialogOpen: false,
		}
	}

	componentDidMount() {
		this.onFetchConnectorActivityList();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.activityActionSuccessFully && !prevProps.activityActionSuccessFully) {
			this.onFetchConnectorActivityList();
		}
	}

	render() {
		const dataGridColumnsStart = [
			{
				field: 'action',
				headerName: this.props.t('connector.actionType'),
				editable: false,
				flex: 1,
				valueGetter: (data) => this.props.t('connector.actionType_' + data.value)
			},
			{
				field: 'folderName',
				headerName: this.props.t('connector.folder'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => cellValues.row?.folder?.name || ''
			},
			{
				field: 'userEmail',
				headerName: this.props.t('connector.user'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => cellValues.row?.user?.email || ''
			}
		];

		const configColumns = this.props.activityConfigOptions.map(option => {
			return {
				field: option.name,
				headerName: option.label,
				editable: false,
				flex: 1,
			}
		});

		const dataGridColumnsEnd = [
			{
				field: 'uploadFileNameDocumentIdPrefix',
				headerName: this.props.t('connector.uploadFileNameDocumentIdPrefix'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) =>  cellValues.row?.uploadFileNameDocumentIdPrefix === undefined ? null : <Checkbox
					checked={cellValues.row?.uploadFileNameDocumentIdPrefix}
					disabled={true}
				/>
			},
			{
				field: 'lastDiscoveryAt',
				headerName: this.props.t('connector.lastDiscoveryAt'),
				editable: false,
				flex: 1,
				valueFormatter: (data) => !!data.value ? formatInTimeZone(data.value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : '',
			},
			{
				field: 'lastSyncAt',
				headerName: this.props.t('connector.lastSyncAt'),
				editable: false,
				flex: 1,
				valueFormatter: (data) => !!data.value ? formatInTimeZone(data.value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : '',
			},
			{
				field: 'actions',
				headerName: this.props.t('connector.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						size="small"
						variant="contained"
						color="primary"
						title={this.props.t('connector.edit')}
						disabled={this.props.busy}
						onClick={() => this.onOpenDialogForEditing(cellValues.row)}>
						<EditIcon fontSize="small" />
					</IconButton>
					<IconButton
						size="small"
						variant="contained"
						color="primary"
						title={this.props.t('connector.delete')}
						disabled={this.props.busy}
						onClick={() => this.onOpenDeleteDialog(cellValues.row)}>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Fragment>)
			}
		];

		const dataGridColumns = dataGridColumnsStart.concat(configColumns).concat(dataGridColumnsEnd);

		const activityList = this.props.activityList?.map(activity => {
				const result = {
					...activity,
				};
				for (const column of configColumns) {
					const config = activity.config.entries.find(config => config.key === column.field);
					if (!!config) {
						result[column.field] = config.value;
					}
				}
				return result;
			}
		);

		return <Box>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
				<Button variant="contained"
						onClick={this.onOpenDialogForCreating}
						startIcon={<AddIcon/>}
						disabled={this.props.busy}
						id="btn-connector-activity-create"
				>
					{this.props.t('connector.addActivity')}
				</Button>
			</Box>
			<Box sx={{mb: 2, mt: 2}}>
				<Typography sx={{fontWeight: 700}} variant="h7">{this.props.t('connector.activityGrid')}
				</Typography>
			</Box>

			<DataGrid
				pagination
				paginationMode="server"
				paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
				onPaginationModelChange={this.onPaginationModelChange}
				disableColumnSelector
				columns={dataGridColumns}
				disableColumnFilter
				disableRowSelectionOnClick

				sortingMode="server"
				sortModel={this.state.sortModel}
				onSortModelChange={this.onSortModelChange}

				rows={activityList || []}
				rowCount={this.props.activityCount || 0}
				pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
				loading={this.props.busy}
				density="compact"
				autoHeight

				slots={{
					toolbar: FlexibleToolbar,
				}}
				slotProps={{
					toolbar: {
						filterId: 'input-activity-overview-search-text',
						filterValue: this.state.filterValue,
						onChangeFilterValue: this.onFilterValueChange,
						refreshAction: this.onFetchConnectorActivityList
					},
				}}
			/>

			<ConnectorActivityDialog
				open={this.state.updateDialogOpen}
				onClose={this.onCloseUpdateDialog}
				onSave={this.onUpdateConnectorActivity}
				connectorActivity={this.state.activeConnectorActivity}
				supportedActions={this.props.availableActions}
				activityConfigOptions={this.props.activityConfigOptions}
			/>

			<ConnectorActivityDialog
				open={this.state.createDialogOpen}
				onClose={this.onCloseCreateDialog}
				onSave={this.onCreateConnectorActivity}
				supportedActions={this.props.availableActions}
				activityConfigOptions={this.props.activityConfigOptions}
			/>

			<ConfirmationDialog
				title={this.props.t('connector.activityDelete')}
				confirm={this.props.t('connector.activityDeleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onCloseDeleteDialog}
				onConfirm={this.onConfirmDeletion}
			/>
		</Box>
	}

	onOpenDialogForCreating = () => {
		this.setState({
			createDialogOpen: true
		});
	}

	onCloseCreateDialog = () => {
		this.setState({createDialogOpen: false});
	}

	onOpenDialogForEditing = (activeConnectorActivity) => {
		this.setState({
			activeConnectorActivity,
			updateDialogOpen: true
		});
	}

	onCloseUpdateDialog = () => {
		this.setState({
			activeConnectorActivity: null,
			updateDialogOpen: false
		});
	}

	onOpenDeleteDialog = (activeConnectorActivity) => {
		this.setState({
			activeConnectorActivity,
			deleteDialogOpen: true
		});
	}

	onCloseDeleteDialog = () => {
		this.setState({
			activeConnectorActivity: null,
			deleteDialogOpen: false
		});
	}

	onConfirmDeletion = () => {
		const activityId = this.state.activeConnectorActivity.id;
		this.setState({
			activeConnectorActivity: null,
			deleteDialogOpen: false
		}, () => this.props.onCompanyDeleteConnectorActivity(this.props.instanceId, activityId));
	}

	onCreateConnectorActivity = (connectorActivity) => {
		this.setState({
			createDialogOpen: false,
		}, () => this.props.onCompanyCreateConnectorActivity(this.props.instanceId, connectorActivity));
	}

	onUpdateConnectorActivity = (connectorActivity) => {
		const activityId = this.state.activeConnectorActivity.id;
		this.setState({
			updateDialogOpen: false,
			activeConnectorActivity: null
		}, () => this.props.onCompanyUpdateConnectorActivity(this.props.instanceId, activityId, connectorActivity));
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onFetchConnectorActivityList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onFetchConnectorActivityList);
	}

	onFilterValueChange = (e) => {
		this.setState({
			filterValue: e.target.value,
		}, this.onFetchConnectorActivityList)
	}

	onFetchConnectorActivityList = () => {
		this.props.onFetchConnectorActivityList(
			this.props.instanceId,
			{
				page: this.state.page,
				pageSize: this.state.pageSize,
				filterValue: this.state.filterValue,
				sortField: this.state.sortModel.length > 0 ? {
					name: this.state.sortModel[0].field,
					sortOrder: this.state.sortModel[0].sort.toUpperCase()
				} : null
			}
		);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			activityList: state.company.connectorSettings?.activityList,
			activityCount: state.company.connectorSettings?.activityCount,
			activityActionSuccessFully: state.company.connectorActivityActionSuccessFully,
			busy: state.company.busy,
			serverError: state.company.serverError,
		};
	},
	dispatch => {
		return {
			onFetchConnectorActivityList: (instanceId, request) => {
				dispatch({
					type: 'COMPANY_FETCH_CONNECTOR_ACTIVITY_LIST',
					instanceId,
					request
				})
			},
			onCompanyCreateConnectorActivity: (instanceId, request) => {
				dispatch({
					type: 'COMPANY_CREATE_CONNECTOR_ACTIVITY',
					instanceId,
					request
				})
			},
			onCompanyUpdateConnectorActivity: (instanceId, activityId, request) => {
				dispatch({
					type: 'COMPANY_UPDATE_CONNECTOR_ACTIVITY',
					instanceId,
					activityId,
					request
				})
			},
			onCompanyDeleteConnectorActivity: (instanceId, activityId) => {
				dispatch({
					type: 'COMPANY_DELETE_CONNECTOR_ACTIVITY',
					instanceId,
					activityId,
				})
			}
		};
	}
)(ConnectorActivityGridComponent));