import React, {Component} from "react";
import {Box, MenuItem, Select} from "@mui/material";
import {withTranslation} from "react-i18next";

class UserNotificationSettingComponent extends Component {

	availableControlValues = ['ON', 'DAILY_DIGEST', 'OFF'];

	render() {
		return <Box sx={{display: 'flex', alignItems: 'center', gap: 1, maxWidth: '520px'}}>
			<Box sx={{flexGrow: 1}}>{this.props.label}</Box>
			<Select
				disabled={this.props.disabled}
				size="small"
				value={this.convertValueToControl(this.props.value)}
				onChange={(e) => this.props.onChange(this.convertControlToValue(e.target.value))}
				sx={{width: '180px', flexShrink: 0}}
			>
				{(this.availableControlValues).map(order =>
					<MenuItem key={order} value={order}>
						{this.props.t('user.notification_' + order)}
					</MenuItem>
				)}
			</Select>


		</Box>
	}

	convertValueToControl(value) {
		switch (value) {
			case 'IMMEDIATE':
				return 'ON';
			case 'DAILY_DIGEST':
				return 'DAILY_DIGEST';
			default:
				return 'OFF';
		}
	}

	convertControlToValue(control) {
		switch (control) {
			case 'ON':
				return 'IMMEDIATE';
			case 'DAILY_DIGEST':
				return 'DAILY_DIGEST';
			default:
				return null;
		}
	}
}

export default withTranslation()(UserNotificationSettingComponent);