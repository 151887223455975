import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputBase,
	MenuItem,
	Select,
	TextField
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {DatePicker} from "@mui/x-date-pickers";

export default (props) => {

	const {t} = useTranslation();

	const onResetFilterValue = () => props.onChangeFilterValue({target: {value: ''}});

	const [columnDialogOpen, setColumnDialogOpen] = useState(false);
	const onColumnDialogClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			setColumnDialogOpen(false);
		}
	}
	const onColumnDialogOpen = () => setColumnDialogOpen(true);
	const onColumnChange = (e, checked) => {
		const column = e.target.name;
		props.onChangeColumns(props.columns.filter(name => name !== column).concat(checked ? [column] : []));
	}

	const [filterDialogOpen, setFilterDialogOpen] = useState(false);
	const onFilterDialogOpen = () => setFilterDialogOpen(true);
	const onFilterDialogClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			setFilterDialogOpen(false);
		}
	}

	const fieldFilterActive = props.fieldFilters?.some(filter => !!(props.fieldFiltersValues || {})[filter.field]);

	return <Box
		sx={{
			display: 'flex',
			alignItems: 'center',
			borderBottom: '1px solid rgba(224, 224, 224, 1)',
			p: 0.5,
		}}
	>
		<Box
			sx={{
				pl: 0.5,
				display: 'flex',
				flexGrow: 1,
				alignItems: 'center',
				...(props.valueActive ? {backgroundColor: '#EEEEFF'} : {})
			}}
		>
			{!!props.refreshAction &&
				<>
					<IconButton onClick={props.refreshAction} sx={{p: 0.5, mr: 0.5}}>
						<RefreshIcon/>
					</IconButton>
					<Divider orientation="vertical" sx={{mr: 1}} flexItem/>
				</>
			}

			<SearchIcon fontSize="small"/>
			<InputBase
				id={props.filterId}
				autoComplete="off"
				value={props.filterValue}
				onChange={props.onChangeFilterValue}
				placeholder={t('search')}
				sx={{flexGrow: 1, pl: '2px'}}
				autoFocus={props.filterFocus || false}
			/>
			<IconButton
				title={t('clear')}
				aria-label="Clear"
				size="small"
				style={{visibility: props.filterValue ? 'visible' : 'hidden'}}
				onClick={onResetFilterValue}
			>
				<ClearIcon fontSize="small"/>
			</IconButton>
		</Box>
		{props.possibleColumns && <Button
			variant="contained"
			color="secondary"
			size="small"
			startIcon={<FilterListIcon/>}
			sx={{ml: 0.5}}
			onClick={onColumnDialogOpen}
		>
			{t('columns')}
		</Button>}
		{props.fieldFilters && <Button
			variant="contained"
			color={fieldFilterActive ? 'primary' : 'secondary'}
			size="small"
			startIcon={<ManageSearchIcon/>}
			sx={{ml: 0.5}}
			onClick={onFilterDialogOpen}
		>
			{t('filter')}
		</Button>}


		<Dialog open={columnDialogOpen} onClose={onColumnDialogClose} fullWidth maxWidth="sm">
			<DialogTitle>
				{t('columns')}
			</DialogTitle>
			<DialogContent>
				<FormGroup>
					{props?.possibleColumns?.map(column =>
						<FormControlLabel
							key={column.field}
							control={
								<Checkbox checked={props.columns.indexOf(column.field) >= 0} onChange={onColumnChange}
										  name={column.field}/>
							}
							label={column.headerName}
						/>
					)}
				</FormGroup>
			</DialogContent>
			<DialogActions>
				{!!props.onResetColumnsWidth && <Button onClick={props.onResetColumnsWidth} id="btn-columns-reset-width">{t('resetColumnsWidth')}</Button>}
				<Box sx={{flexGrow: 1}} />
				<Button onClick={onColumnDialogClose} id="btn-columns-back">{t('back')}</Button>
			</DialogActions>
		</Dialog>

		<Dialog open={filterDialogOpen} onClose={onFilterDialogClose} fullWidth maxWidth="sm">
			<DialogTitle>{t('filter')}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
					{props?.fieldFilters?.map(filter => {
						const value = props.fieldFiltersValues[filter.field];
						const onChange = (e) => props.onChangeFieldFilter(filter.field, e.target.value);

						return <Box key={filter.field} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
							<Box sx={{minWidth: '150px'}}>{filter.name}</Box>
							<Box sx={{flexGrow: 1}}>
								{filter.mode === 'text' && <TextField
									fullWidth
									size="small"
									value={value || ''}
									onChange={onChange}
								/>}
								{filter.mode === 'date' && <DatePicker
									slotProps={{
										textField: {
											error: false,
											fullWidth: true,
											variant: 'outlined',
											size: 'small',
											autoComplete: 'off',
											inputProps: {
												placeholder: t('locale.dateFormat')
											}
										}
									}}
									value={value || null}
									onChange={(value) => props.onChangeFieldFilter(filter.field, value)}
									format={"dd/LL/yyyy"}
								/>}
								{filter.mode === 'select' && <FormControl size="small" fullWidth>
									<Select
										value={value || ''}
										onChange={onChange}
									>
										{filter.options.map(option =>
											<MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
										)}
									</Select>
								</FormControl>}
							</Box>
							<Box>
								<IconButton
									title={t('clear')}
									aria-label="Clear"
									size="small"
									onClick={() => props.onChangeFieldFilter(filter.field, undefined)}
								>
									<ClearIcon fontSize="small"/>
								</IconButton>
							</Box>
						</Box>
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onFilterDialogClose} id="btn-field-filters-back">{t('back')}</Button>
			</DialogActions>
		</Dialog>
	</Box>
}
