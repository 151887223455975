import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Box, Checkbox, IconButton, Typography} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useTranslation} from "react-i18next";
import {format, parse} from "date-fns";
import {FORM_FIELD_DATE_VALUE_FORMAT, FORM_FIELD_DATE_VISUAL_FORMAT, FORM_FIELD_UNSET_VALUE, IS_MOBILE_DEVICE} from "../common/Constants";
import {DatePicker} from "@mui/x-date-pickers";
import FormattedTextField from "../common/FormattedTextField";


/**
 * Date picker component which behaves different for mobile: automatically open the date-selection popup when focused
 */
const InternalDatePicker = ({inputRef, value, onChange, onAccept, onKeyUp, onFocus, mobile, index}) => {
	const [open,setOpen] = useState(false);
	const [initialFocus,setInitialFocus] = useState(true);

	return mobile ?
		<DatePicker
			open={open}
			onFocus={() => {
				setOpen(true);
			}}
			slotProps={{
				textField: {
					inputRef: inputRef,
					error: false,
					variant: 'standard',
					autoComplete: 'off',
					onClick: () => {
						setOpen(true);
						setInitialFocus(false);
					},
					onFocus: () => {
						if (!initialFocus) {
							return;
						}
						setOpen(true);
						setInitialFocus(false);
						onFocus(index);
					},
					inputProps: {
						placeholder: '',
						sx: {
							p: 1,
							'&:focus': {
								backgroundColor: 'rgba(50, 50, 200, 0.1)'
							}
						}
					},
					InputProps: {
						disableUnderline: true,
					}
				},
				openPickerButton: {
					onClick: () => {
						setOpen(true);
						onFocus(index);
					},
				},
			}}
			onClose={() => {
				setOpen(false)
				setTimeout(() => {
					setInitialFocus(true);
				}, 100)
			}}
			value={value ? parse(value, FORM_FIELD_DATE_VALUE_FORMAT, Date.now()) : null}
			onAccept={(value) => {
				onAccept(value);
				setTimeout(() => {
					setInitialFocus(true);
				}, 100)
			}}
			format={FORM_FIELD_DATE_VISUAL_FORMAT}
			sx={{flexGrow: 1, pr: 1}}
		/> :
		<DatePicker
			slotProps={{
				textField: {
					inputRef: inputRef,
					error: false,
					variant: 'standard',
					autoComplete: 'off',
					onKeyUp,
					onFocus,
					inputProps: {
						placeholder: '',
						sx: {
							p: 1,
							'&:focus': {
								backgroundColor: 'rgba(50, 50, 200, 0.1)'
							}
						}
					},
					InputProps: {
						disableUnderline: true,
					}
				},
			}}
			value={value ? parse(value, FORM_FIELD_DATE_VALUE_FORMAT, Date.now()) : null}
			onChange={onChange}
			format={FORM_FIELD_DATE_VISUAL_FORMAT}
			sx={{flexGrow: 1, pr: 1}}
		/>
}


const FormFieldEditComponent = ({field, focus, onFocus, onUpdateFormFieldValue, onNextField, onPreviousField, index}) => {
	const {t} = useTranslation();
	const type = field.type;

	const ref = useRef();
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (focus) {
				ref?.current?.focus();
			}
		}, 100);

		return () => {
			clearTimeout(timeout);
		};
	}, [focus]);

	const onChangeTextFieldValue = useCallback((e) => {
		const value = e.target.value;
		onUpdateFormFieldValue(field.id, value);
	}, [field]);

	const onChangeDatePickerValue = useCallback((value) => {
		const newValue = (value instanceof Date && !isNaN(value)) ? format(value, FORM_FIELD_DATE_VALUE_FORMAT) : null;
		onUpdateFormFieldValue(field.id, newValue);
	}, [field])

	const onToggleCheckBox = useCallback(() => {
		onUpdateFormFieldValue(field.id, field.value === 'true' ? 'false' : 'true');
		if (IS_MOBILE_DEVICE) {
			onNextField(index);
		}
	}, [field]);

	const onKeyUp = useCallback((e) => {
		if ('Enter' === e.key) {
			if (e.shiftKey) {
				onPreviousField();
			} else {
				onNextField();
			}
		}
	}, [onNextField, onPreviousField]);

	// NOTE: the enterKeyHint and inputMode = 'numeric'properties are required to make the 'next'(enter) button on mobile devices act the same as for a desktop device (onKeyUp event will not be captured)
	if ('TEXT' === type || 'NUMERIC' === type) {
		return <FormattedTextField
			inputRef={ref}
			variant="standard"
			autoComplete="off"
			InputProps={{
				disableUnderline: true,
			}}
			value={field.value || ''}
			format={field.validatorFormat}
			onChange={onChangeTextFieldValue}
			onKeyUp={onKeyUp}
			onFocus={onFocus}
			fullWidth
			type={!IS_MOBILE_DEVICE && 'NUMERIC' === type ? 'number' : undefined}
			sx={{
				'& Input': {
					p: 1,
				},
				'& Input:focus': {
					backgroundColor: 'rgba(50, 50, 200, 0.1)'
				}
			}}
			inputProps={{
				enterKeyHint: 'enter',
				inputMode: 'NUMERIC' === type ? 'numeric' : undefined
			}}
		/>
	} else if ('DATE' === type) {
		return <InternalDatePicker
			inputRef={ref}
			value={field.value}
			onChange={onChangeDatePickerValue}
			onAccept={(value) => {
				onChangeDatePickerValue(value);
				onNextField(index);
			}}
			onKeyUp={onKeyUp}
			onFocus={onFocus}
			index={index}
			mobile={IS_MOBILE_DEVICE}
		/>
	} else if ('CHECKBOX' === type) {
		return <Checkbox
			inputRef={ref}
			size="small"
			checked={'true' === field.value}
			onChange={onToggleCheckBox}
			onFocus={() => {
				if (IS_MOBILE_DEVICE) {
					return;
				}
				onFocus(index);
			}}
			onKeyUp={onKeyUp}
		/>
	}
}

const FormFieldActionComponent = ({field, pdfViewerApiRef}) => {
	const {t} = useTranslation();

	return <IconButton
		variant="contained"
		color="primary"
		title={t('signing.formFieldsGoTo')}
		onClick={() => pdfViewerApiRef.current.onScrollToPage(field.pageIndex + 1)}
		disabled={false}
	>
		<VisibilityIcon fontSize="small"/>
	</IconButton>
}

export default ({formFields, onUpdateFormFieldValue, pdfViewerApiRef}) => {
	const {t} = useTranslation();

	// determine the initial field index to focus
	const initialFocusIndex = useMemo(() => {
		for (let index = 0; index < formFields.length; index++) {
			if (FORM_FIELD_UNSET_VALUE(formFields[index]?.value)) {
				return index;
			}
		}
	}, [formFields?.length || 0]); // should change when the contents changes

	const [focusIndex, setFocusIndex] = useState(initialFocusIndex);

	const onNextField = useCallback((startIndex) => {
		const number = ((!!startIndex ? startIndex : focusIndex) + 1) % formFields.length;
		setFocusIndex(number);
	}, [focusIndex, formFields?.length || 0]);

	const onPreviousField = useCallback(() => {
		setFocusIndex((formFields.length + focusIndex -1) % formFields.length);
	}, [focusIndex, formFields?.length || 0]);

	const onFocusFormField = (index) => {
		setFocusIndex(index);
	}

	return <Box component="form" sx={{display: 'flex', flexDirection: 'column', borderWidth: '1px', borderStyle: 'solid', borderColor: 'divider'}}>
		{formFields.map((field, index) => {
			const hasProblem = FORM_FIELD_UNSET_VALUE(field.value) && field.required ||
				(!!field.validatorFormat && field.validatorFormat.length !== field?.value?.length);

			return <Box
				key={field.id}
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexWrap: 'wrap',
					'&:not(:last-child)': {
						borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: 'divider'
					}
				}}
			>
				<Box sx={{flexBasis: '200px', pl: 1, pr: 2}}>
					<Typography variant="body2" color={hasProblem ? 'red' : undefined}>{field.name + (field.required ? ' *' : '')}</Typography>
				</Box>
				<Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<FormFieldEditComponent
						field={field}
						focus={focusIndex === index}
						index={index}
						onFocus={() => onFocusFormField(index)}
						onUpdateFormFieldValue={onUpdateFormFieldValue}
						onNextField={onNextField}
						onPreviousField={onPreviousField}
					/>
					<FormFieldActionComponent
						field={field}
						pdfViewerApiRef={pdfViewerApiRef}
					/>
				</Box>
			</Box>
		})}
	</Box>;
}