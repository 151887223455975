import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import SaveIcon from "@mui/icons-material/Save";
import {TimePicker} from "@mui/x-date-pickers";
import {getHours, getMinutes, setHours, setMinutes} from "date-fns";

class CompanyNotificationSettingsComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.onCompanyFetchNotificationSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		const notificationSettings = this.props.companyNotificationSettings;

		if (!notificationSettings) {
			return <LoadingComponent/>;

		}

		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('company.notificationSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.notificationSettingsReminders')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox
							checked={notificationSettings.pendingApprovalAndSignaturesReminderEnabled || false}
							onChange={this.onChangePendingApprovalAndSignatureNotificationEnabled}/>}
						label={this.props.t('company.notificationSettingsEnableReminders')}
						disabled={this.props.companyBusy}
					/>
					{notificationSettings.pendingApprovalAndSignaturesReminderEnabled &&
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<TextField
								variant="outlined"
								size="small"
								value={notificationSettings.pendingApprovalAndSignaturesReminderInDays}
								onChange={this.onChangePendingApprovalAndSignaturesReminderInDays}
								sx={{mr: 1, width: 100}}
								disabled={this.props.companyBusy}
							/>
							<span>{this.props.t('company.notificationSettingsRemindersDays')}</span>
						</Box>}
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.notificationSettingsDailyDigest')}</Typography>
				<Box sx={{ml: 2, mt: 2}}>
					<TimePicker
						label={this.props.t('company.notificationSettingsDailyDigestTime')}
						value={this.props.dailyDigestTime}
						onChange={this.onChangeDailyDigestTime}
						ampm={false}
						disabled={this.props.companyBusy}
						sx={{width: 300}}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.notificationSettingsMinimizeCollectionInvites')}</Typography>
				<Box sx={{ml: 2, display: 'flex', flexDirection: 'column'}}>
					<FormControlLabel
						control={<Checkbox
							checked={notificationSettings.collectionInvitationsMinimize || false}
							onChange={this.onChangeCollectionInvitationsMinimize}/>}
						label={this.props.t('company.notificationSettingsMinimizeCollectionInvitesEnable')}
						disabled={this.props.companyBusy}
					/>
					{!!notificationSettings.collectionInvitationsMinimize && <>
						<TextField
							variant="outlined"
							type="number"
							label={this.props.t('company.notificationSettingsMinimizeCollectionInvitesGracePeriod')}
							value={typeof notificationSettings.collectionInvitationsGraceTimeMinutes === 'number' ? notificationSettings.collectionInvitationsGraceTimeMinutes : ''}
							onChange={this.onChangeCollectionInvitationsGraceTimeMinutes}
							sx={{mr: 1, width: 300}}
							disabled={this.props.companyBusy}
						/>

						<Typography variant="body2">{this.props.t('company.notificationSettingsMinimizeCollectionInvitesGracePeriodHelp')}</Typography>
					</>}
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.notificationSettingsMinimizeCollectionFinishedNotifications')}</Typography>
				<Box sx={{ml: 2, display: 'flex', flexDirection: 'column'}}>
					<FormControlLabel
						control={<Checkbox
							checked={notificationSettings.collectionFinishedNotificationsMinimize || false}
							onChange={this.onChangeCollectionFinishedNotificationsMinimize}/>}
						label={this.props.t('company.notificationSettingsMinimizeCollectionFinishedNotificationsEnable')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Button variant="contained" onClick={this.onSave} id="btn-settings-save"
						startIcon={<SaveIcon/>}>{this.props.t('save')}</Button>
			</Box>
		</Box>
	}

	onChangePendingApprovalAndSignatureNotificationEnabled = (e, value) => {
		if (!value) {
			this.props.onCompanyChangeNotificationSettingsItem('pendingApprovalAndSignaturesReminderInDays', 0);
		}
		this.props.onCompanyChangeNotificationSettingsItem('pendingApprovalAndSignaturesReminderEnabled', value);
	}

	onChangePendingApprovalAndSignaturesReminderInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onCompanyChangeNotificationSettingsItem('pendingApprovalAndSignaturesReminderInDays', !value ? 0 : value);
	}

	onChangeDailyDigestTime = (time) => {
		this.props.onCompanyChangeNotificationSettingsItem('dailyDigestSendTimeHour', getHours(time));
		this.props.onCompanyChangeNotificationSettingsItem('dailyDigestSendTimeMinute', getMinutes(time));
	}

	onSave = () => {
		this.props.onCompanyUpdateNotificationSettings(this.props.companyNotificationSettings);
	}

	onChangeCollectionInvitationsMinimize = (e, value) => {
		if (!value) {
			this.props.onCompanyChangeNotificationSettingsItem('collectionInvitationsGraceTimeMinutes', null);
		}
		this.props.onCompanyChangeNotificationSettingsItem('collectionInvitationsMinimize', value);
	}

	onChangeCollectionInvitationsGraceTimeMinutes = (e) => {
		const value = parseInt(e.target.value, 10);
		if (!isNaN(value) && value > 0) {
			this.props.onCompanyChangeNotificationSettingsItem('collectionInvitationsGraceTimeMinutes', value);
		} else {
			this.props.onCompanyChangeNotificationSettingsItem('collectionInvitationsGraceTimeMinutes', null);
		}
	}

	onChangeCollectionFinishedNotificationsMinimize = (e, value) => {
		this.props.onCompanyChangeNotificationSettingsItem('collectionFinishedNotificationsMinimize', value);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyNotificationSettings: state.company.notificationSettings,

			dailyDigestTime: setMinutes(setHours(new Date(), state.company.notificationSettings?.dailyDigestSendTimeHour), state.company.notificationSettings?.dailyDigestSendTimeMinute)
		}
	},
	dispatch => {
		return {
			onCompanyFetchNotificationSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_NOTIFICATION_SETTINGS'
				});
			},
			onCompanyUpdateNotificationSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_NOTIFICATION_SETTINGS',
					settings
				});
			},
			onCompanyChangeNotificationSettingsItem: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_NOTIFICATION_SETTINGS_ITEM',
					key,
					value
				});
			}
		}
	}
)(CompanyNotificationSettingsComponent));
