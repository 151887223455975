import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	IconButton,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Toolbar,
	Typography
} from "@mui/material";
import ContentEditable from "react-contenteditable";
import {Add, FormatAlignCenter, FormatAlignLeft, FormatAlignRight, FormatBold, FormatItalic, FormatUnderlined, HorizontalRule, Image, Link, Remove, SortByAlpha} from "@mui/icons-material";
import {v4 as uuidv4} from 'uuid';


const PLACEHOLDERS = ['APPROVER', 'ATTACHMENT_MAX_SIZE', 'COMPANY', 'COMPANY_LOGO', 'CONTENT_TABLE', 'CREATOR', 'CUSTOM_MESSAGES',	'DAILY_DIGEST_TABLE', 'DATE', 'DEADLINE', 'DECLINE_REASON',
	'DECLINED_TABLE', 'DIOSS_LOGO', 'DOCUMENT', 'DOCUMENT_COUNT', 'DOCUMENT_URL', 'OTP', 'RECEIVER', 'REJECT_REASON', 'SIGNER', 'TITLE', 'QUILL_LOGO', 'USER_INVITE_LINK_EXPIRY', 'USER_URL'];

const MAX_TEXT_LENGTH = 5000;

const PREFIX_URL = (url) => {
	// prefix URL with // to enforce using the url as a new domain iso referencing it from the current domain
	return !url.startsWith('http') ? "//" + url : url;
}

const UNPREFIX_URL = (url) => {
	return !!url && url.startsWith('//') ? url.substring(2, url.length) : url;
}

const GENERATE_DOM_ID = () => {
	return '_gen_' + uuidv4();
}

const DEFAULT_STATE = {
	rowCount: 1,
	selectedColumnIndex: 0,
	selectedRowIndex: 0,
	languageRowGroupObject: null,
	languageSubjects: null,
	languageHeaderTitles: null,
	textAlignments: null,
	textColors: null,
	textUppercase: null,
	linkDialogOpen: false,
	imageDialogOpen: false,
	activeUrlHref: null,
	activeUrlDisplayText: null,
	activeUrlClass: null,
	activeImageSrc: null,
	activeDomId: null,
	activeContentEditable: null,
};

const LinkEditDialog = ({urlHref, urlDisplayText, urlClass, onClose, onEdit, onInsert}) => {

	const {t} = useTranslation();
	const [href, setHref] = useState(UNPREFIX_URL(urlHref));
	const [displayText, setDisplayText] = useState(urlDisplayText);
	const [type, setType] = useState(!!urlClass && urlClass === 'BUTTON' ? urlClass : 'TEXT_LINK');

	const onConfirm = () => {
		const prefixedHref = PREFIX_URL(href);

		const urlClass = type;

		if (!!urlHref) {
			onEdit(prefixedHref, displayText, urlClass)
		} else {
			onInsert(prefixedHref, displayText, urlClass)
		}
	}

	const onKeyUp = (e) => {
		if (e.key === 'Enter' && !!href && !!displayText) {
			onConfirm();
		}
	}

	return <Dialog open onClose={onClose} maxWidth="sm" fullWidth onKeyUp={onKeyUp}>
		<DialogTitle>{t('mail.emailFieldsLinkEdit')}</DialogTitle>
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			margin: 2,
			gap: 2
		}}>
			<TextField
				variant="outlined"
				label="URL"
				required
				value={href || ''}
				onChange={(e) => setHref(e.target.value)}
				autoComplete="off"
				autoFocus
			/>

			<TextField
				variant="outlined"
				label="Display text"
				required
				value={displayText || ''}
				onChange={(e) => setDisplayText(e.target.value)}
				autoComplete="off"
			/>

			<RadioGroup
				defaultValue="TEXT_LINK"
				onChange={(e) => setType(e.target.value)}
			>
				<FormControlLabel value="TEXT_LINK"
								  checked={'TEXT_LINK' === type}
								  control={<Radio/>}
								  label="Text link"/>
				<FormControlLabel value="BUTTON"
								  checked={'BUTTON' === type}
								  control={<Radio/>}
								  label="Button"/>

			</RadioGroup>
		</Box>
		<DialogContent>
			<DialogActions>
				<Button onClick={onClose}
						id="btn-link-edit-cancel"
				>
					{t('cancel')}
				</Button>
				<Button variant="contained"
						onClick={onConfirm}
						disabled={!href || !displayText}
						id="btn-link-edit-confirm"
				>
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}

const ImageEditDialog = ({imgSrc, imgWidth, imgHeight, onClose, onEdit, onInsert}) => {

	const {t} = useTranslation();
	const [src, setSrc] = useState(imgSrc);

	const onConfirm = () => {
		const prefixedSrc = PREFIX_URL(src);

		if (!!imgSrc) {
			onEdit(prefixedSrc)
		} else {
			onInsert(prefixedSrc);
		}
	}

	const onKeyUp = (e) => {
		if (e.key === 'Enter' && !!src) {
			onConfirm();
		}
	}

	return <Dialog open onClose={onClose} maxWidth="sm" fullWidth onKeyUp={onKeyUp}>
		<DialogTitle>{t('mail.emailFieldsImageEdit')}</DialogTitle>
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			margin: 2,
			gap: 2
		}}>
			<TextField
				variant="outlined"
				label="URL"
				required
				value={src || ''}
				onChange={(e) => setSrc(e.target.value)}
				autoComplete="off"
				autoFocus
			/>
		</Box>
		<DialogContent>
			<DialogActions>
				<Button onClick={onClose}
						id="btn-link-edit-cancel"
				>
					{t('cancel')}
				</Button>
				<Button variant="contained"
						onClick={onConfirm}
						disabled={!src}
						id="btn-link-edit-confirm"
				>
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}

class EmailTemplateFieldsComponent extends Component {

	constructor(props) {
		super(props);

		if (props.convertHtmlRef) {
			props.convertHtmlRef.current = {
				convert: () => {
					return this.convertToHtml();
				}
			}
		}

		this.state = {
			...DEFAULT_STATE,
		}
	}


	componentDidMount() {
		if (!!this.props.templateMap) {
			this.parseTemplateMap(this.props.templateMap);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.templateId !== this.props.templateId) {
			this.parseTemplateMap(this.props.templateMap);
		}
	}

	render() {
		const rows = this.state.languageRowGroupObject && this.state.languageRowGroupObject[this.props.language];
		const subject = this.state.languageSubjects && this.state.languageSubjects[this.props.language];
		const headerTitle = this.state.languageHeaderTitles && this.state.languageHeaderTitles[this.props.language];

		return <>
			<Box sx={{mb: 2}}>
				<TextField
					variant="outlined"
					label={this.props.t('mail.templateSubject')}
					required
					value={subject || ''}
					onChange={this.onChangeTemplateSubject}
					disabled={this.props.disabled}
					autoComplete="off"
					sx={{flex: '1 0 300px'}}
					fullWidth
					size="small"
				/>

				<TextField
					variant="outlined"
					label={this.props.t('mail.templateHeaderTitle')}
					required
					value={headerTitle || ''}
					onChange={this.onChangeTemplateHeaderTitle}
					disabled={this.props.disabled}
					autoComplete="off"
					sx={{flex: '1 0 300px', mt: 1}}
					fullWidth
					size="small"
				/>
			</Box>

			<Toolbar
				variant="dense"
				sx={{
					border: "1px solid rgba(224, 224, 224, 1)",
					borderRadius: '4px',
					mb: 0.25,
					gap: 1
				}}>
				<IconButton
					variant="contained"
					edge="start"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatBold}
				>
					<FormatBold format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatItalic}
				>
					<FormatItalic format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatUnderline}
				>
					<FormatUnderlined format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatUppercase}
				>
					<SortByAlpha format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onInsertHorizontalRule}
				>
					<HorizontalRule format="small"/>
				</IconButton>

				<Divider orientation="vertical" flexItem/>

				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatAlignLeft}
				>
					<FormatAlignLeft format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatAlignCenter}
				>
					<FormatAlignCenter format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onFormatAlignRight}
				>
					<FormatAlignRight format="small"/>
				</IconButton>

				<Divider orientation="vertical" flexItem/>

				<TextField
					variant="outlined"
					size="small"
					type="color"
					value={this.state.textColors?.[this.state.selectedRowIndex]?.[this.state.selectedColumnIndex] || ''}
					disabled={this.props.disabled}
					onChange={this.onFormatColor}
					sx={{width: 50}}
					autoComplete="off"
				/>

				<Divider orientation="vertical" flexItem/>

				<Typography sx={{
					ml: 1,
					mr: 0.5
				}}>{this.props.t('mail.emailFieldsRows')}</Typography>
				<TextField
					value={this.state.rowCount}
					disabled={this.props.disabled}
					onChange={this.onChangeRowCount}
					type="number"
					size="small"
					sx={{width: '70px'}}
				/>

				<Divider orientation="vertical" flexItem/>

				<Select
					value={''}
					displayEmpty={true}
					disabled={this.props.disabled}
					onChange={this.onSelectPlaceholder}
					size="small"
					renderValue={() => this.props.t('mail.emailFieldsPlaceholder')}
				>
					{PLACEHOLDERS.map((value, index) =>
						<MenuItem key={index} value={value}>
							{this.props.t('mail.emailFieldsPlaceholder' + '_' + value)}
						</MenuItem>
					)}
				</Select>

				<Divider orientation="vertical" flexItem/>

				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onOpenLinkDialog}
				>
					<Link format="small"/>
				</IconButton>
				<IconButton
					variant="contained"
					color="inherit"
					disabled={this.props.disabled}
					onClick={this.onOpenImageDialog}
				>
					<Image format="small"/>
				</IconButton>
			</Toolbar>


			<Box sx={{display: 'flex', justifyContent: 'space-evenly', gap: 1, flexDirection: 'column'}}>
				{!!rows && rows.map((rowGroupContent, rowIndex) => {
					return <Box
						key={rowIndex}
						id={"email-text-row-" + rowIndex}
						sx={{
							display: 'flex',
							width: '100%',
							gap: 1
						}}
					>
						{rowGroupContent.map((columnGroupContent, columnIndex) => (
							<Box
								key={columnIndex}
								sx={{
									flex: 1,
									textAlign: !!this.state.textAlignments[rowIndex][columnIndex] ? this.state.textAlignments[rowIndex][columnIndex] : 'left',
									color: !!this.state.textColors[rowIndex][columnIndex] ? this.state.textColors[rowIndex][columnIndex] : '#000000',
									textTransform: !!this.state.textUppercase[rowIndex][columnIndex] ? this.state.textUppercase[rowIndex][columnIndex] : 'none',
									'& a': {color: 'inherit !important'}
								}}>
								<ContentEditable
									id={"email-content-editor-" + rowIndex + "-column-" + columnIndex}
									onFocus={() => this.onChangeSelectedEditor(rowIndex, columnIndex)}
									onChange={(e) => this.onChangeHtml(e, e?.currentTarget?.innerHTML, rowIndex, columnIndex)}
									onBlur={(e) => {
										this.onChangeHtml(e, e?.currentTarget?.innerHTML, rowIndex, columnIndex)}
									}
									onSelect={(e) => this.onSelectText(e)}
									html={columnGroupContent}
									disabled={this.props.disabled}
									style={{
										flex: 1,
										minHeight: '75px',
										border: "1px solid rgba(224, 224, 224, 1)",
										borderRadius: "4px",
										padding: "8.5px 14px",
										textWrap: 'wrap',
										overflowWrap: 'break-word',
										height: '100%',
										...(this.props.disabled && {color: 'rgba(0, 0, 0, 0.38)'})
									}}
								/>
							</Box>
						))}

						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							margin: 'auto'
						}}>
							<IconButton
								variant="contained"
								color="inherit"
								disabled={this.props.disabled}
								onClick={() => this.onColumnAdd(rowIndex)}
							>
								<Add format="small"/>
							</IconButton>
							<IconButton
								variant="contained"
								color="inherit"
								disabled={this.props.disabled}
								onClick={() => this.onColumnRemove(rowIndex)}
							>
								<Remove format="small"/>
							</IconButton>
						</Box>
					</Box>
				})}
			</Box>

			{this.state.linkDialogOpen && <LinkEditDialog
				onClose={this.onCloseLinkDialog}
				urlHref={this.state.activeUrlHref}
				urlDisplayText={this.state.activeUrlDisplayText}
				urlClass={this.state.activeUrlClass}
				onEdit={this.onEditLink}
				onInsert={this.onInsertLink}
			/>}

			{this.state.imageDialogOpen && <ImageEditDialog
				onClose={this.onCloseImageDialog}
				imgSrc={this.state.activeImageSrc}
				onEdit={this.onEditImage}
				onInsert={this.onInsertImage}
			/>}
		</>
	}

	onChangeTemplateSubject = (e) => {
		const subject = e.target.value;

		this.setState({
			languageSubjects: {
				...this.state.languageSubjects,
				[this.props.language]: subject
			}
		});
	}

	onChangeTemplateHeaderTitle = (e) => {
		const headerTitle = e.target.value;

		this.setState({
			languageHeaderTitles: {
				...this.state.languageHeaderTitles,
				[this.props.language]: headerTitle
			}
		});
	}

	onFormatBold = () => {
		const selectedEditorText = this.extractSelectedEditorText();
		const boldTagRegex = /<[/]?(b)>/gi;

		let changed;
		if (boldTagRegex.test(selectedEditorText)) {
			changed = selectedEditorText.replaceAll(boldTagRegex, '');
		} else {
			changed = '<b>' + selectedEditorText + '</b>';
		}

		this.updateSelectedEditorText(changed);
	}

	onFormatItalic = () => {
		const selectedEditorText = this.extractSelectedEditorText();
		const italicTagRegex = /<[/]?(i)>/gi;

		let changed;
		if (italicTagRegex.test(selectedEditorText)) {
			changed = selectedEditorText.replaceAll(italicTagRegex, '');
		} else {
			changed = '<i>' + selectedEditorText + '</i>';
		}

		this.updateSelectedEditorText(changed);
	}

	onFormatUnderline = () => {
		const selectedEditorText = this.extractSelectedEditorText();
		const underlineTagRegex = /<[/]?(u)>/gi;

		let changed;
		if (underlineTagRegex.test(selectedEditorText)) {
			changed = selectedEditorText.replaceAll(underlineTagRegex, '');
		} else {
			changed = '<u>' + selectedEditorText + '</u>';
		}

		this.updateSelectedEditorText(changed);
	}

	onFormatUppercase = () => {
		const selectedColumnIndex = this.state.selectedColumnIndex;
		const selectedRowIndex = this.state.selectedRowIndex;
		const textUppercase = this.state.textUppercase.slice(0);
		textUppercase[selectedRowIndex][selectedColumnIndex] = textUppercase[selectedRowIndex][selectedColumnIndex] === 'uppercase' ? null : 'uppercase';
		this.setState({textUppercase});
	}

	onInsertHorizontalRule = () => {
		const selectedEditorText = this.extractSelectedEditorText();

		let changed = selectedEditorText + '<hr/>';

		this.updateSelectedEditorText(changed);
	}

	onFormatAlignLeft = () => {
		this.updateAlignment('left');
	}

	onFormatAlignCenter = () => {
		this.updateAlignment('center');
	}

	onFormatAlignRight = () => {
		this.updateAlignment('right');
	}

	onFormatColor = (e) => {
		const selectedColumnIndex = this.state.selectedColumnIndex;
		const selectedRowIndex = this.state.selectedRowIndex;
		const textColors = this.state.textColors.slice(0);
		textColors[selectedRowIndex][selectedColumnIndex] = e.target.value;
		this.setState({textColors});
	}

	updateAlignment = (alignment) => {
		const selectedColumnIndex = this.state.selectedColumnIndex;
		const selectedRowIndex = this.state.selectedRowIndex;
		const textAlignments = this.state.textAlignments.slice(0);
		textAlignments[selectedRowIndex][selectedColumnIndex] = alignment;
		this.setState({textAlignments});
	}

	onSelectPlaceholder = (e) => {
		const language = this.props.language;
		const selectedRowIndex = this.state.selectedRowIndex;
		const selectedColumnIndex = this.state.selectedColumnIndex;
		const selectedEditorText = this.state.languageRowGroupObject[language][selectedRowIndex][selectedColumnIndex];
		const changed = selectedEditorText + '$' + e.target.value + '$';

		this.setState({
			languageRowGroupObject: {
				...this.state.languageRowGroupObject,
				[language]: this.state.languageRowGroupObject[language].map((rowContent, rowIndex) =>
					rowIndex === selectedRowIndex ? this.state.languageRowGroupObject[language][this.state.selectedRowIndex].map((columnContent, columnIndex) => {
						return columnIndex === selectedColumnIndex ? changed : columnContent;
					}) : rowContent)
			}
		});
	}

	parseTemplateMap = (templateMap) => {
		const languageSubjects = {};
		const languageHeaderTitles = {};
		const languageRowGroupObject = {}
		const textAlignments = [];
		const textColors = [];
		const textUppercase = [];

		Object.keys(templateMap).forEach((language) => {
			languageRowGroupObject[language] = [];

			// subject
			languageSubjects[language] = templateMap[language].subject;
			languageHeaderTitles[language] = templateMap[language].headerTitle;
			// content: extract the different row groups based on <tr></tr> tags
			this.parseHtmlRows(templateMap[language].content).forEach((rowHtml, rowIndex) => {
				languageRowGroupObject[language][rowIndex] = [];
				textAlignments[rowIndex] = [];
				textColors[rowIndex] = [];
				textUppercase[rowIndex] = [];

				// extract the different column groups based on <td></td> tags
				this.parseHtmlColumnsAttributesAndContent(rowHtml).forEach(({attributes, content}, columnIndex) => {
					languageRowGroupObject[language][rowIndex][columnIndex] = content;
					textAlignments[rowIndex][columnIndex] = this.parseTextAlignment(attributes);
					textColors[rowIndex][columnIndex] = this.parseTextColors(attributes);
					textUppercase[rowIndex][columnIndex] = this.parseTextUppercase(attributes);
				});
			});
		});

		this.setState({
			languageSubjects,
			languageHeaderTitles,
			rowCount: languageRowGroupObject?.[this.props.language]?.length || 0,
			languageRowGroupObject,
			textAlignments,
			textColors,
			textUppercase
		});
	}

	extractSelectedEditorText = () => {
		const language = this.props.language;
		const selectedRowIndex = this.state.selectedRowIndex;
		const selectedColumnIndex = this.state.selectedColumnIndex;
		return this.state.languageRowGroupObject[language][selectedRowIndex][selectedColumnIndex];
	}

	updateSelectedEditorText = (text) => {
		const language = this.props.language;
		const selectedRowIndex = this.state.selectedRowIndex;
		const selectedColumnIndex = this.state.selectedColumnIndex;

		this.setState({
			languageRowGroupObject: {
				...this.state.languageRowGroupObject,
				[language]: this.state.languageRowGroupObject[language].map((rowContent, row) =>
					row === selectedRowIndex ? rowContent.map((columnContent, column) => {
						return column === selectedColumnIndex ? text : columnContent;
					}) : rowContent)
			}
		});
	}

	parseHtmlRows = (html) => {
		const matcher = html.matchAll(/<tr(\s*)>(.*?)<\/tr>/gs);
		return Array.from(matcher, x => x[2]);
	}

	parseHtmlColumnsAttributesAndContent = (html) => {
		const matcher = html.matchAll(/<td(.*?)>(.*?)<\/td>/gs);
		return Array.from(matcher, x => ({attributes: x[1], content: x[2]}));
	}

	parseTextAlignment = (attributes) => {
		const possibleAlignments = ['left', 'center', 'right'];
		const matcher = attributes.matchAll(/align="(.*?)"/g);
		const alignment = Array.from(matcher)?.[0]?.[1];
		if (!!alignment && possibleAlignments.includes(alignment)) {
			return alignment;
		}
		return null;
	}

	parseTextColors = (attributes) => {
		const matcher = attributes.matchAll(/color: (.*?)[;|"]/g);
		const color = Array.from(matcher)?.[0]?.[1];
		if (!!color) {
			return color;
		}
		return null;
	}

	parseTextUppercase = (attributes) => {
		const matcher = attributes.matchAll(/text-transform: (.*?)[;|"]/g);
		const uppercase = Array.from(matcher)?.[0]?.[1];
		if (!!uppercase) {
			return uppercase;
		}
		return null;
	}

	onChangeHtml = (e, html, rowIndex, columnIndex) => {
		if (typeof html !== 'string') { // !html is true for empty fields
			return;
		} else if (e.type !== "input") {
			// if there is a block level element (e.g. hr) on the contentEditable, we suddenly start receiving the onKeyDown/onKeyUp events too, so filter on input type
			return;
		}

		if (html.length > MAX_TEXT_LENGTH) {
			html = html.substring(0, MAX_TEXT_LENGTH);
		}

		const language = this.props.language;
		if (this.state.languageRowGroupObject?.[language]?.[rowIndex]?.[columnIndex] !== html) {
			this.setState({
				languageRowGroupObject: {
					...this.state.languageRowGroupObject,
					[language]: this.state.languageRowGroupObject[language].map((rowContent, row) =>
						row === rowIndex ? rowContent.map((columnContent, column) => {
							return column === columnIndex ? html : columnContent;
						}) : rowContent)
				}
			});
		}
	}

	onChangeRowCount = (e) => {
		if (!e.target.value) return;

		const rowCount = parseInt(e.target.value);
		if (rowCount < 1 || rowCount > 99) return;

		const languageRowGroupObject = {...this.state.languageRowGroupObject};
		const textAlignments = [...this.state.textAlignments];
		const textColors = [...this.state.textColors];
		const textUppercase = [...this.state.textUppercase];

		// check if the bottom row should be kept, if so insert/remove at the second-last index iso the last one
		const preserveBottomRow = this.props.disabledBottomRowModification;

		if (rowCount < this.state.rowCount) {
			// reduction
			if (preserveBottomRow) {
				Object.keys(languageRowGroupObject).forEach((language) => {
					languageRowGroupObject[language].splice(-2, 1);
				});

				textAlignments.splice(-2, 1);
				textColors.splice(-2, 1);
				textUppercase.splice(-2, 1);
			} else {
				Object.keys(languageRowGroupObject).forEach((language) => {
					languageRowGroupObject[language].splice(-1);
				});

				textAlignments.splice(-1);
				textColors.splice(-1);
				textUppercase.splice(-1);
			}
		} else {
			// addition
			if (preserveBottomRow) {
				Object.keys(languageRowGroupObject).forEach((language) => {
					languageRowGroupObject[language].splice(textAlignments.length - 1, 0, [""])
				});

				textAlignments.splice(textAlignments.length - 1, 0, ['left'])
				textColors.splice(textAlignments.length - 1, 0, ['#000000'])
				textUppercase.splice(textUppercase.length - 1, 0, ['none'])
			} else {
				Object.keys(languageRowGroupObject).forEach((language) => {
					languageRowGroupObject[language].splice(textAlignments.length, 0, [""])
				});

				textAlignments.splice(textAlignments.length, 0, ['left'])
				textColors.splice(textAlignments.length, 0, ['#000000'])
				textUppercase.splice(textUppercase.length, 0, ['none'])
			}
		}

		this.setState({rowCount, languageRowGroupObject, textAlignments, textColors, textUppercase});
	}

	onColumnAdd = (rowIndex) => {
		const currentColumnCount = this.state.languageRowGroupObject[this.props.language][rowIndex].length;
		if (currentColumnCount === 4) return;

		let languageRowGroupObject = {...this.state.languageRowGroupObject};

		Object.keys(languageRowGroupObject).forEach((language) => {
			languageRowGroupObject[language][rowIndex].push("");
		});

		const textAlignments = this.state.textAlignments.map((rowContent, row) => (
			row === rowIndex ? [...rowContent, 'left'] : rowContent
		));

		const textColors = this.state.textColors.map((rowContent, row) => (
			row === rowIndex ? [...rowContent, '#000000'] : rowContent
		));

		const textUppercase = this.state.textUppercase.map((rowContent, row) => (
			row === rowIndex ? [...rowContent, 'none'] : rowContent
		));

		this.setState({languageRowGroupObject, textAlignments, textColors, textUppercase});
	}

	onColumnRemove = (rowIndex) => {
		const currentColumnCount = this.state.languageRowGroupObject[this.props.language][rowIndex].length;
		if (currentColumnCount === 1) return;

		let languageRowGroupObject = {...this.state.languageRowGroupObject};

		Object.keys(languageRowGroupObject).forEach((language) => {
			languageRowGroupObject[language][rowIndex].pop();
		});

		const textAlignments = this.state.textAlignments.map((rowContent, row) => (
			row === rowIndex ? rowContent.slice(0, rowContent.length - 1) : rowContent
		));

		const textColors = this.state.textColors.map((rowContent, row) => (
			row === rowIndex ? rowContent.slice(0, rowContent.length - 1) : rowContent
		));

		const textUppercase = this.state.textUppercase.map((rowContent, row) => (
			row === rowIndex ? rowContent.slice(0, rowContent.length - 1) : rowContent
		));

		this.setState({languageRowGroupObject, textAlignments, textColors, textUppercase});
	}

	onChangeSelectedEditor = (selectedRowIndex, selectedColumnIndex) => {
		this.setState({selectedRowIndex, selectedColumnIndex})
	}

	convertRowsToHtml = (rows) => {
		const htmlBuilder = [];

		htmlBuilder.push('<table cellspacing="0" cellpadding="0" width="650"\n' +
			'       style="width: 650px; font-family: Arial,serif; font-size: 12px; line-height: 150%; background-color: inherit; border: 0; border-collapse: collapse;">');

		const anchorDefaultStyle = 'style="color: inherit !important"';


		htmlBuilder.push('<tr>');
		for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
			const columnAlignments = this.state.textAlignments[rowIndex];
			const columnColors = this.state.textColors[rowIndex];
			const columnUppercases = this.state.textUppercase[rowIndex];
			const row = rows[rowIndex];

			for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
				const columnAlignment = columnAlignments[columnIndex];
				const columnColor = columnColors[columnIndex];
				const columnUppercase = columnUppercases[columnIndex];
				let columnContent = row[columnIndex];

				let style = '';
				if (!!columnColor || !!columnUppercase) {
					style = ` style="`;
					if (!!columnColor) {
						style += `color: ${columnColor};`
					}
					if (!!columnUppercase) {
						style += `text-transform: uppercase;`
					}
					style += `"`;
				}

				htmlBuilder.push(`<td` + (!!columnAlignment ? ` align="${columnAlignment}"` : '') + style + `>`);
				// strip the editor style from the anchor, it's final style will be applied based on the class upon instantiation of the content
				htmlBuilder.push(columnContent.replaceAll(anchorDefaultStyle, ''));

				htmlBuilder.push('</td>');
			}

			if (rowIndex < rows.length - 1) {
				htmlBuilder.push('</tr><tr>');
			}
		}

		htmlBuilder.push('</tr>');
		htmlBuilder.push('</table>');

		return htmlBuilder.join('');
	}

	onSelectText = (e) => {
		const el = e.nativeEvent.srcElement;
		if (el.localName === 'a') {
			// try to obtain the 'raw' href from the html iso using the DOM, since the DOM will parse (prefix) the href
			const anchorRawHtml = el.outerHTML;
			const matcher = anchorRawHtml.matchAll(/(href=")(.*?)(")/g);
			const rawHref = Array.from(matcher)?.[0]?.[2];

			this.setState({
				activeContentEditable: el.parentNode.cloneNode(true),
				activeUrlHref: decodeURIComponent(!!rawHref ? rawHref : el.href),
				activeUrlClass: el.className,
				activeUrlDisplayText: el.text,
				activeDomId: el.id,
				linkDialogOpen: true
			});
		} else if (el.localName === 'img') {
			this.setState({
				activeContentEditable: el.parentNode.cloneNode(true),
				activeImageSrc: el.src,
				activeDomId: el.id,
				imageDialogOpen: true
			});
		}
	}

	onOpenLinkDialog = () => {
		this.setState({linkDialogOpen: true, activeUrlHref: null, activeUrlDisplayText: null, activeUrlClass: null});
	}

	onCloseLinkDialog = () => {
		this.setState({linkDialogOpen: false, activeUrlHref: null, activeUrlDisplayText: null, activeUrlClass: null});
	}

	onOpenImageDialog = () => {
		this.setState({imageDialogOpen: true, activeImageSrc: null});
	}

	onCloseImageDialog = () => {
		this.setState({imageDialogOpen: false, activeImageSrc: null});
	}

	onInsertLink = (href, displayText, urlClass) => {
		let style = "color: inherit !important";

		this.setState({
			linkDialogOpen: false,
			activeUrlHref: null,
			activeUrlDisplayText: null,
			activeUrlClass: null,
		}, () => this.onInsertHtml(`<a id="${GENERATE_DOM_ID()}" href="${href}" target="_blank" rel="noopener noreferrer" class="${urlClass}" style="${style}">${displayText}</a>`));
	}

	onEditLink = (href, displayText, urlClass) => {
		const activeDomId = this.state.activeDomId;

		this.setState({
			linkDialogOpen: false,
			activeUrlHref: null,
			activeUrlDisplayText: null,
			activeUrlClass: null,
			activeDomId: null,
		}, () => this.onUpdateHtml(activeDomId, {href, text: displayText, className: urlClass}));
	}

	onInsertImage = (src) => {
		this.setState({
			imageDialogOpen: false,
		}, () => this.onInsertHtml(`<img src="${src}" id="${GENERATE_DOM_ID()}">`));
	}

	onEditImage = (src) => {
		const activeDomId = this.state.activeDomId;
		this.setState({
			imageDialogOpen: false,
			activeImageSrc: null,
			activeDomId: null,
		}, () => this.onUpdateHtml(activeDomId, {src}));
	}

	onInsertHtml = (html) => {
		const {selectedRowIndex, selectedColumnIndex, languageRowGroupObject} = this.state;
		const currentHtml = languageRowGroupObject[this.props.language][selectedRowIndex][selectedColumnIndex] + html;
		this.setState({
			languageRowGroupObject: {
				...languageRowGroupObject,
				[this.props.language]: this.state.languageRowGroupObject[this.props.language].map((rowContent, row) =>
					row === selectedRowIndex ? rowContent.map((columnContent, column) => {
						return column === selectedColumnIndex ? currentHtml : columnContent;
					}) : rowContent)
			}
		});
	}

	onUpdateHtml = (id, props) => {
		const {selectedRowIndex, selectedColumnIndex, languageRowGroupObject, activeContentEditable} = this.state;
		const targetChild = Array.from(activeContentEditable.children).find((a) => a.id === id);

		if (!targetChild) {
			return;
		}
		for (const key of Object.keys(props)) {
			if (!props[key]) {
				continue;
			}

			targetChild[key] = props[key];
		}

		const currentHtml = activeContentEditable.innerHTML;
		this.setState({
			languageRowGroupObject: {
				...languageRowGroupObject,
				[this.props.language]: this.state.languageRowGroupObject[this.props.language].map((rowContent, row) =>
					row === selectedRowIndex ? rowContent.map((columnContent, column) => {
						return column === selectedColumnIndex ? currentHtml : columnContent;
					}) : rowContent)
			}
		});
	}

	convertToHtml = () => {
		const languageHtmlObject = {...this.state.languageRowGroupObject};
		Object.keys(languageHtmlObject).forEach((language) => {
			languageHtmlObject[language] = {
				subject: this.state.languageSubjects[language],
				headerTitle: this.state.languageHeaderTitles[language],
				content: this.convertRowsToHtml(languageHtmlObject[language])
			};
		});

		return languageHtmlObject;
	}
}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(EmailTemplateFieldsComponent));
