import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Paper, Tab, Tabs} from "@mui/material";
import {withTranslation} from "react-i18next";
import CompanyGeneralSettingsComponent from "./CompanyGeneralSettingsComponent";
import CompanyStyleSettingsComponent from "./CompanyStyleSettingsComponent";
import CompanyEmailSettingsComponent from "./CompanyEmailSettingsComponent";
import CompanyDelegatesComponent from "./CompanyDelegatesComponent";
import CompanySwitchComponent from "./CompanySwitchComponent";
import CompanyOidcComponent from "./CompanyOidcComponent";
import CompanyNotificationSettingsComponent from "./CompanyNotificationSettingsComponent";
import CompanyConnectorSettingsComponent from "./CompanyConnectorSettingsComponent";
import CompanyCertificateConfigSettingsComponent from "./CompanyCertificateSettingsComponent";
import CompanyApiKeyOverviewComponent from "./CompanyApiKeyOverviewComponent";

class CompanySettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null,
			requestedTab: null,
			shouldSave: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.eligibleTabs.length > 0) {
			const tab = this.props.eligibleTabs.at(0);
			this.setState({tab, requestedTab: tab});
		}
		// save action has completed (succeeded/failed)
		if ((prevProps.companySettingsPendingChanges && !this.props.companySettingsPendingChanges) ||
			(!prevProps.companyServerError && !!this.props.companyServerError)) {
			this.setState({shouldSave: false});
		}
	}

	render() {
		return <AppContainer
			needsSession
			hasPendingChanges={this.props.companySettingsPendingChanges}
			saveIsBusy={this.props.companyBusy}
			saveHasError={!!this.props.companyServerError}
			saveDisabled={!this.props.companySettingsPendingChangesSavePossible}
			onSave={this.onSave}
			customNavigation={this.state.tab !== this.state.requestedTab}
			onCustomNavigationComplete={this.onCustomNavigationComplete}
			onCustomNavigationCanceled={this.onCustomNavigationCanceled}
		>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <Fragment>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.eligibleTabs.map(tab => <Tab key={tab} value={tab}
																 label={this.props.t('company.settingsTab_' + tab)}/>)}
					</Tabs>

					{this.renderCurrentTab()}
				</Fragment>}
			</Paper>
		</AppContainer>
	}

	onCustomNavigationComplete = () => {
		this.setState({tab: this.state.requestedTab});
	}

	onCustomNavigationCanceled = () => {
		this.setState({requestedTab: this.state.tab});
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case 'GENERAL_SETTINGS':
				return <CompanyGeneralSettingsComponent shouldSave={this.state.shouldSave}/>;
			case 'CERTIFICATE_SETTINGS':
				return <CompanyCertificateConfigSettingsComponent shouldSave={this.state.shouldSave}/>;
			case 'APIKEY_SETTINGS':
				return <CompanyApiKeyOverviewComponent/>;
			case 'EMAIL_SETTINGS':
				return <CompanyEmailSettingsComponent shouldSave={this.state.shouldSave}/>;
			case 'NOTIFICATION_SETTINGS':
				return <CompanyNotificationSettingsComponent shouldSave={this.state.shouldSave}/>
			case 'STYLE_SETTINGS':
				return <CompanyStyleSettingsComponent shouldSave={this.state.shouldSave}/>;
			case 'DELEGATES':
				return <CompanyDelegatesComponent/>;
			case 'OIDC_SETTINGS':
				return <CompanyOidcComponent/>;
			case 'CONNECTORS':
				return <CompanyConnectorSettingsComponent shouldSave={this.state.shouldSave}/>;
			case 'SWITCH':
				return <CompanySwitchComponent/>;
		}
	}

	onChangeTab = (e, requestedTab) => {
		this.setState({requestedTab});
	}

	onSave = () => {
		this.setState({shouldSave: true});
	}

}

export default withTranslation()(connect(
	state => {
		const eligibleTabs = [];
		if (state.session?.info?.companySettingsAccessible) {
			eligibleTabs.push('GENERAL_SETTINGS');
			eligibleTabs.push('CERTIFICATE_SETTINGS');
			if (state.session?.info?.companyApiKeysOverviewAccessible) {
				eligibleTabs.push('APIKEY_SETTINGS');
			}
			eligibleTabs.push('EMAIL_SETTINGS');
			eligibleTabs.push('NOTIFICATION_SETTINGS');
			eligibleTabs.push('STYLE_SETTINGS');
			eligibleTabs.push('DELEGATES');
			eligibleTabs.push('OIDC_SETTINGS');
			eligibleTabs.push('CONNECTORS');
		}
		if (state.session?.info?.inMultipleCompanies) {
			eligibleTabs.push('SWITCH');
		}

		return {
			sessionInfo: state.session.info,
			eligibleTabs,
			companySettingsPendingChanges: state.company.settingsPendingChanges,
			companySettingsPendingChangesSavePossible : state.company.settingsPendingChangesSavePossible,
			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
		}
	},
	dispatch => {
		return {
			onCompanyUpdateGeneralSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_GENERAL_SETTINGS',
					settings
				});
			},
		}
	}
)(CompanySettingsComponent));
