import {Component} from "react";
import {connect} from "react-redux";
import {Alert, Snackbar} from "@mui/material";
import {withTranslation} from "react-i18next";

class GlobalSnackbar extends Component {

	render() {
		return <Snackbar open={this.props.snackbarOpen}
						 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
						 autoHideDuration={3000}
						 onClose={this.props.onSnackbarClose}>
			<Alert severity={!!this.props.snackbarMessageSeverity ? this.props.snackbarMessageSeverity : "success"} sx={{width: '100%'}}>
				{this.props.snackbarMessageKey ?
					this.props.t(this.props.snackbarMessageKey) :
					this.props.snackbarMessage}
			</Alert>
		</Snackbar>
	}

}

export default withTranslation()(connect(
	state => {
		return {
			snackbarOpen: state.snackbar.open,
			snackbarMessage: state.snackbar.message,
			snackbarMessageKey: state.snackbar.messageKey,
			snackbarMessageSeverity: state.snackbar.messageSeverity,
		}
	},
	dispatch => {
		return {
			onSnackbarClose: () => {
				dispatch({
					type: 'SNACKBAR_CLOSE'
				})
			}
		}
	}
)(GlobalSnackbar))
