import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import {SIGNATURE_TYPES} from "../common/Constants";

class CompanyAdminSettingsDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onCompanyFetchAdminSettings(this.props.companyId);
		}
	}

	render() {
		const signatureTypes = Object.keys(SIGNATURE_TYPES);
		const companyAdminSettings = this.props.companyAdminSettings;


		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.adminSettingsHeader')}</DialogTitle>
			{!companyAdminSettings && <DialogContent><LoadingComponent /></DialogContent>}
			{companyAdminSettings && <DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.companyName')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.name')}
								required
								value={companyAdminSettings.companyName}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								autoFocus
								sx={{ m: 0 }}
							/>

							<Box sx={{mt: 1}}>
								<FormControlLabel
									control={<Checkbox checked={companyAdminSettings.inactive} sx={{ p: 0 }}
													   onChange={this.onChangeInactive}/>}
									label={this.props.t('company.adminSettingsInactive')}
									sx={{ m: 0 }}/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsSignatureTypes')}</StepLabel>
						<StepContent>
							{signatureTypes.map(signatureType => {
								const signatureTypeEnabled = companyAdminSettings.enabledSignatureTypes.indexOf(signatureType);
								return <Box key={signatureType}>
									<FormControlLabel
										control={<Checkbox checked={signatureTypeEnabled >= 0} sx={{ p: 0}}
														   onChange={(e, value) => this.onChangeSignatureType(signatureType, value)}/>}
										label={this.props.t('company.adminSettingsSignature_'+ signatureType)}
										sx={{ m: 0 }}
									/>
								</Box>
							})}
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsDomain')}</StepLabel>
						<StepContent>
							<Box>
								<FormControl fullWidth>
									<RadioGroup
										value={this.getUrlDomainTypeRadioGroupValue(companyAdminSettings)}
										onChange={this.onChangeUrlDomainType}
									>
										<FormControlLabel value="NONE" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainNone')}/>
										<FormControlLabel value="SUBDOMAIN" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainSubdomain')}/>
										{companyAdminSettings.urlSubdomainEnabled && <Box sx={{ml: 2, width: '100%'}}>
											<Alert severity="info" sx={{mb: 1}}>{this.props.t('company.adminSettingsDomainSubdomainExample') + ` https://${companyAdminSettings.urlSubdomain}.quill.dioss.com`}</Alert>

											<TextField
												variant="outlined"
												fullWidth
												helperText={this.props.t('company.adminSettingsDomainSubdomainValidation')}
												label={this.props.t('company.adminSettingsDomainSubdomain')}
												value={companyAdminSettings.urlSubdomain || ''}
												onChange={this.onChangeUrlSubdomain}
											/>

										</Box>}

										<FormControlLabel value="CUSTOMDOMAIN" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainCustomDomain')}/>
										{companyAdminSettings.urlCustomDomainEnabled && <Box sx={{ml: 2}}>
											<TextField
												InputProps={{
													startAdornment: <InputAdornment position="start" sx={{mr: 0}}>https://</InputAdornment>,
												}}
												variant="outlined"
												fullWidth
												helperText={this.props.t('company.adminSettingsDomainCustomDomainValidation')}
												label={this.props.t('company.adminSettingsDomainCustomDomain')}
												value={companyAdminSettings.urlCustomDomain || ''}
												onChange={this.onChangeUrlCustomDomain}
											/>
										</Box>}
									</RadioGroup>
								</FormControl>
							</Box>

						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsEmail')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={companyAdminSettings.mailboxEnabled} sx={{ p: 0 }}
													   onChange={this.onChangeMailboxEnabled}/>}
									label={this.props.t('company.adminSettingsMailboxEnabled')}
									sx={{ m: 0 }}/>
							</Box>
							<Box>
								<TextField
									fullWidth
									label={this.props.t('company.adminSettingsWhitelistedEmailDomains')}
									value={companyAdminSettings.whitelistedEmailDomainsDelimited}
									onChange={this.onChangeWhitelistedEmailDomainsDelimited}
									autoComplete="off"
									sx={{mt:2}}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsMisc')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={companyAdminSettings.pdfRecreatedShown} sx={{ p: 0 }}
													   onChange={this.onChangePdfRecreatedShown}/>}
									label={this.props.t('company.adminSettingsPdfRecreatedShown')}
									sx={{ m: 0 }}/>
							</Box>

							{this.props.applicationAdmin &&
								<>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.canManageChildCompanies} sx={{ p: 0 }}
															   onChange={this.onChangeCanManageChildCompanies}/>}
											label={this.props.t('company.adminSettingsCanManageChildCompanies')}
											disabled={companyAdminSettings.childCompany}
											sx={{ m: 0 }}/>
									</Box>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.canRequestSensitiveSignerData} sx={{ p: 0 }}
															   onChange={this.onChangeCanRequestSensitiveSignerData}/>}
											label={this.props.t('company.adminSettingsCanRequestSensitiveSignerData')}
											sx={{ m: 0 }}/>
									</Box>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.useEidMiddlewarePinInput} sx={{ p: 0 }}
															   onChange={this.onChangeUseEidMiddlewarePinInput}/>}
											label={this.props.t('company.adminSettingsUseEidMiddlewarePinInput')}
											sx={{ m: 0 }}/>
									</Box>
									<Box sx={{display: 'flex', flexDirection: 'column'}}>
										<FormControlLabel
											control={<Checkbox checked={typeof companyAdminSettings.itsmePartnerCode === 'string'}
															   sx={{ p: 0 }}
															   onChange={this.onChangeItsmePartnerCodeOverride}/>}
											label={this.props.t('company.adminSettingsItsmePartnerCodeOverride')}
											sx={{ m: 0 }}/>

										{typeof companyAdminSettings.itsmePartnerCode === 'string' && <>
											<TextField
												label={this.props.t('company.adminSettingsItsmePartnerCode')}
												value={companyAdminSettings.itsmePartnerCode}
												onChange={this.onChangeItsmePartnerCode}
												autoComplete="off"
												sx={{mt:1, ml: 3, width: 250}}
											/>
										</>}

										{typeof companyAdminSettings.itsmePartnerCode === 'string' && <Alert severity="warning"
											   sx={{mt: 1, ml: 3}}>{this.props.t('company.adminSettingsItsmePartnerCodeWarning')}</Alert>}
									</Box>
								</>
							}

						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.adminSettingsFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>}

			<DialogActions>
				<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!companyAdminSettings || !this.canContinue()} onClick={this.onUpdate}
						id="btn-settings-save">
					{this.props.t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	getUrlDomainTypeRadioGroupValue(companyAdminSettings) {
		if (!companyAdminSettings.urlSubdomainEnabled && !companyAdminSettings.urlCustomDomainEnabled) {
			return 'NONE';
		} else if (companyAdminSettings.urlSubdomainEnabled) {
			return 'SUBDOMAIN';
		} else if (companyAdminSettings.urlCustomDomainEnabled) {
			return 'CUSTOMDOMAIN';
		}
	}

	onChangeName = (e) => {
		this.props.onCompanyChangeAdminSetting('companyName', e.target.value);
	}

	onChangeInactive = (e, value) => {
		this.props.onCompanyChangeAdminSetting('inactive', value);
	}

	onChangeSignatureType = (type, enabled) => {
		const types = this.props.companyAdminSettings.enabledSignatureTypes
			.filter(t => t !== type);
		if (enabled) types.push(type);
		this.props.onCompanyChangeAdminSetting('enabledSignatureTypes', types);
	}

	onChangePdfRecreatedShown = (e, value) => {
		this.props.onCompanyChangeAdminSetting('pdfRecreatedShown', value);
	}

	onChangeMailboxEnabled = (e, value) => {
		this.props.onCompanyChangeAdminSetting('mailboxEnabled', value);
	}

	onChangeWhitelistedEmailDomainsDelimited = (e) => {
		this.props.onCompanyChangeAdminSetting('whitelistedEmailDomainsDelimited', e.target.value);
	}

	onChangeUrlDomainType = (e) => {
		if (e.target.value === 'NONE') {
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', null);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', null);
		} else if (e.target.value === 'SUBDOMAIN') {
			let	proposedName = this.props.companyAdminSettings.companyName.toLowerCase();
			proposedName = proposedName.replaceAll(/[^a-z0-9-_]/g, "");

			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', null);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', true);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', proposedName);

		} else if (e.target.value === 'CUSTOMDOMAIN') {
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', true);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', null);
		}
	}

	onChangeUrlSubdomain = (e) => {
		if (/^[a-z0-9_-]*$/.test(e.target.value)) {
			this.props.onCompanyChangeAdminSetting('urlSubdomain', e.target.value);
		}
	}

	onChangeUrlCustomDomain = (e) => {
		if (/^[a-z0-9._-]*$/.test(e.target.value)) {
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', e.target.value);
		}
	}

	onChangeCanManageChildCompanies = (e, value) => {
		this.props.onCompanyChangeAdminSetting('canManageChildCompanies', value);
	}

	onChangeCanRequestSensitiveSignerData = (e, value) => {
		this.props.onCompanyChangeAdminSetting('canRequestSensitiveSignerData', value);
	}

	onChangeUseEidMiddlewarePinInput = (e, value) => {
		this.props.onCompanyChangeAdminSetting('useEidMiddlewarePinInput', value);
	}

	onChangeItsmePartnerCodeOverride = (e, value) => {
		this.props.onCompanyChangeAdminSetting('itsmePartnerCode', value ? "" : null);
	}

	onChangeItsmePartnerCode = (e) => {
		this.props.onCompanyChangeAdminSetting('itsmePartnerCode', e.target.value);
	}

	canContinue = () => {
		return !!this.props.companyAdminSettings?.companyName &&
			((!this.props.companyAdminSettings?.urlSubdomainEnabled || this.props.companyAdminSettings?.urlSubdomain?.length > 0)
			&& (!this.props.companyAdminSettings?.urlCustomDomainEnabled || this.props.companyAdminSettings?.urlCustomDomain?.length > 0))
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onUpdate();
		}
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onUpdate = () => {
		this.props.onUpdateAdminSettings(this.props.companyAdminSettings);
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyBusy: state.company.busy,
			companyAdminSettings: state.company.adminSettings,
			applicationAdmin: state.session.info.applicationAdmin
		}
	},
	dispatch => {
		return {
			onCompanyFetchAdminSettings: (companyId) => {
				dispatch({
					type: 'COMPANY_FETCH_ADMIN_SETTINGS',
					companyId
				});
			},
			onCompanyChangeAdminSetting: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_ADMIN_SETTING',
					key,
					value
				});
			}
		}
	}
)(CompanyAdminSettingsDialog));
