import React, {Component, Fragment} from "react";
import {
	Autocomplete,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const CURRENT_ACTION_OR_DEFAULT = (props) => {
	if (!!props.connectorActivity?.action) {
		return props.connectorActivity?.action;
	}
	return props.supportedActions?.length > 0 ? props.supportedActions[0] : '';
}

const INIT_STATE = (props) => {
	const state = {
		action: CURRENT_ACTION_OR_DEFAULT(props),
		folderId: props.connectorActivity?.folder?.id || null,
		folderName: props.connectorActivity?.folder?.name || '',
		userId: props.connectorActivity?.user?.id || null,
		userEmail: props.connectorActivity?.user?.email || '',
		uploadFileNameDocumentIdPrefix: (!!props.connectorActivity ? props.connectorActivity.uploadFileNameDocumentIdPrefix : true)
	};
	for (const configOption of props.activityConfigOptions) {
		state[configOption.name] = props.connectorActivity?.[configOption.name] || '';
	}
	return state;
}

class ConnectorActivityDialog extends Component {

	constructor(props) {
		super(props);

		this.state = INIT_STATE(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.open && this.props.open) {
			this.setState(INIT_STATE(this.props));
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.t('connector.activitySettings')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('connector.activityActionType')}</StepLabel>
						<StepContent>
							{this.props.supportedActions && <Select
								size="small"
								value={this.state.action}
								onChange={this.onChangeAction}
							>
								{this.props.supportedActions.map(supportedAction =>
									<MenuItem key={supportedAction}
											  value={supportedAction}>{this.props.t('connector.actionType_' + supportedAction)}</MenuItem>
								)}
							</Select>}
						</StepContent>
					</Step>
					{'DOWNLOAD_DOCUMENTS' === this.state.action && <Step active>
						<StepLabel>{this.props.t('connector.activityUser')}</StepLabel>
						<StepContent>
							<Typography sx={{mb: 2}}>{this.props.t('connector.activityUserInfo')}</Typography>
							<Autocomplete
								freeSolo
								inputValue={this.state.userEmail}
								onInputChange={this.onUserInputChange}
								onChange={this.onUserChange}
								isOptionEqualToValue={(option, value) => option.email === value.email}
								getOptionLabel={(option) => option.email}
								options={this.props.companyConnectorSettingsUsers}
								filterOptions={(x) => x}
								loading={this.props.companyConnectorSettingsUserSearchBusy}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{this.props.companyConnectorSettingsUserSearchBusy ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
										required
									/>
								)}
							/>
						</StepContent>
					</Step>}
					<Step active>
						<StepLabel>{this.props.t('connector.activityFolder')}</StepLabel>
						<StepContent>
							<Typography sx={{mb: 1}}>{this.props.t('connector.activityFolderInfo')}</Typography>
							<Autocomplete
								freeSolo
								inputValue={this.state.folderName}
								onInputChange={this.onFolderInputChange}
								onChange={this.onFolderChange}
								isOptionEqualToValue={(option, value) => option.name === value.name}
								getOptionLabel={(option) => option.name}
								options={this.props.companyConnectorSettingsFolders}
								filterOptions={(x) => x}
								loading={this.props.companyConnectorSettingsFolderSearchBusy}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{this.props.companyConnectorSettingsFolderSearchBusy ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						</StepContent>
					</Step>
					{this.props.activityConfigOptions?.length > 0 && !!this.state.action && <Step active>
						<StepLabel>{this.props.t('connector.activityAdditionalConfiguration')}</StepLabel>
						<StepContent>
							{this.props.activityConfigOptions.map(option => <Fragment key={option.name}>
								<Typography sx={{mb: 1}}>{option.description(this.state.action)}</Typography>
								<TextField
									variant="outlined"
									size="small"
									label={option.label}
									required={option.required}
									value={this.state[option.name]}
									onChange={(e) => this.onChangeConfig(option.name, e.target.value)}
									autoComplete="off"
									fullWidth
									sx={{mb: 2}}
								/>
							</Fragment>)}

							{'UPLOAD_COMPLETED_DOCUMENTS' === this.state.action && <>
								<Typography sx={{mb: 1, mt: 1}}>{this.props.t('connector.uploadFileNameDocumentIdPrefixDescription')}</Typography>
								<FormControlLabel
									control={<Checkbox checked={this.state.uploadFileNameDocumentIdPrefix}
													   onChange={this.onChangeDocumentIdPrefix}
													   sx={{paddingTop: 0, paddingBottom: 0}}/>}
									label={this.props.t('connector.uploadFileNameDocumentIdPrefix')}
									disabled={this.props.companyBusy}
									sx={{p: 0}}
								/>
							</>}
						</StepContent>
					</Step>}
					<Step active>
						<StepLabel>{this.props.t('connector.activitySettingsFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('connector.activitySettingsFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>

			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={!this.canSave()}
						onClick={this.onSave}
						id="btn-create-confirm"
				>
					{this.props.t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	canSave = () => {
		return !!this.state.action &&
			('DOWNLOAD_DOCUMENTS' !== this.state.action || !!this.state.userId) &&
			this.mandatoryConfigOptionsFilledIn();
	}

	mandatoryConfigOptionsFilledIn = () => {
		return this.props.activityConfigOptions?.length <= 0 ||
			this.props.activityConfigOptions.filter(option => option.required)
				.every(option => {
					return !!this.state[option.name];
				});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canSave()) {
			this.onSave();
		}
	}

	onChangeAction = (e) => {
		this.setState({
			...this.state,
			action: e.target.value,
			...('UPLOAD_COMPLETED_DOCUMENTS' === e.target.value && {uploadFileNameDocumentIdPrefix: true}),
			...('UPLOAD_COMPLETED_DOCUMENTS' !== e.target.value && {uploadFileNameDocumentIdPrefix: undefined})
		});
	}

	onUserChange = (e, value) => {
		this.setState({
			userId: value?.id || null,
			userEmail: value?.email || '' // can't be null
		});
	}

	onUserInputChange = (e, searchValue) => {
		const isTyping = !!e && 'click' !== e?.type;
		this.setState({userEmail: searchValue || ''}, () => {
			if (isTyping && !!searchValue) {
				this.props.onCompanySearchConnectorUser(searchValue);
			}
		});
	}

	onFolderChange = (e, value) => {
		this.setState({
			folderId: value?.id || null,
			folderName: value?.name || '' // can't be null
		})
	}

	onFolderInputChange = (e, searchValue) => {
		const isTyping = !!e && 'click' !== e?.type;
		this.setState({folderName: searchValue || ''}, () => {
			if (isTyping && !!searchValue) {
				this.props.onCompanySearchConnectorFolder(this.state.action, this.state.userId, searchValue);
			}
		});
	}

	onChangeDocumentIdPrefix = (e, value) => {
		this.setState({
			uploadFileNameDocumentIdPrefix: value
		});
	}

	onChangeConfig = (key, value) => {
		const newState = this.state;
		newState[key] = value;
		this.setState({
			newState
		});
	}

	onSave = () => {
		const activity = {
			action: this.state.action,
			userId: this.state.userId,
			folderId: this.state.folderId,
			...('UPLOAD_COMPLETED_DOCUMENTS' === this.state.action && {uploadFileNameDocumentIdPrefix: this.state.uploadFileNameDocumentIdPrefix})
		};
		const config = [];
		for (const configOption of this.props.activityConfigOptions) {
			config.push({key: configOption.name, value: this.state[configOption.name]});
		}
		activity.config = config;
		this.props.onSave(activity);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyConnectorSettingsUserSearchBusy: state.company?.connectorSettings?.userSearchBusy || false,
			companyConnectorSettingsUsers: state.company?.connectorSettings?.users || [],
			companyConnectorSettingsFolderSearchBusy: state.company?.connectorSettings?.folderSearchBusy || false,
			companyConnectorSettingsFolders: state.company?.connectorSettings?.folders || [],
		}
	},
	dispatch => {
		return {
			onCompanySearchConnectorUser: (value) => {
				dispatch({
					type: 'COMPANY_SEARCH_CONNECTOR_USER',
					value
				})
			},
			onCompanySearchConnectorFolder: (action, userId, value) => {
				dispatch({
					type: 'COMPANY_SEARCH_CONNECTOR_FOLDER',
					action,
					userId,
					value
				})
			},

		}
	}
)(ConnectorActivityDialog));
