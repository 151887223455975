import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const LEVELS = ['ALL', 'FINEST', 'FINER', 'FINE', 'CONFIG', 'INFO', 'WARNING', 'SEVERE', 'OFF'];

class ApplicationLoggingComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			logger: '',
			level: 'INFO',
			filterValue: '',
		}
	}

	componentDidMount() {
		this.props.onApplicationFetchLogSettings();
	}

	render() {
		const columns = [
			{
				field: 'name',
				headerName: this.props.t('application.loggingLogger'),
				editable: false,
				flex: 1
			},
			{
				field: 'level',
				headerName: this.props.t('application.loggingLevel'),
				editable: false,
				flex: 1
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				width: 50,
				renderCell: (cellValues) => <Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('application.loggingDelete')}
						onClick={() => this.onRemoveLogger(cellValues.row.name)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>
			},
		];

		const entries = (this.props.applicationLogSettings?.entries || [])
			.filter(entry => entry.name.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) >= 0);

		return <Box sx={{ mt: 2 }}>
			<Typography variant="h6">{this.props.t('application.loggingHeader')}</Typography>
			<ServerErrorComponent serverError={this.props.applicationServerError} />
			<Box sx={{ mt: 2 }}>
				<DataGrid
					autoHeight
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick
					pagination

					columns={columns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange
						}
					}}

					loading={this.props.applicationBusy}

					getRowId={(row) => row.name}
					rows={entries}
					rowCount={entries.length}

					density="compact"
					sx={{mt: 1}}
				/>
				<Box sx={{display: 'flex', alignItems: 'center', gap: 1, mt: 1}}>
					<TextField
						label={this.props.t('application.loggingLogger')}
						value={this.state.logger}
						onChange={this.onChangeLogger}
						sx={{flexGrow: 1}}
						disabled={this.props.applicationBusy}
						autoComplete="off"
					/>
					<FormControl sx={{flexGrow: 1}}>
						<InputLabel id="level-label">{this.props.t('application.loggingLevel')}</InputLabel>
						<Select
							labelId="level-label"
							value={this.state.level}
							label={this.props.t('application.loggingLevel')}
							onChange={this.onChangeLevel}
						>
							{LEVELS.map(level => <MenuItem key={level} value={level}>{level}</MenuItem>)}
						</Select>
					</FormControl>
					<Button variant="contained"
							onClick={this.onAddLogger}
							startIcon={<AddIcon/>}
							color="secondary"
							disabled={this.state.logger.length === 0}
							sx={{ml: 1, mt:1}}
					>
						{this.props.t('application.loggingAdd')}
					</Button>
				</Box>
			</Box>
		</Box>
	}

	onFilterValueChange = (filterValue) => {
		this.setState({filterValue});
	}

	onChangeLogger = (e) => {
		this.setState({logger: e.target.value});
	}

	onChangeLevel = (e, level) => {
		this.setState({level: e.target.value});
	}

	onAddLogger = () => {
		const entries = (this.props.applicationLogSettings?.entries || [])
			.filter(entry => entry.name != this.state.logger);
		entries.push({name: this.state.logger, level: this.state.level})
		this.props.onApplicationUpdateLogSettings({entries});
	}

	onRemoveLogger = (logger) => {
		const entries = (this.props.applicationLogSettings?.entries || [])
			.filter(entry => entry.name != logger);
		this.props.onApplicationUpdateLogSettings({entries});
	}

}

export default withTranslation()(connect(
	state => {
		return {
			applicationBusy: state.application.busy,
			applicationServerError: state.application.serverError,
			applicationLogSettings: state.application.logSettings,
		}
	},
	dispatch => {
		return {
			onApplicationFetchLogSettings: () => {
				dispatch({
					type: 'APPLICATION_FETCH_LOG_SETTINGS',
				});
			},
			onApplicationUpdateLogSettings: (settings) => {
				dispatch({
					type: 'APPLICATION_UPDATE_LOG_SETTINGS',
					settings
				});
			},
		}
	}
)(ApplicationLoggingComponent));
