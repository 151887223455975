import React, {Component, Fragment} from "react";
import {v4 as uuidv4} from 'uuid';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {Alert, Box, Button, Fab, FormControl, MenuItem, Paper, Select, Tab, Tabs, Typography} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import NotificationsIcon from "@mui/icons-material/Notifications";
import DownloadIcon from "@mui/icons-material/Download";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AttachmentIcon from '@mui/icons-material/Attachment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowDown, faFileArrowUp, faFileInvoice} from '@fortawesome/free-solid-svg-icons'
import AppContainer from "../common/AppContainer";
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ConfirmationDialog from "../common/ConfirmationDialog";
import EditorSignersComponent from "./EditorSignersComponent";
import EditorPdfComponent from "./EditorPdfComponent";
import EditorGeneralComponent from "./EditorGeneralComponent";
import EditorMultiSignComponent from "./EditorMultiSignComponent";
import EditorActionLogsDialog from "./EditorActionLogsDialog";
import EditorApproversComponent from "./EditorApproversComponent";
import EditorPostActionsComponent from "./EditorPostActionsComponent";
import EditorApplyTemplateDialog from "./EditorApplyTemplateDialog";
import EditorSaveTemplateDialog from "./EditorSaveTemplateDialog";
import EditorFormFieldSettingsDialog from "./EditorFormFieldSettingsDialog";
import {
	APPROVAL_ERROR_COUNT,
	CAN_SAVE,
	CAN_SEND,
	COLLECTION_DISTRIBUTE_APPROVE_ORDER_TYPE,
	COLLECTION_DISTRIBUTE_APPROVERS,
	COLLECTION_DISTRIBUTE_APPROVERS_CUSTOM_EMAIL_HEADERS,
	COLLECTION_DISTRIBUTE_FOLDER,
	COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS,
	COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVALS,
	COLLECTION_DISTRIBUTE_OVERRIDE_REASON_SETTINGS,
	COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_AND_EVIDENCE_REPORT_EMAIL_DISTRIBUTION_LIST,
	COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_EMAIL_DISTRIBUTION_LIST,
	COLLECTION_DISTRIBUTE_REASON_AVAILABLE,
	COLLECTION_DISTRIBUTE_REASON_LEGAL_NOTICE_MANDATORY,
	COLLECTION_DISTRIBUTE_REASON_LEGAL_NOTICE_TEXT,
	COLLECTION_DISTRIBUTE_SIGNERS,
	COLLECTION_DISTRIBUTE_SIGNERS_CUSTOM_EMAIL_HEADERS,
	COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE,
	COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE_KIOSK,
	GENERAL_ERROR_COUNT,
	MERGE_DOCUMENT,
	MULTI_SIGN_DISTRIBUTE_EXTRA_SIGNATURE_FIELDS,
	MULTI_SIGN_DISTRIBUTE_FORM_FIELDS,
	MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS,
	MULTI_SIGN_ERROR_COUNT,
	MULTI_SIGN_EXTRACT_CUSTOM_EMAIL_HEADERS,
	MULTI_SIGN_EXTRACT_EXTRA_SIGNATURE_FIELDS,
	MULTI_SIGN_EXTRACT_FORM_FIELDS,
	MULTI_SIGN_EXTRACT_PARAPH_FIELDS,
	MULTI_SIGN_EXTRACT_SIGNATURE_FIELD,
	SELECT_DOCUMENT,
	SIGNERS_ERROR_COUNT,
} from "./EditorUtils";
import EditorApplyTemplateOptionsDialog from "./EditorApplyTemplateOptionsDialog";
import {TEMPLATE_OPTIONS} from "./EditorConstants";
import SplitButton from "../common/SplitButton";
import DocumentReminderFeedbackComponent from "../document/DocumentReminderComponent";
import EditorOverviewComponent from "./EditorOverviewComponent";
import EditorFieldStyleDialog from "./EditorFieldStyleDialog";
import SelectUserDialog from "../common/SelectUserDialog";
import EditIcon from "@mui/icons-material/Edit";

export const SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH = 0.30;
export const SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT = 0.065;
export const PARAPH_FIELD_DEFAULT_RELATIVE_WIDTH = 0.10;
export const PARAPH_FIELD_DEFAULT_RELATIVE_HEIGHT = 0.05;

class EditorComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: 'GENERAL',
			selectedChildDocumentId: 0,
			selectedFieldPlaceholder: null,
			actionLogsDialogOpen: false,
			selectedTemplate: null,
			applyTemplateDialogOpen: false,
			applyTemplateOptionsDialogOpen: false,
			applyTemplateOnAllChildren: false,
			applyTemplateDocumentId: null,
			saveTemplateDialogOpen: false,
			deleteDialogOpen: false,
			closeDialogOpen: false,
			formFieldSettingsDialogOpen: false,
			formFieldSettingsMultiSignDialogOpen: false,
			formFieldToEdit: null,
			fieldStyleDialogOpen: false,
			fieldStyleEditInfo: null,
			restartBusy: false,
			multiSignSignatureField: {},
			multiSignParaphFields: [],
			multiSignExtraSignatureFields: [],
			multiSignFormFields: [],
			multiSignSignatureTypes: [],
			multiSignCustomEmailHeaders: {}
		};

		this.tabs = [
			{value: 'OVERVIEW', errorCounter: () => 0},
			{value: 'GENERAL', errorCounter: GENERAL_ERROR_COUNT},
			{value: 'APPROVERS', errorCounter: APPROVAL_ERROR_COUNT},
			{value: 'SIGNERS', errorCounter: SIGNERS_ERROR_COUNT},
			{value: 'MULTI_SIGN', errorCounter: MULTI_SIGN_ERROR_COUNT},
			{value: 'POST_ACTIONS', errorCounter: () => 0},
		]
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.editorEditInfo && !prevProps.editorEditInfo) {
			this.setState({
				multiSignSignatureField: MULTI_SIGN_EXTRACT_SIGNATURE_FIELD(this.props.editorEditInfo.document),
				multiSignParaphFields: MULTI_SIGN_EXTRACT_PARAPH_FIELDS(this.props.editorEditInfo.document),
				multiSignExtraSignatureFields: MULTI_SIGN_EXTRACT_EXTRA_SIGNATURE_FIELDS(this.props.editorEditInfo.document),
				multiSignFormFields: MULTI_SIGN_EXTRACT_FORM_FIELDS(this.props.editorEditInfo.document),
				multiSignSignatureTypes: [...this.props.editorEditInfo.document.enabledSignatureTypes],
				multiSignCustomEmailHeaders: MULTI_SIGN_EXTRACT_CUSTOM_EMAIL_HEADERS(this.props.editorEditInfo.document),
				tab: this.determineInitialTab(this.props.editorEditInfo.document, prevState.tab),
				// make sure we select a child for virtual collection
				selectedChildDocumentId: this.props.editorEditInfo.document.id === 0 ? this.props.editorEditInfo.document.children?.at(0)?.id : prevState.selectedChildDocumentId
			});
		}

		// Update after restart
		if (this.props.editorEditInfo?.document && prevProps.editorEditInfo?.document &&
			this.props.editorEditInfo.document.id !== prevProps.editorEditInfo.document.id) {
			const state = {restartBusy: false};
			const childDocument = this.props.editorEditInfo.document.children?.filter(doc => doc.id === this.state.selectedChildDocumentId).at(0);
			if (!childDocument) {
				this.setState({
					...state,
					selectedChildDocumentId: 0,
					tab: this.validateTab(this.props.editorEditInfo.document, prevState.tab)
				});
			} else {
				this.setState({
					...state,
					tab: this.validateTab(childDocument, prevState.tab)
				});
			}
		}

		// Change tab when we detect change from normal document to attachment after upload, a bit ugly..
		if (!!this.state.selectedChildDocumentId &&
			!!this.props.editorEditInfo?.document &&
			this.props.editorEditInfo !== prevProps.editorEditInfo &&
			SELECT_DOCUMENT(this.props.editorEditInfo.document, this.state.selectedChildDocumentId)?.attachment &&
			'GENERAL' !== this.state.tab) {
			this.setState({
				tab: 'GENERAL'
			});
		}
	}

	determineInitialTab = (document, previousTab) => {
		if (document?.attachment) {
			return 'GENERAL';
		}
		if (document?.terminal) {
			return 'OVERVIEW';
		}
		return previousTab;
	}

	validateTab = (document, previousTab) => {
		if (document?.attachment) {
			return 'GENERAL';
		}
		if (previousTab === 'OVERVIEW' && !document?.terminal) {
			return 'GENERAL';
		}
		return previousTab;
	}

	render() {
		if (!this.props.editorEditInfo) {
			return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
				<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
					{!this.props.editorServerError && <LoadingComponent/>}
					{!!this.props.editorServerError &&
						<ServerErrorComponent serverError={this.props.editorServerError}/>}
				</Paper>
			</AppContainer>
		}

		return <AppContainer
			needsSession
			limitHeight
			hasPendingChanges={this.props.editorPendingChanges}
			saveIsBusy={this.props.editorBusy}
			saveHasError={!!this.props.editorServerError}
			onSave={this.onEditorSaveDocument}
			onSessionCreated={this.onSessionCreated}
		>
			<Box sx={{display: 'flex', justifyContent: 'center', gap: 2, height: '100%', maxHeight: '100%'}}>
				<Box sx={{width: '45%', minWidth: 500, overflowY: 'auto'}}>
					{this.renderOverview()}
				</Box>
				<Box sx={{width: '55%', height: '100%', maxHeight: '100%'}}>
					{this.renderPdfEditor()}
				</Box>
			</Box>
		</AppContainer>
	}

	renderOverview = () => {
		const rootDocument = this.props.editorEditInfo.document;
		const canEdit = rootDocument.editPermission;
		const canRemove = rootDocument.removePermission;
		const canBeRestarted = rootDocument.canBeRestarted;

		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const readOnly = document.readOnly;
		const isTemplateMode = this.isTemplateMode();
		const isVirtualCollection = rootDocument.id === 0;
		const canDownloadEvidenceReport = document.canDownloadEvidenceReport;
		const canSendReminders = document.state === 'WAITING_FOR_SIGNATURES' ||
			document.state === 'WAITING_FOR_APPROVAL' ||
			document.state === 'WAITING_FOR_CHILD_DOCUMENTS';
		const hasApproversOnRootDoc = (rootDocument?.approvers || []).length > 0;
		const editorHeaderSuffix = rootDocument.terminal ? ' (' + this.props.t('document.status_' + rootDocument.state).toUpperCase() + ')' : '';
		const signerUsersForFieldPlaceholders = (document?.signers || []).filter(signer => !!signer.person).map(signer => ({...signer.person, id: signer.id}));

		return <Paper variant="outlined" sx={{p: 2}}>
			{!rootDocument.documentCollection && !isTemplateMode &&
				<Typography variant="h6">{this.props.t('editor.editDocumentHeader') + editorHeaderSuffix}</Typography>
			}

			{isTemplateMode &&
				<Typography variant="h6">{this.props.t('editor.editTemplateHeader')}</Typography>
			}

			{rootDocument.documentCollection && <Fragment>
				{!isTemplateMode && <Typography variant="h6">{this.props.t(isVirtualCollection ? 'editor.editVirtualCollectionHeader' : 'editor.editCollectionHeader') + editorHeaderSuffix}</Typography>}
				<FormControl size="small" fullWidth>
					<Select
						value={this.state.selectedChildDocumentId}
						onChange={(e) => this.onChangeSelectedChildDocumentId(e.target.value)}
						disabled={this.props.editorBusy}
					>
						{/* no root for virtual collections */}
						{!isVirtualCollection && <MenuItem key={0}
														   value={0}>{this.props.t('editor.collectionAllDocuments') + rootDocument.name}</MenuItem>}
						{rootDocument.children.filter(child => child.childType !== 'MULTI_SIGN').map(child =>
							<MenuItem key={child.id} value={child.id}>
								{child.name}
							</MenuItem>
						)}
					</Select>
				</FormControl>
			</Fragment>}

			{this.props.editorServerError &&
				<ServerErrorComponent serverError={this.props.editorServerError}/>}

			<Box sx={{display: 'flex', mt: 1, mb: 1, alignItems: 'center', gap: 1, flexWrap: 'wrap'}}>
				<>
					{<Fab size="small"
						  color="secondary"
						  title={this.props.t('editor.actionLogs')}
						  onClick={this.onOpenActionLogsDialog}
						  id="btn-editor-actionlogs">
						<FontAwesomeIcon icon={faFileInvoice} size="lg"/>
					</Fab>}
					{!readOnly && !isTemplateMode &&
						<Fab size="small"
							 color="secondary"
							 title={this.props.t('editor.templateApply')}
							 disabled={document.attachment}
							 onClick={this.onOpenApplyTemplateDialog}
							 id="btn-editor-template-apply"
						>
							<FontAwesomeIcon icon={faFileArrowDown} size="lg"/>
						</Fab>
					}
					{!readOnly && !isTemplateMode && this.props.editorEditInfo.canCreateTemplates &&
						<Fab size="small"
							 color="secondary"
							 title={this.props.t('editor.templateSave')}
							 disabled={document.attachment || document.documentCollection}
							 onClick={this.onOpenSaveTemplateDialog}
							 id="btn-editor-template-save"
						>
							<FontAwesomeIcon icon={faFileArrowUp} size="lg"/>
						</Fab>
					}
					{canSendReminders && !isTemplateMode &&
						<Fab size="small"
							 color="secondary"
							 title={this.props.t('document.reminder')}
							 onClick={this.onDocumentSendReminders}
							 id="btn-editor-reminder">
							<NotificationsIcon/>
						</Fab>
					}
					{!isTemplateMode && (document.hasPdf || document.documentCollection) &&
						<Fab size="small"
							 color="secondary"
							 title={this.props.t('document.download')}
							 onClick={this.onDocumentDownloadDocument}
							 id="btn-editor-download"
						>
							<DownloadIcon/>
						</Fab>
					}
					{canDownloadEvidenceReport && !isTemplateMode &&
						<Fab size="small"
							 color="secondary"
							 title={this.props.t('document.downloadEvidenceReport')}
							 onClick={this.onDocumentDownloadEvidenceReport}
							 id="btn-editor-download-evidence-report"
						>
							<PlagiarismIcon/>
						</Fab>
					}
					{!isTemplateMode && !!document.attachmentType &&
						<Fab size="small"
							 color="secondary"
							 title={this.props.t('document.downloadAttachment')}
							 onClick={this.onDocumentDownloadAttachment}
							 id="btn-editor-download">
							<AttachmentIcon/>
						</Fab>
					}
				</>

				<Box sx={{flexGrow: 1}}></Box>

				{(!rootDocument.readOnly || rootDocument.canUpdateSigningMethods) && canEdit
					&& <Button variant="contained"
							   onClick={this.onEditorSaveDocument}
							   startIcon={<SaveIcon/>}
							   disabled={this.props.editorBusy || !this.props.editorPendingChanges || !CAN_SAVE(rootDocument)}
							   id="btn-editor-save"
					>
						{this.props.t('editor.save')}
					</Button>}

				{!rootDocument.readOnly && canEdit && !isTemplateMode && rootDocument.signingOrderType !== 'KIOSK' &&
					<SplitButton actions={[
						{
							title: this.props.t('editor.send'),
							action: this.onEditorSendDocument,
							disabled: (this.props.editorBusy || !CAN_SEND(rootDocument)),
							icon: <SendIcon/>,
							id: "btn-editor-send"
						},
						{
							title: this.props.t('editor.sendAndSign'),
							action: this.onEditorSendAndSignDocument,
							disabled: (this.props.editorBusy || !CAN_SEND(rootDocument) || hasApproversOnRootDoc),
							icon: <SendIcon/>,
							id: "btn-editor-send-sign"
						}
					]}/>
				}
				{!rootDocument.readOnly && canEdit && !isTemplateMode && rootDocument.signingOrderType === 'KIOSK' &&
					<Button variant="contained"
							onClick={this.onEditorSignDocument}
							startIcon={<EditIcon/>}
							disabled={this.props.editorBusy || !CAN_SEND(rootDocument, this.props.sessionInfo.userId)}
							id="btn.editor.sign"
					>
						{this.props.t('editor.sign')}
					</Button>
				}
				{rootDocument.readOnly && canEdit && !rootDocument.terminal && <Button variant="contained"
																					   onClick={this.onOpenCloseDialog}
																					   startIcon={<CloseIcon/>}
																					   disabled={this.props.editorBusy}
																					   id="btn.editor.close"
				>
					{this.props.t('editor.close')}
				</Button>}
				{canBeRestarted && canEdit &&
					<Button variant="contained"
							onClick={this.onEditorRestartDocument}
							startIcon={<RestartAltIcon/>}
							disabled={this.props.editorBusy}
							id="btn.editor.restart"
					>
						{this.props.t('editor.restart')}
					</Button>}
				{canRemove && <Button variant="contained"
									  onClick={this.onOpenDeleteDialog}
									  startIcon={<DeleteIcon/>}
									  disabled={this.props.editorBusy}
									  id="btn-editor-delete"
				>
					{this.props.t('editor.delete')}
				</Button>}
			</Box>

			{document.sendingFailed && <Alert severity="error" sx={{mt: 1}}>{this.props.t('editor.sendingFailed')}</Alert>}

			<DocumentReminderFeedbackComponent reminderResult={this.props.documentReminderSentResult}/>

			<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable">
				{this.tabs
					.filter(tab => {
						// attachments have only the general tab
						if (document.attachment) {
							return tab.value === 'GENERAL';
						}
						switch (tab.value) {
							case 'OVERVIEW':
								return rootDocument.terminal;
							case 'APPROVERS':
								return document.signingOrderType !== 'KIOSK';
							case 'MULTI_SIGN':
								return !(document.documentCollection && document.id !== 0) && !isTemplateMode && document.signingOrderType !== 'KIOSK';
							default:
								return true;
						}
					})
					.map(tab => <Tab key={tab.value} value={tab.value} label={
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							{tab.errorCounter(document) > 0 && <ReportProblemIcon color="error"/>}
							{this.props.t('editor.tab_' + tab.value)}
						</Box>
					}/>)
				}
			</Tabs>

			{this.state.tab === 'OVERVIEW' && <EditorOverviewComponent
				document={document}
				onDocumentDownloadDeclineAttachment={this.onDocumentDownloadDeclineAttachment}
			/>}

			{this.state.tab === 'GENERAL' && <EditorGeneralComponent
				document={document}
				child={this.state.selectedChildDocumentId !== 0}
				onChangeDocumentAttachment={this.onChangeDocumentAttachment}
				onChangeDocumentDescription={this.onChangeDocumentDescription}
				onChangeSelectedFolderId={this.onChangeSelectedFolderId}
				onChangeDocumentConfidential={this.onChangeDocumentConfidential}
				onChangeOverrideReasonSettings={this.onChangeOverrideReasonSettings}
				onChangeReasonAvailable={this.onChangeReasonAvailable}
				onChangeReasonLegalNoticeMandatory={this.onChangeReasonLegalNoticeMandatory}
				onChangeReasonLegalNoticeText={this.onChangeReasonLegalNoticeText}
				onChangeOverrideExpirationSettings={this.onChangeOverrideExpirationSettings}
				onChangeEnableExpirationSettings={this.onChangeEnableExpirationSettings}
				onChangeDaysForExpiration={this.onChangeDaysForExpiration}
				onChangeAllowForwarding={this.onChangeAllowForwarding}
				onChangeDocumentPageCount={this.onChangeDocumentPageCount}
				onChangeCollectionChildSeqIncrement={this.onChangeCollectionChildSeqIncrement}
				onChangeCollectionChildSeqDecrement={this.onChangeCollectionChildSeqDecrement}
				onChangeDocumentName={this.onChangeDocumentName}
				onRemoveChildDocument={this.onRemoveChildDocument}
				onAddCollectionDocumentChild={this.onAddCollectionDocumentChild}
				onChangeSelectedChildDocumentId={this.onChangeSelectedChildDocumentId}
				onSelectApplyTemplate={this.onSelectApplyTemplate}
			/>}
			{this.state.tab === 'APPROVERS' && <EditorApproversComponent
				document={document}
				onChangeApproveOrderType={this.onChangeApproveOrderType}
				onChangeApprovers={this.onChangeApprovers}
				onChangeMinimalNumberOfApprovalDecisions={this.onChangeMinimalNumberOfApprovalDecisions}
				onChangeMinimalNumberOfApprovals={this.onChangeMinimalNumberOfApprovals}
				onChangeApproversCustomEmailHeaders={this.onChangeApproversCustomEmailHeaders}
			/>}
			{this.state.tab === 'SIGNERS' && <EditorSignersComponent
				document={document}
				child={this.state.selectedChildDocumentId !== 0}
				onSignerDropped={this.onSignerDropped}
				onParaphFieldDropped={this.onParaphFieldDropped}
				onParaphFieldRemove={this.onParaphFieldRemove}
				onExtraSignFieldDropped={this.onExtraSignFieldDropped}
				onExtraSignFieldRemove={this.onExtraSignFieldRemove}
				onFormFieldDropped={this.onFormFieldDropped}
				onFormFieldRemove={this.onFormFieldRemove}
				onFormFieldEditSettings={this.onOpenFormFieldSettingsDialog}
				onChangeSigningOrderType={this.onChangeSigningOrderType}
				onChangeSigners={this.onChangeSigners}
				onChangeSignersCustomEmailHeaders={this.onChangeSignersCustomEmailHeaders}
				onDistributeSignerToCollection={this.onDistributeSignerToCollection}
				folderId={document.folderId}
			/>}
			{this.state.tab === 'MULTI_SIGN' && <EditorMultiSignComponent
				document={document}
				signatureField={this.state.multiSignSignatureField}
				paraphFields={this.state.multiSignParaphFields}
				extraSignatureFields={this.state.multiSignExtraSignatureFields}
				formFields={this.state.multiSignFormFields}
				signatureTypes={this.state.multiSignSignatureTypes}
				multiSignCustomEmailHeaders={this.state.multiSignCustomEmailHeaders}
				onSignatureFieldDropped={this.onMultiSignSignatureFieldDropped}
				onParaphFieldDropped={this.onMultiSignParaphFieldDropped}
				onParaphFieldRemove={this.onMultiSignParaphFieldRemove}
				onParaphFieldRemoveAll={this.onMultiSignParaphFieldRemoveAll}
				onExtraSignFieldDropped={this.onMultiSignExtraSignFieldDropped}
				onExtraSignFieldRemove={this.onMultiSignExtraSignFieldRemove}
				onFormFieldDropped={this.onMultiSignFormFieldDropped}
				onFormFieldRemove={this.onMultiSignFormFieldRemove}
				onChangeSignatureTypes={this.onChangeMultiSignSignatureTypes}
				onChangeMultiSigners={this.onChangeMultiSigners}
				onChangeSignersCustomEmailHeaders={this.onChangeMultiSignCustomEmailHeaders}
			/>}
			{this.state.tab === 'POST_ACTIONS' && <EditorPostActionsComponent
				document={document}
				onChangePostSignDocumentEmailDistributionList={this.onChangePostSignDocumentEmailDistributionList}
				onChangePostSignDocumentAndEvidenceReportEmailDistributionList={this.onChangePostSignDocumentAndEvidenceReportEmailDistributionList}
			/>}

			<EditorActionLogsDialog
				document={document}
				open={this.state.actionLogsDialogOpen}
				onClose={this.onCloseActionLogsDialog}
			/>

			<EditorApplyTemplateDialog
				open={this.state.applyTemplateDialogOpen}
				onClose={this.onCloseApplyTemplateDialog}
				onSelect={this.onTemplateSelectToApply}
				folderId={this.props.editorEditInfo.document.folderId}
			/>

			<EditorApplyTemplateOptionsDialog
				open={this.state.applyTemplateOptionsDialogOpen}
				documentCollection={document?.documentCollection}
				hasChildApprovers={(document?.children || []).some(child => child?.approvers?.length > 0)}
				hasChildSigners={(document?.children || []).some(child => child?.signers?.length > 0)}
				onClose={this.onCloseApplyTemplateOptionsDialog}
				onSelect={this.onTemplateSelectOptionsToApply}
			/>

			<EditorSaveTemplateDialog
				open={this.state.saveTemplateDialogOpen}
				onClose={this.onCloseSaveTemplateDialog}
				onSave={this.onTemplateSave}
			/>

			<ConfirmationDialog
				title={this.props.t('attention')}
				confirm={this.props.t('editor.closeConfirm')}
				open={this.state.closeDialogOpen}
				onClose={this.onCloseCloseDialog}
				onConfirm={this.onEditorCloseDocument}
			/>

			<ConfirmationDialog
				title={this.props.t('attention')}
				confirm={this.props.t(isTemplateMode ? 'editor.templateDeleteConfirm' : 'editor.documentDeleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onCloseDeleteDialog}
				onConfirm={this.onEditorDeleteDocument}
			/>

			<EditorFormFieldSettingsDialog
				title={this.props.t('editor.signingSignatureFieldSettings')}
				confirm={this.props.t('editor.updateFormFieldSettings')}
				field={this.state.formFieldToEdit}
				validators={this.props.editorEditInfo.formFieldValidators}
				open={this.state.formFieldSettingsDialogOpen || this.state.formFieldSettingsMultiSignDialogOpen}
				template={isTemplateMode}
				onTestFormFieldValidator={(validatorId, input) => this.props.onTestFormFieldValidator(document.id, validatorId, input)}
				onClose={this.onCloseFormFieldSettingsDialog}
				onConfirm={this.onConfirmFormFieldSettingsDialog}
			/>

			<EditorFieldStyleDialog
				style={this.state.fieldStyleEditInfo?.style}
				open={this.state.fieldStyleDialogOpen}
				onClose={this.onCloseFieldStyleDialog}
				onConfirm={this.onConfirmFieldStyleDialog}
			/>

			<SelectUserDialog
				open={!!this.state.selectedFieldPlaceholder}
				onClose={this.onSignerSelectForFieldPlaceholderCancel}
				users={signerUsersForFieldPlaceholders}
				onSelect={this.onSignerSelectForFieldPlaceholderSelected}
			/>
		</Paper>;
	}

	renderPdfEditor = () => {
		const rootDocument = this.props.editorEditInfo.document
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const readOnly = document.readOnly;
		return <EditorPdfComponent
			readOnly={readOnly}
			document={document}
			regenerating={this.state.restartBusy}
			multiSignSignatureField={this.state.multiSignSignatureField}
			multiSignParaphFields={this.state.multiSignParaphFields}
			multiSignExtraSignatureFields={this.state.multiSignExtraSignatureFields}
			multiSignFormFields={this.state.multiSignFormFields}
			fieldPlaceholderCanSelect={(this.state.tab === 'SIGNERS' && !!document.signers?.length)}
			onChangeDocumentName={(name) => this.onChangeDocumentName(this.state.selectedChildDocumentId, name)}
			onSignerDropped={this.onSignerDropped}
			onSignatureFieldResize={this.onSignatureFieldResize}
			onSignerRemoveSignatureField={this.onSignerRemoveSignatureField}
			onParaphFieldDuplicate={this.onParaphFieldDuplicate}
			onParaphFieldResize={this.onParaphFieldResize}
			onParaphFieldRemove={this.onParaphFieldRemove}
			onParaphFieldDropped={this.onParaphFieldDropped}
			onExtraSignFieldDropped={this.onExtraSignFieldDropped}
			onExtraSignFieldResize={this.onExtraSignFieldResize}
			onExtraSignFieldRemove={this.onExtraSignFieldRemove}
			onFormFieldDropped={this.onFormFieldDropped}
			onFormFieldResize={this.onFormFieldResize}
			onFormFieldRemove={this.onFormFieldRemove}
			onFormFieldEditSettings={this.onOpenFormFieldSettingsDialog}
			onMultiSignSignatureFieldDropped={this.onMultiSignSignatureFieldDropped}
			onMultiSignSignatureFieldResize={this.onMultiSignSignatureFieldResize}
			onMultiSignParaphFieldDropped={this.onMultiSignParaphFieldDropped}
			onMultiSignParaphFieldResize={this.onMultiSignParaphFieldResize}
			onMultiSignParaphFieldRemove={this.onMultiSignParaphFieldRemove}
			onMultiSignParaphFieldDuplicate={this.onMultiSignParaphFieldDuplicate}
			onMultiSignExtraSignFieldDropped={this.onMultiSignExtraSignFieldDropped}
			onMultiSignExtraSignFieldResize={this.onMultiSignExtraSignFieldResize}
			onMultiSignExtraSignFieldRemove={this.onMultiSignExtraSignFieldRemove}
			onMultiSignFormFieldDropped={this.onMultiSignFormFieldDropped}
			onMultiSignFormFieldRemove={this.onMultiSignFormFieldRemove}
			onMultiSignFormFieldResize={this.onMultiSignFormFieldResize}
			onMultiSignFormFieldEditSettings={this.onOpenFormFieldSettingsMultiSignDialog}
			onFieldEditSettings={this.onOpenFieldStyleDialog}
			onPagePlaceholderClicked={this.onDocumentDownloadAttachment}
			onFieldPlaceholderClicked={this.onFieldPlaceholderClicked}
			onRemoveChildDocument={this.onRemoveChildDocument}
			onSaveDocument={this.onEditorSaveDocument}
		/>;
	}

	onSessionCreated = () => {
		const args = this.props?.match?.params?.data?.split(';') || [];
		for (let i = 0; i < args.length; i++) {
			const arg = args[i];
			if (arg.startsWith('id=')) {
				this.props.onEditorFetchEditInfo(arg.substring(3), this.isTemplateMode());
			} else if (arg.startsWith('ids=')) {
				this.props.onEditorFetchEditInfo(arg.substring(4), this.isTemplateMode());
			} else if (arg === 'hideMenu') {
				this.props.onMenuClose();
			} else if (arg === 'template') {
				this.setState({applyTemplateDialogOpen: true, applyTemplateOnAllChildren: true});
			}
		}
	}

	onChangeSelectedChildDocumentId = (selectedChildDocumentId) => {
		const document = SELECT_DOCUMENT(this.props.editorEditInfo.document, selectedChildDocumentId);
		this.setState({
			selectedChildDocumentId,
			multiSignSignatureField: MULTI_SIGN_EXTRACT_SIGNATURE_FIELD(document),
			multiSignSignatureTypes: [...document.enabledSignatureTypes],
			multiSignCustomEmailHeaders: MULTI_SIGN_EXTRACT_CUSTOM_EMAIL_HEADERS(document),
			tab: (document.attachment || (document.documentCollection && this.state.tab === 'MULTI_SIGN')) ? 'GENERAL' : this.state.tab
		});
	}

	onOpenActionLogsDialog = () => {
		this.setState({actionLogsDialogOpen: true});
	}

	onCloseActionLogsDialog = () => {
		this.setState({actionLogsDialogOpen: false});
	}

	onOpenApplyTemplateDialog = () => {
		this.setState({applyTemplateDialogOpen: true});
	}

	onCloseApplyTemplateDialog = () => {
		this.setState({applyTemplateDialogOpen: false, applyTemplateOnAllChildren: false, applyTemplateDocumentId: null});
	}

	onCloseApplyTemplateOptionsDialog = () => {
		this.setState({applyTemplateOptionsDialogOpen: false, applyTemplateDocumentId: null});
	}

	onTemplateSelectToApply = (template) => {
		this.setState({
			selectedTemplate: template,
			applyTemplateDialogOpen: false,
			applyTemplateOptionsDialogOpen: true
		});
	}

	onTemplateSelectOptionsToApply = (templateOptions) => {
		const documentId = this.state.applyTemplateDocumentId;
		const template = this.state.selectedTemplate;
		const rootDocument = this.props.editorEditInfo.document;
		// fix for virtual collections where the template needs to be applied on all children and where we can't select the parent
		let selectedChildDocumentId;
		if (this.state.applyTemplateOnAllChildren) {
			selectedChildDocumentId = 0;
		} else if (!!documentId) {
			selectedChildDocumentId = documentId;
		} else {
			selectedChildDocumentId = this.state.selectedChildDocumentId;
		}

		const oldDocument = SELECT_DOCUMENT(rootDocument, selectedChildDocumentId);
		const oldSigners = oldDocument?.signers || [];
		const oldSignersOrderOffset = oldSigners.reduce((acc, signer) => acc < signer.order ? signer.order : acc, 0);
		const templateSigners = template.signers
			.map(newSigner => newSigner.otpNumberKnown ? {...newSigner, useKnownOtpNumber: true} : newSigner)
			.map(newSigner => !!newSigner?.signatureField?.pageIndex ? {
				...newSigner,
				signatureField: {
					...newSigner.signatureField,
					pageIndex: Math.min(oldDocument.pageCount - 1, newSigner.signatureField.pageIndex)
				}
			} : newSigner)
			.map(newSigner => !!newSigner?.paraphFields ? {
				...newSigner,
				paraphFields: newSigner?.paraphFields.filter((field) => field.pageIndex < oldDocument.pageCount)
			} : newSigner)
			.map(newSigner => !!newSigner?.extraSignatureFields ? {
				...newSigner,
				extraSignatureFields: newSigner?.extraSignatureFields.filter((field) => field.pageIndex < oldDocument.pageCount)
			} : newSigner)
			.map(newSigner => !!newSigner?.formFields ? {
				...newSigner,
				formFields: newSigner?.formFields.filter((field) => field.pageIndex < oldDocument.pageCount)
			} : newSigner)
		;
		const newSigners = oldSigners.concat(templateSigners.map(newSigner => ({
			...newSigner,
			order: newSigner.order + oldSignersOrderOffset
		})));
		const newSigningOrderGroupSettings = (oldDocument.signingOrderGroupSettings || []).concat(
			(template.signingOrderGroupSettings || []).map(setting => ({
				...setting,
				signingOrder: setting.signingOrder + oldSignersOrderOffset
			}))
		);
		const oldApprovers = oldDocument?.approvers || [];
		const newApprovers = oldApprovers.concat(template.approvers
			.filter(newApprover => !oldApprovers.some(oldApprover => oldApprover.id === newApprover.id)));

		let newRootDocument;
		if (selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = MERGE_DOCUMENT(rootDocument, selectedChildDocumentId, {
				...oldDocument,
				...templateOptions.includes(TEMPLATE_OPTIONS.CONFIDENTIALITY) && {confidential: template.confidential}
			})

			// signers
			if (templateOptions.includes(TEMPLATE_OPTIONS.SIGNERS)) {
				// remove all signers from children
				newRootDocument = {
					...newRootDocument,
					children: rootDocument.children.map(child => ({
						...child,
						signers: [],
						signingOrderGroupSettings: []
					}))
				};

				newRootDocument = COLLECTION_DISTRIBUTE_SIGNERS(newRootDocument, {
					...newRootDocument,
					signers: newSigners,
					signingOrderGroupSettings: template.signingOrderGroupSettings
				});

				if (!!template.signingOrderType) {
					newRootDocument = COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE(newRootDocument, {
						...newRootDocument,
						signingOrderType: template.signingOrderType
					});
				}
			}

			if (templateOptions.includes(TEMPLATE_OPTIONS.SIGNERS_MAIL)) {
				newRootDocument = COLLECTION_DISTRIBUTE_SIGNERS_CUSTOM_EMAIL_HEADERS(newRootDocument, {
					...newRootDocument,
					signersCustomEmailHeaders: template.signersCustomEmailHeaders
				});
			}

			// approvers
			if (templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS)) {
				// remove all approvers from children
				newRootDocument = {
					...newRootDocument,
					children: newRootDocument.children.map(child => ({
						...child,
						approvers: [],
					}))
				};
				newRootDocument = COLLECTION_DISTRIBUTE_APPROVERS(newRootDocument, {
					...newRootDocument,
					approvers: newApprovers,
				});
				if (!!template.approveOrderType) {
					newRootDocument = COLLECTION_DISTRIBUTE_APPROVE_ORDER_TYPE(newRootDocument, {
						...newRootDocument,
						approveOrderType: template.approveOrderType
					});
				}
				if (template.minimalNumberOfApprovalDecisions) {
					newRootDocument = COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS(newRootDocument, {
						...newRootDocument,
						minimalNumberOfApprovalDecisions: template.minimalNumberOfApprovalDecisions
					});
				}
				if (template.minimalNumberOfApprovals) {
					newRootDocument = COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVALS(newRootDocument, {
						...newRootDocument,
						minimalNumberOfApprovals: template.minimalNumberOfApprovals
					});
				}
			}

			if (templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS_MAIL)) {
				newRootDocument = COLLECTION_DISTRIBUTE_APPROVERS_CUSTOM_EMAIL_HEADERS(newRootDocument, {
					...newRootDocument,
					approversCustomEmailHeaders: template.approversCustomEmailHeaders
				});
			}

			// post sign
			if (templateOptions.includes(TEMPLATE_OPTIONS.POSTSIGN_DISTRIBUTION_LIST_WITHOUT_EVIDENCE_REPORT)) {
				if (template?.postSignDocumentEmailDistributionList.length > 0) {
					newRootDocument = COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_EMAIL_DISTRIBUTION_LIST(newRootDocument, {
						...newRootDocument,
						postSignDocumentEmailDistributionList: template.postSignDocumentEmailDistributionList
					});
				}
			}
			if (templateOptions.includes(TEMPLATE_OPTIONS.POSTSIGN_DISTRIBUTION_LIST_WITH_EVIDENCE_REPORT)) {
				if (template?.postSignDocumentAndEvidenceReportEmailDistributionList.length > 0) {
					newRootDocument = COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_AND_EVIDENCE_REPORT_EMAIL_DISTRIBUTION_LIST(newRootDocument, {
						...newRootDocument,
						postSignDocumentAndEvidenceReportEmailDistributionList: template.postSignDocumentAndEvidenceReportEmailDistributionList
					});
				}
			}
		} else {
			const document = {
				...oldDocument,
				...(templateOptions.includes(TEMPLATE_OPTIONS.CONFIDENTIALITY) && {confidential: template.confidential}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS) && {approvers: newApprovers}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS_MAIL) && {approversCustomEmailHeaders: template.approversCustomEmailHeaders}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.SIGNERS) && {signers: newSigners, signingOrderGroupSettings: newSigningOrderGroupSettings}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.SIGNERS_MAIL) && {signersCustomEmailHeaders: template.signersCustomEmailHeaders}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.SIGNERS) && template.signingOrderType && {signingOrderType: template.signingOrderType}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS) && template.approveOrderType && {approveOrderType: template.approveOrderType}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS) && template.minimalNumberOfApprovals && {minimalNumberOfApprovals: template.minimalNumberOfApprovals}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS) && template.minimalNumberOfApprovalDecisions && {minimalNumberOfApprovalDecisions: template.minimalNumberOfApprovalDecisions}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.POSTSIGN_DISTRIBUTION_LIST_WITHOUT_EVIDENCE_REPORT) && template?.postSignDocumentEmailDistributionList.length > 0 && {postSignDocumentEmailDistributionList: template.postSignDocumentEmailDistributionList}),
				...(templateOptions.includes(TEMPLATE_OPTIONS.POSTSIGN_DISTRIBUTION_LIST_WITH_EVIDENCE_REPORT) && template?.postSignDocumentAndEvidenceReportEmailDistributionList.length > 0 && {postSignDocumentAndEvidenceReportEmailDistributionList: template.postSignDocumentAndEvidenceReportEmailDistributionList}),
			};

			newRootDocument = MERGE_DOCUMENT(rootDocument, selectedChildDocumentId, document);
		}

		this.setState({applyTemplateOptionsDialogOpen: false, applyTemplateOnAllChildren: false, applyTemplateDocumentId: null}, () => {
			const signersAdded = templateOptions.includes(TEMPLATE_OPTIONS.SIGNERS) ? newSigners.length - oldSigners.length : 0;
			const approversAdded = templateOptions.includes(TEMPLATE_OPTIONS.APPROVERS) ? newApprovers.length - oldApprovers.length : 0;

			this.props.onEditorUpdateDocument(newRootDocument);
			this.props.onSnackbarOpen(this.props.t('editor.templateApplySignersApproversPlaced')
				.replace('{0}', signersAdded)
				.replace('{1}', approversAdded));
		});
	}

	onOpenSaveTemplateDialog = () => {
		this.setState({saveTemplateDialogOpen: true});
	}

	onCloseSaveTemplateDialog = () => {
		this.setState({saveTemplateDialogOpen: false});
	}

	onTemplateSave = (name) => {
		const document = this.selectDocument();
		const request = {
			documentId: document.id,
			template: {
				name,
				confidential: document.confidential,
				postSignDocumentEmailDistributionList: document.postSignDocumentEmailDistributionList,
				postSignDocumentAndEvidenceReportEmailDistributionList: document.postSignDocumentAndEvidenceReportEmailDistributionList,
				signers: document.signers,
				signingOrderType: document.signingOrderType,
				signingOrderGroupSettings: document.signingOrderGroupSettings,
				signersCustomEmailHeaders: document.signersCustomEmailHeaders,
				approvers: document.approvers,
				approveOrderType: document.approveOrderType,
				approversCustomEmailHeaders: document.approversCustomEmailHeaders,
				minimalNumberOfApprovals: document.minimalNumberOfApprovals,
				minimalNumberOfApprovalDecisions: document.minimalNumberOfApprovalDecisions
			}
		}
		this.setState({saveTemplateDialogOpen: false}, () => this.props.onEditorSaveTemplate(request));
	}

	onEditorSaveDocument = () => {
		this.props.onEditorSaveDocument(this.props.editorEditInfo.document);
	}

	onEditorSignDocument = () => {
		this.props.onEditorSignDocument(this.props.editorEditInfo.document);
	}

	onEditorSendDocument = () => {
		this.props.onEditorSendDocument(this.props.editorEditInfo.document, false);
	}

	onEditorSendAndSignDocument = () => {
		this.props.onEditorSendDocument(this.props.editorEditInfo.document, true);
	}

	onOpenCloseDialog = () => {
		this.setState({closeDialogOpen: true});
	}

	onCloseCloseDialog = () => {
		this.setState({closeDialogOpen: false});
	}

	onEditorCloseDocument = () => {
		const ids = this.props.editorEditInfo.document.id === 0
			? this.props.editorEditInfo.document.children.map(pd => pd.id).join('-')
			: this.props.editorEditInfo.document.id

		this.setState({closeDialogOpen: false},
			() => this.props.onEditorCloseDocument(ids));
	}

	onOpenDeleteDialog = () => {
		this.setState({deleteDialogOpen: true});
	}

	onCloseDeleteDialog = () => {
		this.setState({deleteDialogOpen: false});
	}

	onEditorDeleteDocument = () => {
		this.setState({deleteDialogOpen: false},
			() => this.isTemplateMode()
				? this.props.onEditorDeleteTemplate(this.props.editorEditInfo.document.id)
				: this.props.onEditorDeleteDocument(this.props.editorEditInfo.document.id));
	}

	onEditorRestartDocument = () => {
		this.props.onEditorRestartDocument(this.props.editorEditInfo.document.id);
		this.setState({restartBusy: true})
	}

	onChangeTab = (e, tab) => {
		this.setState({tab});
	}

	onChangeDocumentName = (id, name) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, id),
			name
		};

		this.props.onEditorUpdateDocument(
			MERGE_DOCUMENT(rootDocument, id, document));
	}

	onRemoveChildDocument = (childId) => {
		const rootDocument = this.props.editorEditInfo.document;

		// remove the specified child and resequence the children
		const children = rootDocument.children;
		const newRootDocument = {
			...rootDocument,
			children: children.filter(child => child.id !== childId)
				.sort((d1, d2) => d1.childSeq - d2.childSeq)
				.map((child, index) => ({
				...child,
				childSeq: index+1
			}))
		};

		// if the removal occurred from the view of the child doc itself, show the next document in the selector
		if (this.state.selectedChildDocumentId === childId) {
			const removedChildIndex = children.findIndex((child) => child.id === childId);
			const selectedChildId = removedChildIndex < newRootDocument.children.size-1 ? newRootDocument.children[removedChildIndex].id : newRootDocument.children[newRootDocument.children.length-1].id
			this.setState({selectedChildDocumentId: selectedChildId})
		}
		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeDocumentAttachment = (attachment) => {
		// only on childs
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			attachment
		};

		this.props.onEditorUpdateDocument(
			MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document));
	}

	onChangeDocumentDescription = (documentDescription) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			documentDescription
		};

		this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document));
	}

	onChangeSelectedFolderId = (folderId) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			folderId
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_FOLDER(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);

		if (rootDocument.signingOrderType === 'KIOSK') {
			const kioskModeEnabledForFolder = this.props.editorEditInfo.folders.find(folder => folder.id === folderId).kioskSigningOrderTypeEnabled;
			if (!kioskModeEnabledForFolder) {
				this.onChangeSigningOrderType('PARALLEL');
			}
		}
	}

	onChangeDocumentConfidential = (confidential) => {
		// only on root (or virtual collection children)
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			confidential
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = document;
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeOverrideReasonSettings = (overrideReasonSettings) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			overrideReasonSettings
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_OVERRIDE_REASON_SETTINGS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeReasonAvailable = (reasonAvailable) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			reasonAvailable
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_REASON_AVAILABLE(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeReasonLegalNoticeMandatory = (reasonLegalNoticeMandatory) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			reasonLegalNoticeMandatory
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_REASON_LEGAL_NOTICE_MANDATORY(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeReasonLegalNoticeText = (reasonLegalNoticeText) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			reasonLegalNoticeText
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_REASON_LEGAL_NOTICE_TEXT(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeOverrideExpirationSettings = (overrideExpirationSettings) => {
		// only on root (or virtual collection children)
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			overrideExpirationSettings
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = document;
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeEnableExpirationSettings = (enableExpirationSettings) => {
		// only on root (or virtual collection children)
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			enableExpirationSettings
		};
		if (!enableExpirationSettings) {
			document.daysForSignatureExpiration = null;
		}

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = document;
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeDaysForExpiration = (daysForSignatureExpiration) => {
		// only on root (or virtual collection children)
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			daysForSignatureExpiration
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = document;
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeAllowForwarding = (allowForwarding) => {
		// only on root (or virtual collection children)
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			allowForwarding
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = document;
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeDocumentPageCount = (value) => {
		const rootDocument = this.props.editorEditInfo.document;

		// if the page count has been reduced, make sure no dead signature/paraph/.. fields remain on the (possibly) removed pages
		let newRootDocument;
		if (rootDocument.pageCount > value) {
			let signers = rootDocument.signers.map(signer => ({
				...signer,
				signatureField: signer.signatureField?.pageIndex < value ? signer.signatureField : undefined,
				paraphFields: signer.paraphFields.filter(paraphField => paraphField.pageIndex < value),
				extraSignatureFields: signer.extraSignatureFields.filter(signatureField => signatureField.pageIndex < value),
				formFields: signer.formFields.filter(formField => formField.pageIndex < value),
			}));

			newRootDocument = {
				...rootDocument,
				pageCount: value,
				signers
			};
		} else {
			newRootDocument = {
				...rootDocument,
				pageCount: value
			};
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeCollectionChildSeqIncrement = (childId, currentSeq) => {
		const rootDocument = this.props.editorEditInfo.document;
		if (!rootDocument.documentCollection) {
			return;
		}

		let newRootDocument = {
			...rootDocument,
			children: rootDocument.children.map(child => {
				if (child.childSeq === currentSeq) {
					return {
						...child,
						childSeq: currentSeq+1
					};
				} else if (child.childSeq === currentSeq+1) {
					return {
						...child,
						childSeq: currentSeq
					};
				} else {
					return child;
				}})
		};

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeCollectionChildSeqDecrement = (childId, currentSeq) => {
		const rootDocument = this.props.editorEditInfo.document;
		if (!rootDocument.documentCollection) {
			return;
		}

		let newRootDocument = {
			...rootDocument,
			children: rootDocument.children.map(child => {
				if (child.childSeq === currentSeq) {
					return {
						...child,
						childSeq: currentSeq-1
					};
				} else if (child.childSeq === currentSeq-1) {
					return {
						...child,
						childSeq: currentSeq
					};
				} else {
					return child;
				}})
		};

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeApproveOrderType = (approveOrderType) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			approveOrderType
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_APPROVE_ORDER_TYPE(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeApprovers = (approvers) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			approvers
		};
		if (approvers.length === 0) {
			delete document.minimalNumberOfApprovalDecisions;
			delete document.minimalNumberOfApprovals;
		}

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_APPROVERS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeMinimalNumberOfApprovalDecisions = (minimalNumberOfApprovalDecisions) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			minimalNumberOfApprovalDecisions
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeMinimalNumberOfApprovals = (minimalNumberOfApprovals) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			minimalNumberOfApprovals
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVALS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeApproversCustomEmailHeaders = (approversCustomEmailHeaders) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			approversCustomEmailHeaders
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_APPROVERS_CUSTOM_EMAIL_HEADERS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	mergeDocumentWithRootAndUpdate(rootDocument, document) {
		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_SIGNERS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onSignerDropped = ({signer, pageIndex, rect, placeholder}) => {
		const rootDocument = this.props.editorEditInfo.document;

		const relativeLocationX = rect.hasOwnProperty('relativeLocationX') ?
			rect.relativeLocationX : Math.min(1, Math.max(0, rect.x / rect.width));

		const relativeLocationY = rect.hasOwnProperty('relativeLocationY') ?
			rect.relativeLocationY : Math.min(1, Math.max(0, rect.y / rect.height));

		const relativeWidth = rect.hasOwnProperty('relativeWidth') ?
			rect.relativeWidth : SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH;

		const relativeHeight = rect.hasOwnProperty('relativeHeight') ?
			rect.relativeHeight : SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT;

		const style = {
			...rootDocument.defaultFieldStyle,
			...(!!placeholder && {backgroundOpacity: 1})
		}

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				signatureField: {
					pageIndex,
					relativeLocationX,
					relativeLocationY,
					relativeWidth: !!search.signatureField ? search.signatureField.relativeWidth : relativeWidth,
					relativeHeight: !!search.signatureField ? search.signatureField.relativeHeight : relativeHeight,
					style: !!search.signatureField ? search.signatureField.style : style,
					...(!!placeholder && {placeholderId: placeholder.id})
				}
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onSignatureFieldResize = (signer, relativeWidth, relativeHeight) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(oldSigner => oldSigner.id !== signer.id ? oldSigner : {
				...oldSigner,
				signatureField: {
					...oldSigner.signatureField,
					relativeWidth,
					relativeHeight
				}
			})
		}

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onSignerRemoveSignatureField = (signer) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				signatureField: undefined,
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onSignerSelectForFieldPlaceholderCancel = () => {
		this.setState({ selectedFieldPlaceholder: null });
	}

	onSignerSelectForFieldPlaceholderSelected = (selectedIds) => {
		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);

		const signer = document.signers.find(search => search.id === selectedIds[0]);
		const placeholder = this.state.selectedFieldPlaceholder;

		if (!!signer && !!placeholder) {
			this.setState({
				selectedFieldPlaceholder: null
			}, () => {
				const rect = {
					relativeLocationX: placeholder.location.relativeX,
					relativeLocationY: placeholder.location.relativeY,
					relativeWidth: placeholder.location.relativeWidth,
					relativeHeight: placeholder.location.relativeHeight
				};

				if ('SIGNATURE' === placeholder.fieldType) {
					// can be extra signature, see if main signature field is set
					if (!signer.signatureField) {
						this.onSignerDropped({
							signer,
							pageIndex : placeholder.location.pageIndex,
							rect,
							placeholder
						});
					} else {
						this.onExtraSignFieldDropped({
							signer,
							pageIndex : placeholder.location.pageIndex,
							rect,
							placeholder
						});
					}
				} else if ('PARAPH' === placeholder.fieldType) {
					this.onParaphFieldDropped({
						signer,
						pageIndex : placeholder.location.pageIndex,
						rect,
						placeholder
					});
				} else if ('FORM' === placeholder.fieldType) {
					this.onFormFieldDropped({
						signer,
						pageIndex : placeholder.location.pageIndex,
						rect,
						type: placeholder.formFieldType,
						placeholder
					});
				}
			});
		} else {
			this.setState({ selectedFieldPlaceholder: null });
		}
	}

	onParaphFieldDropped = ({signer, pageIndex, rect, instance, placeholder}) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const field = (() => {
			const paraphFields = oldDocument.signers
				.filter((search, ignored) => search.id === signer.id)
				.flatMap(search => (search.paraphFields || []));

			if (instance !== undefined && instance in paraphFields) {
				return paraphFields[instance];
			}

			return {
				relativeWidth: rect.hasOwnProperty('relativeWidth') ? rect.relativeWidth : PARAPH_FIELD_DEFAULT_RELATIVE_WIDTH,
				relativeHeight: rect.hasOwnProperty('relativeHeight') ? rect.relativeHeight : PARAPH_FIELD_DEFAULT_RELATIVE_HEIGHT,
				style: {
					...rootDocument.defaultFieldStyle,
					...(!!placeholder && {backgroundOpacity: 1})
				}
			}
		})();

		const relativeLocationX = rect.hasOwnProperty('relativeLocationX') ?
			rect.relativeLocationX : Math.min(1, Math.max(0, rect.x / rect.width));

		const relativeLocationY = rect.hasOwnProperty('relativeLocationY') ?
			rect.relativeLocationY : Math.min(1, Math.max(0, rect.y / rect.height));

		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				paraphFields: (search.paraphFields || [])
					.filter((ign, index) => instance === undefined || instance !== index)
					.concat([{
						pageIndex,
						relativeLocationX,
						relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						style: field.style,
						...(!!placeholder && {placeholderId: placeholder.id})
					}])
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onParaphFieldResize = (signer, relativeWidth, relativeHeight, instance) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(oldSigner => oldSigner.id !== signer.id ? oldSigner : {
				...oldSigner,
				paraphFields: (oldSigner.paraphFields || [])
					.map((paraphField, index) => index !== instance ? paraphField : {
						...paraphField,
						relativeWidth,
						relativeHeight,
					})
			})
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onParaphFieldRemove = (signer, index) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				paraphFields: (search.paraphFields || [])
					.filter((ign, filterIdx) => index === undefined || filterIdx !== index)
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onParaphFieldDuplicate = (signer, instance) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const selectedSigner = oldDocument.signers.find((search) => search.id === signer.id);
		const paraphToDuplicate = selectedSigner.paraphFields.find((ign, index) => instance === index);
		const blacklist = (selectedSigner.extraSignatureFields || [])
			.concat(!!selectedSigner.signatureField ? [selectedSigner.signatureField] : [])
			.map((field) => field.pageIndex)
			.concat([paraphToDuplicate.pageIndex]);

		const duplicatedParaphs = [];
		for (let i = 0; i < oldDocument.pageCount; i++) {
			if (blacklist.indexOf(i) >= 0) continue;

			duplicatedParaphs.push({
				pageIndex: i,
				relativeLocationX: paraphToDuplicate.relativeLocationX,
				relativeLocationY: paraphToDuplicate.relativeLocationY,
				relativeWidth: paraphToDuplicate.relativeWidth,
				relativeHeight: paraphToDuplicate.relativeHeight,
				style: paraphToDuplicate.style,
			})
		}

		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== selectedSigner.id ? search : ({
				...search,
				paraphFields: (search.paraphFields || [])
					.concat(duplicatedParaphs)
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onExtraSignFieldDropped = ({signer, pageIndex, rect, instance, placeholder}) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const field = (() => {
			const extraFields = oldDocument.signers
				.filter(search => search.id === signer.id)
				.flatMap(search => (search.extraSignatureFields || []));

			if (instance !== undefined && instance in extraFields) {
				return extraFields[instance];
			}

			return {
				relativeWidth: rect.hasOwnProperty('relativeWidth') ? rect.relativeWidth : SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH,
				relativeHeight: rect.hasOwnProperty('relativeHeight') ? rect.relativeHeight : SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT,
				style: {
					...rootDocument.defaultFieldStyle,
					...(!!placeholder && {backgroundOpacity: 1})
				}
			}
		})();

		const relativeLocationX = rect.hasOwnProperty('relativeLocationX') ?
			rect.relativeLocationX : Math.min(1, Math.max(0, rect.x / rect.width));

		const relativeLocationY = rect.hasOwnProperty('relativeLocationY') ?
			rect.relativeLocationY : Math.min(1, Math.max(0, rect.y / rect.height));

		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				extraSignatureFields: (search.extraSignatureFields || [])
					.filter((ign, index) => instance === undefined || instance !== index)
					.concat([{
						id: field.id,
						pageIndex,
						relativeLocationX,
						relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						style: field.style,
						...(!!placeholder && {placeholderId: placeholder.id})
					}])
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onExtraSignFieldResize = (signer, relativeWidth, relativeHeight, instance) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(oldSigner => oldSigner.id !== signer.id ? oldSigner : {
				...oldSigner,
				extraSignatureFields: (oldSigner.extraSignatureFields || [])
					.map((extraSignatureField, index) => index !== instance ? extraSignatureField : {
						...extraSignatureField,
						relativeWidth,
						relativeHeight,
					})
			})
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onExtraSignFieldRemove = (signer, instance) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				extraSignatureFields: (search.extraSignatureFields || [])
					.filter((ign, index) => instance === undefined || instance !== index)
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onFormFieldDropped = ({signer, pageIndex, rect, instance, type, placeholder}) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);

		const field = (() => {
			const fields = oldDocument.signers
				.filter((search, ignored) => search.id === signer.id)
				.flatMap(search => (search.formFields || []));

			if (instance !== undefined && instance in fields) {
				return fields[instance];
			}

			let index = 1;
			let name = 'Field ' + index;

			while (fields.find(field => field.name === name)) {
				index++;
				name = 'Field ' + index;
			}

			return {
				id: uuidv4(),
				name: name,
				type: type || 'TEXT',
				attributeType: 'FIRST_NAME',
				required: false,
				editable: true,
				pageIndex: 0,
				relativeLocationX: 0.0,
				relativeLocationY: 0.0,
				relativeWidth: rect.hasOwnProperty('relativeWidth') ? rect.relativeWidth : SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH,
				relativeHeight: rect.hasOwnProperty('relativeHeight') ? rect.relativeHeight : SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT,
				style: {
					...rootDocument.defaultFieldStyle,
					...(!!placeholder && {backgroundOpacity: 1})
				}
			}
		})();

		const relativeLocationX = rect.hasOwnProperty('relativeLocationX') ?
			rect.relativeLocationX : Math.min(1, Math.max(0, rect.x / rect.width));

		const relativeLocationY = rect.hasOwnProperty('relativeLocationY') ?
			rect.relativeLocationY : Math.min(1, Math.max(0, rect.y / rect.height));

		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(search => search.id !== signer.id ? search : ({
				...search,
				formFields: (search.formFields || [])
					.filter((ign, index) => instance === undefined || instance !== index)
					.concat([{
						id: field.id,
						name: field.name,
						type: field.type,
						attributeType: field.attributeType,
						required: field.required,
						editable: field.editable,
						pageIndex: pageIndex,
						relativeLocationX,
						relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						style: field.style,
						value: field.value,
						...(!!placeholder && {placeholderId: placeholder.id})
					}])
			}))
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onFormFieldResize = (signer, relativeWidth, relativeHeight, instance, field) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(signer => ({
				...signer,
				formFields: (signer.formFields || [])
					.map(formField => field.id === formField.id ? {
						...formField,
						relativeWidth,
						relativeHeight
					} : formField)
			}))
		}

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onFormFieldRemove = (field) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(signer => ({
				...signer,
				formFields: (signer.formFields || [])
					.filter((formField, ign) => field === undefined || field.id !== formField.id)
			}))
		}

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onFormFieldUpdate = (updatedField) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(signer => ({
				...signer,
				formFields: (signer.formFields || [])
					.map((field) => {
						if (updatedField.id === undefined || updatedField.id !== field.id) {
							return field;
						}
						return {
							...field,
							...updatedField,
						};
					})
			}))
		}

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onChangeSigningOrderType = (signingOrderType) => {
		const rootDocument = this.props.editorEditInfo.document;

		if ('KIOSK' === signingOrderType) {
			this.onChangeSigningOrderTypeKiosk(rootDocument);
		} else {
			const document = {
				...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
				signingOrderType
			};

			let newRootDocument;
			if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
				newRootDocument = COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE(rootDocument, document);
			} else if (rootDocument.documentCollection && rootDocument.signingOrderType === 'KIOSK') {
				// make sure no combination of kiosk and non-kiosk is configured on the collection
				newRootDocument = COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE(rootDocument, {
					...rootDocument,
					signingOrderType
				});
			} else {
				newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
			}

			this.props.onEditorUpdateDocument(newRootDocument);
		}
	}

	onChangeSigningOrderTypeKiosk = (rootDocument) => {
		const document = {
			...rootDocument,
			signingOrderType: 'KIOSK',
			signingOrderGroupSettings: [],
			approvers: rootDocument.approvers.filter(approver => approver.approvalRequestState !== 'NEW'),
			children: rootDocument.children.filter(child => child.childType !== 'MULTI_SIGN')
		};

		let newRootDocument;
		if (rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE_KIOSK(document);
		} else {
			newRootDocument = document;
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangeSigners = (signers, signingOrderGroupSettings) => {
		const rootDocument = this.props.editorEditInfo.document;
		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers,
			...(!!signingOrderGroupSettings && {signingOrderGroupSettings})
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onChangeSignersCustomEmailHeaders = (signersCustomEmailHeaders) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			signersCustomEmailHeaders
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_SIGNERS_CUSTOM_EMAIL_HEADERS(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onDistributeSignerToCollection = (signer) => {
		const rootDocument = this.props.editorEditInfo.document;
		const signers = [...rootDocument.signers];
		const index = signers.findIndex(search => search.id === signer.id)
		if (index >= 0) {
			signers[index] = structuredClone(signer);
		} else {
			signers.push(structuredClone(signer));
		}

		const document = {
			...rootDocument,
			signers: signers
				.map(newSigner => !!newSigner?.signatureField?.pageIndex ? {
					...newSigner,
					signatureField: {
						...newSigner.signatureField,
						pageIndex: Math.min(rootDocument.pageCount - 1, newSigner.signatureField.pageIndex)
					}
				} : newSigner)
				.map(newSigner => !!newSigner?.paraphFields ? {
					...newSigner,
					paraphFields: newSigner?.paraphFields.filter((field) => field.pageIndex < rootDocument.pageCount)
				} : newSigner)
				.map(newSigner => !!newSigner?.extraSignatureFields ? {
					...newSigner,
					extraSignatureFields: newSigner?.extraSignatureFields.filter((field) => field.pageIndex < rootDocument.pageCount)
				} : newSigner)
		};
		const newRootDocument = COLLECTION_DISTRIBUTE_SIGNERS(rootDocument, document);
		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onMultiSignSignatureFieldDropped = ({signer, pageIndex, rect}) => {
		const multiSignSignatureField = {
			pageIndex,
			relativeLocationX: Math.min(1, Math.max(0, rect.x / rect.width)),
			relativeLocationY: Math.min(1, Math.max(0, rect.y / rect.height)),
			relativeWidth: !!this.state.multiSignSignatureField ? this.state.multiSignSignatureField.relativeWidth : SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH,
			relativeHeight: !!this.state.multiSignSignatureField ? this.state.multiSignSignatureField.relativeHeight : SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT,
			style: !!this.state.multiSignSignatureField ? this.state.multiSignSignatureField.style : this.props.editorEditInfo.document.defaultFieldStyle,
		};

		this.setState({multiSignSignatureField}, () => {
			// update the field into existing signers as well
			const rootDocument = this.props.editorEditInfo.document;
			const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
			const newDocument = {
				...document,
				children: document.children.map(child => child.childType !== 'MULTI_SIGN' ? child : {
					...child,
					signers: child.signers.map(signer => ({
						...signer,
						signatureField: {...multiSignSignatureField},
					})),
				})
			};

			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignSignatureFieldResize = (signer, relativeWidth, relativeHeight) => {
		const multiSignSignatureField = {
			...this.state.multiSignSignatureField,
			relativeWidth,
			relativeHeight,
		};

		this.setState({multiSignSignatureField}, () => {
			// update the field into existing signers as well
			const rootDocument = this.props.editorEditInfo.document;
			const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
			const newDocument = {
				...document,
				children: document.children.map(child => child.childType !== 'MULTI_SIGN' ? child : {
					...child,
					signers: child.signers.map(signer => ({
						...signer,
						signatureField: {...multiSignSignatureField},
					})),
				})
			};

			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignParaphFieldDropped = ({signer, pageIndex, rect, instance}) => {
		const field = (() => {
			const multiSignParaphFields = this.state.multiSignParaphFields;
			if (instance !== undefined && instance in multiSignParaphFields) {
				return multiSignParaphFields[instance];
			}
			return {
				relativeWidth: PARAPH_FIELD_DEFAULT_RELATIVE_WIDTH,
				relativeHeight: PARAPH_FIELD_DEFAULT_RELATIVE_HEIGHT,
				style: this.props.editorEditInfo.document.defaultFieldStyle,
			}
		})();

		const multiSignParaphFields = this.state.multiSignParaphFields
			.filter((ign, index) => instance === undefined || instance !== index)
			.concat([{
				...field,
				pageIndex,
				relativeLocationX: Math.min(1, Math.max(0, rect.x / rect.width)),
				relativeLocationY: Math.min(1, Math.max(0, rect.y / rect.height)),
				style: field.style,
			}])

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS(document, multiSignParaphFields);

		this.setState({multiSignParaphFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignParaphFieldResize = (signer, relativeWidth, relativeHeight, instance) => {
		const multiSignParaphFields = this.state.multiSignParaphFields
			.map((multiSignParaphField, index) => instance !== index ? multiSignParaphField :
				{
					...multiSignParaphField,
					relativeWidth,
					relativeHeight,
				}
			);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS(document, multiSignParaphFields);

		this.setState({multiSignParaphFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignParaphFieldRemove = (ign, instance) => {
		const multiSignParaphFields = this.state.multiSignParaphFields
			.filter((ign, index) => instance === undefined || instance !== index);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS(document, multiSignParaphFields);

		this.setState({multiSignParaphFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignParaphFieldRemoveAll = (ign) => {
		const multiSignParaphFields = [];

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS(document, multiSignParaphFields);

		this.setState({multiSignParaphFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignExtraSignFieldDropped = ({pageIndex, rect, instance}) => {
		const field = (() => {
			const multiSignExtraSignatureFields = this.state.multiSignExtraSignatureFields;
			if (instance !== undefined && instance in multiSignExtraSignatureFields) {
				return multiSignExtraSignatureFields[instance];
			}
			return {
				relativeWidth: SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH,
				relativeHeight: SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT,
				style: this.props.editorEditInfo.document.defaultFieldStyle,
			}
		})();

		const multiSignExtraSignatureFields = this.state.multiSignExtraSignatureFields
			.filter((ign, index) => instance === undefined || instance !== index)
			.concat([{
				...field,
				pageIndex: pageIndex,
				relativeLocationX: Math.min(1, Math.max(0, rect.x / rect.width)),
				relativeLocationY: Math.min(1, Math.max(0, rect.y / rect.height)),
				style: field.style,
			}])

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_EXTRA_SIGNATURE_FIELDS(document, multiSignExtraSignatureFields);

		this.setState({multiSignExtraSignatureFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignExtraSignFieldResize = (signer, relativeWidth, relativeHeight, instance) => {
		const multiSignExtraSignatureFields = this.state.multiSignExtraSignatureFields
			.map((multiSignExtraSignField, index) => instance !== index ? multiSignExtraSignField :
				{
					...multiSignExtraSignField,
					relativeWidth,
					relativeHeight,
				}
			);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_EXTRA_SIGNATURE_FIELDS(document, multiSignExtraSignatureFields);

		this.setState({multiSignExtraSignatureFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignExtraSignFieldRemove = (ign, instance) => {
		const multiSignExtraSignatureFields = this.state.multiSignExtraSignatureFields
			.filter((ign, index) => instance === undefined || instance !== index);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_EXTRA_SIGNATURE_FIELDS(document, multiSignExtraSignatureFields);

		this.setState({multiSignExtraSignatureFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignParaphFieldDuplicate = (ign, instance) => {
		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);

		const paraphToDuplicate = this.state.multiSignParaphFields.find((ign, index) => instance === index);
		const blacklist = (this.state.multiSignExtraSignatureFields || [])
			.concat(!!this.state.multiSignSignatureField ? [this.state.multiSignSignatureField] : [])
			.map((field) => field.pageIndex)
			.concat([paraphToDuplicate.pageIndex]);

		const duplicatedParaphs = [];
		for (let i = 0; i < document.pageCount; i++) {
			if (blacklist.indexOf(i) >= 0) continue;

			duplicatedParaphs.push({
				pageIndex: i,
				relativeLocationX: paraphToDuplicate.relativeLocationX,
				relativeLocationY: paraphToDuplicate.relativeLocationY,
				relativeWidth: paraphToDuplicate.relativeWidth,
				relativeHeight: paraphToDuplicate.relativeHeight,
				style: paraphToDuplicate.style,
			})
		}

		const multiSignParaphFields = this.state.multiSignParaphFields.concat(duplicatedParaphs);
		const newDocument = MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS(document, multiSignParaphFields);

		this.setState({multiSignParaphFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignFormFieldDropped = ({pageIndex, rect, instance}) => {
		const field = (() => {
			const fields = this.state.multiSignFormFields;
			if (instance !== undefined && instance in fields) {
				return fields[instance];
			}

			let index = 1;
			let name = 'Field ' + index;
			while (fields.find(field => field.name === name)) {
				index++;
				name = 'Field ' + index;
			}

			return {
				id: uuidv4(),
				name: name,
				type: 'TEXT',
				required: false,
				editable: true,
				relativeWidth: SIGNATURE_FIELD_DEFAULT_RELATIVE_WIDTH,
				relativeHeight: SIGNATURE_FIELD_DEFAULT_RELATIVE_HEIGHT,
				style: {
					...this.props.editorEditInfo.document.defaultFieldStyle,
					fontSize: 9.0,
				},
			}
		})();

		field.pageIndex = pageIndex;
		field.relativeLocationX = Math.min(1, Math.max(0, rect.x / rect.width));
		field.relativeLocationY = Math.min(1, Math.max(0, rect.y / rect.height));

		const multiSignFormFields = this.state.multiSignFormFields
			.filter((ign, index) => instance === undefined || instance !== index)
			.concat([field]);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_FORM_FIELDS(document, multiSignFormFields);

		this.setState({multiSignFormFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignFormFieldRemove = (field) => {
		const multiSignFormFields = this.state.multiSignFormFields
			.filter(search => search.id !== field.id)

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_FORM_FIELDS(document, multiSignFormFields);

		this.setState({multiSignFormFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignFormFieldResize = (signer, relativeWidth, relativeHeight, instance, field) => {
		const multiSignFormFields = this.state.multiSignFormFields
			.map(formField => field.id === formField.id ? {
				...formField,
				relativeWidth,
				relativeHeight
			} : formField)

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_FORM_FIELDS(document, multiSignFormFields);

		this.setState({multiSignFormFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onMultiSignFormFieldUpdate = (updatedField) => {
		const multiSignFormFields = this.state.multiSignFormFields
			.map((field) => {
				if (updatedField.id === undefined || updatedField.id !== field.id) {
					return field;
				}
				return {
					...field,
					...updatedField,
				};
			})

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_FORM_FIELDS(document, multiSignFormFields);

		this.setState({multiSignFormFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onChangeMultiSignSignatureTypes = (multiSignSignatureTypes) => {
		this.setState({multiSignSignatureTypes});
	}

	onChangeMultiSigners = (signers) => {
		const rootDocument = this.props.editorEditInfo.document;
		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			children: signers.map(signer => ({
				childType: 'MULTI_SIGN',
				state: signer.signRequestState === "WAITING_FOR_SIGNATURE" ? 'WAITING_FOR_SIGNATURES' : 'FUTURE',
				signers: [{
					...signer,
					signatureTypes: signer.signatureTypes || [...this.state.multiSignSignatureTypes],
					signatureField: {...this.state.multiSignSignatureField},
					paraphFields: [...this.state.multiSignParaphFields],
					extraSignatureFields: [...this.state.multiSignExtraSignatureFields],
					formFields: [...this.state.multiSignFormFields]
				}],
				signersCustomEmailHeaders: {
					...this.state.multiSignCustomEmailHeaders
				}
			}))
		};

		this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document));
	}

	onOpenFormFieldSettingsDialog = (field) => {
		this.setState({formFieldSettingsDialogOpen: true, formFieldToEdit: field});
	}

	onOpenFormFieldSettingsMultiSignDialog = (field) => {
		this.setState({formFieldSettingsMultiSignDialogOpen: true, formFieldToEdit: field});
	}

	onCloseFormFieldSettingsDialog = () => {
		this.setState({
			formFieldSettingsDialogOpen: false,
			formFieldSettingsMultiSignDialogOpen: false,
			formFieldToEdit: null
		});
	}

	onConfirmFormFieldSettingsDialog = (field) => {
		const multiSign = this.state.formFieldSettingsMultiSignDialogOpen;
		this.setState({
			formFieldSettingsDialogOpen: false,
			formFieldSettingsMultiSignDialogOpen: false,
			formFieldToEdit: null
		}, () => {
			if (multiSign) {
				this.onMultiSignFormFieldUpdate(field);
			} else {
				this.onFormFieldUpdate(field);
			}
		});
	}

	onOpenFieldStyleDialog = (editInfo) => {
		this.setState({fieldStyleDialogOpen: true, fieldStyleEditInfo: editInfo});
	}

	onCloseFieldStyleDialog = () => {
		this.setState({
			fieldStyleDialogOpen: false,
			fieldStyleEditInfo: null,
		});
	}

	onConfirmFieldStyleDialog = (style) => {
		const fieldType = this.state.fieldStyleEditInfo.fieldType;
		const signerId = this.state.fieldStyleEditInfo.signerId;
		const instance = this.state.fieldStyleEditInfo.instance;
		this.setState({
			fieldStyleDialogOpen: false,
			fieldStyleEditInfo: null,
		}, () => {
			switch (fieldType) {
				case 'SIGNATURE':
					this.onChangeSignatureStyle(signerId, style);
					break;
				case 'EXTRA_SIGNATURE':
					this.onChangeExtraSignatureStyle(signerId, instance, style);
					break;
				case 'PARAPH':
					this.onChangeParaphStyle(signerId, instance, style);
					break;
				case 'MULTI_SIGNATURE':
					this.onChangeMultiSignSignatureStyle(style);
					break;
				case 'MULTI_EXTRA_SIGNATURE':
					this.onChangeMultiSignExtraSignatureStyle(instance, style);
					break;
				case 'MULTI_PARAPH':
					this.onChangeMultiSignParaphStyle(instance, style);
					break;
			}
		})
	}

	onChangeSignatureStyle = (signerId, style) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(oldSigner => oldSigner.id !== signerId ? oldSigner : {
				...oldSigner,
				signatureField: {
					...oldSigner.signatureField,
					style,
				}
			})
		}

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onChangeExtraSignatureStyle = (signerId, instance, style) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(oldSigner => oldSigner.id !== signerId ? oldSigner : {
				...oldSigner,
				extraSignatureFields: (oldSigner.extraSignatureFields || [])
					.map((extraSignatureField, index) => index !== instance ? extraSignatureField : {
						...extraSignatureField,
						style,
					})
			})
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onChangeParaphStyle = (signerId, instance, style) => {
		const rootDocument = this.props.editorEditInfo.document;

		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			signers: oldDocument.signers.map(oldSigner => oldSigner.id !== signerId ? oldSigner : {
				...oldSigner,
				paraphFields: (oldSigner.paraphFields || [])
					.map((paraphField, index) => index !== instance ? paraphField : {
						...paraphField,
						style,
					})
			})
		};

		this.mergeDocumentWithRootAndUpdate(rootDocument, document);
	}

	onChangeMultiSignSignatureStyle = (style) => {
		const multiSignSignatureField = {
			...this.state.multiSignSignatureField,
			style,
		};

		this.setState({multiSignSignatureField}, () => {
			// update the field into existing signers as well
			const rootDocument = this.props.editorEditInfo.document;
			const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
			const newDocument = {
				...document,
				children: document.children.map(child => child.childType !== 'MULTI_SIGN' ? child : {
					...child,
					signers: child.signers.map(signer => ({
						...signer,
						signatureField: {...multiSignSignatureField},
					})),
				})
			};

			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onChangeMultiSignExtraSignatureStyle = (instance, style) => {
		const multiSignExtraSignatureFields = this.state.multiSignExtraSignatureFields
			.map((multiSignExtraSignField, index) => instance !== index ? multiSignExtraSignField :
				{
					...multiSignExtraSignField,
					style,
				}
			);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_EXTRA_SIGNATURE_FIELDS(document, multiSignExtraSignatureFields);

		this.setState({multiSignExtraSignatureFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onChangeMultiSignParaphStyle = (instance, style) => {
		const multiSignParaphFields = this.state.multiSignParaphFields
			.map((multiSignParaphField, index) => instance !== index ? multiSignParaphField :
				{
					...multiSignParaphField,
					style,
				}
			);

		const rootDocument = this.props.editorEditInfo.document;
		const document = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const newDocument = MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS(document, multiSignParaphFields);

		this.setState({multiSignParaphFields}, () => {
			this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, newDocument));
		});
	}

	onChangeMultiSignCustomEmailHeaders = (signersCustomEmailHeaders) => {
		const rootDocument = this.props.editorEditInfo.document;
		const oldDocument = SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId);
		const document = {
			...oldDocument,
			children: oldDocument.children.map(child => ({
				...child,
				signersCustomEmailHeaders
			}))
		};
		this.props.onEditorUpdateDocument(MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document));

		this.setState({multiSignCustomEmailHeaders: signersCustomEmailHeaders});
	}

	onChangePostSignDocumentEmailDistributionList = (postSignDocumentEmailDistributionList) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			postSignDocumentEmailDistributionList
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_EMAIL_DISTRIBUTION_LIST(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	onChangePostSignDocumentAndEvidenceReportEmailDistributionList = (postSignDocumentAndEvidenceReportEmailDistributionList) => {
		const rootDocument = this.props.editorEditInfo.document;

		const document = {
			...SELECT_DOCUMENT(rootDocument, this.state.selectedChildDocumentId),
			postSignDocumentAndEvidenceReportEmailDistributionList
		};

		let newRootDocument;
		if (this.state.selectedChildDocumentId === 0 && rootDocument.documentCollection) {
			newRootDocument = COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_AND_EVIDENCE_REPORT_EMAIL_DISTRIBUTION_LIST(rootDocument, document);
		} else {
			newRootDocument = MERGE_DOCUMENT(rootDocument, this.state.selectedChildDocumentId, document);
		}

		this.props.onEditorUpdateDocument(newRootDocument);
	}

	selectDocument() {
		return SELECT_DOCUMENT(this.props.editorEditInfo.document, this.state.selectedChildDocumentId);
	}

	onDocumentSendReminders = () => {
		this.props.onDocumentSendReminders(this.selectDocument().id);
	}

	onDocumentDownloadDocument = () => {
		this.props.onDocumentSingleDownloadArtifacts(this.selectDocument().id, ['DOCUMENT', 'ATTACHMENT', 'DECLINE_ATTACHMENT', 'PREVIOUS_VERSIONS']);
	}

	onDocumentDownloadEvidenceReport = () => {
		this.props.onDocumentSingleDownloadArtifacts(this.selectDocument().id, ['EVIDENCE_REPORT']);
	}

	onDocumentDownloadAttachment = () => {
		this.props.onDocumentSingleDownloadArtifacts(this.selectDocument().id, ['ATTACHMENT', 'PREVIOUS_VERSIONS']);
	}

	onDocumentDownloadDeclineAttachment = () => {
		this.props.onDocumentSingleDownloadArtifacts(this.selectDocument().id, ['DECLINE_ATTACHMENT']);
	}

	isTemplateMode = () => {
		return this.props.mode === 'TEMPLATE';
	}

	onFieldPlaceholderClicked = (placeholder) => {
		if ('SIGNERS' === this.state.tab) {
			this.setState({
				selectedFieldPlaceholder: placeholder
			});
		}
	}

	onAddCollectionDocumentChild = (addedChild) => {
		const rootDocument = this.props.editorEditInfo.document;

		// apply the parent's current settings to the new child
		const updatedChild = {
			...addedChild,
			folderId: rootDocument.folderId,
			overrideReasonSettings: rootDocument.overrideReasonSettings,
			reasonAvailable: rootDocument.reasonAvailable,
			reasonLegalNoticeMandatory: rootDocument.reasonLegalNoticeMandatory,
			reasonLegalNoticeText: rootDocument.reasonLegalNoticeText,
			approveOrderType: rootDocument.approveOrderType,
			approvers: rootDocument.approvers,
			...(!!rootDocument.minimalNumberOfApprovalDecisions && {minimalNumberOfApprovalDecisions: rootDocument.minimalNumberOfApprovalDecisions}),
			...(!!rootDocument.minimalNumberOfApprovals && {minimalNumberOfApprovals: rootDocument.minimalNumberOfApprovals}),
			approversCustomEmailHeaders: rootDocument.approversCustomEmailHeaders,
			signingOrderType: rootDocument.signingOrderType,
			signers: rootDocument.signers,
			signingOrderGroupSettings: rootDocument.signingOrderGroupSettings,
			signersCustomEmailHeaders: rootDocument.signersCustomEmailHeaders,
			postSignDocumentEmailDistributionList: rootDocument.postSignDocumentEmailDistributionList,
			postSignDocumentAndEvidenceReportEmailDistributionList: rootDocument.postSignDocumentAndEvidenceReportEmailDistributionList,
		}

		const updatedRootDocument = {
			...rootDocument,
			children: rootDocument.children.map(child => child.id === addedChild.id ? updatedChild : child)
		}

		this.props.onEditorUpdateDocument(updatedRootDocument);
	}

	onSelectApplyTemplate = (documentId) => {
		this.setState({applyTemplateDocumentId: documentId, applyTemplateDialogOpen: true})
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			editorBusy: state.editor.busy,
			editorServerError: state.editor.serverError,
			editorPendingChanges: state.editor.pendingChanges,
			editorEditInfo: state.editor.editInfo,

			documentReminderSentResult: state.document.reminderSentResult,
		}
	},
	dispatch => {
		return {
			onEditorFetchEditInfo: (id, template) => {
				dispatch({
					type: 'EDITOR_FETCH_EDIT_INFO',
					id, template
				});
			},
			onEditorUpdateDocument: (document) => {
				dispatch({
					type: 'EDITOR_UPDATE_DOCUMENT',
					document
				});
			},
			onEditorSaveDocument: (document) => {
				dispatch({
					type: 'EDITOR_SAVE_DOCUMENT',
					document
				});
			},
			onEditorSignDocument: (document) => {
				dispatch({
					type: 'EDITOR_SIGN_DOCUMENT',
					document,
				});
			},
			onEditorSendDocument: (document, selfSign) => {
				dispatch({
					type: 'EDITOR_SEND_DOCUMENT',
					document,
					selfSign: selfSign
				});
			},
			onEditorCloseDocument: (documentId) => {
				dispatch({
					type: 'EDITOR_CLOSE_DOCUMENT',
					documentId
				});
			},
			onEditorDeleteDocument: (documentId) => {
				dispatch({
					type: 'EDITOR_DELETE_DOCUMENT',
					documentId
				});
			},
			onEditorDeleteTemplate: (documentId) => {
				dispatch({
					type: 'EDITOR_DELETE_TEMPLATE',
					documentId
				});
			},
			onEditorRestartDocument: (documentId) => {
				dispatch({
					type: 'EDITOR_RESTART_DOCUMENT',
					documentId
				});
			},
			onEditorSaveTemplate: (request) => {
				dispatch({
					type: 'EDITOR_SAVE_TEMPLATE',
					request
				});
			},
			onDocumentSendReminders: (id) => {
				dispatch({
					type: 'DOCUMENT_SEND_REMINDERS',
					id
				});
			},
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types,
					editorOrigin: true
				});
			},
			onMenuClose: () => {
				dispatch({
					type: 'MENU_CLOSE'
				})
			},
			onSnackbarOpen: (message) => {
				dispatch({
					type: 'SNACKBAR_OPEN',
					message
				})
			},
		}
	}
)(EditorComponent)));
