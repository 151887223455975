import {TextField} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";

export default (props) => {

	const format = props.format;
	const placeholder = useMemo(() => (format || '').replace(/#/g, '_'), [format]);

	const [value, setValue] = useState(props.value || '');

	const onLocalChange = useCallback((e) => {
		const newValue = e?.target?.value || '';

		let buildValue = value;
		if (!!format) {
			if (newValue.length > value.length) {
				// keep track of the index in the value & in the format
				let valueIndex = value.length;
				let formatIndex = value.length;

				while (valueIndex < newValue.length && formatIndex < format.length) {
					const valueChar = newValue.charAt(valueIndex)
					let formatChar = format.charAt(formatIndex);

					switch (formatChar) {
						case '#':
							if (valueChar >= '0' && valueChar <= '9') {
								buildValue += valueChar;
								formatIndex++;
							}
							valueIndex++;
							break;

						default:
							if (valueChar === formatChar) {
								buildValue += valueChar;
								valueIndex++;
								formatIndex++;
							} else {
								// so it is possible we are in this situation
								// format ddd|.ddddd-ddd
								// newValue = 1
								// advance format until we get non 'default' branch char
								if ('#'.indexOf(formatChar) == -1) {
									while ('#'.indexOf(formatChar) == -1 && formatIndex < format.length) {
										buildValue += formatChar;
										formatIndex++;
										formatChar = format.charAt(formatIndex);
									}
								} else {
									// no ignore value char
									valueIndex++;
								}
							}
							break;
					}
				}
			} else {
				buildValue = value.substring(0, newValue.length);
			}
		} else if ('number' === props?.type) {
			buildValue = newValue.replaceAll(/[-|+|e]/g, '');
		} else {
			buildValue = newValue;
		}
		setValue(buildValue);
		props.onChange({target: {value: buildValue}}, buildValue);
	}, [value, format, props.onChange]);

	useEffect(() => {
		if (props.value !== value) {
			onLocalChange({target: {value: props.value}});
		}
	}, [props.value, value]);

	return <TextField {...props} placeholder={placeholder} value={value} onChange={onLocalChange} onFocus={props.onFocus} inputProps={props.inputProps}/>;
};
